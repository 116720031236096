import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/images/auth-bg-1.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  auth: {
    margin: theme.spacing(0, 6),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 10),
    },
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& form': {
      width: '100%',
    },
  },
  logo: {
    width: '100%',
    maxWidth: '460px',
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
