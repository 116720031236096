import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  tableContainerLast: {
    borderBottomRightRadius: '16px',
    borderBottomLeftRadius: '16px',
    borderBottom: '1px solid rgba(158,158,158,0.22)',
  },
  table: {
    overflow: 'hidden',
    //borderLeft: '1px solid rgba(158,158,158,0.22)',
    //borderRight: '1px solid rgba(158,158,158,0.22)',
    '& tr > td': {
      borderColor: theme.custom.bg,
      borderWidth: '6px',

      [theme.breakpoints.down('xs')]: {
        padding: '16px',
        border: 0,
      },
    },
    '& tr:last-of-type > td': {
      borderBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.custom.bg,
      border: 0,
      overflow: 'unset',
    },
  },
  small: {
    width: '24px',
    height: '24px',
  },
  large: {
    width: '86px',
    height: '86px',
    borderRadius: 0,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.custom.photoBorderHover}`,
      [theme.breakpoints.down('xs')]: {
        border: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      height: '264px',
      width: '100%',
      padding: 0,
    },
  },
  largeDisabled: {
    cursor: 'not-allowed',
  },
  tableRow: {
    '& > td': {
      paddingTop: '6px',
      paddingBottom: '6px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 'unset',
      },
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '20px',
      marginBottom: '20px',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
  },
  tableRowHighlight: {
    '& > td': {
      paddingTop: '2px',
      paddingBottom: '2px',
    },
  },
  seeMore: {
    fontWeight: 400,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  siteName: {
    lineHeight: 1.1,
  },
  category: {
    padding: '16px 26px',
    cursor: 'pointer',
    fontWeight: 100,
    backgroundColor: theme.custom.issueboxHeadingBackground,
    borderBottom: 0,
    fontSize: '0.92rem',
  },
  userBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& p': {
      lineHeight: 0.75,
      paddingTop: '8px',
    },
  },
  photoCell: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  photoBadge: {
    backgroundColor: theme.palette.primary.darkest,
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    color: theme.custom.lightestgrey,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontWeight: 700,
    marginLeft: '-12px',
    zIndex: 2,
    fontSize: '0.8rem',
    lineHeight: 1,
    border: `2px solid ${theme.palette.background.paper}`,
    position: 'absolute',
    bottom: '-4px',
    right: '-10px',
    [theme.breakpoints.down('xs')]: {
      right: '14px',
      bottom: '-12px',
      width: 'unset',
      minWidth: '60px',
      fontSize: '0.9rem',
      borderRadius: '20px',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  photoIcon: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '0.75em',
      height: '0.75em',
      marginLeft: '3px',
    },
  },
  expand: {
    padding: '0 4px',
  },
  hide: {
    color: 'transparent!important',
  },
  firstCol: {
    [theme.breakpoints.down('xs')]: {
      padding: '12px',
      order: 0,
      backgroundColor: theme.palette.primary.darkest,
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
  },
  checkboxCol: {
    padding: 0,
    margin: 0,
    '& span': {
      padding: '0 0 0 6px',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  severityCol: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  secondCol: {
    [theme.breakpoints.down('xs')]: {
      order: 2,
      paddingTop: '12px',
      paddingBottom: '12px',
    },
  },
  thirdCol: {
    paddingTop: '0!important',
    paddingBottom: '0!important',
    height: '92px',
    [theme.breakpoints.down('xs')]: {
      padding: '0!important',
      order: 1,
      height: 'unset',
    },
  },
  fourthCol: {
    '& button': {
      padding: '8px',
    },
    [theme.breakpoints.down('xs')]: {
      order: 3,
      borderTop: '1px solid rgba(158,158,158,0.22)!important',
    },
  },
  resolved: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    height: '26px',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontWeight: 700,
    '& svg': {
      color: '#fff!important',
    },
  },
  severityWrapper: {
    marginTop: '4px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  head: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    backgroundColor: theme.custom.lightestgrey,
    '& tr > th': {
      lineHeight: '1rem',
      padding: '8px 16px',
    },
  },
  colGroup: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hidden: {
    display: 'none',
  },
  cardHeader: {
    color: theme.palette.primary.contrastText,
    '& div, span': {
      color: theme.custom.lightergrey,
      '&:first-of-type': {
        fontWeight: 700,
      },
    },
  },
  firstColTable: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  firstColCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  firstColCardText: {
    color: 'white',
    paddingLeft: '16px',
  },
  firstColCardHeader: {
    display: 'flex',
  },
  calContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '46px',
    height: '46px',
  },
  calMonth: {
    borderBottom: `1px solid ${theme.palette.primary.darkest}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '900',
    fontSize: '0.8rem',
    backgroundColor: '#ffffff63',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    color: '#fffffff2',
    height: '18px',
    alignItems: 'center',
    letterSpacing: '0.5px',
  },
  calDay: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.25rem',
    fontWeight: '700',
    backgroundColor: '#ffffff3d',
    height: '28px',
    alignItems: 'center',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    color: '#fffffff2',
  },
}));

export default useStyles;
