import React, { useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Components
import AlertBar from './components/alert-bar/alert-bar.component';
import Spinner from './components/spinner/spinner.component';
import PrivateRoute from './components/private-route/private-route.component';

// Pages
import SignIn from './pages/sign-in/sign-in.component';
import ForgotPassword from './pages/forgot-password/forgot-password.component';
import ForgotPasswordSuccess from './pages/forgot-password-success/forgot-password-success.component';
import SignUp from './pages/sign-up/sign-up.component';
import ResetPassword from './pages/reset-password/reset-password.component';
import Dashboard from './pages/dashboard/dashboard.component';
import UpdateProject from './pages/update-project/update-project.component';
import CreateProject from './pages/create-project/create-project.component';
import Projects from './pages/projects/projects.component';
import Milestones from './pages/milestones/milestones.component';
import Submissions from './pages/submissions/submissions.component';
import SubmissionSingle from './pages/submission-single/submission-single.component';
import SubmissionShare from './pages/submission-share/submission-share.component';
import Issues from './pages/issues/issues.component';
import IssueSingle from './pages/issue-single/issue-single.component';
import IssueShare from './pages/issue-share/issue-share.component';
import Profile from './pages/profile/profile.component';
import Team from './pages/team/team.component';
import RecentlyDeleted from './pages/recently-deleted/recently-deleted.component';
import NotFound from './pages/not-found/not-found.component';
import Feed from './pages/feed/feed.component';

import CodeEntry from './pages/code-entry/code-entry.component';
import CodeUser from './pages/code-user/code-user.component';

// Actions
import { checkUserSession } from './redux/user/user.actions';

// Selectors
import { selectCurrentUser } from './redux/user/user.selectors';

function App({ checkUserSessionDispatch, currentUser }) {
  useEffect(() => {
    checkUserSessionDispatch();
  }, [checkUserSessionDispatch]);

  return (
    <>
      <AlertBar />
      <Suspense fallback={<Spinner />}>
        <Switch>
          {/*<Route exact path='/' component={Home} />*/}
          <Route
            exact
            path='/'
            render={() =>
              currentUser ? (
                currentUser.defaultProject ? (
                  <Redirect to={`/projects/${currentUser.defaultProject}`} />
                ) : (
                  <Redirect to='/projects/' />
                )
              ) : (
                <SignIn />
              )
            }
          />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route
            exact
            path='/forgot-password-success'
            component={ForgotPasswordSuccess}
          />
          <Route
            exact
            path='/signin'
            render={() =>
              currentUser ? (
                currentUser.defaultProject ? (
                  <Redirect to={`/projects/${currentUser.defaultProject}`} />
                ) : (
                  <Redirect to='/projects/' />
                )
              ) : (
                <SignIn />
              )
            }
          />
          <Route
            exact
            path='/forgot-password/:resetToken'
            render={(props) =>
              currentUser ? <Redirect to='/' /> : <ResetPassword {...props} />
            }
          />
          <PrivateRoute exact path='/projects' component={Projects} />
          <PrivateRoute
            exact
            path='/create-project'
            component={CreateProject}
          />
          <PrivateRoute
            exact
            path='/projects/:projectId'
            component={Dashboard}
          />
          {/* <PrivateRoute
            exact
            path='/projects/:projectId/feed'
            component={Feed}
          /> */}
          <PrivateRoute
            exact
            path='/projects/:projectId/update'
            component={UpdateProject}
          />
          <PrivateRoute
            exact
            path='/projects/:projectId/milestones'
            component={Milestones}
          />
          <PrivateRoute
            exact
            path='/projects/:projectId/profile'
            component={Profile}
          />
          <PrivateRoute
            exact
            path='/projects/:projectId/team'
            component={Team}
          />
          <PrivateRoute
            exact
            path='/projects/:projectId/issues'
            component={Issues}
          />
          <PrivateRoute
            exact
            path='/projects/:projectId/issues/:docId'
            component={IssueSingle}
          />
          <PrivateRoute
            exact
            path='/projects/:projectId/submissions'
            component={Submissions}
          />
          <PrivateRoute
            exact
            path='/projects/:projectId/submissions/:docId'
            component={SubmissionSingle}
          />
          <PrivateRoute
            exact
            path='/projects/:projectId/trash'
            component={RecentlyDeleted}
          />
          <Route
            exact
            path='/share/submission/:shareId'
            component={SubmissionShare}
          />
          <Route exact path='/share/issue/:shareId' component={IssueShare} />
          <Route exact path='/code' component={CodeEntry} />
          <Route exact path='/code-user' component={CodeUser} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
}

// PropTypes
App.propTypes = {
  checkUserSessionDispatch: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
};

App.defaultProps = {
  currentUser: null,
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  checkUserSessionDispatch: () => dispatch(checkUserSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
