import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '12px',
    border: `1px solid ${theme.custom.lightgrey}`,
    boxShadow: 'none',
    margin: '12px 24px 12px 24px',
  },
  rootFullWidth: {
    width: '100%',
    marginTop: '12px',
    border: `1px solid ${theme.custom.lightgrey}`,
    boxShadow: 'none',
    margin: 0,
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  cardHeader: {
    paddingBottom: '16px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    cursor: 'pointer',
  },
  withPhoto: {
    paddingTop: '16px!important',
  },
  editIcons: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    fontSize: '1rem',
    transform: 'translate(2px,2px)',
  },
  actionType: {
    backgroundColor: theme.custom.issueUncatBg,
    fontSize: '0.65rem',
    textAlign: 'center',
    fontWeight: 700,
    borderRadius: '8px',
  },
  actionTypeFieldSub: {
    backgroundColor: theme.palette.primary.main,
  },
  actionTypeResolve: {
    backgroundColor: theme.palette.success.main,
  },
  actionTypeReopened: {
    backgroundColor: theme.palette.success.light,
  },
  actionTypeDismiss: {
    backgroundColor: theme.palette.success.main,
  },
  actionTypeDeleted: {
    backgroundColor: theme.palette.secondary.main,
  },
  actionTypeEdited: {
    backgroundColor: theme.custom.mediumpink,
  },
  actionTypeRestored: {
    backgroundColor: theme.palette.secondary.main,
  },
  actionTypeUpdated: {
    backgroundColor: theme.custom.mediumpurple,
  },
  actionTypeShareLinkComment: {
    backgroundColor: theme.custom.ganttGreyText,
  },
  actionTypeShared: {
    backgroundColor: theme.custom.slateBlue,
  },
  actionTypeReCat0: {
    backgroundColor: theme.custom.issueUncatBg,
  },
  actionTypeReCat1: {
    backgroundColor: theme.custom.issueLowBg,
  },
  actionTypeReCat2: {
    backgroundColor: theme.custom.issueMediumBg,
  },
  actionTypeReCat3: {
    backgroundColor: theme.custom.issueHighBg,
  },
  actionTypeReCat4: {
    backgroundColor: theme.custom.issueOffTrackBg,
  },
  userAvatar: {
    width: '48px',
    height: '48px',
    marginRight: '8px',
    marginTop: '6px',
  },
  textContent: {
    paddingBottom: '16px',
    paddingTop: '0px',
    '& span': {
      lineHeight: '1.1',
      fontSize: '0.9rem',
    },
  },
  subheader: {
    lineHeight: '1rem',
    paddingBottom: '4px',
  },
  listItem: {
    borderTop: `1px solid ${theme.custom.lightergrey}`,
  },
  photoBadgeContainer: {
    position: 'relative',
    height: '32px',
    top: '-16px',
    marginBottom: '-32px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  elevatedPhotoBadge: {
    top: '-38px',
  },
  photoBadgeLarge: {
    color: '#eee',
    width: '90px',
    border: '2px solid #fff',
    height: '30px',
    display: 'flex',
    zIndex: '2',
    fontSize: '0.9rem',
    alignItems: 'center',
    fontWeight: '400',
    lineHeight: '1.1',
    marginRight: '18px',
    borderRadius: '20px',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.darkest,
  },
  highlighted: {
    backgroundColor: 'inherit',
    boxShadow: 'unset',
    animation: '$glow 0.7s 10 alternate',
  },
  '@keyframes glow': {
    from: {
      boxShadow: `0 0 3px 3px ${theme.custom.highlighter}`,
      backgroundColor: theme.custom.highlighterAlpha,
    },
    to: {
      boxShadow: `0 0 0px 0px ${theme.custom.highlighter}`,
      backgroundColor: theme.custom.highlighterAlpha,
    },
  },
}));

export default useStyles;
