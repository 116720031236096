import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  issueboxPopup: {
    '& .MuiPaper-rounded': {
      minWidth: '212px',
      marginLeft: '9px',
      borderRadius: 0,
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  issueboxPopupItems: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  formControl: {
    width: '100%',
    padding: '0px 16px 32px 16px',
  },
  numHidden: {
    paddingBottom: '8px',
  },
}));

export default useStyles;
