import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import RoomIcon from '@material-ui/icons/Room';

// Components
import MainContainer from '../../components/main-container/main-container.component';
import HeaderGeneral from '../../components/header-general/header-general.component';
import TableHeader from '../../components/table-header/table-header.component';
import MilestoneTable from '../../components/milestone-table/milestone-table.component';
import NotificationMenu from '../../components/notification-menu/notification-menu.component';
import ActionMenu from '../../components/action-menu/action-menu.component';
import RefreshButton from '../../components/refresh-button/refresh-button.component';

// Styles
import useStyles from './milestones.styles';

// Selector
import { selectProjectMilestoneDetails } from '../../redux/milestone/milestone.selectors';
import { selectCurrentUserId } from '../../redux/user/user.selectors';

// Actions
import { toggleModal, getDashboardStart } from '../../redux/ui/ui.actions';

// Utilities
import { sortAlpha } from '../../utils/utilityFns';
import { parseQueryParams } from '../../utils/apiFeatures';

// Custom Hooks
import useQuery from '../../hooks/useQuery';

const Milestones = ({
  milestones,
  toggleModalDispatch,
  userId,
  getDashboardStartDispatch,
}) => {
  const { projectId } = useParams();
  const classes = useStyles();
  const query = useQuery();

  const defaultQueryObj = {
    limitObj: parseQueryParams(query),
  };

  const [queryObj, setQueryObj] = useState(defaultQueryObj);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedStatuses, setSelectedStatues] = useState({
    'Not Started': true,
    Ongoing: true,
    'Off Track': true,
    Completed: true,
  });

  useEffect(() => {
    getDashboardStartDispatch({ projectId });
  }, [getDashboardStartDispatch, projectId]);

  useEffect(() => {
    if (
      queryObj &&
      queryObj.hasOwnProperty('limitObj') &&
      queryObj.limitObj.hasOwnProperty('site._id')
    ) {
      setSelectedSites(queryObj.limitObj['site._id']);
    } else {
      setSelectedSites(Object.keys(milestones));
    }
  }, [milestones, queryObj]);

  const handleToggleSite = (_, siteId) => {
    if (selectedSites.includes(siteId)) {
      setSelectedSites((prev) => prev.filter((id) => id !== siteId));
    } else {
      setSelectedSites((prev) => [...prev, siteId]);
    }
  };

  const handleSelectNone = () => {
    setSelectedSites([]);
  };

  const handleSelectAll = () => {
    setSelectedSites(Object.keys(milestones));
  };

  const handleStatusToggle = (_, status) => {
    setSelectedStatues((prev) => ({
      ...prev,
      [status]: !selectedStatuses[status],
    }));
  };

  const sites = Object.keys(milestones)
    .map((el) => ({ _id: el, name: milestones[el].siteName }))
    .sort(sortAlpha('name', 'asc'));

  return (
    <MainContainer>
      <HeaderGeneral title='Milestone Archive' />
      <div>
        <div>
          <span className={classes.statusFilters}>
            Filter Milestones by Status
          </span>
          <div
            onClick={(e) => handleStatusToggle(e, 'Not Started')}
            className={clsx(
              classes.statusPill,
              selectedStatuses['Not Started'] ? classes.notStarted : null
            )}>
            N
          </div>
          <div
            onClick={(e) => handleStatusToggle(e, 'Ongoing')}
            className={clsx(
              classes.statusPill,
              selectedStatuses['Ongoing'] ? classes.ongoing : null
            )}>
            O
          </div>
          <div
            onClick={(e) => handleStatusToggle(e, 'Off Track')}
            className={clsx(
              classes.statusPill,
              selectedStatuses['Off Track'] ? classes.offTrack : null
            )}>
            X
          </div>
          <div
            onClick={(e) => handleStatusToggle(e, 'Completed')}
            className={clsx(
              classes.statusPill,
              selectedStatuses['Completed'] ? classes.completed : null
            )}>
            C
          </div>
          <span className={classes.siteFilters}>
            and/or by Site (
            <span className={classes.link} onClick={handleSelectAll}>
              All
            </span>{' '}
            |{' '}
            <span className={classes.link} onClick={handleSelectNone}>
              None
            </span>
            )
          </span>
        </div>
        <div className={classes.chipContainer}>
          {sites.map((el) => (
            <Chip
              className={classes.chip}
              icon={<RoomIcon />}
              key={`chip-${el._id}`}
              label={el.name}
              size='small'
              color={selectedSites.includes(el._id) ? 'primary' : 'default'}
              onClick={(e) => handleToggleSite(e, el._id)}
            />
          ))}
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {sites
            .filter((el) => selectedSites.includes(el._id))
            .map((el) => {
              const siteId = el._id;
              const filteredMilestones = milestones[
                siteId
              ].allMilestones.filter(
                (milestone) => selectedStatuses[milestone.actualStatus]
              );

              return filteredMilestones.length > 0 ? (
                <Fragment key={siteId}>
                  <TableHeader title={milestones[siteId]?.siteName} />
                  <MilestoneTable
                    docs={filteredMilestones}
                    handleToggleModal={toggleModalDispatch}
                    currentUserId={userId}
                  />
                </Fragment>
              ) : null;
            })}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

Milestones.propTypes = {
  milestones: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  getDashboardStartDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  milestones: selectProjectMilestoneDetails,
  userId: selectCurrentUserId,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  getDashboardStartDispatch: (projectId) =>
    dispatch(getDashboardStart(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Milestones);
