import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Styles
import useStyles from './update-buttons.styles';

const UpdateButtons = ({
  docId,
  actionType,
  toggleModalDispatch,
  handleDeleteUpdate,
  reactions,
  currentUserId,
  update,
}) => {
  const classes = useStyles();

  // Deleting Comment/Sharelink comment is easy
  // Deleting Reply is TBD due to recursion (need to construct path to reply as we render)
  // Use relative path to traverse tree. Should just mark it as "isDeleted: true"
  // Should also find the update in the history list (via the _id of the update, and mark it there too)

  const showDelete =
    (update?.createdBy?._id === currentUserId && actionType === 'Updated') ||
    actionType === 'Share Link Comment';

  return (
    <div className={classes.root}>
      <span
        className={classes.textButton}
        onClick={() =>
          toggleModalDispatch({
            modalName: 'comment',
            modalContent: { update, currentUserId },
          })
        }>
        Reply
      </span>
      <span
        className={classes.textButton}
        onClick={() =>
          toggleModalDispatch({
            modalName: 'reactionMenu',
            modalContent: { reactions, currentUserId },
          })
        }>
        React
      </span>
      <span
        className={clsx(classes.textButton, showDelete ? null : classes.hidden)}
        onClick={() => handleDeleteUpdate({ docId, embeddedId: update._id })}>
        Delete
      </span>
    </div>
  );
};

UpdateButtons.propTypes = {
  docId: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  handleDeleteUpdate: PropTypes.func.isRequired,
  reactions: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  update: PropTypes.object.isRequired,
};

export default UpdateButtons;
