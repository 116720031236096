import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectModalShareCreate } from '../../redux/ui/ui.selectors';

const ShareCreateModal = ({
  shareCreate: { isOpen, content },
  toggleModalDispatch,
}) => {
  const { projectId, docId, createShare } = content;
  const [optionsObj, setOptionsObj] = useState({
    canExport: false,
    canComment: false,
    showUpdates: false,
  });

  const handleChange = (event) => {
    setOptionsObj((cur) => {
      if (event.target.name === 'canComment' && event.target.checked) {
        cur.showUpdates = true;
      }

      return { ...cur, [event.target.name]: event.target.checked };
    });
  };

  const handleClose = () => {
    toggleModalDispatch({ modalName: 'shareCreate', modalContent: {} });
    setOptionsObj({
      canExport: false,
      canComment: false,
      showUpdates: false,
    });
  };

  const handleSubmit = () => {
    createShare({
      projectId,
      docId,
      optionsObj,
    });
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      transitionDuration={{ appear: 225, enter: 225, exit: 0 }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>
        Start Sharing this Content?
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id='alert-dialog-description'
          style={{ marginBottom: '24px' }}>
          Anyone with this shared link will be able to view this content. Please
          ensure it does not contain sensitive information. Some restrictions
          can be applied by selecting the sliders, below. Do you wish to
          proceed?
        </DialogContentText>
        <FormControl component='fieldset'>
          <FormLabel component='div' focused={false}>
            Users with this link can:
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={optionsObj.canExport}
                  onChange={handleChange}
                  name='canExport'
                />
              }
              label='Export the content (e.g. to PDF or CSV)'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={optionsObj.canComment}
                  onChange={handleChange}
                  name='canComment'
                />
              }
              label='Add comments'
            />
            <FormControlLabel
              control={
                <Switch
                  disabled={optionsObj.canComment}
                  checked={optionsObj.showUpdates}
                  onChange={handleChange}
                  name='showUpdates'
                />
              }
              label='View the History and Additional Notes'
            />
          </FormGroup>
          <FormHelperText>
            Note: granting comment permission automatically allows shared users
            to view the history and additional notes.
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          No
        </Button>
        <Button onClick={handleSubmit} color='primary' autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ShareCreateModal.propTypes = {
  shareCreate: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  shareCreate: selectModalShareCreate,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareCreateModal);
