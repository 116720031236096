import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: '8px 24px',
    borderRadius: '20px',
    boxShadow: '0 3px 14px rgb(0 0 0 / 40%)',
    width: '200px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '2px',
  },
  headerRow: {
    fontWeight: 700,
    fontSize: '0.9rem',
    borderBottom: `1px dotted ${theme.custom.softGrey}`,
    marginBottom: '8px',
  },
  name: {
    paddingRight: '8px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  active: {
    color: theme.custom.mainAlt,
    fontWeight: 700,
    '&::before': {
      content: `'${theme.custom.symbols.rightTriangle.symbol} '`,
      position: 'absolute',
      transform: 'translateX(-10px)',
    },
  },
  status: {
    width: '16px',
    height: '16px',
    borderRadius: '8px',
    backgroundColor: theme.custom.statusGrey,
  },
  notStarted: {
    backgroundColor: theme.custom.statusGrey,
  },
  completed: {
    backgroundColor: theme.custom.statusGreen,
  },
  ongoing: {
    backgroundColor: theme.custom.statusOrange,
  },
  offTrack: {
    backgroundColor: theme.custom.statusRed,
  },
}));

export default useStyles;
