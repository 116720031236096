import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// Material UI
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BookmarkIcon from '@material-ui/icons/Bookmark';

// Styles
import useStyles from './project-card.styles';

// Constants
const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

const ProjectCard = ({
  project,
  user,
  updateDefaultProjectStartDispatch,
  updateProjectlistStartDispatch,
}) => {
  const { name, photo, createdAt, description, id } = project;
  const classes = useStyles();

  const date = new Date(createdAt).toLocaleString('en-US', dateOptions);

  const handleDefaultProjectChange = () => {
    if (id !== user.defaultProject) {
      updateDefaultProjectStartDispatch({ userId: user._id, projectId: id });
    } else {
      updateDefaultProjectStartDispatch({ userId: user._id, projectId: null });
    }
  };

  const handleProjectlistChange = () => {
    if (user?.projectList) {
      if (user.projectList.includes(id)) {
        updateProjectlistStartDispatch({
          userId: user._id,
          projects: user.projectList.filter((el) => el !== id),
        });
      } else {
        updateProjectlistStartDispatch({
          userId: user._id,
          projects: [...user.projectList, id],
        });
      }
    } else {
      updateProjectlistStartDispatch({
        userId: user._id,
        projects: [id],
      });
    }
  };

  if (!user) return null;

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label='Project' className={classes.avatar}>
            {name[0].toUpperCase()}
          </Avatar>
        }
        title={name}
        subheader={`Created: ${date}`}
      />
      <CardMedia className={classes.media} image={photo} title='site image' />
      <CardContent>
        <Typography variant='body2' color='textSecondary' component='p'>
          {description}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <span>
          <Button size='small' color='primary' href={`/projects/${id}`}>
            Open
          </Button>
          <Button size='small' color='primary' href={`/projects/${id}`}>
            Summary
          </Button>
        </span>
        <span>
          <IconButton
            aria-label='projectlist'
            onClick={handleProjectlistChange}>
            <BookmarkIcon
              color={user.projectList.includes(id) ? 'primary' : 'disabled'}
            />
          </IconButton>
          <IconButton
            aria-label='favorite'
            onClick={handleDefaultProjectChange}>
            <FavoriteIcon
              color={id === user.defaultProject ? 'secondary' : 'disabled'}
            />
          </IconButton>
        </span>
      </CardActions>
    </Card>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.object.isRequired,
  user: PropTypes.object,
  updateDefaultProjectStartDispatch: PropTypes.func.isRequired,
  updateProjectlistStartDispatch: PropTypes.func.isRequired,
};

export default ProjectCard;
