import React from 'react';
import PropTypes from 'prop-types';

// Styles
import useStyles from './update-links.styles';

const UpdateLinks = ({ notes }) => {
  const classes = useStyles();

  const urlPattern = /\b(?:https?:\/\/|www\.)[^\s/$.?#].[^\s]*\b/g;

  if (!urlPattern.test(notes)) {
    return <>{notes}</>;
  }
  const htmlStr = notes.replace(urlPattern, function (url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `<a class="update-link" href="https://${url}" target="_blank" rel="noopener noreferrer">Link</a>`;
    }
    return `<a class="update-link" href="${url}" target="_blank" rel="noopener noreferrer">Link</a>`;
  });

  return (
    <span
      className={classes.root}
      dangerouslySetInnerHTML={{ __html: htmlStr }}
    />
  );
};

UpdateLinks.propTypes = {
  notes: PropTypes.string.isRequired,
};

export default UpdateLinks;
