import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mentionText: {
    fontSize: '0.85rem',
    fontWeight: 700,
    color: '#464646',
    lineHeight: 1.1,
  },
  userText: {
    fontWeight: 500,
    backgroundColor: theme.custom.orangeHilighter,
    padding: '1px 2px',
    lineHeight: 1.1,
  },
}));

export default useStyles;
