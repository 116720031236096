import React from 'react';
import { useField } from 'formik';

// Material UI
import TextField from '@material-ui/core/TextField';

const FormikTextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      {...field}
      {...props}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default FormikTextField;
