import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Styles
import useStyles from './gantt-header.styles';

function GanttHeader({
  weekArr,
  monthArr,
  yearArr,
  weekStr,
  monthStr,
  yearStr,
  hideCalElements: { hideYears, hideMonths, hideWeeks },
}) {
  const classes = useStyles({
    weekStr,
    monthStr,
    yearStr,
  });
  return (
    <div className={classes.headerWrapper}>
      <div className={clsx(classes.yearRow, hideYears ? classes.hide : null)}>
        <div></div>
        <div></div>
        {yearArr.map((el, idx) => (
          <span key={`year-num-${el}-${idx}`}>{el}</span>
        ))}
      </div>
      <div
        className={clsx(
          classes.row,
          classes.months,
          hideMonths ? classes.hide : null
        )}>
        <div></div>
        <div></div>
        {monthArr.map((el, idx) => (
          <span className={classes.monthLetter} key={`month-num-${el}-${idx}`}>
            {el}
          </span>
        ))}
      </div>
      <div className={clsx(classes.weekRow, hideWeeks ? classes.hide : null)}>
        <div></div>
        <div></div>
        {weekArr.map((el, idx) => (
          <span key={`week-num-${el}-${idx}`}>{el}</span>
        ))}
      </div>
    </div>
  );
}

GanttHeader.propTypes = {
  weekArr: PropTypes.array.isRequired,
  monthArr: PropTypes.array.isRequired,
  yearArr: PropTypes.array.isRequired,
  monthStr: PropTypes.string.isRequired,
  yearStr: PropTypes.string.isRequired,
  hideCalElements: PropTypes.object.isRequired,
};

export default GanttHeader;
