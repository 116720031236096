import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

// Material UI
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import SvgIcon from '@material-ui/core/SvgIcon';

import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import TodayIcon from '@material-ui/icons/Today';
import EventIcon from '@material-ui/icons/Event';

import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ScheduleIcon from '@material-ui/icons/Schedule';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

// Styles
import useStyles from './gantt-toolbar.styles';

// Actions
import { addMilestoneMods } from '../../redux/milestone/milestone.actions';

// Utilities
import { adjustGanttDates } from '../../utils/ganttFns';
import { isEmptyObj } from '../../utils/utilityFns';

function GanttToolbar({
  milestones,
  activeSiteId,
  modIds,
  setModIds,
  editMode,
  addMilestoneModsDispatch,
}) {
  const [dayInc, setDayInc] = useState(7);
  const [checkState, setCheckState] = useState({
    plannedCheck: true,
    actualCheck: true,
    startCheck: true,
    endCheck: true,
  });

  const classes = useStyles();

  useEffect(() => {
    setCheckState({
      plannedCheck: true,
      actualCheck: true,
      startCheck: true,
      endCheck: true,
    });
  }, [editMode]);

  const handleAdjustGantt = (type, val) => {
    const modObj = {};

    const adjustMilestones = milestones.filter((m) => modIds.includes(m._id)); //Both IF blocks need to account for status limitations <-- Maybe build all into adjustGanttDates?
    adjustMilestones.forEach((m) => {
      let updateObj = {};
      const id = m._id;
      if (type === 'date') {
        const dateObj = adjustGanttDates(m, checkState, dayInc * val);
        if (dateObj) {
          updateObj = {
            ...dateObj,
            isCompleted: m.isCompleted,
            actualStatus: m.actualStatus,
          };
        }
      } else if (type === 'actualStatus') {
        if (
          val === 'Completed' ||
          val === 'Ongoing' ||
          val === 'Not Started' ||
          val === 'Off Track'
        ) {
          // Start Date Modification on Status Change
          let startNew;
          let endNew;
          const now = new Date();
          const yesterday = new Date(now - 24 * 60 * 60 * 1000);

          if (val === 'Not Started') {
            startNew = m.datePlannedStart;
            endNew = m.datePlannedEnd;
          } else if (val === 'Ongoing' || val === 'Off Track') {
            startNew =
              new Date(m.dateActualStart) >= now
                ? yesterday
                : m.dateActualStart;
            endNew = now;
          } else {
            startNew = m.dateActualStart;
            endNew = m.dateActualEnd;
          }

          updateObj = {
            isCompleted: val === 'Completed' ? true : false,
            actualStatus: val,
            datePlannedStart: m.datePlannedStart,
            datePlannedEnd: m.datePlannedEnd,
            dateActualStart: startNew,
            dateActualEnd: endNew,
          };
        }
      }

      if (!isEmptyObj(updateObj)) {
        Object.assign(modObj, {
          [id]: {
            ...updateObj,
            originalDatePlannedStart: m.datePlannedStart,
            originalDatePlannedEnd: m.datePlannedEnd,
            originalDateActualStart: m.dateActualStart,
            originalDateActualEnd: m.dateActualEnd,
            originalActualStatus: m.actualStatus,
            originalIsCompleted: m.isCompleted,
          },
        });
      }
    });
    if (!isEmptyObj(modObj)) {
      addMilestoneModsDispatch(modObj);
    }
  };

  const handleDeselectAll = () => {
    setModIds([]);
  };

  const handleSelectAll = () => {
    setModIds(
      milestones
        .filter((m) => m.siteId === activeSiteId && m.type !== 'site')
        .map((m) => m._id)
    );
  };

  const handleIncrementChange = (e) => {
    setDayInc(e.target.value);
  };

  const handleClick = (e) => {
    setCheckState((cur) => ({
      ...cur,
      [e.currentTarget.id]: !cur[e.currentTarget.id],
    }));
  };

  return (
    <div className={clsx(editMode ? classes.root : classes.hide)}>
      <div className={classes.actionSection}>
        <Tooltip title={`-${dayInc} days`} arrow={true}>
          <ToggleButton
            value='DateBackwards'
            className={clsx(classes.toggleButton, classes.action)}
            onClick={() => handleAdjustGantt('date', -1)}>
            <SkipPreviousIcon />
          </ToggleButton>
        </Tooltip>
        <FormControl className={classes.formControl} variant='outlined'>
          <Select
            labelId='demo-simple-select-filled-label'
            id='demo-simple-select-filled'
            className={classes.select}
            value={dayInc}
            onChange={handleIncrementChange}
            inputProps={{
              name: 'inc',
              id: 'inc-gantt',
            }}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={14}>14</MenuItem>
            <MenuItem value={21}>21</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={31}>31</MenuItem>
          </Select>
        </FormControl>
        <Tooltip title={`+${dayInc} days`} arrow={true}>
          <ToggleButton
            value='DateForwards'
            className={clsx(classes.toggleButton, classes.action)}
            onClick={() => handleAdjustGantt('date', 1)}>
            <SkipNextIcon />
          </ToggleButton>
        </Tooltip>
      </div>
      <div className={classes.allNoneSection}>
        <Tooltip title='Select All' arrow={true}>
          <ToggleButton
            value='Toggle'
            className={clsx(classes.toggleButton, classes.actionAll)}
            onClick={handleSelectAll}>
            <CheckCircleIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title='Deselect All' arrow={true}>
          <ToggleButton
            value='Toggle'
            className={clsx(classes.toggleButton, classes.actionAll)}
            onClick={handleDeselectAll}>
            <RemoveCircleIcon />
          </ToggleButton>
        </Tooltip>
      </div>
      <div className={classes.selectionSection}>
        <Tooltip title='Toggle Adjust Start Date' arrow={true}>
          <ToggleButton
            value='Toggle'
            id='startCheck'
            className={clsx(
              classes.toggleButton,
              classes.default,
              checkState.startCheck ? classes.selected : null
            )}
            onClick={handleClick}>
            <TodayIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title='Toggle Adjust End Date' arrow={true}>
          <ToggleButton
            value='Toggle'
            id='endCheck'
            className={clsx(
              classes.toggleButton,
              classes.default,
              checkState.endCheck ? classes.selected : null
            )}
            onClick={handleClick}>
            <EventIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title='Toggle Adjust Planned Timeline' arrow={true}>
          <ToggleButton
            value='Toggle'
            id='plannedCheck'
            className={clsx(
              classes.toggleButton,
              classes.default,
              checkState.plannedCheck ? classes.selected : null
            )}
            onClick={handleClick}>
            <SvgIcon style={{ transform: 'translateY(-1px)' }}>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 24 24`}>
                <path d='M10 11H6v5L10 13zm4 0h-4v2h4zh4v5L14 13zm2-7h2V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z' />
              </svg>
            </SvgIcon>
          </ToggleButton>
        </Tooltip>
        <Tooltip title='Toggle Adjust Actual Timeline' arrow={true}>
          <ToggleButton
            value='Toggle'
            id='actualCheck'
            className={clsx(
              classes.toggleButton,
              classes.default,
              checkState.actualCheck ? classes.selected : null
            )}
            onClick={handleClick}>
            <SvgIcon>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 24 24`}>
                <path d='M17 10zm2-7h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zm-2-5H7c-1 1-1 2 0 3h10c1-1 1-2 0-3z' />
              </svg>
            </SvgIcon>
          </ToggleButton>
        </Tooltip>
      </div>
      <div className={classes.statusSection}>
        <Tooltip title='Set Status to Not Started' arrow={true}>
          <ToggleButton
            value='Toggle'
            className={clsx(classes.toggleButton, classes.notStarted)}
            onClick={() => handleAdjustGantt('actualStatus', 'Not Started')}>
            <NotInterestedIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title='Set Status to Ongoing' arrow={true}>
          <ToggleButton
            value='Toggle'
            className={clsx(classes.toggleButton, classes.ongoing)}
            onClick={() => handleAdjustGantt('actualStatus', 'Ongoing')}>
            <ScheduleIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title='Set Status to Off Track' arrow={true}>
          <ToggleButton
            value='Toggle'
            className={clsx(classes.toggleButton, classes.offTrack)}
            onClick={() => handleAdjustGantt('actualStatus', 'Off Track')}>
            <HighlightOffIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title='Set Status to Completed' arrow={true}>
          <ToggleButton
            value='Toggle'
            className={clsx(classes.toggleButton, classes.completed)}
            onClick={() => handleAdjustGantt('actualStatus', 'Completed')}>
            <CheckCircleOutlineIcon />
          </ToggleButton>
        </Tooltip>
      </div>
    </div>
  );
}

GanttToolbar.propType = {
  milestones: PropTypes.array.isRequired,
  activeSiteId: PropTypes.string.isRequired,
  modIds: PropTypes.array.isRequired,
  setModIds: PropTypes.func.isRequired,
  addMilestoneModsDispatch: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addMilestoneModsDispatch: (mods) => dispatch(addMilestoneMods(mods)),
});

export default connect(null, mapDispatchToProps)(GanttToolbar);
