import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useJumpTo = () => {
  const jumpToRef = useRef(null);
  const [jumpToReady, setJumpToReady] = useState(false);

  useEffect(() => {
    if (jumpToReady && jumpToRef && jumpToRef.current) {
      jumpToRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [jumpToReady]);

  const jumpQuery = new URLSearchParams(useLocation().search);
  const jumpToId = jumpQuery.get('jump') ? jumpQuery.get('jump') : null;
  return [jumpToId, jumpToRef, setJumpToReady];
};

export default useJumpTo;
