import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Components
import GanttRowInfo from '../gantt-row-info/gantt-row-info.component';
import GanttRowPlanned from '../gantt-row-planned/gantt-row-planned.component';
import GanttRowActual from '../gantt-row-actual/gantt-row-actual.component';

// Styles
import useStyles from './gantt-row.styles';

function GanttRow({
  el,
  hideCalElements: { hideProgress, hidePlannedTimeline, hideHighlightRow },
  handlePopoverToggle,
  activeSiteId,
  editMode,
  modIds,
  handleModIdsToggle,
}) {
  const classes = useStyles();

  const milestoneRow = el.type === 'milestone' || activeSiteId === '-1';

  return (
    <div
      className={clsx(
        classes.row,
        milestoneRow
          ? hideHighlightRow
            ? null
            : classes.milestoneRow
          : classes.nonMilestoneRow
      )}>
      <GanttRowInfo
        el={el}
        editMode={editMode}
        hideProgress={hideProgress}
        handleModIdsToggle={handleModIdsToggle}
        modIds={modIds}
        activeSiteId={activeSiteId}
      />
      <div></div>
      <div
        className={clsx(
          classes.timelineWrapper,
          editMode ? classes.timelineWrapperEditMode : null
        )}>
        <GanttRowPlanned
          el={el}
          hidePlannedTimeline={hidePlannedTimeline}
          editMode={editMode}
        />
        <GanttRowActual
          el={el}
          handlePopoverToggle={handlePopoverToggle}
          editMode={editMode}
        />
      </div>
    </div>
  );
}

GanttRow.propTypes = {
  el: PropTypes.object.isRequired,
  hideCalElements: PropTypes.object.isRequired,
  handlePopoverToggle: PropTypes.func.isRequired,
  activeSiteId: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  handleModIdsToggle: PropTypes.func.isRequired,
  modIds: PropTypes.array.isRequired,
};

export default GanttRow;
