import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: '0.5rem 1.4rem',
    borderRadius: '20px',
    boxShadow: '0 3px 14px rgb(0 0 0 / 40%)',
    fontSize: '0.9rem',
  },
}));

export default useStyles;
