import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.darkest,
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px',
    },
  },
}));

export default useStyles;
