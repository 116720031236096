import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Components
import FeedItem from '../../components/feed-item/feed-item.component';
import FeedJump from '../feed-jump/feed-jump.component';

// Styles
import useStyles from './feed-container.styles';

const FeedContainer = forwardRef(
  ({ sortedUpdates, jumpToId, setJumpToReady, clickFunc = undefined }, ref) => {
    const classes = useStyles();

    return (
      <div className={classes.dateContainer}>
        {sortedUpdates.map((sortedUpdateObj, idx) => (
          <React.Fragment key={`feed-dateblock-${idx}`}>
            <div className={classes.dateHeader}>{sortedUpdateObj.dateKey}</div>
            <div className={classes.feedItemContainer}>
              {sortedUpdateObj.updates.map((update, jdx) => (
                <span key={`jump-${jdx}-${update._id}`}>
                  {update._id === jumpToId && (
                    <FeedJump ref={ref} setJumpToReady={setJumpToReady} />
                  )}
                  <FeedItem
                    key={`feed-item-${jdx}`}
                    update={update}
                    hideIcon={false}
                    hideBreadcrumbs={false}
                    highlighted={update._id === jumpToId}
                    clickFunc={clickFunc}
                  />
                </span>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }
);

FeedContainer.propTypes = {
  sortedUpdates: PropTypes.array.isRequired,
  jumpToId: PropTypes.string,
  setJumpToReady: PropTypes.func,
  clickFunc: PropTypes.func,
};

export default FeedContainer;
