import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
//import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
//import AttachmentIcon from '@material-ui/icons/Attachment';
//import ListAltIcon from '@material-ui/icons/ListAlt';
//import RoomIcon from '@material-ui/icons/Room';

// Styles
import useStyles from './drawer-items.styles';

function DrawerItems({ projectId, drawerOpen, activePage }) {
  const classes = useStyles({ drawerOpen });
  return (
    <>
      <List className={classes.list}>
        <div>
          <ListItem
            button
            component={RouterLink}
            to={`/projects/${projectId}`}
            className={clsx(
              classes.listItem,
              activePage === '' ? classes.active : null
            )}>
            <ListItemIcon className={classes.icon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText className={classes.itemText} primary='Dashboard' />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to={`/projects/${projectId}/milestones`}
            className={clsx(
              classes.listItem,
              activePage === 'milestones' ? classes.active : null
            )}>
            <ListItemIcon className={classes.icon}>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
            <ListItemText className={classes.itemText} primary='Milestones' />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to={`/projects/${projectId}/issues`}
            className={clsx(
              classes.listItem,
              activePage === 'issues' ? classes.active : null
            )}>
            <ListItemIcon className={classes.icon}>
              <ErrorIcon />
            </ListItemIcon>
            <ListItemText className={classes.itemText} primary='Issues' />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to={`/projects/${projectId}/submissions`}
            className={clsx(
              classes.listItem,
              activePage === 'submissions' ? classes.active : null
            )}>
            <ListItemIcon className={classes.icon}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText className={classes.itemText} primary='Submissions' />
          </ListItem>
        </div>
      </List>
      <Divider className={classes.divider} />
      <List className={classes.list}>
        <ListItem
          button
          component={RouterLink}
          to={`/projects/${projectId}/trash`}
          className={clsx(
            classes.listItem,
            activePage === 'trash' ? classes.active : null
          )}>
          <ListItemIcon className={classes.icon}>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText className={classes.itemText} primary='Trash' />
        </ListItem>
      </List>
    </>
  );
}

DrawerItems.propTypes = {
  projectId: PropTypes.string.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  activePage: PropTypes.string.isRequired,
};

export default DrawerItems;
