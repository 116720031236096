import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  '@global': {
    '.leaflet-control-attribution': {
      display: 'none',
    },
    '.leaftlet-popup': {
      margin: 0,
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '.leaflet-popup-content-wrapper': {
      margin: 0,
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '.leaflet-popup-tip-container': {
      display: 'none',
    },
    '.leaflet-popup-tip': {
      display: 'none',
    },
    '.leaflet-popup-content': {
      margin: 0,
      marginBottom: '0.6rem',
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
});

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
