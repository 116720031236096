import { makeStyles } from '@material-ui/core/styles';

// Constants
import { GANTT_COL_1 as gc1, GANTT_COL_2 as gc2 } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    //overflow: 'auto',
    zIndex: 5,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: `${gc1} ${gc2} 1fr`,
    backgroundColor: '#fff',
    zIndex: 5,
  },
  months: {
    color: '#fff',
    backgroundColor: theme.custom.ganttHeaderBg,
    gridTemplateColumns: (props) => props.monthStr,
    '& div': {
      borderTop: '0 !important',
      display: 'flex',
    },
    '& span': {
      textAlign: 'center',
      fontSize: '13px',
      alignSelf: 'center',
      fontWeight: 400,
      padding: '4px 0',
      color: theme.custom.ganttHeaderText,
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
  },
  monthLetter: {
    [theme.breakpoints.down('xs')]: {
      visibility: 'hidden',
      '&::first-letter': {
        visibility: 'visible',
      },
    },
  },
  yearRow: {
    display: 'grid',
    gridTemplateColumns: (props) => props.yearStr,
    backgroundColor: theme.custom.ganttHeaderBg,
    zIndex: 5,
    '& span': {
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '32px',
      alignSelf: 'center',
      fontWeight: 500,
      color: theme.custom.ganttHeaderText,
    },
  },
  weekRow: {
    display: 'grid',
    gridTemplateColumns: (props) => props.weekStr,
    backgroundColor: theme.custom.ganttHeaderBg,
    zIndex: 5,
    '& span': {
      color: theme.custom.ganttHeaderText,
      textAlign: 'center',
      fontSize: '9px',
      alignSelf: 'center',
      fontWeight: 400,
    },
  },
  hide: {
    display: 'none',
  },
}));

export default useStyles;
