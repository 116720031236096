import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';

// Material UI
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Action
import { getDashboardStart } from '../../redux/ui/ui.actions';

// Selectors
import { selectDashboardLastRefresh } from '../../redux/ui/ui.selectors';

const RefreshButton = ({ lastRefresh, getDashboardStartDispatch }) => {
  const { projectId } = useParams();
  const format = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return (
    <Tooltip
      title={`Last Synced ${
        lastRefresh === null ? 'Never' : lastRefresh.toLocaleDateString('en', format)
      }`}>
      <IconButton onClick={() => getDashboardStartDispatch({ projectId })}>
        <SyncIcon />
      </IconButton>
    </Tooltip>
  );
};

RefreshButton.propTypes = {
  lastRefresh: PropTypes.instanceOf(Date),
  getDashboardStartDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  lastRefresh: selectDashboardLastRefresh,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboardStartDispatch: (projectId) => dispatch(getDashboardStart(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefreshButton);
