import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '320px',
    maxWidth: '400px',
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
    zIndex: 6,
    boxShadow: theme.custom.popoverShadow,
    '& div:last-of-type': {
      borderBottom: 0,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.custom.modalDivider}`,
    alignItems: 'center',
    padding: '8px',
    minHeight: '48px',
    justifyContent: 'center',
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.custom.modalDivider}`,
    alignItems: 'center',
    padding: '8px',
    minHeight: '48px',
    justifyContent: 'space-around',
  },
  name: {
    fontSize: '1rem',
    fontWeight: 700,
    paddingLeft: '20px',
  },
  description: {
    fontSize: '0.85rem',
    textAlign: 'center',
    '& strong': {
      display: 'flex',
      flexBasis: '100%',
      justifyContent: 'center',
    },
    '& p': {
      display: 'flex',
      flexBasis: '100%',
      justifyContent: 'center',
    },
  },
  link: {},
  contact: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  avatar: {
    marginRight: '8px',
  },
  email: {
    paddingLeft: '8px',
    '& span': {
      display: 'block',
    },
  },
  status: {
    marginLeft: '4px',
    borderRadius: '20px',
    padding: '4px 8px',
    textAlign: 'center',
    color: theme.custom.statusContrastText,
    fontSize: '0.8rem',
    textTransform: 'capitalize',
    backgroundColor: theme.custom.lightgreyGantt,
    '&.completed': {
      backgroundColor: theme.custom.statusGreen,
    },
    '&.ongoing': {
      backgroundColor: theme.custom.statusOrange,
    },
    '&.stopped': {
      backgroundColor: theme.custom.statusRed,
    },
  },
  completed: {
    color: theme.custom.statusGreen,
  },
  listItem: {
    borderBottom: `1px solid ${theme.custom.lightergrey}`,
  },
  attachmentList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  attachmentsHeader: {
    fontWeight: 700,
    textAlign: 'center',
    paddingTop: '8px',
  },
  input: {
    display: 'none',
  },
  editIcons: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    fontSize: '1rem',
    transform: 'translate(2px,2px)',
  },
  photoAvatars: {
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));

export default useStyles;
