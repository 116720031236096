import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

// Material UI
import Popover from '@material-ui/core/Popover';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

// Styles
import useStyles from './gantt-options.styles';

// Selectors
import { selectMSProjectExport } from '../../redux/milestone/milestone.selectors';

// Actions
import { startAlert } from '../../redux/alert/alert.actions';

// Utilities
import { exportToCSVDownload } from '../../utils/exportFns';

function GanttOptions({
  showPopover,
  anchorEl,
  handlePopoverClose,
  hideCalElements,
  handleHideCalendarEl,
  msProjectExport,
  startAlertDispatch,
}) {
  const classes = useStyles();

  const handleMsProjectExport = () => {
    try {
      exportToCSVDownload(
        msProjectExport,
        'milestoneMsProject',
        undefined,
        document
      );
      startAlertDispatch({
        msg: `CSV File Created!`,
        alertType: 'success',
      });
    } catch (err) {
      startAlertDispatch({
        msg: `Error creating CSV File. ${err.message}`,
        alertType: 'error',
      });
    }
  };

  return (
    <Popover
      open={showPopover}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      style={{ transform: 'translateY(2px)' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <div className={classes.root}>
        <div className={clsx(classes.row, classes.name)}>Options</div>
        <div className={classes.row}>
          <FormControlLabel
            control={
              <Switch
                checked={!hideCalElements.hidePlannedTimeline}
                name='hidePlannedTimeline'
                id='hidePlannedTimeline'
                color='primary'
                onChange={(e) => handleHideCalendarEl(e)}
              />
            }
            label='Planned Timelines'
          />
        </div>
        <div className={classes.row}>
          <FormControlLabel
            control={
              <Switch
                checked={!hideCalElements.hideToday}
                name='hideToday'
                id='hideToday'
                color='primary'
                onChange={(e) => handleHideCalendarEl(e)}
              />
            }
            label='Today Indicator'
          />
        </div>
        <div className={classes.row}>
          <FormControlLabel
            control={
              <Switch
                checked={!hideCalElements.hideHighlightRow}
                name='hideHighlightRow'
                id='hideHighlightRow'
                color='primary'
                onChange={(e) => handleHideCalendarEl(e)}
              />
            }
            label='Row Highlighting'
          />
        </div>
        <div className={classes.row}>
          <FormControlLabel
            control={
              <Switch
                checked={!hideCalElements.hideMonth}
                name='hideMonth'
                id='hideMonth'
                color='primary'
                onChange={(e) => handleHideCalendarEl(e)}
              />
            }
            label='Month Indicator'
          />
        </div>
        <div className={classes.row}>
          <FormControlLabel
            control={
              <Switch
                checked={!hideCalElements.hideProgress}
                name='hideProgress'
                id='hideProgress'
                color='primary'
                onChange={(e) => handleHideCalendarEl(e)}
              />
            }
            label='Progress Circles'
          />
        </div>
        <div className={classes.row}>
          <FormControlLabel
            control={
              <Switch
                checked={!hideCalElements.hideYears}
                disabled={true}
                name='hideYears'
                id='hideYears'
                color='primary'
                onChange={(e) => handleHideCalendarEl(e)}
              />
            }
            label='Years'
          />
        </div>
        <div className={classes.row}>
          <FormControlLabel
            control={
              <Switch
                checked={!hideCalElements.hideMonths}
                disabled={true}
                name='hideMonths'
                id='hideMonths'
                color='primary'
                onChange={(e) => handleHideCalendarEl(e)}
              />
            }
            label='Months'
          />
        </div>
        <div className={classes.row}>
          <FormControlLabel
            control={
              <Switch
                checked={!hideCalElements.hideWeeks}
                name='hideWeeks'
                id='hideWeeks'
                color='primary'
                onChange={(e) => handleHideCalendarEl(e)}
              />
            }
            label='Weeks'
          />
        </div>
        <div className={classes.buttonRow}>
          <Button
            onClick={handleMsProjectExport}
            variant='contained'
            size='small'
            color='primary'
            className={classes.button}
            startIcon={<SaveIcon />}>
            Export to Project
          </Button>
        </div>
      </div>
    </Popover>
  );
}

GanttOptions.propTypes = {
  showPopover: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  handlePopoverClose: PropTypes.func.isRequired,
  hideCalElements: PropTypes.object.isRequired,
  handleHideCalendarEl: PropTypes.func.isRequired,
  msProjectExport: PropTypes.array.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  msProjectExport: selectMSProjectExport,
});

const mapDispatchToProps = (dispatch) => ({
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GanttOptions);
