import { makeStyles } from '@material-ui/core/styles';

// Constants
import { DASHBOARD_WIDGET_MAIN_HEIGHT as mapHeight } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    height: mapHeight,
    width: '100%',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    border: `4px solid ${theme.palette.primary.contrastText}`,
  },
  latLngBox: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '64px',
  },
}));

export default useStyles;
