import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';

// Component
import MapMarkerGroupedPopup from '../map-marker-grouped-popup/map-marker-grouped-popup.component';

const MapMarkerGrouped = ({
  activeSiteId,
  updateActiveSiteIdDispatch,
  el: {
    usePopup,
    toggleId,
    lat,
    lng,
    popupAnchor,
    groupName,
    groupSites,
    markerClickFunc,
  },
}) => {
  const markerRef = useRef();
  const isActive = groupSites.map((el) => el._id).includes(activeSiteId);
  const fillColor = isActive ? '#319cf8' : '#616161';

  const marker = {
    className: 'marker',
    html: `
    <div style="display: flex; flex-direction: column; width: 48px">
    <div style="display: flex; width: 48px; height: 48px">
    <svg height="48" width="48" viewBox="0 0 24 24" class="marker" fill-rule="evenodd" clip-rule="evenodd">
      <defs>
        <filter id="shadow">
          <feDropShadow dx="0" dy="0" stdDeviation="0.85" flood-color="black" flood-opacity="0.5"/>
        </filter>
      </defs>
      <path fill="${fillColor}" d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/>
    </svg>
    </div>
    <div style="color: ${fillColor}; width: 48px; display: flex; align-items: center; justify-content: center; text-align: center; line-height: 1.2; font-size: 1rem; font-weight: 700; font-family: 'Roboto'">${groupName}</div>
    </div>`,
    iconAnchor: [0, 0],
    popupAnchor: popupAnchor,
  };

  const siteIcon = divIcon(marker);

  const handleOpenPopup = () => {
    markerRef.current.openPopup();
  };

  const handleMarkerClick = () => {
    if (markerClickFunc === 'togglePopup') {
      // Default behavior which is toggle popup
    }

    if (markerClickFunc === 'setActiveSiteId') {
      if (markerRef.current.options.id === activeSiteId) {
        updateActiveSiteIdDispatch('-1');
      } else {
        updateActiveSiteIdDispatch(toggleId);
      }
    }
  };

  return (
    <Marker
      id={toggleId}
      ref={markerRef}
      position={[lat, lng]}
      icon={siteIcon}
      eventHandlers={{ add: handleOpenPopup, click: handleMarkerClick }}>
      {usePopup ? (
        <MapMarkerGroupedPopup
          groupName={groupName}
          groupSites={groupSites}
          activeSiteId={activeSiteId}
          updateActiveSiteIdDispatch={updateActiveSiteIdDispatch}
        />
      ) : null}
    </Marker>
  );
};

MapMarkerGrouped.propTypes = {
  groupSites: PropTypes.array,
  activeSiteId: PropTypes.string.isRequired,
  updateActiveSiteIdDispatch: PropTypes.func.isRequired,
};

export default MapMarkerGrouped;
