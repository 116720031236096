import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  textHeader: {
    marginTop: 0,
    marginBottom: 0,
  },
  noIssueText: {
    marginTop: '8px',
  },
  answerContainer: {
    marginBottom: 0,
    paddingBottom: 0,
    border: '1px solid #333',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  answerTitle: {
    margin: 0,
    padding: '16px',
    backgroundColor: '#d5d5d5',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  issueBg: {
    backgroundColor: theme.palette.primary.darkest,
  },
  issueBorder: {
    border: `4px solid ${theme.palette.primary.darkest}`,
  },
  answer: {
    background: '#FFF',
    margin: '16px',
    fontSize: '1.25rem',
  },
  answerHeaderContainer: {
    backgroundColor: theme.palette.primary.darkest,
    color: '#fff',
  },
  notes: {
    background: '#FFF',
    margin: 0,
    padding: '0px 16px 8px 16px',
    fontSize: '1rem',
    fontWeight: 400,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  issue: {
    background: theme.palette.primary.darkest,
    margin: 0,
    padding: '0px 16px 16px 16px',
    display: 'flex',
    flexDirection: 'row',
  },
  issueLink: {
    backgroundColor: '#FFF',
    color: theme.palette.primary.darkest,
    fontWeight: 900,
    textDecoration: 'none',
    padding: '0px 8px',
    borderRadius: '12px',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    lineHeight: 1.1,
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    '&:hover': {
      backgroundColor: theme.custom.popupBackground,
    },
  },
  chip: {
    color: '#fff',
    fontWeight: 900,
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    lineHeight: 1.1,
    letterSpacing: '1px',
    marginLeft: '8px',
    '& svg': {
      color: '#FFFFFF!important',
    },
  },
  pill: {
    display: 'flex',
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 900,
    textDecoration: 'none',
    padding: '2px 8px 2px 8px',
    borderRadius: '12px',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    lineHeight: 1.1,
    letterSpacing: '1px',
    marginLeft: '8px',
  },
  none: {
    backgroundColor: theme.custom.issueUncatBg,
  },
  low: {
    backgroundColor: theme.custom.issueLowBg,
  },
  medium: {
    backgroundColor: theme.custom.issueMediumBg,
  },
  high: {
    backgroundColor: theme.custom.issueHighBg,
  },
  offTrack: {
    backgroundColor: theme.custom.issueOffTrackBg,
  },
  isOpen: {
    backgroundColor: theme.custom.softGrey,
  },
  isResolved: {
    backgroundColor: theme.palette.success.main,
  },
  isDeleted: {
    backgroundColor: theme.custom.statusRed,
  },
}));

export default useStyles;
