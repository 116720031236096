import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    padding: '8px 12px',
    borderRadius: '6px',
    fontSize: '1rem',
    border: '1px solid rgba(0,0,0,0.12)',
    display: 'flex',
    lineHeight: 'normal',
    gap: '12px',
    [theme.breakpoints.up('sm')]: {
      padding: '8px 20px',
      gap: '20px',
    },
  },
  rootClickable: {
    '&:hover': {
      backgroundColor: '#46a6f90d',
      cursor: 'pointer',
      '& $icon': {
        color: '#777',
      },
    },
  },
  deletedRoot: {
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  iconContainer: {
    display: 'flex',
  },
  icon: {
    color: '#a4a4a4',
    width: '1.1em',
    height: '1.1em',
  },
  inlineBadge: {
    height: '8px',
    width: '8px',
    minHeight: '8px',
    minWidth: '8px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px',
    transform: 'translateY(-5px)',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2px',
  },
  metaContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  deleted: {
    color: '#333',
    fontSize: '0.95rem',
    fontStyle: 'italic',
    transform: 'skeyX(4deg)',
  },
  author: {
    color: '#666',
    fontWeight: 700,
  },
  date: {
    color: '#888',
  },
  notes: {
    paddingTop: '6px',
    lineHeight: '1.3',
  },
  hidden: {
    display: 'none',
  },
  highlighted: {
    backgroundColor: 'inherit',
    boxShadow: 'unset',
    animation: '$glow 0.7s 10 alternate',
  },
  '@keyframes glow': {
    from: {
      boxShadow: `0 0 3px 3px #46a6f9aa`,
      backgroundColor: '#46a6f91d',
    },
    to: {
      boxShadow: `0 0 1px 1px #46a6f933`,
      backgroundColor: '#46a6f91d',
    },
  },
}));

export default useStyles;
