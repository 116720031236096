import { createSelector } from 'reselect';

// Selectors
import { selectActiveSiteId } from '../site/site.selectors';

// Utility Functions
import { sortDate } from '../../utils/utilityFns';

const selectSubmissionSlice = (state) => state.submission;

export const selectNumSubmissions = createSelector(
  [selectSubmissionSlice],
  (submission) => submission.numSubmissions
);

export const selectSubmissions = createSelector(
  [selectSubmissionSlice],
  (submissionSlice) => submissionSlice.submissions
);

export const selectSubmissionsDeleted = createSelector(
  [selectSubmissions],
  (submissions) =>
    Object.values(submissions).filter((el) => el.isDeleted === true)
);

export const selectNumSubmissionsDeleted = createSelector(
  [selectSubmissionsDeleted],
  (submissions) => submissions.length
);

export const selectSubmissionsNotDeleted = createSelector(
  [selectSubmissions],
  (submissions) =>
    Object.values(submissions).filter((el) => el.isDeleted === false)
);

export const selectSingleSubmission = createSelector(
  [selectSubmissions],
  (submissions) => Object.values(submissions)[0]
);

export const selectActiveSiteSubmissions = createSelector(
  [selectSubmissions, selectActiveSiteId],
  (submissions, activeSiteId) => {
    if (activeSiteId === '-1') {
      return Object.values(submissions)
        .sort(sortDate('createdAt', 'desc'))
        .filter((el) => el.isDeleted === false)
        .slice(0, 6);
    } else if (activeSiteId) {
      return Object.values(submissions)
        .filter((el) => el.site._id === activeSiteId && el.isDeleted === false)
        .sort(sortDate('createdAt', 'desc'))
        .slice(0, 6);
    } else {
      return [];
    }
  }
);
