import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    height: '22px',
    display: 'inherit',
    padding: '5px 8px',
    flexGrow: '0',
    fontSize: '0.7rem',
    minWidth: '20px',
    alignItems: 'center',
    flexShrink: '0',
    fontWeight: '700',
    whiteSpace: 'nowrap',
    borderRadius: '4px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    justifyContent: 'center',
    border: '0!important',
    lineHeight: 1.3,
    textAlign: 'center',
  },
  large: {
    minWidth: '64px',
    height: '36.5px',
    fontSize: '0.875rem',
    padding: '0px 8px',
    margin: '0px 8px 0px 0px',
    fontWeight: 500,
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  midsize: {
    fontSize: '0.8rem',
    fontWeight: 700,
    height: '26px',
  },
  pill: {
    borderRadius: '12px!important',
  },
  rootDisplay: {
    display: 'inline-block',
  },
  popover: {
    marginTop: '2px',
    '& div.MuiPaper-rounded': {
      borderRadius: '4px',
    },
  },
  buttonLoading: {
    cursor: 'not-allowed',
    animation: `$pulsate 500ms ${theme.transitions.easing.easeInOut} 100ms infinite`,
  },
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(0.8)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  buttonGroup: {
    '& button': {
      color: theme.palette.primary.contrastText,
      fontSize: '0.7rem',
      borderRadius: 4,
      border: 0,
      padding: '4px 8px',
      height: '30px',
    },
  },
  punchlist: {
    display: 'block',
    fontSize: '0.55rem',
    lineHeight: 1,
  },
  none: {
    backgroundColor: theme.custom.issueUncatBg,
    '&:hover': {
      backgroundColor: theme.custom.issueUncatBgHover,
    },
  },
  low: {
    backgroundColor: theme.custom.issueLowBg,
    '&:hover': {
      backgroundColor: theme.custom.issueLowBgHover,
    },
  },
  medium: {
    backgroundColor: theme.custom.issueMediumBg,
    '&:hover': {
      backgroundColor: theme.custom.issueMediumBgHover,
    },
  },
  high: {
    backgroundColor: theme.custom.issueHighBg,
    '&:hover': {
      backgroundColor: theme.custom.issueHighBgHover,
    },
  },
  offTrack: {
    backgroundColor: theme.custom.issueOffTrackBg,
    '&:hover': {
      backgroundColor: theme.custom.issueOffTrackBgHover,
    },
  },
}));

export default useStyles;
