import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Styles
import useStyles from './feed-action.styles';

const FeedAction = ({ update, refId }) => {
  const classes = useStyles();

  // Action String and Icon
  let actionStr;
  switch (update.actionType) {
    case 'Status Change':
      actionStr = (
        <span className={classes.inlinePill}>
          {`${update.milestone.name} Milestone Status Changed to`}
          <span className={clsx(classes.statusRoot, update.status)}>
            {update.status}
          </span>
        </span>
      );
      break;
    case 'Updated':
      actionStr = 'Comment';
      break;
    case 'Share Link Comment':
      actionStr = 'Comment via Sharing Link';
      break;
    case 'Field Submission':
      if (update.type === 'issue') {
        actionStr = `Issue ${refId} Identified on Field Submission`;
      } else if (update.type === 'submission') {
        actionStr = `New Field Submission ${refId}`;
      }
      break;
    case 'Deleted':
      if (update.type === 'issue') {
        actionStr = `Issue ${refId} Deleted`;
      } else if (update.type === 'submission') {
        actionStr = `Submission ${refId} Deleted`;
      }
      break;
    case 'Restored':
      if (update.type === 'issue') {
        actionStr = `Issue ${refId} Restored from Trash`;
      } else if (update.type === 'submission') {
        actionStr = `Submission ${refId} Restored from Trash`;
      }
      break;
    case 'Resolved':
      actionStr = `Issue ${refId} Resolved`;
      break;
    case 'Reopened':
      actionStr = `Issue ${refId} Reopened`;
      break;
    case 'Recategorized':
      let recatText;
      let iconClass;
      if (update?.categorization === 0) {
        recatText = 'UNCATEGORIZED';
        iconClass = classes.none;
      } else if (update?.categorization === 1) {
        recatText = 'LOW';
        iconClass = classes.low;
      } else if (update?.categorization === 2) {
        recatText = 'MEDIUM';
        iconClass = classes.medium;
      } else if (update?.categorization === 3) {
        recatText = 'HIGH';
        iconClass = classes.high;
      } else if (update?.categorization === 4) {
        recatText = 'OFF TRACK';
        iconClass = classes.offTrack;
      } else {
        recatText = 'ERROR';
        iconClass = classes.none;
      }
      actionStr = (
        <span className={classes.inlinePill}>
          {`Issue ${refId} Recategorized to`}
          <span className={clsx(classes.recatRoot, iconClass)}>
            {recatText}
          </span>
        </span>
      );
      break;
    default:
      actionStr = 'Invalid Action';
      break;
  }

  return <div className={classes.action}>{actionStr}</div>;
};

FeedAction.propTypes = {
  update: PropTypes.object.isRequired,
  refId: PropTypes.string.isRequired,
};

export default FeedAction;
