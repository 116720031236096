import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '200px',
    maxWidth: '300px',
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
    zIndex: 6,
    boxShadow: theme.custom.popoverShadow,
    '& div:last-of-type': {
      borderBottom: 0,
    },
    '& label': {
      marginLeft: 0,
      fontSize: '0.9rem',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.custom.modalDivider}`,
    alignItems: 'center',
    padding: '0px 16px 0px 8px',
    minHeight: '42px',
  },
  name: {
    fontSize: '1rem',
    fontWeight: 700,
    justifyContent: 'center',
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px 0px 8px',
    minHeight: '46px',
    borderBottom: `1px solid ${theme.custom.modalDivider}`,
  },
  button: {
    borderRadius: '8px',
    padding: '4px 14px',
    fontSize: '0.8rem',
  },
}));

export default useStyles;
