import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// Components
import Copyright from '../../components/copyright/copyright.component';

// Images
import logo from '../../assets/GBS_Text_light.png';

// Styles
import useStyles from './auth-container.styles';

function AuthContainer({ children }) {
  const classes = useStyles();
  return (
    <Grid container component='main' className={classes.root}>
      <Grid item xs={false} sm={3} md={5} lg={7} xl={8} className={classes.image} />
      <Grid item xs={12} sm={9} md={7} lg={5} xl={4}>
        <Box className={classes.auth}>
          <img src={logo} className={classes.logo} alt='GBS Logo' />
          {children}
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}

AuthContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContainer;
