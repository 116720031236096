import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

// Components
import SpinnerBlank from '../spinner-blank/spinner-blank.component';

// Selectors
import {
  selectUserAuthenticationStatus,
  selectUserLoadingStatus,
} from '../../redux/user/user.selectors';

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  loading,
  ...otherProps
}) => (
  <Route
    {...otherProps}
    render={(props) =>
      loading ? (
        <SpinnerBlank />
      ) : isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to='/signin' />
      )
    }
  />
);

PrivateRoute.propTypes = {
  loading: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectUserAuthenticationStatus,
  loading: selectUserLoadingStatus,
});

export default connect(mapStateToProps)(PrivateRoute);
