import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  locationContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  crumb: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    lineHeight: 1.1,
    fontSize: '0.95rem',
  },
  arrow: {
    color: '#333',
    transform: 'translateY(-1px)',
  },
  target: {
    lineHeight: 1,
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    padding: '3px 8px',
    borderRadius: '8px',
  },
}));

export default useStyles;
