import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardContainer: {
    width: '100%',
  },
  modalFormContainer: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textHeader: {
    marginTop: 0,
    marginBottom: '18px',
  },
  numHidden: {
    paddingTop: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.custom.softGrey,
  },
}));

export default useStyles;
