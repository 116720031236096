import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    fontWeight: 700,
    fontSize: '1rem',
  },
  inlinePill: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  recatRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    minWidth: '32px',
    padding: '2px 6px',
    marginLeft: '4px',
    backgroundColor: 'transparent',
    color: '#fff',
    fontSize: '0.8rem',
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  none: {
    backgroundColor: theme.custom.issueUncatBg,
  },
  low: {
    backgroundColor: theme.custom.issueLowBg,
  },
  medium: {
    backgroundColor: theme.custom.issueMediumBg,
  },
  high: {
    backgroundColor: theme.custom.issueHighBg,
  },
  offTrack: {
    backgroundColor: theme.custom.issueOffTrackBg,
  },
  statusRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    minWidth: '32px',
    padding: '2px 6px',
    marginLeft: '4px',
    backgroundColor: theme.custom.issueUncatBg,
    color: '#fff',
    fontSize: '0.8rem',
    fontWeight: 900,
    textTransform: 'uppercase',
    '&.completed': {
      backgroundColor: theme.custom.statusGreen,
    },
    '&.ongoing': {
      backgroundColor: theme.custom.statusOrange,
    },
    '&.stopped': {
      backgroundColor: theme.custom.statusRed,
    },
  },
}));

export default useStyles;
