import {
  TOGGLE_DRAWER,
  TOGGLE_MODAL,
  GET_DASHBOARD_START,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  UPDATE_DASHBOARD_MAIN_WIDGET,
  UPDATE_ISSUE_NOTES_MODAL,
  UPDATE_SUBMISSION_NOTES_MODAL,
  UPDATE_SIDEBAR_MODAL,
  UPDATE_PHOTO_MODAL,
  SET_ISSUEBOX_TYPE,
  SET_UPDATE_FILTER_OBJ,
  SET_ISSUEBOX_FILTER_OBJ,
  SET_ISSUEBOX_SORT_OBJ,
} from '../types';

const INITIAL_STATE = {
  dashboard: {
    lastRefresh: null,
    widgets: {
      main: {
        options: ['map', 'schedule'],
        selected: 'map',
      },
    },
  },
  updateFilters: {
    'Field Submission': true,
    Recategorized: true,
    Updated: true,
    Dismissed: true,
    Resolved: true,
    Reopened: true,
    Restored: true,
    Edited: true,
    Deleted: true,
    'Started Sharing': true,
    'Stopped Sharing': true,
    'Share Link Comment': true,
    Sharing: true,
    Resolutions: true,
  },
  issueboxFilters: {
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
  },
  issueboxSort: {
    vector: 'createdAt',
    order: 'asc',
  },
  drawerOpen: false,
  issuebox: {
    type: 'categorized',
  },
  modals: {
    issueResolve: {
      isOpen: false,
      content: {},
    },
    submissionResolve: {
      isOpen: false,
      content: {},
    },
    issueNotes: {
      isOpen: false,
      content: {},
    },
    submissionNotes: {
      isOpen: false,
      content: {},
    },
    exportOptions: {
      isOpen: false,
      content: {},
    },
    photoGallery: {
      isOpen: false,
      content: {},
    },
    sidebarNotes: {
      isOpen: false,
      content: {},
    },
    confirmDialog: {
      isOpen: false,
      content: {},
    },
    editSubmission: {
      isOpen: false,
      content: {},
    },
    fullscreenSubmission: {
      isOpen: false,
      content: {},
    },
    shareDelete: {
      isOpen: false,
      content: {},
    },
    shareCreate: {
      isOpen: false,
      content: {},
    },
    reactionMenu: {
      isOpen: false,
      content: {},
    },
    comment: {
      isOpen: false,
      content: {},
    },
  },
};

const uiReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_UPDATE_FILTER_OBJ:
      return {
        ...state,
        updateFilters: {
          ...state.updateFilters,
          ...payload,
        },
      };
    case SET_ISSUEBOX_FILTER_OBJ:
      return {
        ...state,
        issueboxFilters: {
          ...state.issueboxFilters,
          ...payload,
        },
      };
    case SET_ISSUEBOX_SORT_OBJ:
      return {
        ...state,
        issueboxSort: {
          ...state.issueboxSort,
          ...payload,
        },
      };
    case TOGGLE_DRAWER:
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modalName]: {
            ...state.modals[payload.modalName],
            isOpen: !state.modals[payload.modalName].isOpen,
            content: payload.modalContent,
          },
        },
      };
    case UPDATE_ISSUE_NOTES_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          issueNotes: {
            ...state.modals.issueNotes,
            content: {
              ...state.modals.issueNotes.content,
              updates: payload,
            },
          },
        },
      };
    case UPDATE_SUBMISSION_NOTES_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          submissionNotes: {
            ...state.modals.submissionNotes,
            content: {
              ...state.modals.submissionNotes.content,
              updates: payload,
            },
          },
        },
      };
    case UPDATE_SIDEBAR_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          sidebarNotes: {
            ...state.modals.sidebarNotes,
            content: {
              ...state.modals.sidebarNotes.content,
              item: payload,
            },
          },
        },
      };
    case UPDATE_PHOTO_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          photoGallery: {
            ...state.modals.photoGallery,
            content: {
              ...state.modals.photoGallery.content,
              photos: payload,
              startPhoto: 0,
            },
          },
        },
      };
    case GET_DASHBOARD_START:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          lastRefresh: null,
          widgets: {
            ...state.dashboard.widgets,
            main: {
              ...state.dashboard.widgets.main,
              selected: 'map',
            },
          },
        },
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          lastRefresh: payload.lastRefresh,
        },
      };
    case GET_DASHBOARD_FAILURE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          lastRefresh: null,
        },
      };
    case SET_ISSUEBOX_TYPE:
      return {
        ...state,
        issuebox: {
          ...state.issuebox,
          type: payload,
        },
      };
    case UPDATE_DASHBOARD_MAIN_WIDGET:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          widgets: {
            ...state.dashboard.widgets,
            main: {
              ...state.dashboard.widgets.main,
              selected: payload,
            },
          },
        },
      };

    default: {
      return state;
    }
  }
};

export default uiReducer;
