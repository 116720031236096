import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Components
import NotificationMenu from '../../components/notification-menu/notification-menu.component';
import ProjectMenu from '../../components/project-menu/project-menu.component';
import SettingsMenu from '../settings-menu/settings-menu.component';

// Material UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// Styles
import useStyles from './main-appbar.styles';

// Actions
import { toggleDrawer } from '../../redux/ui/ui.actions';

// Selectors
import { selectDrawerPosition } from '../../redux/ui/ui.selectors';

const MainAppbar = ({ drawerOpen, toggleDrawerDispatch }) => {
  const classes = useStyles();
  return (
    <AppBar
      position='fixed'
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawerOpen,
      })}>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          className={clsx(classes.menuButton)}
          onClick={toggleDrawerDispatch}>
          {drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
        <div className={classes.mainContainer}>
          <Typography variant='h6' noWrap className={classes.title}>
            Global Build Solutions
          </Typography>
          <div className={classes.buttonGroup}>
            <ProjectMenu />
            <NotificationMenu />
            <SettingsMenu />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

MainAppbar.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  toggleDrawerDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  drawerOpen: selectDrawerPosition,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDrawerDispatch: () => dispatch(toggleDrawer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainAppbar);
