import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useParams, Link as RouterLink } from 'react-router-dom';

// Material UI
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// Components
import PhotoGrid from '../../components/photo-grid/photo-grid.component';

// Styles
import useStyles from './issue-details.styles';

const IssueDetails = ({
  title,
  question,
  initialUpdate,
  submission,
  toggleModalDispatch,
  currentCategorization,
  isDeleted,
  isResolved,
}) => {
  const classes = useStyles();
  const { projectId } = useParams();

  const severityChipGenerator = (currentCategorization) => {
    if (currentCategorization === 4) {
      return (
        <div className={clsx(classes.pill, classes.offTrack)}>Off Track</div>
      );
    } else if (currentCategorization === 3) {
      return <div className={clsx(classes.pill, classes.high)}>High</div>;
    } else if (currentCategorization === 2) {
      return <div className={clsx(classes.pill, classes.medium)}>Medium</div>;
    } else if (currentCategorization === 1) {
      return <div className={clsx(classes.pill, classes.low)}>Low</div>;
    } else if (currentCategorization === 0) {
      return (
        <div className={clsx(classes.pill, classes.none)}>Uncategorized</div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {question && initialUpdate ? (
        <Grid container spacing={0} className={classes.root}>
          <Grid
            item
            xs={12}
            className={clsx(classes.answerContainer, classes.issueBorder)}>
            <div className={classes.answerHeaderContainer}>
              <h2
                className={clsx(
                  classes.answerTitle,
                  classes.issueBg
                )}>{`${question.name} - ${question.questionText}`}</h2>
            </div>
            <div className={classes.issue}>
              <Tooltip title='Click to open submission.'>
                <RouterLink
                  className={classes.issueLink}
                  to={`/projects/${projectId}/submissions/${submission}`}>
                  Issue Identified
                </RouterLink>
              </Tooltip>
              {severityChipGenerator(currentCategorization)}
              {isResolved ? (
                <Chip
                  avatar={<DoneIcon />}
                  label={'Resolved'}
                  size='small'
                  className={clsx(classes.chip, classes.isResolved)}
                />
              ) : (
                <Chip
                  avatar={<AccessTimeIcon />}
                  label={'Open'}
                  size='small'
                  className={clsx(classes.chip, classes.isOpen)}
                />
              )}
              {isDeleted ? (
                <Chip
                  avatar={<DeleteIcon />}
                  label={'Deleted'}
                  size='small'
                  className={clsx(classes.chip, classes.isDeleted)}
                />
              ) : null}
            </div>
            {initialUpdate?.content?.answers[0] ? (
              <h4 className={classes.answer}>
                {initialUpdate?.content?.answers[0]}
              </h4>
            ) : null}
            {initialUpdate?.content?.notes ? (
              <h4 className={classes.notes}>{initialUpdate?.content?.notes}</h4>
            ) : null}
            <PhotoGrid
              title='Photos from Field Submission'
              type='issue'
              photos={initialUpdate.content.photos}
              toggleModalDispatch={toggleModalDispatch}
              showTitle={false}
              padGrid={true}
            />
          </Grid>
        </Grid>
      ) : (
        <div className={classes.noIssueText}>
          No content provided with this issue.
        </div>
      )}
    </>
  );
};

IssueDetails.propTypes = {
  title: PropTypes.string.isRequired,
  question: PropTypes.oneOfType([
    PropTypes.oneOf([null]).isRequired,
    PropTypes.object.isRequired,
  ]),
  initialUpdate: PropTypes.oneOfType([
    PropTypes.oneOf([null]).isRequired,
    PropTypes.object.isRequired,
  ]),
  submission: PropTypes.string.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  currentCategorization: PropTypes.number.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  isResolved: PropTypes.bool.isRequired,
};

export default IssueDetails;
