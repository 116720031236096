import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

// Action Types
import {
  GET_ALL_MILESTONES_START,
  UPDATE_MILESTONE_START,
  ADD_ATTACHMENT_MILESTONE_START,
  DELETE_ATTACHMENT_MILESTONE_START,
  ADD_NOTE_MILESTONE_START,
  DELETE_NOTE_MILESTONE_START,
  UPDATE_MANY_MILESTONES_START,
} from '../types';

// Actions
import {
  getAllMilestonesSuccess,
  getAllMilestonesFailure,
  updateMilestoneSuccess,
  updateMilestoneFailure,
  addNoteMilestoneSuccess,
  addNoteMilestoneFailure,
  deleteNoteMilestoneSuccess,
  deleteNoteMilestoneFailure,
  addAttachmentMilestoneSuccess,
  addAttachmentMilestoneFailure,
  deleteAttachmentMilestoneSuccess,
  deleteAttachmentMilestoneFailure,
  updateManyMilestonesSuccess,
  updateManyMilestonesFailure,
} from './milestone.actions';
import { startFetchAction, stopFetchAction } from '../loading/loading.actions';
import { startAlert } from '../alert/alert.actions';
import { updateSidebarModal, updatePhotoModal } from '../ui/ui.actions';

/**
 * Workers
 */
export function* getAllMilestones({ payload }) {
  try {
    yield put(startFetchAction(payload));
    const res = yield axios.get(
      `/api/v1/projects/${payload.projectId}/milestones`
    );
    yield put(getAllMilestonesSuccess(res.data.data.docs));
    yield put(stopFetchAction({ id: payload.id, status: 'success' }));
  } catch (err) {
    yield put(getAllMilestonesFailure());
    yield put(stopFetchAction({ id: payload.id, status: 'error' }));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updateMilestone({
  payload: { update, milestoneId, projectId, component },
}) {
  try {
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/milestones/${milestoneId}/update`,
      update
    );
    yield put(updateMilestoneSuccess(res.data.data.docs));
    if (component && component === 'sidebar') {
      yield put(updateSidebarModal(res.data.data.docs));
    }
  } catch (err) {
    yield put(updateMilestoneFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updateManyMilestones({ payload: { projectId, updateArr } }) {
  try {
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/milestones/updateMany`,
      updateArr
    );
    yield put(updateManyMilestonesSuccess(res.data.data.docs));
    yield put(
      startAlert({
        msg: 'Milestones successfully updated!',
        alertType: 'success',
      })
    );
  } catch (err) {
    yield put(updateManyMilestonesFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* addAttachment({
  payload: { formData, milestoneId, projectId, component },
}) {
  try {
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/milestones/${milestoneId}/addAttachments`,
      formData
    );
    yield put(addAttachmentMilestoneSuccess(res.data.data.docs));
    yield put(
      startAlert({ msg: 'File successfully uploaded!', alertType: 'success' })
    );
    if (component && component === 'sidebar') {
      yield put(updateSidebarModal(res.data.data.docs));
    }
  } catch (err) {
    yield put(addAttachmentMilestoneFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* deleteAttachment({
  payload: { projectId, milestoneId, attachmentId, embeddedField, component },
}) {
  try {
    const res = yield axios.delete(
      `/api/v1/projects/${projectId}/milestones/${milestoneId}/embeddedField/${embeddedField}/embeddedId/${attachmentId}`
    );
    yield put(deleteAttachmentMilestoneSuccess(res.data.data.docs));
    yield put(
      startAlert({
        msg: `${
          embeddedField === 'photos' ? 'Photo' : 'File'
        } successfully removed!`,
        alertType: 'success',
      })
    );
    if (component && component === 'sidebar') {
      yield put(updateSidebarModal(res.data.data.docs));
    }
    if (embeddedField === 'photos') {
      yield put(updatePhotoModal(res.data.data.docs.photos));
    }
  } catch (err) {
    yield put(deleteAttachmentMilestoneFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* addNote({ payload: { text, milestoneId, projectId } }) {
  try {
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/milestones/${milestoneId}/addNote`,
      { text }
    );
    yield put(addNoteMilestoneSuccess(res.data.data.docs));
    yield put(startAlert({ msg: 'Note added!', alertType: 'success' }));
    yield put(updateSidebarModal(res.data.data.docs));
  } catch (err) {
    yield put(addNoteMilestoneFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* deleteNote({
  payload: { projectId, milestoneId, noteId, embeddedField },
}) {
  try {
    const res = yield axios.delete(
      `/api/v1/projects/${projectId}/milestones/${milestoneId}/embeddedField/${embeddedField}/embeddedId/${noteId}`
    );
    yield put(deleteNoteMilestoneSuccess(res.data.data.docs));
    yield put(
      startAlert({ msg: 'Note successfully removed!', alertType: 'success' })
    );
    yield put(updateSidebarModal(res.data.data.docs));
  } catch (err) {
    yield put(deleteNoteMilestoneFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

/**
 * Watchers
 */
export function* onGetAllMilestonesStart() {
  yield takeLatest(GET_ALL_MILESTONES_START, getAllMilestones);
}

export function* onUpdateMilestoneStart() {
  yield takeLatest(UPDATE_MILESTONE_START, updateMilestone);
}

export function* onAddAttachmentMilestoneStart() {
  yield takeLatest(ADD_ATTACHMENT_MILESTONE_START, addAttachment);
}

export function* onDeleteAttachmentMilestoneStart() {
  yield takeLatest(DELETE_ATTACHMENT_MILESTONE_START, deleteAttachment);
}

export function* onAddNoteMilestoneStart() {
  yield takeLatest(ADD_NOTE_MILESTONE_START, addNote);
}

export function* onDeleteNoteMilestoneStart() {
  yield takeLatest(DELETE_NOTE_MILESTONE_START, deleteNote);
}

export function* onUpdateManyMilestonesStart() {
  yield takeLatest(UPDATE_MANY_MILESTONES_START, updateManyMilestones);
}

/**
 * Export Saga
 */
export function* milestoneSagas() {
  yield all([
    call(onGetAllMilestonesStart),
    call(onUpdateMilestoneStart),
    call(onAddAttachmentMilestoneStart),
    call(onDeleteAttachmentMilestoneStart),
    call(onAddNoteMilestoneStart),
    call(onDeleteNoteMilestoneStart),
    call(onUpdateManyMilestonesStart),
  ]);
}
