import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    border: 0,
    padding: '4px 0px 4px 8px',
    zIndex: 3,
    alignItems: 'center',
    '& svg > text': {
      fill: '#000',
    },
  },
  titleSiteMode: {
    padding: '4px 0px 8px 8px',
    '& svg > text': {
      fill: '#fff',
    },
  },
  subtaskTitle: {
    display: 'flex',
    height: '100%',
    border: 0,
    padding: '0px 0px 0px 0px',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    color: theme.custom.mediumgrey,
  },
  progress: {
    display: 'flex',
    paddingRight: '8px',
  },
  checkbox: {
    padding: '0 4px 0 4px',
  },
  siteAvatar: {
    fontWeight: 700,
    height: '38px',
    width: '38px',
  },
  siteInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  siteName: {
    display: 'flex',
    fontWeight: 700,
    fontSize: '16px',
    color: '#000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  backIcon: {
    fontSize: '1rem',
    display: 'flex',
    color: '#FFF',
    alignSelf: 'center',
    marginLeft: '2px',
    transform: 'translateY(1px)',
  },
  siteNameSiteMode: {
    color: '#fff',
  },
  siteStatus: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  siteStatusPill: {
    display: 'flex',
    borderRadius: '16px',
    fontSize: '0.7215rem',
    padding: '1px 5px 1px 5px',
    fontWeight: 700,
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.custom.lightgreyGantt,
  },
  siteStatusCompleted: {
    backgroundColor: theme.custom.statusGreen,
  },
  siteStatusOngoing: {
    backgroundColor: theme.custom.statusOrangeAlt,
  },
  siteStatusOffTrack: {
    backgroundColor: theme.custom.statusRed,
  },
  siteStatusNotStarted: {
    backgroundColor: theme.custom.lightgreyGantt,
  },
  subtask: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: 1.1,
  },
  subtaskNonEditMode: {
    paddingLeft: '4px',
  },
  subtaskUnmodded: {
    borderLeft: '4px solid transparent',
  },
  subtaskModded: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  milestoneModded: {
    cursor: 'context-menu',
  },
  hide: {
    display: 'none',
  },
  milestoneName: {
    '&:hover': {
      color: 'rgba(85,85,85,0.9)',
      cursor: 'pointer',
      textDecoration: 'underline',
      textDecorationColor: 'rgba(85,85,85,0.9)',
      textDecorationStyle: 'dotted',
    },
  },
}));

export default useStyles;
