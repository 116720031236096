import {
  GET_FILTERED_SUBMISSIONS_START,
  GET_FILTERED_SUBMISSIONS_SUCCESS,
  GET_FILTERED_SUBMISSIONS_FAILURE,
  GET_SINGLE_SUBMISSION_START,
  GET_SINGLE_SUBMISSION_SUCCESS,
  GET_SINGLE_SUBMISSION_FAILURE,
  GET_SITE_SUBMISSIONS_SUCCESS,
  GET_SITE_SUBMISSIONS_FAILURE,
  UPDATE_SUBMISSION_START,
  UPDATE_SUBMISSION_SUCCESS,
  UPDATE_SUBMISSION_FAILURE,
  DELETE_SUBMISSION_UPDATE_START,
  DELETE_SUBMISSION_UPDATE_SUCCESS,
  DELETE_SUBMISSION_UPDATE_FAILURE,
  UPDATE_SUBMISSION_SEEN_START,
  UPDATE_SUBMISSION_SEEN_SUCCESS,
  UPDATE_SUBMISSION_SEEN_FAILURE,
  UPDATE_SUBMISSION_EDIT_START,
  UPDATE_SUBMISSION_EDIT_SUCCESS,
  UPDATE_SUBMISSION_EDIT_FAILURE,
  DELETE_SUBMISSION_SHARE_START,
  DELETE_SUBMISSION_SHARE_SUCCESS,
  DELETE_SUBMISSION_SHARE_FAILURE,
  CREATE_SUBMISSION_SHARE_START,
  CREATE_SUBMISSION_SHARE_SUCCESS,
  CREATE_SUBMISSION_SHARE_FAILURE,
} from '../types';

export const getFilteredSubmissionsStart = (fetchObj) => ({
  type: GET_FILTERED_SUBMISSIONS_START,
  payload: fetchObj,
});

export const getFilteredSubmissionsSuccess = (submissionData) => ({
  type: GET_FILTERED_SUBMISSIONS_SUCCESS,
  payload: submissionData,
});

export const getFilteredSubmissionsFailure = () => ({
  type: GET_FILTERED_SUBMISSIONS_FAILURE,
});

export const getSingleSubmissionStart = (fetchObj) => ({
  type: GET_SINGLE_SUBMISSION_START,
  payload: fetchObj,
});

export const getSingleSubmissionSuccess = (submissionData) => ({
  type: GET_SINGLE_SUBMISSION_SUCCESS,
  payload: submissionData,
});

export const getSingleSubmissionFailure = () => ({
  type: GET_SINGLE_SUBMISSION_FAILURE,
});

export const getSiteSubmissionsSuccess = (submissionData) => ({
  type: GET_SITE_SUBMISSIONS_SUCCESS,
  payload: submissionData,
});

export const getSiteSubmissionsFailure = () => ({
  type: GET_SITE_SUBMISSIONS_FAILURE,
});

export const updateSubmissionStart = (submissionObj) => ({
  type: UPDATE_SUBMISSION_START,
  payload: submissionObj,
});

export const updateSubmissionSuccess = (updatedSubmissionObj) => ({
  type: UPDATE_SUBMISSION_SUCCESS,
  payload: updatedSubmissionObj,
});

export const updateSubmissionFailure = () => ({
  type: UPDATE_SUBMISSION_FAILURE,
});

export const deleteSubmissionUpdateStart = (idObj) => ({
  type: DELETE_SUBMISSION_UPDATE_START,
  payload: idObj,
});

export const deleteSubmissionUpdateSuccess = (updateObj) => ({
  type: DELETE_SUBMISSION_UPDATE_SUCCESS,
  payload: updateObj,
});

export const deleteSubmissionUpdateFailure = () => ({
  type: DELETE_SUBMISSION_UPDATE_FAILURE,
});

export const updateSubmissionSeenStart = (idObj) => ({
  type: UPDATE_SUBMISSION_SEEN_START,
  payload: idObj,
});

export const updateSubmissionSeenSuccess = (updateObj) => ({
  type: UPDATE_SUBMISSION_SEEN_SUCCESS,
  payload: updateObj,
});

export const updateSubmissionSeenFailure = () => ({
  type: UPDATE_SUBMISSION_SEEN_FAILURE,
});

export const updateSubmissionEditStart = (updateObj) => ({
  type: UPDATE_SUBMISSION_EDIT_START,
  payload: updateObj,
});

export const updateSubmissionEditSuccess = (updateObj) => ({
  type: UPDATE_SUBMISSION_EDIT_SUCCESS,
  payload: updateObj,
});

export const updateSubmissionEditFailure = () => ({
  type: UPDATE_SUBMISSION_EDIT_FAILURE,
});

export const deleteSubmissionShareStart = (updateObj) => ({
  type: DELETE_SUBMISSION_SHARE_START,
  payload: updateObj,
});

export const deleteSubmissionShareSuccess = (updateObj) => ({
  type: DELETE_SUBMISSION_SHARE_SUCCESS,
  payload: updateObj,
});

export const deleteSubmissionShareFailure = () => ({
  type: DELETE_SUBMISSION_SHARE_FAILURE,
});

export const createSubmissionShareStart = (updateObj) => ({
  type: CREATE_SUBMISSION_SHARE_START,
  payload: updateObj,
});

export const createSubmissionShareSuccess = (updateObj) => ({
  type: CREATE_SUBMISSION_SHARE_SUCCESS,
  payload: updateObj,
});

export const createSubmissionShareFailure = () => ({
  type: CREATE_SUBMISSION_SHARE_FAILURE,
});
