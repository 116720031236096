import { all, call } from 'redux-saga/effects';
import { alertSagas } from './alert/alert.sagas';
import { userSagas } from './user/user.sagas';
import { projectSagas } from './project/project.sagas';
import { siteSagas } from './site/site.sagas';
import { milestoneSagas } from './milestone/milestones.sagas';
import { issueSagas } from './issue/issue.sagas';
import { submissionSagas } from './submission/submission.sagas';
import { uiSagas } from './ui/ui.sagas';

export default function* rootSaga() {
  yield all([
    call(alertSagas),
    call(userSagas),
    call(projectSagas),
    call(siteSagas),
    call(milestoneSagas),
    call(issueSagas),
    call(submissionSagas),
    call(uiSagas),
  ]);
}
