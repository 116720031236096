import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 300,
    maxWidth: 360,
    maxHeight: 600,
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0,
  },
  icon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontSize: '0.9rem',
  },
  iconButton: {
    color: '#fff',
  },
  header: {
    textTransform: 'uppercase',
    fontWeight: 400,
    letterSpacing: '0.08333em',
    textAlign: 'center',
  },
  subtext: {
    display: 'block',
    color: theme.palette.grey[700],
    fontSize: '0.85rem',
  },
}));

export default useStyles;
