import {
  GET_ALL_SITES_START,
  GET_ALL_SITES_SUCCESS,
  GET_ALL_SITES_FAILURE,
  UPDATE_ACTIVE_SITE_ID,
} from '../types';

// Utility Functions
import { arrToObj } from '../../utils/utilityFns';

const INITIAL_STATE = {
  sites: {},
  activeSiteId: '-1',
};

const siteReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_SITES_START:
      return {
        ...state,
        sites: {},
      };
    case GET_ALL_SITES_SUCCESS:
      return {
        ...state,
        sites: arrToObj(payload, '_id'),
        activeSiteId: '-1',
      };
    case GET_ALL_SITES_FAILURE:
      return {
        ...state,
        sites: {},
        activeSiteId: '-1',
      };
    case UPDATE_ACTIVE_SITE_ID:
      return {
        ...state,
        activeSiteId: payload,
      };
    default: {
      return state;
    }
  }
};

export default siteReducer;
