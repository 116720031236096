import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 'unset!important',
    justifyContent: 'space-between',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    alignItems: 'flex-end',
    '& button': {
      padding: '6px',
    },
  },
  extraSpacing: {
    marginTop: '20px',
    marginBottom: '8px',
  },
  toolbarStart: {
    display: 'flex',
    alignSelf: 'center',
  },
  toolbarEnd: {
    display: 'flex',
    alignSelf: 'center',
  },
  toolbarTitle: {
    color: theme.custom.mediumgrey,
    margin: 0,
  },
}));

export default useStyles;
