import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  update: {
    cursor: 'default',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: '100%',
    padding: '8px 16px 8px 0px',
    border: `1px solid ${theme.custom.standardBorder}`,
    borderRadius: '6px',
    backgroundColor: '#f4f4f4',
    '&:hover': {
      backgroundColor: '#ededed',
    },
    '&:hover $updateReply': {
      backgroundColor: '#ededed',
    },
    '&:hover $buttonContainer': {
      display: 'flex',
    },
    '&:hover $icon': {
      transform: 'scale(1.05)',
    },
  },
  updateReply: {
    padding: '4px 0px',
    border: 0,
    borderRadius: 0,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      borderLeft: '1px solid rgba(0, 0, 0, 0.28)',
    },
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '56px',
  },
  replyIconContainer: {
    minWidth: 'unset',
  },
  icon: {
    outline: 'unset',
    boxShadow: 'unset',
  },
  vertLineReply: {
    display: 'flex',
    flexGrow: 1,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    margin: '0px 0px 0px 0px',
    minHeight: '16px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '0px 0px 0px 12px',
    gap: '4px',
    justifyContent: 'center',
  },
  contentContainerReply: {
    paddingTop: '0px',
    justifyContent: 'flex-start',
    minHeight: '32px',
  },
  metaContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
    alignItems: 'flex-end',
    lineHeight: 1,
    flexWrap: 'wrap',
  },
  buttonContainer: {
    display: 'none',
  },
  hidden: {
    display: 'none',
  },
}));

export default useStyles;
