import {
  GET_ALL_PROJECTS_START,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_FAILURE,
  GET_PROJECT_BY_ID_START,
  GET_PROJECT_BY_ID_SUCCESS,
  GET_PROJECT_BY_ID_FAILURE,
  ADD_ATTACHMENT_PROJECT_START,
  ADD_ATTACHMENT_PROJECT_SUCCESS,
  ADD_ATTACHMENT_PROJECT_FAILURE,
  DELETE_ATTACHMENT_PROJECT_START,
  DELETE_ATTACHMENT_PROJECT_SUCCESS,
  DELETE_ATTACHMENT_PROJECT_FAILURE,
  UPDATE_PROJECT_START,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_PHOTO_START,
  UPDATE_PROJECT_PHOTO_SUCCESS,
  UPDATE_PROJECT_PHOTO_FAILURE,
} from '../types';

export const getAllProjectsStart = (fetchObj) => ({
  type: GET_ALL_PROJECTS_START,
  payload: fetchObj,
});

export const getAllProjectsSuccess = (projectData) => ({
  type: GET_ALL_PROJECTS_SUCCESS,
  payload: projectData,
});

export const getAllProjectsFailure = () => ({
  type: GET_ALL_PROJECTS_FAILURE,
});

export const getProjectByIdStart = (fetchObj) => ({
  type: GET_PROJECT_BY_ID_START,
  payload: fetchObj,
});

export const getProjectByIdSuccess = (projectData) => ({
  type: GET_PROJECT_BY_ID_SUCCESS,
  payload: projectData,
});

export const getProjectByIdFailure = () => ({
  type: GET_PROJECT_BY_ID_FAILURE,
});

export const addAttachmentProjectStart = (attachmentsObj) => ({
  type: ADD_ATTACHMENT_PROJECT_START,
  payload: attachmentsObj,
});

export const addAttachmentProjectSuccess = (updatedAttachmentsObj) => ({
  type: ADD_ATTACHMENT_PROJECT_SUCCESS,
  payload: updatedAttachmentsObj,
});

export const addAttachmentProjectFailure = () => ({
  type: ADD_ATTACHMENT_PROJECT_FAILURE,
});

export const deleteAttachmentProjectStart = (attachmentsObj) => ({
  type: DELETE_ATTACHMENT_PROJECT_START,
  payload: attachmentsObj,
});

export const deleteAttachmentProjectSuccess = (updatedAttachmentsObj) => ({
  type: DELETE_ATTACHMENT_PROJECT_SUCCESS,
  payload: updatedAttachmentsObj,
});

export const deleteAttachmentProjectFailure = () => ({
  type: DELETE_ATTACHMENT_PROJECT_FAILURE,
});

export const updateProjectStart = (projectObj) => ({
  type: UPDATE_PROJECT_START,
  payload: projectObj,
});

export const updateProjectSuccess = (projectObj) => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: projectObj,
});

export const updateProjectFailure = (projectObj) => ({
  type: UPDATE_PROJECT_FAILURE,
  payload: projectObj,
});

export const updateProjectPhotoStart = (formData) => ({
  type: UPDATE_PROJECT_PHOTO_START,
  payload: formData,
});

export const updateProjectPhotoSuccess = (projectObj) => ({
  type: UPDATE_PROJECT_PHOTO_SUCCESS,
  payload: projectObj,
});

export const updateProjectPhotoFailure = () => ({
  type: UPDATE_PROJECT_PHOTO_FAILURE,
});
