import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    transform: 'translateY(-48px)',
    maxWidth: '680px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '12px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  codeGrid: {
    width: '100%',
    display: 'grid',
    gap: '8px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  },
  codeDigit: {
    border: '2px solid rgba(0,0,0,0.12)',
    height: '140px',
    fontSize: '3rem',
    fontWeight: 900,
    textAlign: 'center',
    borderRadius: '8px',
    outline: 'none',
    minWidth: '60px',
    maxWidth: '120px',
    textTransform: 'uppercase',
    '&:focus': {
      backgroundColor: '#2876e51c',
      borderColor: '#38f',
    },
    [theme.breakpoints.down('xs')]: {
      height: '100px',
    },
  },
  help: {
    fontSize: '2rem',
    lineHeight: 1.2,
    textAlign: 'center',
  },
  inTouch: {
    paddingTop: '16px',
  },
  link: {
    color: '#38f',
    fontSize: '1.5rem',
    textDecoration: 'none',
  },
}));

export default useStyles;
