import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';

// Components
import SearchFilterTag from '../search-filter-tag/search-filter-tag.component';

//Styles
import useStyles from './archive-header.styles';

const ArchiveHeader = ({
  title,
  numItems,
  queryObj: { page, perPage, searchStr, rangeArr, limitObj },
  setQueryObj,
  resetQueryObj,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const numPages = Math.ceil(numItems / perPage);
  const numFilters = rangeArr.length + Object.keys(limitObj).length;

  const handlePageChange = (_, value) => {
    setQueryObj((prev) => ({ ...prev, page: value }));
  };

  const handleListItemClick = (_, value) => {
    setQueryObj(
      (prev) => ({ ...prev, perPage: value, page: 1 }),
      setAnchorEl(null)
    );
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Grid item xs={12} className={classes.box}>
        <div className={classes.boxLayout}>
          <h1>{title}</h1>
          <span className={classes.pagination}>
            <Pagination
              color='primary'
              className={classes.paginationComponent}
              count={numPages}
              page={page}
              onChange={handlePageChange}
            />
            <IconButton onClick={handleClick}>
              {anchorEl ? (
                <MoreVertIcon color='primary' />
              ) : (
                <MoreHorizIcon className={classes.paginationComponent} />
              )}
            </IconButton>
          </span>
        </div>
      </Grid>
      {searchStr !== '' ? (
        <Grid item xs={12} className={classes.filters}>
          {`Showing ${numItems} search results for `}
          <SearchFilterTag
            text={searchStr}
            handleDelete={() => resetQueryObj('search')}
          />
          {numFilters > 0 ? (
            <>
              {` with `}
              <SearchFilterTag
                text={`${numFilters} filter${numFilters > 1 ? 's' : ''}`}
                handleDelete={() => resetQueryObj('filters')}
              />
            </>
          ) : (
            ''
          )}
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.filters}>
          {`Showing ${numItems} results `}
          {numFilters > 0 ? (
            <>
              {` with `}
              <SearchFilterTag
                text={`${numFilters} filter${numFilters > 1 ? 's' : ''}`}
                handleDelete={() => resetQueryObj('filters')}
              />
            </>
          ) : (
            ''
          )}
        </Grid>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.issueboxFormat}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <List
          component='nav'
          className={classes.issueBoxItems}
          subheader={
            <ListSubheader component='div'>Items per page</ListSubheader>
          }>
          <ListItem
            button
            className={classes.issueBoxItem}
            selected={perPage === 10}
            onClick={(e) => handleListItemClick(e, 10)}>
            <ListItemText primary='10' />
          </ListItem>
          <ListItem
            button
            className={classes.issueBoxItem}
            selected={perPage === 20}
            onClick={(e) => handleListItemClick(e, 20)}>
            <ListItemText primary='20' />
          </ListItem>
          <ListItem
            button
            className={classes.issueBoxItem}
            selected={perPage === 50}
            onClick={(e) => handleListItemClick(e, 50)}>
            <ListItemText primary='50' />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

// Proptypes
ArchiveHeader.propTypes = {
  title: PropTypes.string,
  numItems: PropTypes.number,
  queryObj: PropTypes.object,
  setQueryObj: PropTypes.func,
  resetQueryObj: PropTypes.func,
};

export default ArchiveHeader;
