import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    paddingBottom: 0,
  },
  text: {
    marginBottom: 0,
  },
});

export default useStyles;
