import React from 'react';

// Components
import AuthContainer from '../../components/auth-container/auth-container.component';
import AuthNav from '../../components/auth-nav/auth-nav.component';

// Material UI
import Typography from '@material-ui/core/Typography';

const ForgotPasswordSuccess = () => {
  return (
    <AuthContainer>
      <Typography>
        Please check your email. The reset link is valid for the next 10 minutes.
      </Typography>
      <AuthNav />
    </AuthContainer>
  );
};

export default ForgotPasswordSuccess;
