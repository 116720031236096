import {
  GET_ALL_PROJECTS_START,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_FAILURE,
  GET_PROJECT_BY_ID_START,
  GET_PROJECT_BY_ID_SUCCESS,
  GET_PROJECT_BY_ID_FAILURE,
  ADD_ATTACHMENT_PROJECT_START,
  ADD_ATTACHMENT_PROJECT_SUCCESS,
  ADD_ATTACHMENT_PROJECT_FAILURE,
  DELETE_ATTACHMENT_PROJECT_START,
  DELETE_ATTACHMENT_PROJECT_SUCCESS,
  DELETE_ATTACHMENT_PROJECT_FAILURE,
  UPDATE_PROJECT_START,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_PHOTO_START,
  UPDATE_PROJECT_PHOTO_SUCCESS,
  UPDATE_PROJECT_PHOTO_FAILURE,
} from '../types';

// Utility Functions
import { arrToObj } from '../../utils/utilityFns';

const INITIAL_STATE = {
  projects: {},
};

const projectReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PROJECTS_START:
    case GET_PROJECT_BY_ID_START:
      return {
        ...state,
        projects: {},
      };
    case GET_ALL_PROJECTS_SUCCESS:
    case GET_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        projects: arrToObj(payload, '_id'),
      };
    case ADD_ATTACHMENT_PROJECT_SUCCESS:
    case DELETE_ATTACHMENT_PROJECT_SUCCESS:
      return {
        ...state,
        projects: { ...state.projects, [payload.id]: payload },
      };
    case GET_ALL_PROJECTS_FAILURE:
    case GET_PROJECT_BY_ID_FAILURE:
      return {
        ...state,
        projects: {},
      };
    case ADD_ATTACHMENT_PROJECT_START:
    case ADD_ATTACHMENT_PROJECT_FAILURE:
    case DELETE_ATTACHMENT_PROJECT_START:
    case DELETE_ATTACHMENT_PROJECT_FAILURE:
    case UPDATE_PROJECT_START:
    case UPDATE_PROJECT_FAILURE:
    case UPDATE_PROJECT_PHOTO_START:
    case UPDATE_PROJECT_PHOTO_FAILURE:
    default: {
      return state;
    }
  }
};

export default projectReducer;
