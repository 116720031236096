import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  projectMenu: {
    display: 'flex',
    height: '38px',
    alignItems: 'center',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '6px',
    borderTopLeftRadius: '6px',
    padding: '0px 0px',
    background: 'rgba(255,255,255,0.15)',
    '&:hover': {
      cursor: 'pointer',
      background: 'rgba(255,255,255,0.2)',
    },
  },
  projectCode: {
    fontSize: '1.15rem',
    fontWeight: 900,
    padding: '0px 12px',
  },
  chip: {
    backgroundColor: '#ccc',
    fontWeight: 700,
    fontSize: '0.78rem',
    color: 'white',
    padding: '2px 8px',
    borderRadius: '16px',
  },
  chipUpdates: {
    backgroundColor: theme.custom.statusRed,
  },
  chipActive: {
    backgroundColor: theme.custom.statusGreen,
  },
  small: {
    width: '38px',
    height: '38px',
    fontWeight: 900,
    backgroundColor: 'orange',
    outline: `4px solid ${theme.palette.primary.darkest}`,
    border: '2px solid #fff',
  },
  root: {
    width: '100%',
    minWidth: 300,
    maxWidth: 360,
    backgroundColor: '#FFF',
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'inherit',
      cursor: 'pointer',
    },
  },
  iconButton: {
    color: '#fff',
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  listItemActive: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '&:hover': {
      cursor: 'unset',
    },
  },
  listItemAll: {
    textAlign: 'center',
    padding: '12px 0px',
    color: '#676767',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  popover: {
    '& .MuiPaper-rounded': {
      borderRadius: '6px',
      borderTopRightRadius: 0,
      top: '64px!important',
      [theme.breakpoints.down('xs')]: {
        top: '56px!important',
      },
    },
  },
  link: {
    cursor: 'pointer',
  },
}));

export default useStyles;
