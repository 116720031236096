import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Styles
import useStyles from './gantt-lines.styles';

function GanttLines({ numMonths, monthStr, today }) {
  const classes = useStyles({ monthStr });
  return (
    <div className={clsx(classes.row, classes.lines)}>
      <div></div>
      <div></div>
      {Array.from({ length: numMonths }, (el, idx) => {
        return idx !== today ? (
          <span key={idx}></span>
        ) : (
          <span key={idx} className={classes.marker}></span>
        );
      })}
    </div>
  );
}

GanttLines.propTypes = {
  numMonths: PropTypes.number.isRequired,
  monthStr: PropTypes.string.isRequired,
  today: PropTypes.number,
};

export default GanttLines;
