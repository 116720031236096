import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

// Styles
import useStyles from './search-bar.styles';

const SearchBar = ({ setQueryObj, title }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const handleTextInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleTextInputKey = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setQueryObj((prev) => ({ ...prev, page: 1, searchStr: search }), setSearch(''));
    }
  };

  return (
    <>
      <IconButton className={classes.iconButton} aria-label='menu' disabled={true}>
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        value={search}
        placeholder={title}
        inputProps={{ 'aria-label': 'search issue archive' }}
        onChange={handleTextInputChange}
        onKeyDown={handleTextInputKey}
      />
    </>
  );
};

SearchBar.propTypes = {
  title: PropTypes.string,
  setQueryObj: PropTypes.func,
};

SearchBar.defaultProps = {
  title: 'Search',
};

export default SearchBar;
