import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'grid',
    height: '100%',
    width: '100%',
    position: 'absolute',
    gridTemplateColumns: (props) => props.todayStr,
    backgroundColor: 'transparent',
  },
  todayLine: {
    gridColumnStart: 4,
    backgroundColor: theme.custom.ganttTodayLine,
    zIndex: 1,
  },
  todayFlagWrapper: {
    gridColumnStart: 5,
    alignSelf: 'flex-end',
    zIndex: 6,
  },
  todayFlag: {
    color: '#FFF',
    fontSize: '0.65rem',
    textTransform: 'uppercase',
    backgroundColor: theme.custom.ganttTodayFlag,
    letterSpacing: '0.1em',
    fontWeight: 700,
    lineHeight: 0.5,
    padding: '8px 12px 8px 9px',
    width: '54px',
    textAlign: 'center',
    transform: 'translateX(-54px)',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  hide: {
    display: 'none',
  },
}));

export default useStyles;
