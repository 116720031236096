import { useState, useEffect } from 'react';

// Utilites
import { formatDateBox, isValidDate } from '../utils/utilityFns';

import {
  SEVERITY_RANGE_LOW as low,
  SEVERITY_RANGE_HIGH as high,
} from '../utils/constants';

const useFilters = (project, queryObj, filterOpen) => {
  const now = new Date();

  // Define Default Values
  const dateRangeStart = now;
  const dateRangeEnd = now;
  const lowerSevVal = low;
  const upperSevVal = high;
  const hideResolved = false;
  const hideDismissed = false;
  const defaultSites = [];
  const minIssues = 0;

  // Store them in state
  const [filters, setFilters] = useState({
    startDate: formatDateBox(dateRangeStart),
    endDate: formatDateBox(dateRangeEnd),
    severityLow: lowerSevVal,
    severityHigh: upperSevVal,
    resolveCheck: hideResolved,
    dismissCheck: hideDismissed,
    selectedSites: defaultSites,
    minIssues: minIssues,
  });

  // Every time menu is opened (via filterOpen) fire off effect
  useEffect(() => {
    // Reset State
    const startDate = project.hasOwnProperty('createdAt')
      ? new Date(project.createdAt)
      : new Date();

    const endDate = new Date();

    setFilters((prev) => ({
      ...prev,
      startDate: formatDateBox(startDate),
      endDate: formatDateBox(endDate),
      severityLow: low,
      severityHigh: high,
      resolveCheck: false,
      dismissCheck: false,
      selectedSites: project.hasOwnProperty('sites')
        ? project.sites.map((el) => ({ name: el.name, _id: el._id }))
        : [],
      minIssues: 0,
    }));

    // Process Query Object
    if (queryObj && Object.keys(queryObj).length !== 0) {
      // Process Range Array
      if (queryObj.rangeArr && queryObj.rangeArr.length !== 0) {
        queryObj.rangeArr.forEach((el) => {
          if (el.key === 'createdAt') {
            if (el.lowerVal && isValidDate(el.lowerVal)) {
              setFilters((prev) => ({
                ...prev,
                startDate: formatDateBox(new Date(el.lowerVal)),
              }));
            }
            if (el.upperVal && isValidDate(el.upperVal)) {
              setFilters((prev) => ({
                ...prev,
                endDate: formatDateBox(new Date(el.upperVal)),
              }));
            }
          } else if (el.key === 'currentCategorization') {
            if (
              el.lowerVal &&
              Number.isInteger(el.lowerVal) &&
              el.lowerVal >= low &&
              el.lowerVal <= high
            ) {
              setFilters((prev) => ({
                ...prev,
                severityLow: el.lowerVal,
              }));
            }
            if (
              el.upperVal &&
              Number.isInteger(el.upperVal) &&
              el.upperVal >= low &&
              el.upperVal <= high
            ) {
              setFilters((prev) => ({
                ...prev,
                severityHigh: el.upperVal,
              }));
            }
          } else if (el.key === 'isResolved') {
            if (
              el.notEqualTo &&
              (el.notEqualTo === true || el.notEqualTo === false)
            ) {
              setFilters((prev) => ({
                ...prev,
                resolveCheck: el.notEqualTo,
              }));
            }
          } else if (el.key === 'isDismissed') {
            if (
              el.notEqualTo &&
              (el.notEqualTo === true || el.notEqualTo === false)
            ) {
              setFilters((prev) => ({
                ...prev,
                dismissCheck: el.notEqualTo,
              }));
            }
          } else if (el.key === 'issueCount') {
            if (
              el.lowerVal &&
              Number.isInteger(el.lowerVal) &&
              el.lowerVal >= 0
            ) {
              setFilters((prev) => ({
                ...prev,
                minIssues: el.lowerVal,
              }));
            }
          }
        });
      }

      // Process Limit Object for Sites, Users, Submissions
      if (
        project &&
        project.sites &&
        project.sites.length !== 0 &&
        Object.keys(queryObj.limitObj).length > 0 &&
        Object.keys(queryObj.limitObj).includes('site._id')
      ) {
        const limitedSites = queryObj.limitObj['site._id'];
        setFilters((prev) => ({
          ...prev,
          selectedSites: prev.selectedSites.filter((site) =>
            limitedSites.includes(site._id)
          ),
        }));
      }
    }
  }, [project, queryObj, filterOpen]);

  return [filters, setFilters];
};

export default useFilters;
