import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import RoomIcon from '@material-ui/icons/Room';

// Custom Hooks
import useFilters from '../../hooks/useFilters';

// Styles
import useStyles from './filter-menu.styles';

// Selectors
import { selectCurrentProject } from '../../redux/project/project.selectors';

// Utilites
import {
  SLIDER_MARKS as sliderMarks,
  SLIDER_MARKS_SUBMISSION as submissionSliderMarks,
  SEVERITY_RANGE_LOW as low,
  SEVERITY_RANGE_HIGH as high,
} from '../../utils/constants';
import { parseFilters } from '../../utils/apiFeatures';

const FilterMenu = ({
  filterOpen,
  anchorElFilter,
  handleFilterMenuClose,
  project,
  queryObj,
  setQueryObj,
}) => {
  const classes = useStyles();
  const [filters, setFilters] = useFilters(project, queryObj, filterOpen);

  const handleClick = (_, el) => {
    if (filters.selectedSites.map((elem) => elem._id).includes(el._id)) {
      setFilters((prev) => ({
        ...prev,
        selectedSites: prev.selectedSites.filter((site) => site._id !== el._id),
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        selectedSites: [...prev.selectedSites, el],
      }));
    }
  };

  const handleSliderChange = (_, newValue) => {
    setFilters((prev) => ({
      ...prev,
      severityLow: newValue[0],
      severityHigh: newValue[1],
    }));
  };

  const handleSubmissionSliderChange = (_, newValue) => {
    setFilters((prev) => ({
      ...prev,
      minIssues: newValue,
    }));
  };

  const handleSelectAll = () => {
    setFilters((prev) => ({
      ...prev,
      selectedSites: project.sites,
    }));
  };

  const handleDeselectAll = () => {
    setFilters((prev) => ({
      ...prev,
      selectedSites: [],
    }));
  };

  const handleDateChange = (e) => {
    if (e.target.id === 'startDate') {
      setFilters((prev) => ({
        ...prev,
        startDate: e.target.value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        endDate: e.target.value,
      }));
    }
  };

  const handleCheckboxToggle = (type) => {
    switch (type) {
      case 'resolve':
        setFilters((prev) => ({
          ...prev,
          resolveCheck: !prev.resolveCheck,
        }));
        break;
      case 'dismiss':
        setFilters((prev) => ({
          ...prev,
          dismissCheck: !prev.dismissCheck,
        }));
        break;
      default:
        break;
    }
  };

  const handleReset = () => {
    setQueryObj((prev) => ({
      ...prev,
      rangeArr: [],
      limitObj: {},
    }));
  };

  const handleApply = () => {
    const [limitObj, rangeArr] = parseFilters(project, filters);
    setQueryObj((prev) => ({
      ...prev,
      rangeArr: rangeArr,
      limitObj: limitObj,
    }));
  };

  return (
    <Popover
      open={filterOpen}
      anchorEl={anchorElFilter}
      onClose={handleFilterMenuClose}
      className={classes.filterPopoverRoot}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <List
        component='nav'
        subheader={
          <ListSubheader className={classes.filterPopoverSubheader}>
            Filter Issue Archive By Site &amp; Date
          </ListSubheader>
        }>
        <div className={classes.chipSelect}>
          <div className={classes.chipSelectAction} onClick={handleSelectAll}>
            Select All
          </div>
          <div className={classes.chipSelectAction} onClick={handleDeselectAll}>
            Deselect All
          </div>
        </div>
        <ListItem>
          <div className={classes.chips}>
            {project.sites.map((el) => (
              <Chip
                icon={<RoomIcon />}
                key={el._id}
                label={el.name}
                size='small'
                className={classes.chip}
                color={
                  filters.selectedSites
                    .map((elem) => elem.name)
                    .includes(el.name)
                    ? 'primary'
                    : 'default'
                }
                onClick={(e) => handleClick(e, { name: el.name, _id: el._id })}
              />
            ))}
          </div>
        </ListItem>
        <ListItem className={classes.listItem}>
          <TextField
            id='startDate'
            label='Start Date'
            type='date'
            value={filters.startDate}
            onChange={(e) => handleDateChange(e)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id='endDate'
            label='End Date'
            type='date'
            value={filters.endDate}
            onChange={(e) => handleDateChange(e)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </ListItem>
        {queryObj.pageTitle === 'issue' ? (
          <>
            <ListSubheader className={classes.filterPopoverSubheader}>
              Filter By Issue Severity
            </ListSubheader>
            <ListItem className={classes.listItemSlider}>
              <Slider
                className={classes.slider}
                value={[filters.severityLow, filters.severityHigh]}
                onChange={handleSliderChange}
                valueLabelDisplay='off'
                step={1}
                marks={sliderMarks}
                min={low}
                max={high}
              />
            </ListItem>
          </>
        ) : queryObj.pageTitle === 'submission' ? (
          <>
            <ListSubheader className={classes.filterPopoverSubheader}>
              Filter By Issue Count
            </ListSubheader>
            <ListItem className={classes.listItemSlider}>
              <Slider
                className={classes.slider}
                value={filters.minIssues}
                onChange={handleSubmissionSliderChange}
                valueLabelDisplay='off'
                step={1}
                marks={submissionSliderMarks}
                min={0}
                max={2}
              />
            </ListItem>
          </>
        ) : null}
        {queryObj.pageTitle === 'issue' ? (
          <>
            <ListSubheader className={classes.filterPopoverSubheader}>
              Other Options
            </ListSubheader>
            <ListItem className={classes.listItemOther}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={filters.dismissCheck}
                      onChange={() => handleCheckboxToggle('dismiss')}
                    />
                  }
                  label='Exclude Dismissed'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={filters.resolveCheck}
                      onChange={() => handleCheckboxToggle('resolve')}
                    />
                  }
                  label='Exclude Resolved'
                />
              </FormGroup>
            </ListItem>
          </>
        ) : null}
        <ListItem className={classes.buttons}>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            onClick={handleApply}
            disabled={filters.selectedSites.length === 0 ? true : false}>
            Apply Filters
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            size='small'
            onClick={handleReset}>
            Clear All Filters
          </Button>
        </ListItem>
      </List>
    </Popover>
  );
};

// Proptypes
FilterMenu.propTypes = {
  filterOpen: PropTypes.bool.isRequired,
  anchorElFilter: PropTypes.oneOfType([
    PropTypes.oneOf([null]).isRequired,
    PropTypes.instanceOf(Element).isRequired,
  ]),
  handleFilterMenuClose: PropTypes.func.isRequired,
  project: PropTypes.oneOfType([
    PropTypes.oneOf([null]).isRequired,
    PropTypes.object.isRequired,
  ]),
  queryObj: PropTypes.object.isRequired,
  setQueryObj: PropTypes.func.isRequired,
};

FilterMenu.defaultProps = {
  project: { sites: [], createdAt: '1/1/2020' },
  queryObj: {},
};

const mapStateToProps = createStructuredSelector({
  project: selectCurrentProject,
});

export default connect(mapStateToProps)(FilterMenu);
