import {
  START_UI_LOADING_ACTION,
  STOP_UI_LOADING_ACTION,
  START_API_LOADING_FETCH,
  STOP_API_LOADING_FETCH,
} from '../types';

export const startUiAction = ({ id, name, actionType, params }) => ({
  type: START_UI_LOADING_ACTION,
  payload: {
    uiAction: {
      id,
      name,
      actionType,
      params,
    },
  },
});

export const stopUiAction = (id) => ({
  type: STOP_UI_LOADING_ACTION,
  payload: { id },
});

export const startFetchAction = ({ id, name, actionType, params }) => ({
  type: START_API_LOADING_FETCH,
  payload: {
    apiAction: {
      id,
      name,
      actionType,
      params,
    },
  },
});

export const stopFetchAction = ({ id, status }) => ({
  type: STOP_API_LOADING_FETCH,
  payload: { id, status },
});
