import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  cardHeader: {
    backgroundColor: theme.palette.primary.darkest,
    color: theme.palette.primary.contrastText,
    '& div, span': {
      color: theme.custom.lightergrey,
      '&:first-of-type': {
        fontWeight: 700,
      },
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    borderBottom: `1px solid ${theme.custom.ganttGrid}`,
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(0.9)',
    },
  },
  cardActions: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'none',
    },
  },
  photoBadgeContainer: {
    position: 'relative',
    height: '32px',
    top: '-16px',
    marginBottom: '-32px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  photoBadgeNoPhotoSmallAdjustment: {
    [theme.breakpoints.down('xs')]: {
      top: '15px',
    },
  },
  photoBadge: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: theme.custom.statusRed,
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    color: theme.custom.lightestgrey,
    fontWeight: 700,
    marginLeft: '-12px',
    zIndex: 2,
    fontSize: '1.1rem',
    lineHeight: 1.1,
    border: `2px solid ${theme.palette.background.paper}`,
  },
  photoBadgeLarge: {
    color: '#f1f1f1',
    minWidth: '60px',
    paddingLeft: '8px',
    paddingRight: '8px',
    border: '2px solid #fff',
    height: '30px',
    display: 'flex',
    zIndex: '2',
    fontSize: '0.9rem',
    alignItems: 'center',
    fontWeight: '900',
    lineHeight: '1.1',
    marginRight: '18px',
    borderRadius: '20px',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.darkest,
  },
  photoCountNumber: {
    transform: 'translateY(1px)',
  },
  photoIcon: {
    width: '0.8em',
    height: '0.8em',
    marginLeft: '3px',
  },
  issueBadgeLarge: {
    color: '#f1f1f1',
    width: '90px',
    border: '2px solid #fff',
    height: '30px',
    display: 'flex',
    zIndex: '2',
    fontSize: '0.9rem',
    alignItems: 'center',
    fontWeight: '900',
    lineHeight: '1.1',
    marginRight: '8px',
    borderRadius: '20px',
    justifyContent: 'center',
    backgroundColor: theme.custom.statusRed,
    '&:hover': {
      backgroundColor: theme.custom.statusRedBorderAlt,
      cursor: 'pointer',
    },
  },
  link: {
    textDecoration: 'none',
  },
  calContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '46px',
    height: '46px',
    transform: 'translate(-8px,5px)',
  },
  calMonth: {
    borderBottom: '1px solid #0a3444',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '900',
    fontSize: '0.8rem',
    backgroundColor: '#ffffff63',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    color: '#fffffff2',
    height: '18px',
    alignItems: 'center',
    letterSpacing: '0.5px',
  },
  calDay: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.25rem',
    fontWeight: '700',
    backgroundColor: '#ffffff3d',
    height: '28px',
    alignItems: 'center',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    color: '#fffffff2',
  },
  issueButton: {
    '& svg': {
      fill: theme.custom.statusRed,
    },
  },
  hideMedia: {
    cursor: 'not-allowed',
    '&:hover': {
      filter: 'unset',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      paddingTop: 0,
    },
  },
}));

export default useStyles;
