import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiCardHeader-root': {
      padding: '12px',
      minHeight: '96px',
    },
    '& .MuiCardContent-root': {
      minHeight: '80px',
    },
  },
  actions: {
    justifyContent: 'space-between',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
