import { data } from './feed.seed';

const INITIAL_STATE = {
  items: data,
  numItems: data.length,
  status: 'idle',
};

const feedReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
  }
};

export default feedReducer;
