import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '28px',
    height: '28px',
    '& svg': {
      width: '0.7em',
      height: '0.7em',
    },
  },
  largeAvatar: {
    width: '48px',
    height: '48px',
  },
  user: {
    padding: '8px 0px',
    fontWeight: 700,
    color: theme.custom.statusContrastTextAlt,
  },
  root: {
    width: '100%',
    minWidth: 220,
    maxWidth: 220,
    backgroundColor: '#FFF',
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'inherit',
      cursor: 'pointer',
    },
  },
  userContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '12px',
  },
  iconButton: {
    color: '#fff',
  },
  list: {
    '&:last-of-type': {},
  },
  listItem: {
    cursor: 'pointer',
    paddingTop: '8px',
    paddingBottom: '8px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[100],
    },
  },
  listText: {
    color: '#333',
  },
  popover: {
    '& .MuiPaper-rounded': {
      borderRadius: '6px',
      borderTopRightRadius: 0,
      top: '64px!important',
      [theme.breakpoints.down('xs')]: {
        top: '56px!important',
      },
    },
  },
  link: {
    cursor: 'pointer',
  },
  formRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '12px',
  },
  formControl: {
    padding: 0,
    minWidth: '140px',
  },
  languageSelect: {
    borderRadius: '4px!important',
    '& select': {
      padding: '12px 8px',
    },
  },
  signout: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '12px',
  },
  button: {
    borderRadius: '8px',
    padding: '4px 14px',
    fontSize: '0.8rem',
  },
}));

export default useStyles;
