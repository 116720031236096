import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Components
import GanttHeader from '../gantt-header/gantt-header.component';
import GanttLines from '../gantt-lines/gantt-lines.component';
import GanttToday from '../gantt-today/gantt-today.component';
import GanttRow from '../gantt-row/gantt-row.component';
import GanttRowHidden from '../gantt-row-hidden/gantt-row-hidden.component';

// Styles
import useStyles from './gantt-chart.styles';

// Utilities
import { sortAlpha } from '../../utils/utilityFns';

function GanttChart({
  ganttOverview: {
    calWeekArr,
    calMonthArr,
    calYearArr,
    weekStr,
    monthStr,
    yearStr,
    todayLineGrid,
    highlightMonth,
    ganttArr,
    hiddenArr,
  },
  activeSiteId,
  editMode,
  hideCalElements,
  handlePopoverToggle,
  handleModIdsToggle,
  modIds,
  expanded,
  setShowExpand,
}) {
  const filteredGantt =
    activeSiteId === '-1'
      ? ganttArr.filter((m) => m.type === 'site')
      : ganttArr.filter((m) => activeSiteId === m.siteId);

  const shouldExpand =
    activeSiteId === '-1' ? filteredGantt.length >= 10 : false;

  const filteredHidden =
    activeSiteId === '-1'
      ? []
      : hiddenArr
          .filter((m) => activeSiteId === m.siteId)
          .sort(sortAlpha('name', 'asc'));

  useEffect(() => {
    setShowExpand(shouldExpand);
  }, [shouldExpand, setShowExpand]);

  const classes = useStyles({
    rootMaxHeight: shouldExpand && !expanded ? '600px' : 'unset',
  });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <GanttHeader
            weekArr={calWeekArr}
            monthArr={calMonthArr}
            yearArr={calYearArr}
            weekStr={weekStr}
            monthStr={monthStr}
            yearStr={yearStr}
            hideCalElements={hideCalElements}
            handlePopoverToggle={handlePopoverToggle}
          />
          <GanttLines
            numMonths={calMonthArr.length}
            monthStr={monthStr}
            today={hideCalElements.hideMonth ? -1 : highlightMonth}
          />
          <GanttToday
            todayStr={todayLineGrid}
            hideToday={hideCalElements.hideToday}
          />
          <div
            className={clsx(
              classes.rowWrapper,
              hideCalElements.hideToday ? null : classes.rowWrapperShowToday
            )}>
            {filteredGantt.map((milestone, idx) => (
              <GanttRow
                el={milestone}
                key={`milestone-${idx}`}
                hideCalElements={hideCalElements}
                handlePopoverToggle={handlePopoverToggle}
                activeSiteId={activeSiteId}
                editMode={editMode}
                modIds={modIds}
                handleModIdsToggle={handleModIdsToggle}
              />
            ))}
            {filteredHidden.map((milestone, idx) => (
              <GanttRowHidden
                el={milestone}
                key={`hidden-${idx}`}
                hideCalElements={hideCalElements}
                handlePopoverToggle={handlePopoverToggle}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

GanttChart.propType = {
  ganttOverview: PropTypes.object.isRequired,
  hideCalElements: PropTypes.object.isRequired,
  handlePopoverToggle: PropTypes.func.isRequired,
  activeSiteId: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  modIds: PropTypes.array.isRequired,
  handleModIdsToggle: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  setShowExpand: PropTypes.func.isRequired,
};

export default GanttChart;
