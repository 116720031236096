import { createSelector } from 'reselect';
import bbox from '@turf/bbox';

// Selectors
import { selectCurrentProject } from '../project/project.selectors';

// Utilities
import {
  sortAlpha,
  dateToTimeAgo,
  formatDateTime,
} from '../../utils/utilityFns';
import { MAP_BOUNDS } from '../../utils/constants';

const selectSiteSlice = (state) => state.site;

export const selectAllSites = createSelector(
  [selectSiteSlice],
  (siteSlice) => siteSlice.sites
);

export const selectActiveSiteId = createSelector(
  [selectSiteSlice],
  (siteSlice) => siteSlice.activeSiteId
);

export const selectActiveSiteSubmissionHeaderDashboard = createSelector(
  [selectAllSites, selectActiveSiteId],
  (sites, activeSiteId) => {
    const defaultValues = {
      _id: '-1',
      name: '',
      titleStr: 'Recent Submissions',
      queryStr: '',
    };
    if (
      !activeSiteId ||
      activeSiteId === '-1' ||
      !sites ||
      !sites[activeSiteId]
    ) {
      return defaultValues;
    } else if (activeSiteId !== '-1' && sites[activeSiteId]) {
      return {
        _id: sites[activeSiteId]._id,
        name: sites[activeSiteId].name,
        titleStr: `Recent Submissions: ${sites[activeSiteId].name}`,
        queryStr: `?site._id=${sites[activeSiteId]._id}`,
      };
    } else {
      return defaultValues;
    }
  }
);

export const selectSiteDropdownList = createSelector(
  [selectAllSites],
  (sites) => {
    return Object.values(sites)
      .map((el) => ({ _id: el.id, name: el.name }))
      .sort(sortAlpha('name', 'asc'));
  }
);

export const selectSiteDictionary = createSelector(
  [selectAllSites],
  (sites) => {
    return Object.values(sites).reduce(
      (acc, cur) => ({ ...acc, [cur._id]: cur.name }),
      {}
    );
  }
);

export const selectSiteDictionaryShortName = createSelector(
  [selectAllSites],
  (sites) => {
    return Object.values(sites).reduce(
      (acc, cur) => ({
        ...acc,
        [cur._id]: cur.shortName ? cur.shortName : cur.name,
      }),
      {}
    );
  }
);

export const selectSiteGroupedListName = createSelector(
  [selectAllSites],
  (sites) => {
    return Object.values(sites).reduce(
      (acc, cur) => ({
        ...acc,
        [cur._id]: cur.groupedListName ? cur.groupedListName : cur.name,
      }),
      {}
    );
  }
);

export const selectSiteAndProjectList = createSelector(
  [selectAllSites, selectCurrentProject],
  (sites, project) => {
    if (
      !sites ||
      !project ||
      Object.keys(project).length < 1 ||
      Object.keys(sites).length < 1
    ) {
      return [{ _id: 'no_id', name: 'Welcome!', value: '-1' }];
    } else {
      const arr = Object.values(sites)
        .map((el) => ({ _id: el._id, name: el.name, value: el._id }))
        .sort(sortAlpha('name', 'asc'));

      arr.unshift({ _id: project._id, name: project.name, value: '-1' });
      return arr;
    }
  }
);

export const selectActiveSiteOrProject = createSelector(
  [selectSiteSlice, selectCurrentProject],
  (siteSlice, project) => {
    if (siteSlice.activeSiteId !== '-1') {
      return siteSlice.sites[siteSlice.activeSiteId];
    }
    return project;
  }
);

export const selectSiteBounds = createSelector([selectAllSites], (sites) => {
  if (Object.keys(sites).length < 1) {
    return MAP_BOUNDS;
  } else {
    const points = Object.keys(sites).map((siteId) => [
      sites[siteId].lng,
      sites[siteId].lat,
    ]);

    const pad = 0.01; // FIXME: This is arbitary

    const boundingBox = bbox({
      type: 'Polygon',
      coordinates: [points],
    });
    console.log(boundingBox);

    return [
      [
        boundingBox[1] - boundingBox[1] * pad,
        boundingBox[0] - boundingBox[0] * pad,
      ],
      [
        boundingBox[3] + boundingBox[3] * pad,
        boundingBox[2] + boundingBox[2] * pad,
      ],
    ];
  }
});
