import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  textInput: {
    width: '350px',
    marginBottom: '12px',
    display: 'flex',
  },
});

export default useStyles;
