import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: '4px',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  defaultText: {
    fontSize: '0.85rem',
    fontWeight: 400,
    color: '#000',
    lineHeight: 1.1,
  },
  actionText: {
    fontSize: '0.85rem',
    fontWeight: 700,
    color: '#464646',
    lineHeight: 1.1,
  },
  verboseText: {
    fontSize: '0.85rem',
    fontWeight: 400,
    lineHeight: 1.1,
  },
  authorText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '0.85rem',
    lineHeight: 1.1,
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '0.85rem',
    lineHeight: 1.1,
    cursor: 'pointer',
    '&:hover': {
      color: theme.custom.primaryHover,
    },
  },
  hidden: {
    display: 'none',
  },
  recatRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    minWidth: '32px',
    padding: '1px 6px',
    backgroundColor: 'transparent',
    color: '#fff',
    fontSize: '0.75rem',
    fontWeight: 900,
    lineHeight: 'normal',
  },
  none: {
    backgroundColor: theme.custom.issueUncatBg,
  },
  low: {
    backgroundColor: theme.custom.issueLowBg,
  },
  medium: {
    backgroundColor: theme.custom.issueMediumBg,
  },
  high: {
    backgroundColor: theme.custom.issueHighBg,
  },
  offTrack: {
    backgroundColor: theme.custom.issueOffTrackBg,
  },
}));

export default useStyles;
