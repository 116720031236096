import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// Action Types
import {
  UPDATE_ISSUE_START,
  GET_OUTSTANDING_ISSUES_START,
  GET_FILTERED_ISSUES_START,
  DELETE_ISSUE_UPDATE_START,
  UPDATE_ISSUE_SEEN_START,
  GET_SINGLE_ISSUE_START,
  DELETE_ISSUE_SHARE_START,
  CREATE_ISSUE_SHARE_START,
} from '../types';

// Action Creators
import {
  getOutstandingIssuesSuccess,
  getOutstandingIssuesFailure,
  getFilteredIssuesSuccess,
  getFilteredIssuesFailure,
  updateIssueSuccess,
  updateIssueFailure,
  deleteIssueUpdateSuccess,
  deleteIssueUpdateFailure,
  updateIssueSeenSuccess,
  updateIssueSeenFailure,
  getSingleIssueSuccess,
  getSingleIssueFailure,
  deleteIssueShareSuccess,
  deleteIssueShareFailure,
  createIssueShareSuccess,
  createIssueShareFailure,
} from './issue.actions';
import { startAlert } from '../alert/alert.actions';
import {
  startFetchAction,
  stopFetchAction,
  startUiAction,
  stopUiAction,
} from '../loading/loading.actions';
import { updateIssueNotesModal } from '../ui/ui.actions';

// Utility Functions
import { generateQueryStr } from '../../utils/apiFeatures';

/**
 * Workers
 */
export function* getOutstandingIssues({ payload }) {
  const { id, projectId } = payload;

  try {
    yield put(startFetchAction(payload));
    const resIssue = yield axios.get(
      `/api/v1/projects/${projectId}/issues?isResolved=0`
    );
    yield put(getOutstandingIssuesSuccess(resIssue.data.data));
    yield put(stopFetchAction({ id, status: 'success' }));
  } catch (err) {
    yield put(getOutstandingIssuesFailure());
    yield put(stopFetchAction({ id, status: 'error' }));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* getFilteredIssues({ payload: { projectId, queryObj } }) {
  const actionObj = {
    id: uuidv4(),
    name: 'issue archive',
    actionType: 'issues',
    projectId: projectId,
  };

  const queryStr = yield call(generateQueryStr, queryObj);

  try {
    yield put(startFetchAction(actionObj));
    const resIssue = yield axios.get(
      `/api/v1/projects/${projectId}/issues${queryStr}`
    );
    yield put(getFilteredIssuesSuccess(resIssue.data.data));
    yield put(stopFetchAction({ id: actionObj.id, status: 'success' }));
  } catch (err) {
    yield put(getFilteredIssuesFailure());
    yield put(stopFetchAction({ id: actionObj.id, status: 'error' }));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updateIssue({
  payload: { docId, formData, actionType, projectId },
}) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'issue',
        actionType: actionType,
        params: { docId: docId },
      })
    );
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/issues/${docId}/update`,
      formData
    );
    yield put(updateIssueSuccess(res.data.data.docs));
    yield put(stopUiAction(id));
    if (actionType === 'Updated') {
      yield put(updateIssueNotesModal(res.data.data.docs.updates));
    }
    yield put(startAlert({ msg: 'Success', alertType: 'success' }));
  } catch (err) {
    yield put(updateIssueFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* deleteUpdate({ payload: { docId, embeddedId, projectId } }) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'issue modal',
        actionType: 'issue',
        params: { docId, embeddedId },
      })
    );
    const resIssue = yield axios.delete(
      `/api/v1/projects/${projectId}/issues/${docId}/embeddedField/updates/embeddedId/${embeddedId}`
    );
    yield put(
      deleteIssueUpdateSuccess({
        docId,
        updates: resIssue.data.data.docs.updates,
      })
    );
    yield put(updateIssueNotesModal(resIssue.data.data.docs.updates));
    yield put(
      startAlert({
        msg: 'Success!',
        alertType: 'success',
      })
    );
    yield put(stopUiAction(id));
  } catch (err) {
    yield put(deleteIssueUpdateFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updateSeen({ payload: { docId, projectId } }) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'issue modal',
        actionType: 'issue',
        params: { docId },
      })
    );
    const resIssue = yield axios.patch(
      `/api/v1/projects/${projectId}/issues/${docId}/seen`
    );
    yield put(
      updateIssueSeenSuccess({
        docId,
        updates: resIssue.data.data.docs.updates,
      })
    );
    yield put(stopUiAction(id));
  } catch (err) {
    yield put(updateIssueSeenFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* getSingleIssue({ payload }) {
  const { projectId, docId } = payload;
  const fetchId = uuidv4();
  const fetchObj = {
    id: fetchId,
    name: 'single issue',
    actionType: 'issues',
  };

  try {
    yield put(startFetchAction(fetchObj));
    const resIssue = yield axios.get(
      `/api/v1/projects/${projectId}/issues/${docId}`
    );
    yield put(getSingleIssueSuccess(resIssue.data.data));
    yield put(stopFetchAction({ id: fetchId, status: 'success' }));
  } catch (err) {
    yield put(getSingleIssueFailure());
    yield put(stopFetchAction({ id: fetchId, status: 'error' }));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* deleteShare({ payload: { docId, projectId } }) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'delete share',
        actionType: 'issue',
        params: { docId },
      })
    );
    const res = yield axios.delete(
      `/api/v1/projects/${projectId}/issues/${docId}/deleteShare`
    );
    yield put(deleteIssueShareSuccess(res.data.data.docs));
    yield put(
      startAlert({
        msg: 'Successfully Removed Sharing Link!',
        alertType: 'success',
      })
    );
    yield put(stopUiAction(id));
  } catch (err) {
    yield put(deleteIssueShareFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* createShare({ payload: { docId, projectId, optionsObj } }) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'create share',
        actionType: 'issue',
        params: { docId },
      })
    );
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/issues/${docId}/createShare`,
      optionsObj
    );
    yield put(createIssueShareSuccess(res.data.data.docs));
    yield put(
      startAlert({
        msg: 'Successfully Created Sharing Link!',
        alertType: 'success',
      })
    );
    yield put(stopUiAction(id));
  } catch (err) {
    yield put(createIssueShareFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

/**
 * Watchers
 */
export function* onGetOutstandingIssuesStart() {
  yield takeLatest(GET_OUTSTANDING_ISSUES_START, getOutstandingIssues);
}

export function* onGetFilteredIssuesStart() {
  yield takeLatest(GET_FILTERED_ISSUES_START, getFilteredIssues);
}

export function* onUpdateIssueStart() {
  yield takeLatest(UPDATE_ISSUE_START, updateIssue);
}

export function* onDeleteUpdateStart() {
  yield takeLatest(DELETE_ISSUE_UPDATE_START, deleteUpdate);
}

export function* onUpdateIssueSeenStart() {
  yield takeLatest(UPDATE_ISSUE_SEEN_START, updateSeen);
}

export function* onGetSingleIssueStart() {
  yield takeLatest(GET_SINGLE_ISSUE_START, getSingleIssue);
}

export function* onDeleteIssueShareStart() {
  yield takeLatest(DELETE_ISSUE_SHARE_START, deleteShare);
}

export function* onCreateIssueShareStart() {
  yield takeLatest(CREATE_ISSUE_SHARE_START, createShare);
}

/**
 * Export Saga
 */
export function* issueSagas() {
  yield all([
    call(onUpdateIssueStart),
    call(onGetOutstandingIssuesStart),
    call(onGetFilteredIssuesStart),
    call(onDeleteUpdateStart),
    call(onUpdateIssueSeenStart),
    call(onGetSingleIssueStart),
    call(onDeleteIssueShareStart),
    call(onCreateIssueShareStart),
  ]);
}
