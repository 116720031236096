import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

// Custom Hooks
import useQuery from '../../hooks/useQuery';

// Material UI
import Grid from '@material-ui/core/Grid';

// Components
import MainContainer from '../../components/main-container/main-container.component';
import HeaderSearch from '../../components/header-search/header-search.component';
import IssueboxSection from '../../components/issuebox-section/issuebox-section.component';
import ArchiveHeader from '../../components/archive-header/archive-header.component';

// Actions
import { getFilteredIssuesStart } from '../../redux/issue/issue.actions';
import { getProjectByIdStart } from '../../redux/project/project.actions';
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import {
  selectNumIssues,
  selectNumIssuesDeleted,
  selectIssuesNotDeleted,
} from '../../redux/issue/issue.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';

// Utilities
import { parseQueryParams } from '../../utils/apiFeatures';
import { DEFAULT_ISSUE_QUERY as defaultIssueQuery } from '../../utils/constants';

const Issues = ({
  getFilteredIssuesStartDispatch,
  numIssues,
  numIssuesDeleted,
  getProjectByIdStartDispatch,
  issues,
  toggleModalDispatch,
  user,
}) => {
  const { projectId } = useParams();

  const query = useQuery(); //FIXME: allow range query params

  const defaultQueryObj = {
    ...defaultIssueQuery,
    limitObj: parseQueryParams(query),
  };

  const [queryObj, setQueryObj] = useState(defaultQueryObj);

  useEffect(() => {
    const fetchObj = {
      id: uuidv4(),
      name: 'submission archive',
      actionType: 'projects',
      projectId: projectId,
    };
    getProjectByIdStartDispatch(fetchObj);
    getFilteredIssuesStartDispatch(projectId, queryObj);
  }, [
    getFilteredIssuesStartDispatch,
    projectId,
    queryObj,
    getProjectByIdStartDispatch,
  ]);

  const resetQueryObj = (type) => {
    switch (type) {
      case 'search':
        setQueryObj((prev) => ({
          ...prev,
          searchStr: '',
        }));
        break;
      case 'filters':
        setQueryObj((prev) => ({
          ...prev,
          rangeArr: [],
          limitObj: {},
        }));
        break;
      default:
        setQueryObj(defaultQueryObj);
    }
  };

  return (
    <MainContainer>
      <HeaderSearch
        title='Search Issue Archive'
        queryObj={queryObj}
        setQueryObj={setQueryObj}
      />
      <Grid container spacing={0}>
        <ArchiveHeader
          title='Issue Archive'
          numItems={numIssues - numIssuesDeleted}
          queryObj={queryObj}
          setQueryObj={setQueryObj}
          resetQueryObj={resetQueryObj}
        />
        <Grid item xs={12}>
          <IssueboxSection
            title={'Archive'}
            issues={issues}
            toggleModal={toggleModalDispatch}
            lastOfType={true}
            user={user}
            showExpand={false}
            hideHeader={false}
          />
        </Grid>
      </Grid>
    </MainContainer>
  );
};

Issues.propTypes = {
  getFilteredIssuesStartDispatch: PropTypes.func.isRequired,
  numIssues: PropTypes.number.isRequired,
  getProjectByIdStartDispatch: PropTypes.func.isRequired,
  issues: PropTypes.array.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  numIssues: selectNumIssues,
  numIssuesDeleted: selectNumIssuesDeleted,
  issues: selectIssuesNotDeleted,
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  getFilteredIssuesStartDispatch: (projectId, queryObj) =>
    dispatch(getFilteredIssuesStart({ projectId, queryObj })),
  getProjectByIdStartDispatch: (fetchObj) =>
    dispatch(getProjectByIdStart(fetchObj)),
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
