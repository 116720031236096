/**
 * ALERT ACTION TYPES
 * Handle setting/removing alerts and snackbar toasts
 */
export const START_ALERT = 'START_ALERT';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

/**
 * USER ACTION TYPES
 * Handle signup, sign in, sign out, password reset/forget and user sessions
 */
export const CHECK_USER_SESSION = 'CHECK_USER_SESSION';
export const USER_SESSION_FOUND = 'USER_SESSION_FOUND';
export const USER_SESSION_NOT_FOUND = 'USER_SESSION_NOT_FOUND';
export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const SIGN_IN_START = 'SIGN_IN_START';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT_START = 'SIGN_OUT_START';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const UPDATE_PROFILE_PHOTO_START = 'UPDATE_PROFILE_PHOTO_START';
export const UPDATE_PROFILE_PHOTO_SUCCESS = 'UPDATE_PROFILE_PHOTO_SUCCESS';
export const UPDATE_PROFILE_PHOTO_FAILURE = 'UPDATE_PROFILE_PHOTO_FAILURE';
export const UPDATE_DEFAULT_PROJECT_START = 'UPDATE_DEFAULT_PROJECT_START';
export const UPDATE_DEFAULT_PROJECT_SUCCESS = 'UPDATE_DEFAULT_PROJECT_SUCCESS';
export const UPDATE_DEFAULT_PROJECT_FAILURE = 'UPDATE_DEFAULT_PROJECT_FAILURE';
export const UPDATE_PROJECT_LIST_START = 'UPDATE_PROJECT_LIST_START';
export const UPDATE_PROJECT_LIST_SUCCESS = 'UPDATE_PROJECT_LIST_SUCCESS';
export const UPDATE_PROJECT_LIST_FAILURE = 'UPDATE_PROJECT_LIST_FAILURE';
export const SET_LAST_LOGIN_DATE = 'SET_LAST_LOGIN_DATE';

/**
 * PROJECT ACTION TYPES
 * Handle project specific actions
 */
export const GET_ALL_PROJECTS_START = 'GET_ALL_PROJECTS_START';
export const GET_ALL_PROJECTS_SUCCESS = 'GET_ALL_PROJECTS_SUCCESS';
export const GET_ALL_PROJECTS_FAILURE = 'GET_ALL_PROJECTS_FAILURE';
export const GET_PROJECT_BY_ID_START = 'GET_PROJECT_BY_ID_START';
export const GET_PROJECT_BY_ID_SUCCESS = 'GET_PROJECT_BY_ID_SUCCESS';
export const GET_PROJECT_BY_ID_FAILURE = 'GET_PROJECT_BY_ID_FAILURE';
export const ADD_ATTACHMENT_PROJECT_START = 'ADD_ATTACHMENT_PROJECT_START';
export const ADD_ATTACHMENT_PROJECT_SUCCESS = 'ADD_ATTACHMENT_PROJECT_SUCCESS';
export const ADD_ATTACHMENT_PROJECT_FAILURE = 'ADD_ATTACHMENT_PROJECT_FAILURE';
export const DELETE_ATTACHMENT_PROJECT_START =
  'DELETE_ATTACHMENT_PROJECT_START';
export const DELETE_ATTACHMENT_PROJECT_SUCCESS =
  'DELETE_ATTACHMENT_PROJECT_SUCCESS';
export const DELETE_ATTACHMENT_PROJECT_FAILURE =
  'DELETE_ATTACHMENT_PROJECT_FAILURE';
export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';
export const UPDATE_PROJECT_PHOTO_START = 'UPDATE_PROJECT_PHOTO_START';
export const UPDATE_PROJECT_PHOTO_SUCCESS = 'UPDATE_PROJECT_PHOTO_SUCCESS';
export const UPDATE_PROJECT_PHOTO_FAILURE = 'UPDATE_PROJECT_PHOTO_FAILURE';

/**
 * SITE ACTION TYPES
 * Handle site specific actions
 */
export const GET_ALL_SITES_START = 'GET_ALL_SITES_START';
export const GET_ALL_SITES_SUCCESS = 'GET_ALL_SITES_SUCCESS';
export const GET_ALL_SITES_FAILURE = 'GET_ALL_SITES_FAILURE';
export const UPDATE_ACTIVE_SITE_ID = 'UPDATE_ACTIVE_SITE_ID';

/**
 * SUBMISSION ACTION TYPES
 * Handle submission specific actions
 */
export const GET_FILTERED_SUBMISSIONS_START = 'GET_FILTERED_SUBMISSIONS_START';
export const GET_FILTERED_SUBMISSIONS_SUCCESS =
  'GET_FILTERED_SUBMISSIONS_SUCCESS';
export const GET_FILTERED_SUBMISSIONS_FAILURE =
  'GET_FILTERED_SUBMISSIONS_FAILURE';
export const GET_SINGLE_SUBMISSION_START = 'GET_SINGLE_SUBMISSION_START';
export const GET_SINGLE_SUBMISSION_SUCCESS = 'GET_SINGLE_SUBMISSION_SUCCESS';
export const GET_SINGLE_SUBMISSION_FAILURE = 'GET_SINGLE_SUBMISSION_FAILURE';
export const GET_SITE_SUBMISSIONS_START = 'GET_SITE_SUBMISSIONS_START';
export const GET_SITE_SUBMISSIONS_SUCCESS = 'GET_SITE_SUBMISSIONS_SUCCESS';
export const GET_SITE_SUBMISSIONS_FAILURE = 'GET_SITE_SUBMISSIONS_FAILURE';
export const UPDATE_SUBMISSION_START = 'UPDATE_SUBMISSION_START';
export const UPDATE_SUBMISSION_SUCCESS = 'UPDATE_SUBMISSION_SUCCESS';
export const UPDATE_SUBMISSION_FAILURE = 'UPDATE_SUBMISSION_FAILURE';
export const DELETE_SUBMISSION_UPDATE_START = 'DELETE_SUBMISSION_UPDATE_START';
export const DELETE_SUBMISSION_UPDATE_SUCCESS =
  'DELETE_SUBMISSION_UPDATE_SUCCESS';
export const DELETE_SUBMISSION_UPDATE_FAILURE =
  'DELETE_SUBMISSION_UPDATE_FAILURE';
export const UPDATE_SUBMISSION_SEEN_START = 'UPDATE_SUBMISSION_SEEN_START';
export const UPDATE_SUBMISSION_SEEN_SUCCESS = 'UPDATE_SUBMISSION_SEEN_SUCCESS';
export const UPDATE_SUBMISSION_SEEN_FAILURE = 'UPDATE_SUBMISSION_SEEN_FAILURE';
export const UPDATE_SUBMISSION_EDIT_START = 'UPDATE_SUBMISSION_EDIT_START';
export const UPDATE_SUBMISSION_EDIT_SUCCESS = 'UPDATE_SUBMISSION_EDIT_SUCCESS';
export const UPDATE_SUBMISSION_EDIT_FAILURE = 'UPDATE_SUBMISSION_EDIT_FAILURE';
export const CREATE_SUBMISSION_SHARE_START = 'CREATE_SUBMISSION_SHARE_START';
export const CREATE_SUBMISSION_SHARE_SUCCESS =
  'CREATE_SUBMISSION_SHARE_SUCCESS';
export const CREATE_SUBMISSION_SHARE_FAILURE =
  'CREATE_SUBMISSION_SHARE_FAILURE';
export const DELETE_SUBMISSION_SHARE_START = 'DELETE_SUBMISSION_SHARE_START';
export const DELETE_SUBMISSION_SHARE_SUCCESS =
  'DELETE_SUBMISSION_SHARE_SUCCESS';
export const DELETE_SUBMISSION_SHARE_FAILURE =
  'DELETE_SUBMISSION_SHARE_FAILURE';

/**
 * MILESTONE ACTION TYPES
 * Handle milestone specific actions
 */
export const GET_ALL_MILESTONES_START = 'GET_ALL_MILESTONES_START';
export const GET_ALL_MILESTONES_SUCCESS = 'GET_ALL_MILESTONES_SUCCESS';
export const GET_ALL_MILESTONES_FAILURE = 'GET_ALL_MILESTONES_FAILURE';
export const UPDATE_MILESTONE_START = 'UPDATE_MILESTONE_START';
export const UPDATE_MILESTONE_SUCCESS = 'UPDATE_MILESTONE_SUCCESS';
export const UPDATE_MILESTONE_FAILURE = 'UPDATE_MILESTONE_FAILURE';
export const ADD_NOTE_MILESTONE_START = 'ADD_NOTE_MILESTONE_START';
export const ADD_NOTE_MILESTONE_SUCCESS = 'ADD_NOTE_MILESTONE_SUCCESS';
export const ADD_NOTE_MILESTONE_FAILURE = 'ADD_NOTE_MILESTONE_FAILURE';
export const DELETE_NOTE_MILESTONE_START = 'DELETE_NOTE_MILESTONE_START';
export const DELETE_NOTE_MILESTONE_SUCCESS = 'DELETE_NOTE_MILESTONE_SUCCESS';
export const DELETE_NOTE_MILESTONE_FAILURE = 'DELETE_NOTE_MILESTONE_FAILURE';
export const ADD_ATTACHMENT_MILESTONE_START = 'ADD_ATTACHMENT_MILESTONE_START';
export const ADD_ATTACHMENT_MILESTONE_SUCCESS =
  'ADD_ATTACHMENT_MILESTONE_SUCCESS';
export const ADD_ATTACHMENT_MILESTONE_FAILURE =
  'ADD_ATTACHMENT_MILESTONE_FAILURE';
export const DELETE_ATTACHMENT_MILESTONE_START =
  'DELETE_ATTACHMENT_MILESTONE_START';
export const DELETE_ATTACHMENT_MILESTONE_SUCCESS =
  'DELETE_ATTACHMENT_MILESTONE_SUCCESS';
export const DELETE_ATTACHMENT_MILESTONE_FAILURE =
  'DELETE_ATTACHMENT_MILESTONE_FAILURE';
export const TOGGLE_GANTT_EDIT_MODE = 'TOGGLE_GANTT_EDIT_MODE';
export const ADD_MILESTONE_MODS = 'ADD_MILESTONE_MODS';
export const REMOVE_MILESTONE_MODS = 'REMOVE_MILESTONE_MODS';
export const REMOVE_ALL_MILESTONE_MODS = 'REMOVE_ALL_MILESTONE_MODS';
export const UPDATE_MANY_MILESTONES_START = 'UPDATE_MANY_MILESTONES_START';
export const UPDATE_MANY_MILESTONES_SUCCESS = 'UPDATE_MANY_MILESTONES_SUCCESS';
export const UPDATE_MANY_MILESTONES_FAILURE = 'UPDATE_MANY_MILESTONES_FAILURE';

/**
 * ISSUE ACTION TYPES
 * Handle issue specific actions
 */
export const GET_OUTSTANDING_ISSUES_START = 'GET_OUTSTANDING_ISSUES_START';
export const GET_OUTSTANDING_ISSUES_SUCCESS = 'GET_OUTSTANDING_ISSUES_SUCCESS';
export const GET_OUTSTANDING_ISSUES_FAILURE = 'GET_OUTSTANDING_ISSUES_FAILURE';
export const GET_FILTERED_ISSUES_START = 'GET_FILTERED_ISSUES_START';
export const GET_FILTERED_ISSUES_SUCCESS = 'GET_FILTERED_ISSUES_SUCCESS';
export const GET_FILTERED_ISSUES_FAILURE = 'GET_FILTERED_ISSUES_FAILURE';
export const GET_SINGLE_ISSUE_START = 'GET_SINGLE_ISSUE_START';
export const GET_SINGLE_ISSUE_SUCCESS = 'GET_SINGLE_ISSUE_SUCCESS';
export const GET_SINGLE_ISSUE_FAILURE = 'GET_SINGLE_ISSUE_FAILURE';
export const UPDATE_ISSUE_START = 'UPDATE_ISSUE_START';
export const UPDATE_ISSUE_SUCCESS = 'UPDATE_ISSUE_SUCCESS';
export const UPDATE_ISSUE_FAILURE = 'UPDATE_ISSUE_FAILURE';
export const DELETE_ISSUE_UPDATE_START = 'DELETE_ISSUE_UPDATE_START';
export const DELETE_ISSUE_UPDATE_SUCCESS = 'DELETE_ISSUE_UPDATE_SUCCESS';
export const DELETE_ISSUE_UPDATE_FAILURE = 'DELETE_ISSUE_UPDATE_FAILURE';
export const UPDATE_ISSUE_SEEN_START = 'UPDATE_ISSUE_SEEN_START';
export const UPDATE_ISSUE_SEEN_SUCCESS = 'UPDATE_ISSUE_SEEN_SUCCESS';
export const UPDATE_ISSUE_SEEN_FAILURE = 'UPDATE_ISSUE_SEEN_FAILURE';
export const CREATE_ISSUE_SHARE_START = 'CREATE_ISSUE_SHARE_START';
export const CREATE_ISSUE_SHARE_SUCCESS = 'CREATE_ISSUE_SHARE_SUCCESS';
export const CREATE_ISSUE_SHARE_FAILURE = 'CREATE_ISSUE_SHARE_FAILURE';
export const DELETE_ISSUE_SHARE_START = 'DELETE_ISSUE_SHARE_START';
export const DELETE_ISSUE_SHARE_SUCCESS = 'DELETE_ISSUE_SHARE_SUCCESS';
export const DELETE_ISSUE_SHARE_FAILURE = 'DELETE_ISSUE_SHARE_FAILURE';

/**
 * UI REDUCER TYPES
 * Handle modals, drawers and other UI elements
 */
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const UPDATE_DASHBOARD_MAIN_WIDGET = 'UPDATE_DASHBOARD_MAIN_WIDGET';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_ISSUEBOX_TYPE = 'SET_ISSUEBOX_TYPE';
export const UPDATE_ISSUE_NOTES_MODAL = 'UPDATE_ISSUE_NOTES_MODAL';
export const UPDATE_SUBMISSION_NOTES_MODAL = 'UPDATE_SUBMISSION_NOTES_MODAL';
export const UPDATE_SIDEBAR_MODAL = 'UPDATE_SIDEBAR_MODAL';
export const UPDATE_PHOTO_MODAL = 'UPDATE_PHOTO_MODAL';
export const GET_DASHBOARD_START = 'GET_DASHBOARD_START';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';
export const SET_UPDATE_FILTER_OBJ = 'SET_UPDATE_FILTER_OBJ';
export const SET_ISSUEBOX_FILTER_OBJ = 'SET_ISSUEBOX_FILTER_OBJ';
export const SET_ISSUEBOX_SORT_OBJ = 'SET_ISSUEBOX_SORT_OBJ';

/**
 * LOADING REDUCER TYPES
 * Handle all loading states for fetching data, or performing async actions
 */
export const START_UI_LOADING_ACTION = 'START_UI_LOADING_ACTION';
export const STOP_UI_LOADING_ACTION = 'STOP_UI_LOADING_ACTION';
export const START_API_LOADING_FETCH = 'START_API_LOADING_FETCH';
export const STOP_API_LOADING_FETCH = 'STOP_API_LOADING_FETCH';

/**
 * FEED REDUCER TYPES
 */
