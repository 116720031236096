import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

// Components
import SearchBar from '../search-bar/search-bar.component';
import FilterButton from '../filter-button/filter-button.component';
import SortButton from '../sort-button/sort-button.component';

// Styles
import useStyles from './header-search.styles';

const HeaderSearch = ({ title, queryObj, setQueryObj }) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar} disableGutters>
      <div className={classes.toolbarStart}>
        <Paper component='form' className={classes.root}>
          <SearchBar setQueryObj={setQueryObj} title={title} />
          <FilterButton queryObj={queryObj} setQueryObj={setQueryObj} />
          <Divider className={classes.divider} orientation='vertical' />
          <SortButton queryObj={queryObj} setQueryObj={setQueryObj} />
        </Paper>
      </div>
    </Toolbar>
  );
};

// Proptypes
HeaderSearch.propTypes = {
  title: PropTypes.string,
  queryObj: PropTypes.object,
  setQueryObj: PropTypes.func,
};

HeaderSearch.defaultProps = {
  title: 'Search',
  queryObj: {},
};

export default HeaderSearch;
