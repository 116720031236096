import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

// Components
import HomeContainer from '../../components/home-container/home-container.component';
import UserlistHeader from '../../components/userlist-header/userlist-header.component';
import FormContainer from '../../components/form-container/form-container.component';
import FormGenerator from '../../forms/form-generator.component';
import MapGps from '../../components/map-gps/map-gps.component';

import SvgFileIcon from '../../components/svg-file-icon/svg-file-icon.component';

// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

// Styles
import useStyles from './update-project.styles';

// Selectors
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectCurrentProject } from '../../redux/project/project.selectors';

// Actions
import { startAlert } from '../../redux/alert/alert.actions';

// Formik
import {
  projectInitialize,
  projectValidationSchema,
  projectSchema,
} from '../../forms/schemas/project.schema';

function UpdateProject({ user, startAlertDispatch, project }) {
  const classes = useStyles();
  const history = useHistory();
  const { projectId } = useParams();
  //console.log(project, projectId);

  if (!project) return 'Loading';

  const initialValues = projectInitialize(project);
  return (
    <HomeContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.actionButtons}>
          <Button
            variant='contained'
            color='secondary'
            onClick={history.goBack}
            className={classes.button}
            startIcon={<ArrowBackIcon />}>
            Go Back
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.headerText}>
          <Typography variant='h3'>{project.name}</Typography>
          <Typography variant='h5' gutterBottom>
            Use the tools below to update project details, add users, change the
            project manager, or attach photos and files.
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.project}>
        <Grid item xs={12} md={7}>
          <MapGps
            lat={project.lat}
            lng={project.lng}
            updateGps={() => console.log('Ok')}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <FormContainer title='Project Details' headerPhoto={project.photo}>
            <FormGenerator
              initialValues={initialValues}
              schema={projectSchema}
              validationSchema={projectValidationSchema}
              submitFunc={() => console.log('Submitted')}
              submitElem={
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  size='small'
                  fullWidth
                  style={{
                    margin: '8px 0px',
                  }}
                  startIcon={<AssignmentTurnedInIcon />}>
                  Update Project Details
                </Button>
              }
            />
          </FormContainer>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h2' gutterBottom align='center'>
            Project Team
          </Typography>
          <Typography variant='h5' gutterBottom align='center'>
            {project.description}
          </Typography>
        </Grid>
        <Grid item xs={12} align='center'>
          <UserlistHeader user={project.poc} />
        </Grid>
      </Grid>
    </HomeContainer>
  );
}

UpdateProject.propTypes = {
  user: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  project: selectCurrentProject,
});

const mapDispatchToProps = (dispatch) => ({
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProject);
