import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';

// Components
import MainContainer from '../../components/main-container/main-container.component';

// Hooks
import useJumpTo from '../../hooks/useJumpTo';

// Styles
import useStyles from './feed.styles';
import FeedContainer from '../../components/feed-container/feed-container.component';

// Selectors
import { selectSortedFeedItems } from '../../redux/feed/feed.selectors';

const Feed = ({ sortedUpdates }) => {
  const classes = useStyles();
  const [jumpToId, jumpToRef, setJumpToReady] = useJumpTo();

  const handleOpenUpdate = (update) => {
    console.log(update);
  };

  return (
    <MainContainer>
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12} className={classes.container}>
          <div className={classes.title}>Recent Updates</div>
          <div className={classes.read}>
            <div className={classes.subTitle}>
              Showing <span className={classes.count}>100</span> updates
              starting from Friday, July 3rd, 2024.
            </div>
          </div>
          <FeedContainer
            ref={jumpToRef}
            sortedUpdates={sortedUpdates}
            jumpToId={jumpToId}
            setJumpToReady={setJumpToReady}
            clickFunc={handleOpenUpdate}
          />
        </Grid>
      </Grid>
    </MainContainer>
  );
};

Feed.propTypes = {
  sortedUpdates: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  sortedUpdates: selectSortedFeedItems,
});

export default connect(mapStateToProps, null)(Feed);
