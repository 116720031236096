import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import { overrides, shared, symbols } from './custom';

// TODO: Unstable Version used to avoid strict mode errors
const theme = unstable_createMuiStrictModeTheme({
  overrides: {
    ...overrides,
  },
  custom: {
    ...shared,
    symbols: {
      ...symbols,
    },
  },
  palette: {
    type: 'light',
    primary: {
      lighter: '#deeffd',
      light: '#79bffa',
      main: '#58b2ff',
      dark: '#0376BB',
      darker: '#0165a0',
      darkest: '#0a3444',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff6252',
      main: '#ff6252',
      dark: '#ff6252',
      contrastText: '#fff',
    },
    error: {
      lightest: '#ffdede',
      light: '#e57373',
      main: '#ff6252',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    success: {
      lightest: '#cddade',
      light: '#81c784',
      main: '#55de84',
      dark: '#388e3c',
      contrastText: 'rgba(0,0,0,0.87)',
    },
  },
  shape: {
    borderRadius: 20,
  },
});

export default theme;
