import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    paddingTop: 0,
  },
  boxLayout: {
    margin: '20px 0 0 0',
    padding: '8px 8px 8px 16px',
    backgroundColor: theme.palette.primary.darkest,
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      color: '#fff',
      margin: 0,
    },
  },
  filters: {
    backgroundColor: theme.custom.lightestgrey,
    padding: '12px 8px 12px 16px',
    //borderLeft: '1px solid rgba(158,158,158,0.22)',
    //borderRight: '1px solid rgba(158,158,158,0.22)',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
  },
  issueboxFormat: {
    '& .MuiPaper-rounded': {
      minWidth: '80px',
      marginLeft: '9px',
      borderRadius: 0,
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  issueBoxItems: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  issueBoxItem: {
    textAlign: 'center',
  },
  paginationComponent: {
    color: '#fff',
    '& svg': {
      fill: '#fff',
      '&:hover': {
        fill: theme.palette.primary.light,
      },
    },
    '& li': {
      '& button, div': {
        color: '#fff',
        '&:hover': {
          color: theme.palette.primary.light,
        },
      },
    },
  },
}));

export default useStyles;
