import React from 'react';
import { useField } from 'formik';

// Material UI
import TextField from '@material-ui/core/TextField';

const FormikDummyTextField = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <TextField
      {...props}
      value={field.value}
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default FormikDummyTextField;
