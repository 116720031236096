import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Components
import HomeContainer from '../../components/home-container/home-container.component';

// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Styles
import useStyles from './create-project.styles';

// Selectors
import { selectCurrentUser } from '../../redux/user/user.selectors';

function CreateProject({ user }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <HomeContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom={true}>
            {'Create a New Project!'}
          </Typography>
          <Typography variant='h5' component='h2' gutterBottom>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate vitae
            dolorum consequuntur veritatis dolores hic magnam ratione amet nemo
            blanditiis sequi nam qui praesentium, ad laboriosam ullam deleniti alias
            dolore.
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.actionButtons}>
            <Button
              variant='contained'
              color='secondary'
              onClick={history.goBack}
              className={classes.button}
              startIcon={<ArrowBackIcon />}>
              Go Back
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          Column 1
        </Grid>
        <Grid item xs={12} md={6}>
          Column 2
        </Grid>
      </Grid>
    </HomeContainer>
  );
}

CreateProject.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps)(CreateProject);
