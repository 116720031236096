import React from 'react';
import PropTypes from 'prop-types';

// Components
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import EventIcon from '@material-ui/icons/Event';
import RoomIcon from '@material-ui/icons/Room';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter4Icon from '@material-ui/icons/Filter4';

// Styles
import useStyles from './sort-menu.styles';

// Constants
import { SORT_OPTIONS as sortOptions } from '../../utils/constants';

const SortMenu = ({
  sortOpen,
  anchorElSort,
  handleSortMenuClose,
  queryObj,
  setQueryObj,
}) => {
  const classes = useStyles();

  const sortVectors = queryObj.sortArr.map((el) => el.vector);

  const handleSortClick = (selection) => {
    if (sortVectors.includes(selection.vector)) {
      setQueryObj((prev) => ({
        ...prev,
        sortArr: prev.sortArr.map((el) => {
          if (el.vector === selection.vector) {
            return { ...el, order: el.order === 'desc' ? 'asc' : 'desc' };
          } else {
            return el;
          }
        }),
      }));
    }
  };

  const handleCheckboxToggle = (selection) => {
    if (sortVectors.includes(selection.vector)) {
      setQueryObj((prev) => ({
        ...prev,
        sortArr: prev.sortArr.filter((el) => el.vector !== selection.vector),
      }));
    } else {
      setQueryObj((prev) => ({
        ...prev,
        sortArr: [...prev.sortArr, selection],
      }));
    }
  };

  return (
    <Popover
      open={sortOpen}
      anchorEl={anchorElSort}
      onClose={handleSortMenuClose}
      className={classes.searchPopoverRoot}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <List
        component='nav'
        className={classes.searchPopoverItems}
        dense
        subheader={
          <ListSubheader className={classes.searchPopoverSubheader}>
            Sort Issue Archive By
          </ListSubheader>
        }>
        {sortOptions[queryObj.pageTitle].map((el, idx) => (
          <ListItem
            key={idx}
            button
            disabled={!sortVectors.includes(el.vector)}
            onClick={() => handleSortClick(el)}
            className={classes.searchPopoverListItem}
            selected={false}>
            <ListItemIcon>
              {el.title === 'Date' ? (
                <EventIcon />
              ) : el.title === 'Site' ? (
                <RoomIcon />
              ) : el.title === 'Severity' ? (
                <ErrorIcon />
              ) : el.title === 'Completed' ? (
                <CheckCircleOutlineIcon />
              ) : el.title === 'Number of Issues' ? (
                <AssignmentLateIcon />
              ) : null}
            </ListItemIcon>
            <ListItemText
              primary={el.title}
              secondary={
                sortVectors.includes(el.vector)
                  ? queryObj.sortArr.find((elem) => elem.vector === el.vector).order ===
                    'asc'
                    ? 'Ascending ↑'
                    : 'Descending ↓'
                  : 'Not selected'
              }
            />
            <ListItemSecondaryAction>
              <Checkbox
                edge='end'
                color='primary'
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={
                  sortVectors.includes(el.vector) ? (
                    sortVectors.indexOf(el.vector) === 0 ? (
                      <Filter1Icon fontSize='small' />
                    ) : sortVectors.indexOf(el.vector) === 1 ? (
                      <Filter2Icon fontSize='small' />
                    ) : sortVectors.indexOf(el.vector) === 2 ? (
                      <Filter3Icon fontSize='small' />
                    ) : sortVectors.indexOf(el.vector) === 3 ? (
                      <Filter4Icon fontSize='small' />
                    ) : (
                      <ErrorIcon fontSize='small' />
                    )
                  ) : (
                    <ErrorIcon fontSize='small' />
                  )
                }
                onChange={() => handleCheckboxToggle(el)}
                checked={sortVectors.includes(el.vector)}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

SortMenu.propTypes = {
  sortOpen: PropTypes.bool.isRequired,
  anchorElSort: PropTypes.oneOfType([
    PropTypes.oneOf([null]).isRequired,
    PropTypes.instanceOf(Element).isRequired,
  ]),
  handleSortMenuClose: PropTypes.func.isRequired,
  queryObj: PropTypes.object.isRequired,
  setQueryObj: PropTypes.func.isRequired,
};

export default SortMenu;
