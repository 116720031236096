import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Styles
import useStyles from './fullscreen-submission-modal.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectModalFullscreenSubmission } from '../../redux/ui/ui.selectors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const FullscreenSubmissionModal = ({
  fullscreenSubmission: { isOpen, content },
  toggleModalDispatch,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    toggleModalDispatch({
      modalName: 'fullscreenSubmission',
      modalContent: {},
    });
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant='h6' className={classes.title}>
            {content ? content.title : 'No item found.'}
          </Typography>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.dialog}>
        {content ? <div>Content Goes Here</div> : <div>No Content Found</div>}
      </div>
    </Dialog>
  );
};

FullscreenSubmissionModal.propTypes = {
  fullscreenSubmission: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  fullscreenSubmission: selectModalFullscreenSubmission,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FullscreenSubmissionModal);
