import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  itemContainer: {
    width: '80px',
    height: '80px',
    minWidth: '80px',
    minHeight: '80px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,0.12)',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2px',
  },
  first: { borderRadius: '50%' },
  last: {
    width: '50px',
    height: '50px',
    minWidth: '50px',
    minHeight: '50px',
    background: '#333',
    color: 'white',
    border: '1px solid rgba(0,0,0,0.12)',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    transform: 'translateY(0px)',
  },
  date: {
    fontSize: '0.85rem',
    color: '#333',
  },
}));

export default useStyles;
