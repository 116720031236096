import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-start',
    border: '1px solid rgba(158,158,158,0.22)',
    borderBottomRightRadius: '16px',
    borderBottomLeftRadius: '16px',
    maxHeight: (props) => props.rootMaxHeight,
    overflowY: 'scroll',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'grid',
    border: 0,
    position: 'relative',
    boxSizing: 'border-box',
    alignContent: 'flex-start',
    backgroundColor: theme.palette.background.paper,
    borderBottomRightRadius: '16px',
    borderBottomLeftRadius: '16px',
    overflowX: 'scroll',
    minWidth: '600px',
  },
  rowWrapper: {
    zIndex: '7',
    marginBottom: '0px',
  },
  rowWrapperShowToday: {
    marginBottom: '21px',
  },
}));

export default useStyles;
