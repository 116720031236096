import React from 'react';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PersonIcon from '@material-ui/icons/Person';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import SendIcon from '@material-ui/icons/Send';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

// Styles
import useStyles from './userlist-header.styles';

// Utilities
import { truncate } from '../../utils/utilityFns';

const UserlistHeader = ({ user, headerText = 'Project Manager' }) => {
  const classes = useStyles();
  if (!user) return null;

  const email =
    user.email && user.email !== ''
      ? truncate(user.email, 42, 'email')
      : 'pleaseAdd@email.com';

  const phoneNumber =
    user.phoneNumber && user.phoneNumber !== ''
      ? user.phoneNumber
      : '+1 (XXX) XXX-XXXX';

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Avatar src={user.photo} className={classes.avatar} />
        <Typography variant='h6' component='h6' className={classes.headerText}>
          {headerText}
        </Typography>
      </ListItem>
      <ListItem className={classes.root}>
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={`${user.firstName} ${user.lastName}`} />
      </ListItem>
      <ListItem className={classes.root}>
        <ListItemAvatar>
          <Avatar>
            <EmailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={email}
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        />
        <ListItemSecondaryAction>
          <IconButton edge='end' aria-label='email'>
            <SendIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem className={classes.root}>
        <ListItemAvatar>
          <Avatar>
            <PhoneIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={phoneNumber} />
        <ListItemSecondaryAction>
          <IconButton edge='end' aria-label='call'>
            <PhoneInTalkIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem className={classes.root}>
        <Button
          size='small'
          variant='contained'
          color='primary'
          startIcon={<SwapHorizIcon />}>
          Update Profile
        </Button>
      </ListItem>
    </List>
  );
};

export default UserlistHeader;
