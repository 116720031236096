import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import LaunchIcon from '@material-ui/icons/Launch';

// Components
import TableColgroup from '../table-colgroup/table-colgroup.component';

// Utilities
import { dateToTimeAgo } from '../../utils/utilityFns';
import { addDaysToDate } from '../../utils/ganttFns';

// Styles
import useStyles from './deleted-table.styles';

const dateOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
};

const DeletedTable = ({
  title = 'Trash',
  topSpacing,
  numItems,
  queryObj: { page, perPage },
  setQueryObj,
  items,
  linkPath,
  tooltipText,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const numPages = Math.ceil(numItems / perPage);

  const handlePageChange = (_, value) => {
    setQueryObj((prev) => ({ ...prev, page: value }));
  };

  const handleListItemClick = (_, value) => {
    setQueryObj(
      (prev) => ({ ...prev, perPage: value, page: 1 }),
      setAnchorEl(null)
    );
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Grid
        item
        xs={12}
        className={clsx(classes.box, topSpacing ? classes.topSpacing : null)}>
        <div className={classes.boxLayout}>
          <h1>{title}</h1>
          <span className={classes.pagination}>
            <Pagination
              color='primary'
              className={classes.paginationComponent}
              count={numPages}
              page={page}
              onChange={handlePageChange}
            />
            <IconButton onClick={handleClick}>
              {anchorEl ? (
                <MoreVertIcon color='primary' />
              ) : (
                <MoreHorizIcon className={classes.paginationComponent} />
              )}
            </IconButton>
          </span>
        </div>
      </Grid>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableColgroup cols={[12, 16, 12, 30, 12, 18]} />
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell>Metadata</TableCell>
              <TableCell>Milestone</TableCell>
              <TableCell>Deleted By</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Expires</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => {
              const deletedDate = new Date(item.deletedAt);
              const finalDeleteIdx = item.updates.findLastIndex(
                (el) => el.actionType === 'Deleted'
              );
              const deletionNote =
                finalDeleteIdx >= 0
                  ? item.updates[finalDeleteIdx].content.notes
                  : 'Unknown.';

              return (
                <TableRow key={item._id} className={classes.tableRow}>
                  <TableCell>
                    <>
                      <div>
                        <strong>{`${item.createdBy.firstName
                          .charAt(0)
                          .toUpperCase()}. ${item.createdBy.lastName}`}</strong>
                      </div>
                      <div>
                        {new Date(item.createdAt).toLocaleString(
                          'en-US',
                          dateOptions
                        )}
                      </div>
                    </>
                  </TableCell>
                  <TableCell>
                    <>
                      <div>
                        <strong>{item.site.name}</strong>
                      </div>
                      <div>{item.milestone.name}</div>
                    </>
                  </TableCell>
                  <TableCell>
                    <div>
                      <strong>{`${item.deletedBy.firstName
                        .charAt(0)
                        .toUpperCase()}. ${item.deletedBy.lastName}`}</strong>
                    </div>
                    <div>
                      {deletedDate.toLocaleString('en-US', dateOptions)}
                    </div>
                  </TableCell>
                  <TableCell>{deletionNote}</TableCell>
                  <TableCell>
                    {dateToTimeAgo(addDaysToDate(deletedDate, 90), 'mini')}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={tooltipText}>
                      <IconButton
                        size='small'
                        className={classes.button}
                        aria-label={tooltipText}
                        href={`/projects/${item.project}/${linkPath}/${item._id}`}>
                        <LaunchIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.issueboxFormat}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <List
          component='nav'
          className={classes.issueBoxItems}
          subheader={
            <ListSubheader component='div'>Items per page</ListSubheader>
          }>
          <ListItem
            button
            className={classes.issueBoxItem}
            selected={perPage === 10}
            onClick={(e) => handleListItemClick(e, 10)}>
            <ListItemText primary='10' />
          </ListItem>
          <ListItem
            button
            className={classes.issueBoxItem}
            selected={perPage === 20}
            onClick={(e) => handleListItemClick(e, 20)}>
            <ListItemText primary='20' />
          </ListItem>
          <ListItem
            button
            className={classes.issueBoxItem}
            selected={perPage === 50}
            onClick={(e) => handleListItemClick(e, 50)}>
            <ListItemText primary='50' />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

DeletedTable.propTypes = {
  title: PropTypes.string.isRequired,
  topSpacing: PropTypes.bool.isRequired,
  numItems: PropTypes.number.isRequired,
  queryObj: PropTypes.object,
  setQueryObj: PropTypes.func,
  items: PropTypes.array.isRequired,
  linkPath: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
};

export default DeletedTable;
