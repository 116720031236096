import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// Components
import Copyright from '../../components/copyright/copyright.component';

// Styles
import useStyles from './home-container.styles';

const HomeContainer = ({ children, signOutStartDispatch }) => {
  const classes = useStyles();
  return (
    <>
      <AppBar position='absolute' elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.box}>
            <Typography variant='h6' color='inherit' noWrap>
              Global Build Solutions
            </Typography>
            <Button
              size='small'
              color='inherit'
              startIcon={<ExitToAppIcon />}
              onClick={signOutStartDispatch}>
              Sign Out
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <main className={classes.content}>
          <Container className={classes.container}>
            {children}
            <Copyright />
          </Container>
        </main>
      </div>
    </>
  );
};

// Proptypes
HomeContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default HomeContainer;
