import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  headerText: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  project: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
}));

export default useStyles;
