import { createSelector } from 'reselect';

// Selectors
import { selectProjectMapDetails } from '../project/project.selectors';

import {
  selectSiteDictionary,
  selectSiteDictionaryShortName,
  selectSiteGroupedListName,
} from '../site/site.selectors';

// Utility Functions
import {
  calcMilestonesGantt,
  calculateProjectMilestoneOverview,
  calculateSiteMilestoneOverview,
  calculateSiteMilestoneDetails,
} from './milestone.utils';
import { sortDate } from '../../utils/utilityFns';

const selectMilestoneSlice = (state) => state.milestone;

const selectSiteSlice = (state) => state.site;

const selectAllSites = createSelector(
  [selectSiteSlice],
  (siteSlice) => siteSlice.sites
);

const selectActiveSiteId = createSelector(
  [selectSiteSlice],
  (siteSlice) => siteSlice.activeSiteId
);

export const selectAllMilestones = createSelector(
  [selectMilestoneSlice],
  (milestoneSlice) => milestoneSlice.milestones
);

export const selectGanttEditMode = createSelector(
  [selectMilestoneSlice],
  (milestoneSlice) => milestoneSlice.editMode
);

export const selectGanttMods = createSelector(
  [selectMilestoneSlice],
  (milestoneSlice) => milestoneSlice.mods
);

export const selectGanttModCount = createSelector(
  [selectGanttMods],
  (mods) => Object.keys(mods).length
);

export const selectMSProjectExport = createSelector(
  [selectAllMilestones],
  (milestones) =>
    Object.values(milestones).map((m) => ({
      _id: m._id,
      actualStatus: m.actualStatus,
      taskId: m?.taskId ? m.taskId : 'None',
      dateActualStart: m.dateActualStart,
      dateActualEnd: m.dateActualEnd,
    }))
);

export const selectActiveSiteMilestones = createSelector(
  [selectAllMilestones, selectActiveSiteId],
  (milestones, siteId) =>
    Object.values(milestones)
      .filter((el) => el.site === siteId)
      .sort(sortDate('datePlannedStart', 'asc'))
);

export const selectProjectMilestoneOverview = createSelector(
  [selectAllMilestones],
  (milestones) => calculateProjectMilestoneOverview(milestones)
);

export const selectProjectMilestoneDetails = createSelector(
  [selectAllMilestones, selectSiteDictionaryShortName],
  (milestones, siteObj) => calculateSiteMilestoneDetails(milestones, siteObj)
);

export const selectSiteMilestoneOverview = createSelector(
  [selectAllMilestones],
  (milestones) => calculateSiteMilestoneOverview(milestones)
);

export const selectSiteOrProjectMilestoneOverview = createSelector(
  [
    selectProjectMilestoneOverview,
    selectSiteMilestoneOverview,
    selectActiveSiteId,
  ],
  (proj, sites, activeSiteId) => {
    if (activeSiteId === '-1') {
      return proj;
    } else {
      return sites.hasOwnProperty(activeSiteId) ? sites[activeSiteId] : false;
    }
  }
);

export const selectGanttOverview = createSelector(
  [selectAllMilestones, selectAllSites, selectGanttEditMode, selectGanttMods],
  (milestones, sites, editMode, mods) =>
    calcMilestonesGantt(milestones, sites, editMode, mods)
);

export const selectMilestoneFiles = createSelector(
  [selectAllMilestones, selectSiteDictionary],
  (milestones, siteObj) => {
    const attachments = Object.values(milestones)
      .filter((milestone) => milestone?.attachments.length > 0)
      .map((el) => ({
        attachments: el.attachments.map((attachment) => ({
          ...attachment,
          type: 'Milestone',
          meta: {
            site: {
              _id: el.site,
              name: siteObj.hasOwnProperty(el.site)
                ? siteObj[el.site]
                : 'No Site',
            },
          },
          deletionObj: {
            projectId: el.project,
            milestoneId: el._id,
            attachmentId: attachment._id,
            embeddedField: 'attachments',
          },
        })),
      }))
      .map((el) => el.attachments)
      .flat();
    return attachments;
  }
);

export const selectMilestonePhotos = createSelector(
  [selectAllMilestones],
  (milestones) => {
    return Object.values(milestones)
      .map((el) => el.photos)
      .flat();
  }
);

export const selectMapRenderArr = createSelector(
  [
    selectSiteGroupedListName,
    selectSiteMilestoneOverview,
    selectProjectMapDetails,
  ],
  (siteNames, siteStatus, mapDetails) => {
    if (
      Object.values(siteNames).length === 0 ||
      Object.values(siteStatus).length === 0 ||
      !mapDetails
    ) {
      return [];
    }
    return mapDetails.renderArr.map((groupObj) => {
      const newGroupSitesArr = groupObj.groupSites.map((el) => {
        const name = siteNames.hasOwnProperty(el._id)
          ? siteNames[el._id]
          : 'Name Error';
        const status =
          siteStatus.hasOwnProperty(el._id) &&
          siteStatus[el._id].hasOwnProperty('actualStatus')
            ? siteStatus[el._id].actualStatus
            : 'Not Started';
        return { ...el, name, status };
      });
      return { ...groupObj, groupSites: newGroupSitesArr };
    });
  }
);
