import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Styles
import useStyles from './gantt-today.styles';

function GanttToday({ todayStr, hideToday }) {
  const classes = useStyles({ todayStr });
  return (
    <div className={clsx(classes.row, hideToday ? classes.hide : null)}>
      <div className={classes.todayLine}></div>
      <div className={classes.todayFlagWrapper}>
        <div className={classes.todayFlag}>Today</div>
      </div>
    </div>
  );
}

GanttToday.propTypes = {
  todayStr: PropTypes.string,
  hideToday: PropTypes.bool.isRequired,
};

export default GanttToday;
