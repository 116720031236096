import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.darkest,
  },
  appBarTitle: {
    flex: 1,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: theme.custom.bg,
  },
  container: {
    paddingTop: '16px',
    paddingBottom: '32px',
  },
  submission: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    margin: '20px 0px',
  },
  metaContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  metaHeader: {
    fontSize: '1.4rem',
    fontWeight: 700,
    color: '#333',
    paddingBottom: '12px',
  },
  metaRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '12px',
    fontSize: '0.925rem',
  },
  metaRowHeader: {
    fontWeight: 700,
    minWidth: '164px',
    maxWidth: '164px',
    display: 'flex',
    alignItems: 'center',
  },
  metaRowContent: {
    lineHeight: 1.1,
    display: 'flex',
    alignItems: 'center',
  },
  deleted: {
    backgroundColor: theme.palette.error.main,
    color: '#FFF',
    height: '26px',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '0.85rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    '& svg': {
      color: '#FFF!important',
    },
  },
  open: {
    backgroundColor: theme.custom.slateBlue,
    color: '#FFF',
    height: '26px',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '0.85rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    '& svg': {
      color: '#FFF!important',
    },
  },
  button: {
    marginRight: theme.spacing(1),
    borderRadius: '4px',
    minWidth: 'unset',
    marginBottom: '8px',
  },
  buttonGroup: {
    paddingTop: '24px',
    flexWrap: 'wrap',
  },
  editButton: {
    backgroundColor: theme.custom.buttonPurple,
    color: '#fff',
    '& svg': {
      color: '#FFFFFF!important',
    },
    '&:hover': {
      backgroundColor: theme.custom.buttonHoverPurple,
    },
  },
  exportButton: {
    backgroundColor: theme.custom.statusOrange,
    color: '#fff',
    '& svg': {
      color: '#FFFFFF!important',
    },
    '&:hover': {
      backgroundColor: theme.custom.statusOrangeAlt,
    },
  },
  textHeader: {
    marginTop: 0,
    marginBottom: '6px',
  },
  answerContainer: {
    marginBottom: 0,
    paddingBottom: 0,
    border: '1px solid #333',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  answerTitle: {
    margin: 0,
    padding: '16px',
    backgroundColor: '#d5d5d5',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  issueBg: {
    backgroundColor: theme.palette.primary.darkest,
  },
  issueBorder: {
    border: `4px solid ${theme.palette.primary.darkest}`,
  },
  answer: {
    background: '#FFF',
    margin: '16px',
    fontSize: '1.25rem',
  },
  answerHeaderContainer: {
    backgroundColor: theme.palette.primary.darkest,
    color: '#fff',
  },
  notes: {
    background: '#FFF',
    margin: 0,
    padding: '0px 16px 8px 16px',
    fontSize: '1rem',
    fontWeight: 400,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  issue: {
    background: theme.palette.primary.darkest,
    margin: 0,
    padding: '0px 16px 16px 16px',
    display: 'flex',
    flexDirection: 'row',
  },
  issueLink: {
    backgroundColor: '#FFF',
    color: theme.palette.primary.darkest,
    fontWeight: 900,
    textDecoration: 'none',
    padding: '0px 8px',
    borderRadius: '12px',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    '&:hover': {
      backgroundColor: theme.custom.popupBackground,
    },
  },
  chip: {
    color: '#fff',
    fontWeight: 900,
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    letterSpacing: '1px',
    marginLeft: '8px',
    '& svg': {
      color: '#FFFFFF!important',
    },
  },
  pill: {
    display: 'flex',
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 900,
    textDecoration: 'none',
    padding: '2px 8px 2px 8px',
    borderRadius: '12px',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    letterSpacing: '1px',
    marginLeft: '8px',
  },
  none: {
    backgroundColor: theme.custom.issueUncatBg,
  },
  low: {
    backgroundColor: theme.custom.issueLowBg,
  },
  medium: {
    backgroundColor: theme.custom.issueMediumBg,
  },
  high: {
    backgroundColor: theme.custom.issueHighBg,
  },
  offTrack: {
    backgroundColor: theme.custom.issueOffTrackBg,
  },
  isOpen: {
    backgroundColor: theme.custom.softGrey,
  },
  isResolved: {
    backgroundColor: theme.palette.success.main,
  },
  isDeleted: {
    backgroundColor: theme.custom.statusRed,
  },
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '8px',
    backgroundColor: '#fff',
    width: 'calc(100% - 16px)',
    marginRight: '8px',
    marginLeft: '8px',
    marginBottom: '8px',
  },
  imageGridItem: { display: 'grid', margin: 0, padding: 0 },
  photo: { maxWidth: '100%', cursor: 'pointer' },
}));

export default useStyles;
