import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Styles
import useStyles from './gantt-row-hidden.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectCurrentUserId } from '../../redux/user/user.selectors';

const GanttRowHidden = ({
  el,
  hideCalElements: { hideHighlightRow },
  currentUserId,
  toggleModalDispatch,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.row,
        hideHighlightRow ? null : classes.milestoneRow
      )}>
      <div className={clsx(classes.titleSiteMode, classes.subtaskTitle)}>
        <div
          className={clsx(
            classes.subtask,
            classes.subtaskUnmodded,
            classes.subtaskNonEditMode
          )}>
          <span
            className={classes.milestoneName}
            onClick={() =>
              toggleModalDispatch({
                modalName: 'sidebarNotes',
                modalContent: {
                  id: el._id,
                  item: el,
                  currentUserId: currentUserId,
                  doFetch: true,
                  urlFetch: `/api/v1/projects/${el.project}/milestones/${el._id}/getAllMilestoneDetails`,
                },
              })
            }>
            {el.milestoneName}
          </span>
        </div>
      </div>
      <div></div>
      <div></div>
    </div>
  );
};

GanttRowHidden.propTypes = {
  el: PropTypes.object.isRequired,
  hideCalElements: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentUserId: selectCurrentUserId,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GanttRowHidden);
