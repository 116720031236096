import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/Lock';

// Actions
import { updatePasswordStart } from '../../../redux/user/user.actions';

// Styles
import useStyles from './update-password.styles';

// Validation Schema
const validationSchema = yup.object({
  password: yup
    .string('Enter your current password.')
    .required('Current password is required'),
  newPassword: yup
    .string('Enter your new password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('New password is required'),
  newPasswordConfirm: yup
    .string('Re-Enter your new password')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.newPassword === value;
    })
    .required('New password confirmation is required'),
});

const UpdatePassword = ({ updatePasswordStartDispatch }) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      updatePasswordStartDispatch(values);
      resetForm();
    },
  });

  return (
    <>
      <Typography variant='h6' display='block' gutterBottom>
        Change Password
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          required
          id='password'
          name='password'
          label='Current Password'
          type='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          className={classes.textInput}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          id='newPassword'
          name='newPassword'
          label='New Password'
          type='password'
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
          helperText={formik.touched.newPassword && formik.errors.newPassword}
          className={classes.textInput}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          id='newPasswordConfirm'
          name='newPasswordConfirm'
          label='New Password Confirmation'
          type='password'
          value={formik.values.newPasswordConfirm}
          onChange={formik.handleChange}
          error={
            formik.touched.newPasswordConfirm &&
            Boolean(formik.errors.newPasswordConfirm)
          }
          helperText={
            formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm
          }
          className={classes.textInput}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          type='submit'
          variant='outlined'
          size='small'
          style={{ marginBottom: '36px' }}
          startIcon={<LockIcon />}>
          Update My Password
        </Button>
      </form>
    </>
  );
};

UpdatePassword.propTypes = {
  updatePasswordStartDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updatePasswordStartDispatch: (passObj) => dispatch(updatePasswordStart(passObj)),
});

export default connect(null, mapDispatchToProps)(UpdatePassword);
