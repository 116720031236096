import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  shareHeader: { fontSize: '1rem' },
  shareUrlContainer: {
    margin: '6px 0px',
    padding: '6px 0px',
    borderRadius: '6px',
    backgroundColor: '#cdcdcd',
    color: '#000',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  shareUrl: {
    paddingLeft: '12px',
    display: 'flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#111',
  },
  shareIconContainer: {
    paddingRight: '12px',
    paddingLeft: '12px',
    display: 'flex',
  },
  shareCopy: {
    cursor: 'pointer',
    color: theme.custom.ganttPlannedTimeline,
    '&:hover': {
      color: theme.custom.softGrey,
    },
  },
  sharePermissions: { fontSize: '1rem', margin: '12px 0px' },
  optionContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '6px 0px',
  },
  optionTitle: {
    color: theme.palette.text.secondary,
  },
  pill: {
    display: 'flex',
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 900,
    textDecoration: 'none',
    padding: '2px 8px 2px 8px',
    borderRadius: '12px',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    letterSpacing: '1px',
    marginLeft: '8px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  enabled: {
    backgroundColor: theme.palette.success.main,
  },
  disabled: {
    backgroundColor: theme.custom.statusRed,
  },
  stopSharing: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
    paddingTop: '16px',
  },
}));

export default useStyles;
