import { isValidDate, formatDateBox } from './utilityFns';
import {
  SEVERITY_RANGE_LOW as low,
  SEVERITY_RANGE_HIGH as high,
} from './constants';

// Acceptable Filter Keys as Query String
const queryKeys = [
  'createdBy._id',
  'site._id',
  'submission',
  '_id',
  'isDeleted',
]; // how do we deal with ._id and submission which is just the ID FIXME:
const rangeKeys = [
  'currentCategorization',
  'createdAt',
  'issueCount',
  'isResolved',
];

export const generateQueryStr = (queryObj) => {
  let queryStr = '';
  if (Object.keys(queryObj).length !== 0) {
    const { perPage, page, searchStr, sortArr, rangeArr, fieldArr, limitObj } =
      queryObj;

    // Initialize Query String
    queryStr += '?';

    // Pagination
    if (Number.isInteger(perPage) && Number.isInteger(page) && page > 0)
      queryStr += `limit=${perPage}&page=${page}&`;

    // Sorting
    let sortStr = '';
    sortArr.forEach((el, idx) => {
      if (
        el.hasOwnProperty('order') &&
        el.hasOwnProperty('vector') &&
        el.vector !== '' &&
        (el.order === 'desc' || el.order === 'asc')
      ) {
        if (idx === 0) {
          sortStr += 'sort=';
        }
        if (el.order === 'desc') {
          sortStr += `-${el.vector}`;
        } else {
          sortStr += el.vector;
        }
        if (idx < sortArr.length - 1) {
          sortStr += ',';
        } else {
          sortStr += '&';
        }
      }
    });

    queryStr += sortStr;

    // Fields to Include/Exclude
    let fieldStr = '';
    fieldArr.forEach((el, idx) => {
      if (
        el.hasOwnProperty('hide') &&
        el.hasOwnProperty('vector') &&
        el.vector !== '' &&
        (el.hide === true || el.hide === false)
      ) {
        if (idx === 0) {
          fieldStr += 'fields=';
        }
        if (el.hide === true) {
          fieldStr += `-${el.vector}`;
        } else {
          fieldStr += el.vector;
        }
        if (idx < fieldArr.length - 1) {
          fieldStr += ',';
        } else {
          fieldStr += '&';
        }
      }
    });

    queryStr += fieldStr;

    // Limit site(s), milestone(s), user(s), etc.
    if (limitObj && Object.keys(limitObj).length !== 0) {
      queryKeys.forEach((key) => {
        if (limitObj.hasOwnProperty(key)) {
          if (limitObj[key].length === 1) {
            queryStr += `${key}=${limitObj[key]}&`;
          } else {
            queryStr += `${key}[in]=${limitObj[key].join(',')}&`;
          }
        }
      });
    }

    // Range date, currentCategorization, issueCount
    if (rangeArr && Array.isArray(rangeArr)) {
      rangeArr.forEach((rangeObj) => {
        if (
          rangeKeys.includes(rangeObj.key) &&
          (rangeObj.hasOwnProperty('lowerVal') ||
            rangeObj.hasOwnProperty('upperVal') ||
            rangeObj.hasOwnProperty('notEqualTo'))
        ) {
          if (rangeObj.type === 'integer') {
            if (
              rangeObj.hasOwnProperty('lowerVal') &&
              Number.isInteger(rangeObj.lowerVal)
            ) {
              queryStr += `${rangeObj.key}[gte]=${rangeObj.lowerVal}&`;
            }
            if (
              rangeObj.hasOwnProperty('upperVal') &&
              Number.isInteger(rangeObj.upperVal)
            ) {
              queryStr += `${rangeObj.key}[lte]=${rangeObj.upperVal}&`;
            }
          } else if (rangeObj.type === 'date') {
            if (
              rangeObj.hasOwnProperty('lowerVal') &&
              isValidDate(rangeObj.lowerVal)
            ) {
              queryStr += `${rangeObj.key}[gte]=${rangeObj.lowerVal}&`;
            }
            if (
              rangeObj.hasOwnProperty('upperVal') &&
              isValidDate(rangeObj.upperVal)
            ) {
              queryStr += `${rangeObj.key}[lte]=${rangeObj.upperVal} 11:59:59&`;
            }
          } else if (rangeObj.type === 'bool') {
            if (
              rangeObj.hasOwnProperty('notEqualTo') &&
              (rangeObj.notEqualTo === true || rangeObj.notEqualTo === false)
            ) {
              queryStr += `${rangeObj.key}[ne]=${rangeObj.notEqualTo}&`;
            }
          }
        }
      });
    }

    // Search
    if (searchStr && searchStr !== '' && typeof searchStr === 'string') {
      queryStr += `text[search]=${encodeURIComponent(searchStr.trim())}&`;
    }

    // Remove Trailing & Symbol
    queryStr = queryStr.slice(0, queryStr.length - 1);
  }
  return queryStr;
};

export const parseQueryParams = (query) => {
  const qObj = {};

  queryKeys.forEach((key) => {
    if (query.get(key)) {
      Object.assign(qObj, { [key]: query.get(key).split(',') });
    }
  });

  return qObj;
};

export const parseFilters = (project, filters) => {
  let limitObj = {};
  let rangeArr = [];

  /**
   * Limit Object
   */

  // Sites ADD Issue, Submission, milestone,
  if (project.sites.length !== filters.selectedSites.length) {
    limitObj['site._id'] = filters.selectedSites.map((el) => el._id);
  }

  /**
   * Range Arr
   */

  // Severity
  if (filters.severityLow !== low || filters.severityHigh !== high) {
    rangeArr.push({
      key: 'currentCategorization',
      lowerVal: filters.severityLow,
      upperVal: filters.severityHigh,
      type: 'integer',
      title: 'Current Categorization',
    });
  }

  // Date Range FIXME: dates act weirdnmMAY NEED TO URLENCODE? maybe use format date?
  const start = formatDateBox(new Date(project.createdAt));
  const end = formatDateBox(new Date());

  if (filters.startDate !== start || filters.endDate !== end) {
    rangeArr.push({
      key: 'createdAt',
      lowerVal: filters.startDate,
      upperVal: filters.endDate,
      type: 'date',
      title: 'Date',
    });
  }

  // Resolved and Dismissed
  if (filters.resolveCheck) {
    rangeArr.push({
      key: 'isResolved',
      notEqualTo: true,
      type: 'bool',
      title: 'Hide Resolved',
    });
  }

  if (filters.dismissCheck) {
    rangeArr.push({
      key: 'isDismissed',
      notEqualTo: true,
      type: 'bool',
      title: 'Hide Dismissed',
    });
  }

  // Issue Count
  if (filters.minIssues > 0) {
    rangeArr.push({
      key: 'issueCount',
      lowerVal: filters.minIssues,
      type: 'integer',
      title: 'Issue Count',
    });
  }

  return [limitObj, rangeArr];
};
