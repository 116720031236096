import React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Styles
import useStyles from './copyright.styles';

function Copyright() {
  const classes = useStyles();

  return (
    <Box mt={3}>
      <Typography variant='body2' color='textSecondary' align='center'>
        {'Copyright © '}
        <Link to='/home' className={classes.linkAlt}>
          Global Build Solutions
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
}

export default Copyright;
