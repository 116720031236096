import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    cursor: 'pointer',
  },
  avatarText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.6rem',
    fontWeight: '900',
  },
  popover: {
    marginTop: '2px',
    '& div.MuiPaper-rounded': {
      borderRadius: '4px',
    },
  },
  buttonGroup: {
    border: '2px solid white',
    '& button': {
      color: theme.palette.primary.contrastText,
      fontSize: '0.7rem',
      borderRadius: 4,
      border: 0,
      padding: '4px 8px',
      height: '30px',
    },
  },
  punchlist: {
    display: 'block',
    fontSize: '0.55rem',
    lineHeight: 1,
  },
  none: {
    backgroundColor: theme.custom.issueUncatBg,
    '&:hover': {
      backgroundColor: theme.custom.issueUncatBgHover,
    },
  },
  low: {
    backgroundColor: theme.custom.issueLowBg,
    '&:hover': {
      backgroundColor: theme.custom.issueLowBgHover,
    },
    lineHeight: 1,
  },
  medium: {
    backgroundColor: theme.custom.issueMediumBg,
    '&:hover': {
      backgroundColor: theme.custom.issueMediumBgHover,
    },
  },
  high: {
    backgroundColor: theme.custom.issueHighBg,
    '&:hover': {
      backgroundColor: theme.custom.issueHighBgHover,
    },
  },
  offTrack: {
    backgroundColor: theme.custom.issueOffTrackBg,
    '&:hover': {
      backgroundColor: theme.custom.issueOffTrackBgHover,
    },
  },
}));

export default useStyles;
