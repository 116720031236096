import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Styles
import useStyles from './update-datetime.styles';

// Utilities
import { dateToTimeAgo } from '../../utils/utilityFns';

// Constants
import {
  DATE_FORMAT_DATETIME_COMPLETE as datetimeComplete,
  DATE_FORMAT_DATETIME_LONG as datetimeLong,
  DATE_FORMAT_DATE_LONG as dateLong,
  DATE_FORMAT_TIME_LONG as timeLong,
} from '../../utils/constants';

const UpdateDatetime = ({ createdAt, datetimeType = 'inline' }) => {
  const classes = useStyles();

  const actionDate = new Date(createdAt);
  const completeDateTime = actionDate.toLocaleString('en-US', datetimeComplete);
  let datetimeStr, datetimeStrAlt;
  if (datetimeType === 'fullDatetime') {
    datetimeStr = actionDate.toLocaleString('en-US', datetimeLong);
    datetimeStrAlt = actionDate.toLocaleString('en-US', timeLong);
  } else if (datetimeType === 'timeOnly') {
    datetimeStr = actionDate.toLocaleString('en-US', timeLong);
    datetimeStrAlt = datetimeStr;
  } else if (datetimeType === 'timeAgo') {
    datetimeStr = dateToTimeAgo(createdAt, 'round-minute');
    datetimeStrAlt = datetimeStr;
  } else {
    datetimeStr = actionDate.toLocaleString('en-US', dateLong);
    datetimeStrAlt = datetimeStr;
  }

  return (
    <div className={classes.datetimeContainer}>
      <span className={classes.updateDate} title={completeDateTime}>
        {datetimeStr}
      </span>
      <span className={classes.updateDateShort} title={completeDateTime}>
        {datetimeStrAlt}
      </span>
    </div>
  );
};

UpdateDatetime.propTypes = {
  createdAt: PropTypes.string.isRequired,
  datetimeType: PropTypes.oneOf(['fullDatetime', 'timeOnly', 'timeAgo'])
    .isRequired,
};

export default UpdateDatetime;
