import {
  START_API_LOADING_FETCH,
  STOP_API_LOADING_FETCH,
  START_UI_LOADING_ACTION,
  STOP_UI_LOADING_ACTION,
} from '../types';

const INITIAL_STATE = {
  actions: [],
  fetching: [],
};

const loadingReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_API_LOADING_FETCH:
      return {
        ...state,
        fetching: [...state.fetching, payload.apiAction],
      };
    case START_UI_LOADING_ACTION:
      return {
        ...state,
        actions: [...state.actions, payload.uiAction],
      };
    case STOP_API_LOADING_FETCH:
      return {
        ...state,
        fetching: state.fetching.filter((el) => el.id !== payload.id),
      };
    case STOP_UI_LOADING_ACTION:
      return {
        ...state,
        actions: state.actions.filter((el) => el.id !== payload.id),
      };
    default: {
      return state;
    }
  }
};

export default loadingReducer;
