import { takeLatest, put, all, call } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

// Action Types
import { GET_DASHBOARD_START } from '../types';

// Actions
import { getDashboardSuccess, getDashboardFailure } from './ui.actions';
import { getProjectByIdStart } from '../project/project.actions';
import { getAllSitesStart } from '../site/site.actions';
import { getAllMilestonesStart } from '../milestone/milestone.actions';
import { getOutstandingIssuesStart } from '../issue/issue.actions';
import { getFilteredSubmissionsStart } from '../submission/submission.actions';
import { startAlert } from '../alert/alert.actions';

// Constants
import { DASHBOARD_SUBMISSION_QUERY as dashboardQueryObj } from '../../utils/constants';

/**
 * Workers
 */
export function* getDashboard({ payload: { projectId } }) {
  try {
    yield put(
      getAllSitesStart({
        id: uuidv4(),
        name: 'dashboard',
        actionType: 'sites',
        projectId: projectId,
      })
    );
    yield put(
      getProjectByIdStart({
        id: uuidv4(),
        name: 'dashboard',
        actionType: 'projects',
        projectId: projectId,
      })
    );
    yield put(
      getAllMilestonesStart({
        id: uuidv4(),
        name: 'dashboard',
        actionType: 'milestones',
        projectId: projectId,
      })
    );
    yield put(
      getOutstandingIssuesStart({
        id: uuidv4(),
        name: 'dashboard',
        actionType: 'issues',
        projectId: projectId,
      })
    );
    yield put(
      getFilteredSubmissionsStart({
        id: uuidv4(),
        name: 'dashboard',
        actionType: 'submissions',
        projectId: projectId,
        queryObj: dashboardQueryObj,
      })
    );
    yield put(
      getDashboardSuccess({
        lastRefresh: new Date(),
      })
    );
  } catch (err) {
    yield put(getDashboardFailure());
    yield put(startAlert({ msg: err.response.data.message, alertType: 'error' }));
  }
}

/**
 * Watchers
 */
export function* onGetDashboardStart() {
  yield takeLatest(GET_DASHBOARD_START, getDashboard);
}

/**
 * Export Saga
 */
export function* uiSagas() {
  yield all([call(onGetDashboardStart)]);
}
