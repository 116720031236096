import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: '4px dashed rgba(50,50,50,0.66)',
    borderRadius: '20px',
    height: '100%',
    minHeight: '300px',
    fontSize: '1.4rem',
    fontWeight: 700,
    color: 'rgba(50,50,50,0.90)',
  },
}));

export default useStyles;
