import { makeStyles } from '@material-ui/core/styles';

// Constants
import { GANTT_COL_1 as gc1, GANTT_COL_2 as gc2 } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'grid',
    gridTemplateColumns: `${gc1} ${gc2} 1fr`,
    backgroundColor: theme.palette.background.paper,
  },
  lines: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
    gridTemplateColumns: (props) => `${props.monthStr}`,
    '& span': {
      display: 'grid',
      borderRight: `1px dotted ${theme.palette.divider}`,
      '&:first-of-type': {
        borderLeft: `1px dotted ${theme.palette.divider}`,
      },
      '&:last-of-type': {
        borderRight: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  marker: {
    backgroundColor: theme.custom.ganttMarker,
    zIndex: 0,
    borderRight: '1px solid transparent!important',
    borderLeft: '1px solid transparent!important',
  },
}));

export default useStyles;
