import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    //height: '48px',
    '&::after': {
      content: "''",
      width: 0,
      height: 0,
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderLeft: '12px solid #344f61',
      position: 'relative',
      top: '0px',
      left: '0.5px',
    },
  },
  button: {
    padding: '4px',
  },
  selectAll: {
    fill: theme.custom.ganttHeaderBg,
  },
  deselectAll: {
    fill: theme.custom.ganttHeaderBg,
  },
  toolTitle: {
    borderBottom: '1px dotted #a7a7a7',
    width: '100%',
    marginBottom: '1px',
  },
  formControl: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#516c84',
    },
  },
  select: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0',
    height: '38px',
    width: '42px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    backgroundColor: '#516c84ab',
    fontSize: '24px',
    fontWeight: 900,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    '& svg': {
      display: 'none',
    },
    '& fieldset': {
      display: 'none',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: 0,
      textAlign: 'center',
    },
  },
  actionSection: {
    display: 'flex',
    //marginRight: '2px',
    [theme.breakpoints.down('sm')]: {
      height: '76px',
    },
    '& button:first-of-type': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
  },
  allNoneSection: {
    //marginRight: '2px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  selectionSection: {
    //marginRight: '2px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  statusSection: {
    '& button:last-of-type': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      [theme.breakpoints.down('sm')]: {
        borderTopRightRadius: '0px',
      },
    },
    '& button:nth-child(2)': {
      [theme.breakpoints.down('sm')]: {
        borderTopRightRadius: '8px',
      },
    },
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  toggleButton: {
    backgroundColor: '#516c84ab',
    //height: '38px',
    borderRadius: '0px',
    padding: '6px 8px',
    '&:hover': {
      backgroundColor: '#516c84',
    },
  },
  svg: {
    fill: 'white',
    '& svg': {
      height: '16px',
      transform: 'translateY(-2px)',
    },
  },
  numberButton: {
    padding: '6px 8px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    backgroundColor: '#516c84ab',
    borderRadius: '0',
  },
  action: {
    '& svg': {
      fill: '#fff',
    },
  },
  actionAll: {
    '& svg': {
      fill: '#dbd8de', //'#bbe0ff',
    },
  },
  default: {
    '& svg': {
      fill: '#e1e1e1',
    },
  },
  selected: {
    backgroundColor: '#516c84',
    '& svg': {
      fill: '#fffea1',
    },
  },
  notStarted: {
    '& svg': {
      fill: theme.custom.statusGrey,
    },
  },
  ongoing: {
    '& svg': {
      fill: theme.custom.statusOrange,
    },
  },
  offTrack: {
    '& svg': {
      fill: theme.custom.statusRed,
    },
  },
  completed: {
    '& svg': {
      fill: theme.custom.statusGreen,
    },
  },
  smaller: {
    padding: '0px 2px 0px 2px',
  },
  hide: {
    display: 'none',
  },
}));

export default useStyles;
