import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,

    borderBottom: `1px solid ${theme.custom.lightgreyGantt}`,
    height: '64px',
    '&:last-of-type': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      justifyContent: 'flex-end',
    },
  },
  list: {
    paddingTop: 0,
    maxWidth: '480px',
  },
  listItem: {
    backgroundColor: theme.palette.primary.darkest,
    height: '82px',
    marginTop: '70px',
    display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  avatar: {
    width: '110px',
    height: '110px',
    border: `4px solid ${theme.palette.primary.contrastText}`,
    position: 'absolute',
    left: 'calc(50% - 55px)',
    top: '-70px',
  },
  headerText: {
    color: theme.palette.primary.contrastText,
    transform: 'translateY(19px)',
  },
}));

export default useStyles;
