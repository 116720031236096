import React from 'react';

// Components
import AuthContainer from '../../components/auth-container/auth-container.component';
import AuthNav from '../../components/auth-nav/auth-nav.component';

// Material UI
import Typography from '@material-ui/core/Typography';

const SignUp = () => {
  return (
    <AuthContainer>
      <Typography>
        The GBS platform is still in private beta. We are working hard to open things up
        soon. Please, reach out to us to request more information or a demo.
      </Typography>
      <AuthNav />
    </AuthContainer>
  );
};

export default SignUp;
