import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams, Link as RouterLink } from 'react-router-dom';

// Styles
import useStyles from './widget-stats.styles';

// Selectors
import { selectSiteOrProjectMilestoneOverview } from '../../redux/milestone/milestone.selectors';

const WidgetStats = ({ selection }) => {
  const classes = useStyles();
  const { projectId } = useParams();

  if (!selection) return null;

  let milestoneText;
  let milestoneUrl;
  if (selection.type === 'Project') {
    milestoneText = `Project Milestones: ${selection.totalMilestones}`;
    milestoneUrl = `/projects/${projectId}/milestones`;
  } else if (selection.type === 'Site') {
    milestoneText = `Site Milestones: ${selection.totalMilestones}`;
    milestoneUrl = `/projects/${projectId}/milestones?site._id=${selection.siteId}`;
  } else {
    milestoneText = 'No milestones.';
    milestoneUrl = `/projects/${projectId}/milestones`;
  }

  return (
    <div className={classes.root}>
      <div className={classes.pieContainer}>
        <div className={classes.pieHalf}>
          <div className={clsx(classes.pie, classes.notStarted)}>
            <span>{selection.notStartedMilestones}</span>
          </div>
          <div className={clsx(classes.pie, classes.ongoing)}>
            <span>{selection.ongoingMilestones}</span>
          </div>
        </div>
        <div className={classes.pieHalf}>
          <div className={clsx(classes.pie, classes.offTrack)}>
            <span>{selection.offTrackMilestones}</span>
          </div>
          <div className={clsx(classes.pie, classes.completed)}>
            <span>{selection.metMilestones}</span>
          </div>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.milestones}>Milestone Summary</div>
        <span
          className={
            classes.summary
          }>{`${selection.progressPercent}% Completed`}</span>
        <RouterLink
          className={classes.link}
          to={milestoneUrl}
          rel='noopener noreferrer'>
          {milestoneText}
        </RouterLink>
      </div>
    </div>
  );
};

WidgetStats.propTypes = {
  selection: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  selection: selectSiteOrProjectMilestoneOverview,
});

export default connect(mapStateToProps)(WidgetStats);
