import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';

// Component
import UpdateItem from '../update-item/update-item.component';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

// Styles
import useStyles from './comment-modal.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectModalComment } from '../../redux/ui/ui.selectors';

function CommentModal({ comment: { isOpen, content }, toggleModalDispatch }) {
  const classes = useStyles();
  const { projectId } = useParams();
  const [notes, setNotes] = useState('');

  useEffect(() => {
    return function cleanup() {
      setNotes('');
    };
  }, []);

  if (Object.keys(content).length === 0 || !content?.update) return null;

  const handleClose = () => {
    setNotes('');
    toggleModalDispatch({ modalName: 'comment', modalContent: {} });
  };

  const handleChange = (e) => {
    setNotes(e.target.value);
  };

  const enableSubmit = Boolean(notes && notes.length > 0);

  const singleView = true;
  const showMentions = false;
  const isReply = true;
  const renderReplies = false;
  const datetimeType = 'timeAgo'; // timeOnly OR timeAgo OR fullDatetime
  const type = 'issue';
  const currentUserId = '5ffe0bb7ea960a514ea0e4cc';
  const site = {
    _id: '667d7fdb054a2031bdc6cb86',
    name: 'Kadjucu',
  };
  const milestone = {
    _id: '667d847a054a2031bdc6cbca',
    name: 'Preliminary Assessment',
  };
  const refId = '33A';
  const docId = '1234'; //FIXME need to put this on content.

  console.log(content);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='comment-dialog-title'
      open={isOpen}
      className={classes.root}>
      <div className={classes.container}>
        <div className={classes.update}>
          <UpdateItem
            docId={docId}
            key={`add-comment-${content.update._id}`}
            datetimeType={datetimeType}
            type={type}
            refId={refId}
            singleView={singleView}
            renderReplies={renderReplies}
            isReply={isReply}
            showMentions={showMentions}
            currentUserId={currentUserId}
            site={site}
            milestone={milestone}
            update={content.update}
            toggleModalDispatch={toggleModalDispatch}
            handleDeleteUpdate={() => {}}
          />
        </div>
        <div className={classes.content}>
          <TextField
            className={classes.textField}
            autoFocus={true}
            multiline
            minRows={5}
            margin='dense'
            id='name'
            label='Add a reply'
            type='text'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={notes}
          />
        </div>
        <DialogActions className={classes.buttonGroup}>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>

          <Button
            onClick={() => console.log('submit')}
            disabled={!enableSubmit}
            color='primary'>
            Submit
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

CommentModal.propTypes = {
  comment: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  comment: selectModalComment,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentModal);
