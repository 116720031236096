import { makeStyles } from '@material-ui/core';

const full = '64px';
const half = '32px';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: '88px',
    padding: '4px 24px',
    [theme.breakpoints.down('md')]: {
      padding: '4px 6px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 24px',
    },
  },
  pieContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '4px 0px 4px 16px',
  },
  milestones: {
    display: 'flex',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: 1,
    padding: '5px 0px 2px 0px',
  },
  summary: {
    display: 'flex',
    fontSize: '0.9rem',
    fontWeight: 400,
  },
  link: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.light,
      cursor: 'pointer',
    },
  },
  pie: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: half,
    height: half,
    boxSizing: 'content-box',
  },
  pieHalf: { display: 'flex', width: full },
  completed: {
    backgroundColor: theme.custom.statusGreen,
    borderBottomRightRadius: half,
    '& span': {
      transform: 'translate(-2px, -3px)',
      letterSpacing: '-1px',
      fontWeight: 700,
      fontSize: '0.8rem',
    },
  },
  ongoing: {
    backgroundColor: theme.custom.statusOrange,
    borderTopRightRadius: half,
    borderBottom: '2px solid #fff',
    '& span': {
      transform: 'translate(-2px, 3px)',
      letterSpacing: '-1px',
      fontWeight: 700,
      fontSize: '0.8rem',
    },
  },
  offTrack: {
    backgroundColor: theme.custom.statusRed,
    borderBottomLeftRadius: half,
    borderRight: '2px solid #fff',
    '& span': {
      transform: 'translate(2px, -3px)',
      letterSpacing: '-1px',
      fontWeight: 700,
      fontSize: '0.8rem',
    },
  },
  notStarted: {
    backgroundColor: theme.custom.statusGrey,
    borderTopLeftRadius: half,
    borderRight: '2px solid #fff',
    borderBottom: '2px solid #fff',
    '& span': {
      transform: 'translate(2px, 3px)',
      letterSpacing: '-1px',
      fontWeight: 700,
      fontSize: '0.8rem',
    },
  },
}));

export default useStyles;
