import React from 'react';
import PropTypes from 'prop-types';

// Components
import ReactionsEmoji from '../reactions-emoji/reactions-emoji.component';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';

// Styles
import useStyles from './update-reactions.styles';

const UpdateReactions = ({ reactions, currentUserId, toggleModalDispatch }) => {
  const classes = useStyles();

  if (!reactions || Object.values(reactions).flat().length === 0) return null;

  return (
    <div className={classes.root}>
      <ReactionsEmoji type='love' reactions={reactions} hideEmoji={false} />
      <ReactionsEmoji type='clap' reactions={reactions} hideEmoji={false} />
      <ReactionsEmoji type='fire' reactions={reactions} hideEmoji={false} />
      <ReactionsEmoji type='thumbsUp' reactions={reactions} hideEmoji={false} />
      <ReactionsEmoji
        type='thumbsDown'
        reactions={reactions}
        hideEmoji={false}
      />
      <ReactionsEmoji type='check' reactions={reactions} hideEmoji={false} />

      <span
        className={classes.addIconContainer}
        onClick={() =>
          toggleModalDispatch({
            modalName: 'reactionMenu',
            modalContent: { reactions, currentUserId },
          })
        }>
        <Tooltip
          title='Add reaction'
          arrow
          placement='right-start'
          classes={{
            tooltip: classes.tooltipOverride,
          }}>
          <InsertEmoticonIcon className={classes.addIcon} />
        </Tooltip>
      </span>
    </div>
  );
};

UpdateReactions.propTypes = {
  toggleModalDispatch: PropTypes.func.isRequired,
  reactions: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
};

export default UpdateReactions;
