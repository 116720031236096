import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

// Material UI
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';

// Styles
import useStyles from './action-menu.styles';

// Actions
import { startAlert } from '../../redux/alert/alert.actions';
import { addAttachmentProjectStart } from '../../redux/project/project.actions';

// Constants
import {
  MAX_ATTACHMENTS_ISSUE,
  MAX_ATTACHMENT_SIZE,
} from '../../utils/constants';

function ActionMenu({ addAttachmentProjectStartDispatch, startAlertDispatch }) {
  const classes = useStyles();
  const { projectId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAttachmentAdd = (e) => {
    if (e.target.files.length > MAX_ATTACHMENTS_ISSUE) {
      startAlertDispatch({
        msg: `Max number of attachments (${MAX_ATTACHMENTS_ISSUE}) exceeded.`,
        alertType: 'error',
      });
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > MAX_ATTACHMENT_SIZE) {
        startAlertDispatch({
          msg: `Max filesize (${
            MAX_ATTACHMENT_SIZE / 1024 / 1024
          }mb) exceeded in one or more files.`,
          alertType: 'error',
        });
        return;
      }
    }

    let numFiles = e.target.files.length;

    let formData = new FormData();
    for (let i = 0; i < numFiles; i++) {
      formData.append('attachments', e.target.files[i]);
    }
    addAttachmentProjectStartDispatch({
      formData,
      projectId,
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Tooltip title='Actions'>
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          className={classes.iconButton}>
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <div className={classes.root}>
          <List aria-label='actions'>
            {/* <ListItem button>
              <ListItemIcon>
                <BackupIcon />
              </ListItemIcon>
              <input
                className={classes.input}
                id='attachments'
                multiple
                type='file'
                onChange={(e) => handleAttachmentAdd(e)}
              />
              <label htmlFor='attachments'>
                <ListItemText className={classes.link} primary='Upload a document' />
              </label>
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary='Add a new user' />
            </ListItem> */}
            <ListItem
              button
              component='a'
              href='mailto:globalbuild.support@protonmail.com?subject=Global%20Build%20Solutions%20Help%20Desk'>
              <ListItemIcon>
                <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText primary='Contact helpdesk' />
            </ListItem>
            <Divider />
            <RouterLink to='/projects'>
              <ListItem button>
                <ListItemIcon>
                  <SwapCallsIcon />
                </ListItemIcon>
                <ListItemText primary='Switch Project' />
              </ListItem>
            </RouterLink>
          </List>
        </div>
      </Popover>
    </>
  );
}

ActionMenu.propTypes = {
  addAttachmentProjectStartDispatch: PropTypes.func.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addAttachmentProjectStartDispatch: (attachmentObj) =>
    dispatch(addAttachmentProjectStart(attachmentObj)),
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
});

export default connect(null, mapDispatchToProps)(ActionMenu);
