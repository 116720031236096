import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PhotoIcon from '@material-ui/icons/Photo';
import Checkbox from '@material-ui/core/Checkbox';

// Components
import SeverityTag from '../severity-tag/severity-tag.component';
import SeverityAvatar from '../severity-avatar/severity-avatar.component';
import TableColgroup from '../table-colgroup/table-colgroup.component';
import IssueActionButtons from '../issue-action-buttons/issue-action-buttons.component';

// Styles
import useStyles from './issuebox-section.styles';

// Utility Functions
import { severityNumToString, formatDateTime } from '../../utils/utilityFns';

// Constants
import { ISSUE_PREVIEW_LENGTH as noteMax } from '../../utils/constants';

const dateOptionsDay = { day: '2-digit' };
const dateOptionsMonth = { month: 'long' };

function IssueboxSection({
  issues,
  highlight,
  toggleModal,
  lastOfType,
  user,
  hideHeader = true,
}) {
  const classes = useStyles();
  const { projectId } = useParams();

  const handlePhotoModal = (issue) => {
    if (issue.updates[0].content.photos[0]?.url) {
      toggleModal({
        modalName: 'photoGallery',
        modalContent: {
          photos: issue.updates[0].content.photos,
          isDeletable: false,
          issueUser: issue.createdBy?._id,
        },
      });
    }
  };

  const cardInfo = (issue) => {
    const issueDate = new Date(issue.createdAt);
    return (
      <div className={classes.firstColCard}>
        <span className={classes.firstColCardHeader}>
          <SeverityAvatar
            docId={issue._id}
            severityLevel={issue.currentCategorization}
            site={issue.site.name}
            block={false}
          />
          <span className={classes.firstColCardText}>
            <Typography variant='subtitle2' component='div'>
              {issue.site.name}{' '}
            </Typography>
            {`${issue.createdBy?.firstName?.charAt(0).toUpperCase()}. ${
              issue.createdBy?.lastName
            }`}
          </span>
        </span>
        <span className={classes.calContainer}>
          <div className={classes.calMonth}>
            {issueDate.toLocaleString('en-US', dateOptionsMonth).slice(0, 3)}
          </div>
          <div className={classes.calDay}>
            {issueDate.toLocaleString('en-US', dateOptionsDay)}
          </div>
        </span>
      </div>
    );
  };

  return (
    <>
      <TableContainer
        className={clsx(
          classes.tableContainer,
          lastOfType ? classes.tableContainerLast : null
        )}>
        <Table className={classes.table}>
          <TableColgroup
            cols={[2, 14, 10, 45, 9, 20]}
            className={classes.colGroup}
          />
          <TableHead
            className={clsx(classes.head, hideHeader ? classes.hidden : null)}>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}></TableCell>
              <TableCell className={classes.firstCol}>Metadata</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Severity</TableCell>
              <TableCell>Issue Description</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Photos</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {issues.map((issue) => (
              <TableRow
                key={issue._id}
                className={
                  highlight ? classes.tableRowHighlight : classes.tableRow
                }>
                <TableCell className={classes.checkboxCol}>
                  <Checkbox value={false} color='primary' size='small' />
                </TableCell>
                <TableCell className={classes.firstCol}>
                  <span className={classes.firstColTable}>
                    <Typography
                      variant='subtitle2'
                      component='div'
                      className={classes.siteName}>
                      {issue.site.name}
                    </Typography>
                    {`${issue.createdBy?.firstName?.charAt(0).toUpperCase()}. ${
                      issue.createdBy?.lastName
                    }`}
                    <Typography variant='body2' component='p'>
                      <Tooltip title='Open Issue'>
                        <Link
                          component={RouterLink}
                          to={`/projects/${issue.project}/issues/${issue._id}`}>
                          {formatDateTime(issue.createdAt, 'default')}
                        </Link>
                      </Tooltip>
                    </Typography>
                  </span>
                  {cardInfo(issue)}
                </TableCell>
                <TableCell className={classes.severityCol}>
                  <div className={classes.severityWrapper}>
                    {!issue.isResolved ? (
                      <SeverityTag
                        docId={issue._id}
                        severityLevel={issue.currentCategorization}
                        site={issue.site.name}
                        block={false}>
                        {severityNumToString(issue.currentCategorization)}
                      </SeverityTag>
                    ) : (
                      <Chip
                        avatar={<DoneIcon />}
                        label={'Resolved'}
                        size='small'
                        className={classes.resolved}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell className={classes.secondCol}>
                  <Box className={classes.issueBox}>
                    <Typography variant='overline' style={{ lineHeight: 1 }}>
                      {issue.question?.errorName}
                    </Typography>
                    <Typography
                      variant='body2'
                      style={{ lineHeight: 1.3, fontWeight: 500 }}>
                      {issue.question?.errorText}
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      style={{ lineHeight: 1.3, fontWeight: 400 }}>
                      {issue.updates[0].content.notes &&
                      issue.updates[0].content.notes.length > noteMax ? (
                        <>
                          {issue.updates[0].content.notes.slice(0, noteMax)}
                          <span
                            className={classes.seeMore}
                            onClick={() =>
                              toggleModal({
                                modalName: 'issueNotes',
                                modalContent: {
                                  id: issue._id,
                                  categorization: issue.currentCategorization,
                                  milestoneFlag: 0,
                                  actionType: 'Updated',
                                  updates: issue.updates,
                                  noAutoFocus: true,
                                },
                              })
                            }>
                            ... (see more)
                          </span>
                        </>
                      ) : (
                        issue.updates[0].content.notes
                      )}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell className={classes.thirdCol}>
                  <div className={classes.photoCell}>
                    <Avatar
                      src={issue.updates[0].content.photos[0]?.url}
                      variant='rounded'
                      className={clsx(
                        classes.large,
                        !issue.updates[0].content.photos[0]?.url
                          ? classes.hidden
                          : null
                      )}
                      onClick={() => handlePhotoModal(issue)}>
                      <ErrorOutlineIcon fontSize='large' />
                    </Avatar>
                    {issue.updates[0].content.photos.length > 1 && (
                      <div className={classes.photoBadge}>
                        {issue.updates[0].content.photos.length}
                        <PhotoIcon className={classes.photoIcon} />
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell className={classes.fourthCol}>
                  <IssueActionButtons
                    issue={issue}
                    user={user}
                    toggleModalDispatch={toggleModal}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

IssueboxSection.propTypes = {
  issues: PropTypes.array.isRequired,
  highlight: PropTypes.bool,
  lastOfType: PropTypes.bool,
  user: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  hideHeader: PropTypes.bool,
  showExpand: PropTypes.bool,
};

export default IssueboxSection;
