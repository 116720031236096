import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import AuthNav from '../../components/auth-nav/auth-nav.component';
import AuthContainer from '../../components/auth-container/auth-container.component';

// Material UI
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

// Actions
import { signInStart } from '../../redux/user/user.actions';

const SignIn = ({ signInStartDispatch }) => {
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: '',
  });

  const { email, password } = userCredentials;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signInStartDispatch(email, password);
  };

  return (
    <AuthContainer>
      <ValidatorForm onSubmit={handleSubmit}>
        <TextValidator
          variant='outlined'
          color='primary'
          margin='normal'
          validators={['required', 'isEmail']}
          errorMessages={[
            'This field is required.',
            'You must enter a valid email address.',
          ]}
          fullWidth
          id='email'
          label='Email Address'
          name='email'
          autoComplete='email'
          autoFocus
          onChange={handleChange}
          value={email}
        />
        <TextValidator
          variant='outlined'
          color='primary'
          margin='normal'
          validators={['required', 'minStringLength:8']}
          errorMessages={[
            'This field is required.',
            'Password must be at least 8 characters.',
          ]}
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='password'
          autoComplete='current-password'
          onChange={handleChange}
          value={password}
        />
        <Box mt={3} mb={2}>
          <Button type='submit' variant='contained' color='primary' fullWidth>
            Sign In
          </Button>
        </Box>
      </ValidatorForm>
      <AuthNav />
    </AuthContainer>
  );
};

SignIn.propTypes = {
  signInStartDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  signInStartDispatch: (email, password) => dispatch(signInStart({ email, password })),
});

export default connect(null, mapDispatchToProps)(SignIn);
