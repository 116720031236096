import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';

const SharingTooltip = ({ shareDoc, children }) => {
  if (!shareDoc || !shareDoc.isShared) return children;

  const tooltipHtml = (
    <>
      {`Exporting: ${shareDoc.share.canExport ? 'Enabled' : 'Disabled'}`}
      <br />
      {`Commenting: ${shareDoc.share.canComment ? 'Enabled' : 'Disabled'}`}
      <br />
      {`History: ${shareDoc.share.showUpdates ? 'Visible' : 'Hidden'}`}
    </>
  );

  return <Tooltip title={tooltipHtml}>{children}</Tooltip>;
};

SharingTooltip.propTypes = {
  shareDoc: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default SharingTooltip;
