import { createSelector } from 'reselect';

// External Selectors
import { selectCurrentProjectBuckets } from '../project/project.selectors';

// Utility Functions
import { sortUpdatesByBuckets } from '../../utils/utilityFns';

const selectFeedSlice = (state) => state.feed;

export const selectFeedItems = createSelector(
  [selectFeedSlice],
  (feed) => feed.items
);

export const selectSortedFeedItems = createSelector(
  [selectFeedItems, selectCurrentProjectBuckets],
  (items, buckets) => sortUpdatesByBuckets(items, buckets)
);
