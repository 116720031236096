import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import axios from 'axios';

// Material UI
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import TodayIcon from '@material-ui/icons/Today';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AddCommentIcon from '@material-ui/icons/AddComment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CloseIcon from '@material-ui/icons/Close';

// Components
import FileButton from '../file-button/file-button.component';

// Styles
import useStyles from './sidebar-modal.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';
import { startAlert } from '../../redux/alert/alert.actions';
import {
  addNoteMilestoneStart,
  addAttachmentMilestoneStart,
  deleteNoteMilestoneStart,
  deleteAttachmentMilestoneStart,
  updateMilestoneStart,
} from '../../redux/milestone/milestone.actions';

// Selectors
import { selectModalSidebar } from '../../redux/ui/ui.selectors';
import { selectCurrentUserId } from '../../redux/user/user.selectors';

// Constants
import {
  MAX_ATTACHMENTS_ISSUE,
  MAX_ATTACHMENT_SIZE,
} from '../../utils/constants';

//Utilities
import {
  formatDateTime,
  sortDate,
  capitalizeFirstLetter,
} from '../../utils/utilityFns';

// Utilities
const dateOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
};

const dateOptionsNote = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

function SidebarModal({
  sidebarNotes: { isOpen, content },
  toggleModalDispatch,
  userId,
  startAlertDispatch,
  addNoteMilestoneStartDispatch,
  deleteNoteMilestoneStartDispatch,
  deleteAttachmentMilestoneStartDispatch,
  addAttachmentMilestoneStartDispatch,
  updateMilestoneStartDispatch,
}) {
  const [showForm, setShowForm] = useState(false);
  const [moreSubs, setMoreSubs] = useState(false);
  const [moreIssues, setMoreIssues] = useState(false);
  const [notes, setNotes] = useState('');
  const [extraData, setExtraData] = useState(null);

  // Move to Constants
  const MORE_SUBS_LIMIT = 5;
  const MORE_ISSUES_LIMIT = 5;

  const classes = useStyles();
  const history = useHistory();
  const { projectId } = useParams();

  const { doFetch, urlFetch } = content;

  useEffect(() => {
    if (doFetch && urlFetch) {
      async function fetchExtraData() {
        const res = await axios.get(urlFetch);
        if (res?.data?.data?.docs) {
          setExtraData(res.data.data.docs);
        }
      }
      fetchExtraData();
    }
    return function cleanup() {
      setNotes('');
      setExtraData(null);
      setShowForm(false);
    };
  }, [doFetch, urlFetch]);

  // Merge Phots from Submissions and Home Office
  const addCaptionToPhotoObj = (obj) => {
    const createdAt = new Date(obj.createdAt).toLocaleString(
      'en-US',
      dateOptions
    );
    const isFieldSubmission = obj.hasOwnProperty('question');
    const caption = isFieldSubmission
      ? `Submitted ${createdAt} ${obj?.question?.name}`
      : 'Added From Home Office';
    const captionLink = isFieldSubmission
      ? `/projects/${projectId}/submissions/${obj._id}`
      : false;

    return { ...obj, caption, isFieldSubmission, captionLink };
  };

  let photosCombined = [];
  if (extraData?.photos?.length > 0) {
    photosCombined = [
      ...extraData.photos.map((el) => addCaptionToPhotoObj(el)),
      ...photosCombined,
    ];
  }
  if (content?.item?.photos?.length > 0) {
    photosCombined = [
      ...content.item.photos.map((el) => addCaptionToPhotoObj(el)),
      ...photosCombined,
    ];
  }

  const handleChange = (e) => {
    setNotes(e.target.value);
  };

  const handleClose = () => {
    setNotes('');
    setShowForm(false);
    toggleModalDispatch({ modalName: 'sidebarNotes', modalContent: {} });
  };

  const handleOpenLink = (url) => {
    setNotes('');
    setShowForm(false);
    toggleModalDispatch({ modalName: 'sidebarNotes', modalContent: {} });
    history.push(url);
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const toggleMoreSubs = () => {
    setMoreSubs(!moreSubs);
  };

  const toggleMoreIssues = () => {
    setMoreIssues(!moreIssues);
  };

  const handleFormSubmit = () => {
    if (notes === '') {
      startAlertDispatch({
        msg: `No content.`,
        alertType: 'error',
      });
      return;
    }

    addNoteMilestoneStartDispatch({
      text: notes,
      projectId,
      milestoneId: content.item._id,
    });
    setNotes('');
    setShowForm(false);
  };

  const handleDeleteNote = (deleteObj) => {
    if (deleteObj.noteId) {
      deleteNoteMilestoneStartDispatch(deleteObj);
    }
  };

  const handleDeleteAttachment = (deleteObj) => {
    if (deleteObj.attachmentId) {
      deleteAttachmentMilestoneStartDispatch(deleteObj);
    }
  };

  const handleAttachmentAdd = (e, type) => {
    if (e.target.files.length > MAX_ATTACHMENTS_ISSUE) {
      startAlertDispatch({
        msg: `Max number of attachments (${MAX_ATTACHMENTS_ISSUE}) exceeded.`,
        alertType: 'error',
      });
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > MAX_ATTACHMENT_SIZE) {
        startAlertDispatch({
          msg: `Max filesize (${
            MAX_ATTACHMENT_SIZE / 1024 / 1024
          }mb) exceeded in one or more files.`,
          alertType: 'error',
        });
        return;
      }
    }

    let numFiles = e.target.files.length;

    let formData = new FormData();
    for (let i = 0; i < numFiles; i++) {
      formData.append('attachments', e.target.files[i]);
    }
    addAttachmentMilestoneStartDispatch({
      formData,
      projectId,
      milestoneId: content.item._id,
      component: 'sidebar',
    });
  };

  const elementName = (
    <>
      <strong>Name:</strong> {content?.item?.milestoneName}
    </>
  );

  const elementPaymentGroup = content?.item?.paymentGroup ? (
    <>
      {' - '}
      <strong>Payment Group: </strong>
      {`(${capitalizeFirstLetter(content.item.paymentGroup)})`}
    </>
  ) : null;

  const elementCost =
    content?.item?.cost && content?.item?.costUnit ? (
      <>
        <strong>Cost: </strong>
        {`${content.item.cost.toLocaleString()}${content.item.costUnit} `}
      </>
    ) : null;

  const elementWasPaid = content?.item?.hasOwnProperty('wasPaid') ? (
    <>
      <Chip
        component='span'
        size='small'
        className={content.item.wasPaid ? classes.paid : null}
        icon={<MonetizationOnIcon />}
        label={content.item.wasPaid ? 'Paid' : 'Unpaid'}
      />
    </>
  ) : null;

  const elementDetails = (
    <>
      <strong>Description:</strong> {content?.item?.description}
      {content?.item?.taskId ? (
        <>
          <br />
          <strong>Task ID:</strong> {content?.item?.taskId}
          {elementPaymentGroup}
        </>
      ) : null}
      {content?.item?.hasOwnProperty('wasPaid') ? (
        <>
          <br />

          {elementCost}
          {elementWasPaid}
        </>
      ) : null}
      {content?.item?.implementer ? (
        <>
          <br />
          <strong>Implementer:</strong> {content?.item?.implementer}
        </>
      ) : null}
    </>
  );

  const elementStatus = (
    <>
      <strong>Current Status:</strong> {content?.item?.actualStatus}
    </>
  );

  const elementShowDates =
    content?.item?.hideFromGantt === true ? null : (
      <>
        <strong>Scheduled Dates </strong>
        {`${formatDateTime(content?.item?.datePlannedStart)} - ${formatDateTime(
          content?.item?.datePlannedEnd
        )}`}{' '}
        <br />
        <strong>Actual Dates </strong>
        {`${formatDateTime(content?.item?.dateActualStart)} - ${formatDateTime(
          content?.item?.dateActualEnd
        )}`}{' '}
        <br />
      </>
    );

  const elementDates = (
    <>
      {elementShowDates}
      <strong>{content?.item?.actualStartMessage}</strong>
    </>
  );

  return (
    <Drawer anchor='right' open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div
          className={clsx(
            classes.infoContainer,
            content?.item?.actualStatus.toLowerCase()
          )}>
          <div className={classes.infoHeader}>
            <span className={classes.closeSidebar}>
              <Tooltip title='Close Sidebar'>
                <IconButton
                  size='small'
                  className={classes.iconButon}
                  aria-label='close sidebar'
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Typography
                component='span'
                variant='h5'
                className={classes.header}>
                Milestone Info
              </Typography>
            </span>
            <span
              className={clsx(
                classes.status,
                content?.item?.actualStatus.toLowerCase()
              )}>
              {content?.item?.actualStatus}
            </span>
          </div>
          <Divider />
          <List className={classes.rootChat}>
            <ListItem
              alignItems='flex-start'
              className={classes.listItem}
              key='item1'>
              <ListItemAvatar>
                <Avatar>
                  <AssignmentTurnedInIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={elementName} secondary={elementDetails} />
            </ListItem>
            <ListItem
              alignItems='flex-start'
              className={classes.listItem}
              key='item2'>
              <ListItemAvatar>
                <Avatar color='primary'>
                  <TodayIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={elementStatus} secondary={elementDates} />
            </ListItem>
          </List>
        </div>
        <div className={classes.photoContainer}>
          <div className={classes.photoHeader}>
            <Typography
              component='span'
              variant='h6'
              className={classes.contentHeader}
              color='textPrimary'>
              Photo Gallery
            </Typography>
            <input
              className={classes.input}
              id='photosInput'
              name='photosInput'
              multiple
              type='file'
              accept='image/*'
              onChange={(e) => handleAttachmentAdd(e, 'photos')}
            />
            <label
              htmlFor='photosInput'
              style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant='outlined'
                color='default'
                size='small'
                component='span'
                aria-label='attach photos'
                className={classes.button}
                startIcon={<PhotoCameraIcon />}>
                Add
              </Button>
            </label>
          </div>
          <Divider />
          {photosCombined.length > 0 ? (
            <div className={classes.photos}>
              {photosCombined
                .sort(sortDate('createdAt', 'desc'))
                .slice(0, 9)
                .map((el, idx) => {
                  return (
                    <Avatar
                      key={`${idx}-${el._id}`}
                      alt='Field Photo'
                      src={el.url}
                      variant='rounded'
                      className={clsx(
                        classes.photo,
                        el.isFieldSubmission ? null : classes.homeOffice
                      )}
                      onClick={() =>
                        toggleModalDispatch({
                          modalName: 'photoGallery',
                          modalContent: {
                            photos: photosCombined,
                            startPhoto: idx,
                            handleDelete:
                              deleteAttachmentMilestoneStartDispatch,
                            currentUserId: userId,
                            projectId: projectId,
                            caption: '',
                            issueCount: el?.issueCount ? el.issueCount : 0,
                            milestoneId: content?.id,
                          },
                        })
                      }
                    />
                  );
                })}
              {photosCombined.length > 9 ? (
                <div
                  className={classes.morePhotos}
                  onClick={() =>
                    toggleModalDispatch({
                      modalName: 'photoGallery',
                      modalContent: {
                        photos: photosCombined,
                        startPhoto: 9,
                        handleDelete: deleteAttachmentMilestoneStartDispatch,
                        currentUserId: userId,
                        projectId: projectId,
                        caption: '',
                        issueCount: 0,
                        milestoneId: content?.id,
                      },
                    })
                  }>
                  <span>{`${photosCombined.length - 9} More`}</span>
                  <span>Photos</span>
                </div>
              ) : null}
            </div>
          ) : (
            <Typography
              component='div'
              variant='body2'
              className={classes.defaultMsg}
              color='textPrimary'>
              No Photos.
            </Typography>
          )}
        </div>
        <div className={classes.photoContainer}>
          <div className={classes.photoHeader}>
            <Typography
              component='span'
              variant='h6'
              className={classes.contentHeader}
              color='textPrimary'>
              Field Submissions
            </Typography>
          </div>
          <Divider />
          {extraData?.submissions?.length > 0 ? (
            <div className={classes.submissions}>
              {extraData.submissions
                .sort(sortDate('createdAt', 'desc'))
                .map((el, idx) => {
                  const createdAt = new Date(el.createdAt).toLocaleString(
                    'en-US',
                    dateOptions
                  );

                  const issueText =
                    el.issueCount > 0 ? (
                      <RouterLink
                        className={classes.link}
                        to={`/projects/${projectId}/issues?submission=${el._id}`}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <strong>{`${el.issueCount} Issue${
                          el.issueCount === 1 ? '' : 's'
                        }`}</strong>
                      </RouterLink>
                    ) : (
                      '0 Issues'
                    );
                  return (
                    <div
                      key={`sub-${el._id}`}
                      className={clsx(
                        classes.submissionRow,
                        idx > MORE_SUBS_LIMIT - 1
                          ? moreSubs
                            ? null
                            : classes.hide
                          : null
                      )}>
                      <span>
                        <RouterLink
                          className={classes.link}
                          to={`/projects/${projectId}/submissions/${el._id}`}
                          target='_blank'
                          rel='noopener noreferrer'>{`#${
                          extraData.numSubmissions - idx
                        }`}</RouterLink>
                      </span>
                      <span>
                        <RouterLink
                          className={classes.link}
                          to={`/projects/${projectId}/submissions/${el._id}`}
                          target='_blank'
                          rel='noopener noreferrer'>
                          {createdAt}
                        </RouterLink>
                      </span>
                      <span>{`${el.createdBy?.firstName} 
                   ${el.createdBy?.lastName}`}</span>
                      <span>{issueText}</span>
                    </div>
                  );
                })}
              {extraData.submissions.length > MORE_SUBS_LIMIT ? (
                <div className={classes.link} onClick={toggleMoreSubs}>
                  Show {moreSubs ? 'Less' : 'More'}
                </div>
              ) : null}
            </div>
          ) : (
            <Typography
              component='div'
              variant='body2'
              className={classes.defaultMsg}
              color='textPrimary'>
              No Submissions found for this milestone.
            </Typography>
          )}
        </div>
        <div className={classes.photoContainer}>
          <div className={classes.photoHeader}>
            <Typography
              component='span'
              variant='h6'
              className={classes.contentHeader}
              color='textPrimary'>
              Issues Reported
            </Typography>
          </div>
          <Divider />
          {extraData?.issues?.length > 0 ? (
            <div className={classes.submissions}>
              {extraData.issues.map((el, idx) => {
                const createdAt = new Date(el.createdAt).toLocaleString(
                  'en-US',
                  dateOptions
                );

                const severityClass = el.isResolved
                  ? classes.resolved
                  : el.currentCategorization === 0
                  ? classes.none
                  : el.currentCategorization === 1
                  ? classes.low
                  : el.currentCategorization === 2
                  ? classes.medium
                  : el.currentCategorization === 3
                  ? classes.high
                  : el.currentCategorization === 4
                  ? classes.offTrack
                  : classes.none;

                const severityText = el.isResolved
                  ? 'Resolved'
                  : el.currentCategorization === 0
                  ? 'Uncat'
                  : el.currentCategorization === 1
                  ? 'Low'
                  : el.currentCategorization === 2
                  ? 'Medium'
                  : el.currentCategorization === 3
                  ? 'High'
                  : el.currentCategorization === 4
                  ? 'Off Track'
                  : 'Unknown';

                return (
                  <div
                    key={`sub-${el._id}`}
                    className={clsx(
                      classes.issueRow,
                      idx > MORE_ISSUES_LIMIT - 1
                        ? moreIssues
                          ? null
                          : classes.hide
                        : null
                    )}>
                    <span>
                      <RouterLink
                        className={classes.link}
                        to={`/projects/${projectId}/issues?_id=${el._id}`}
                        target='_blank'
                        rel='noopener noreferrer'>{`#${
                        extraData.numIssues - idx
                      }`}</RouterLink>
                    </span>
                    <span>
                      <RouterLink
                        className={classes.link}
                        to={`/projects/${projectId}/issues?_id=${el._id}`}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {createdAt}
                      </RouterLink>
                    </span>
                    <span>
                      <RouterLink
                        className={classes.link}
                        to={`/projects/${projectId}/issues?_id=${el._id}`}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {el.errorName}
                      </RouterLink>
                    </span>

                    <span className={clsx(classes.issuePill, severityClass)}>
                      {severityText}
                    </span>
                  </div>
                );
              })}
              {extraData.issues.length > MORE_ISSUES_LIMIT ? (
                <div className={classes.link} onClick={toggleMoreIssues}>
                  Show {moreIssues ? 'Less' : 'More'}
                </div>
              ) : null}
            </div>
          ) : (
            <Typography
              component='div'
              variant='body2'
              className={classes.defaultMsg}
              color='textPrimary'>
              No Issues found for this milestone.
            </Typography>
          )}
        </div>
        <div
          className={clsx(
            classes.fileContainer,
            extraData?.issues?.length > 0 ? classes.topPad : null
          )}>
          <div className={classes.photoHeader}>
            <Typography
              component='span'
              variant='h6'
              className={classes.contentHeader}
              color='textPrimary'>
              Attachments
            </Typography>
            <input
              className={classes.input}
              id='attachments'
              multiple
              type='file'
              onChange={(e) => handleAttachmentAdd(e, 'files')}
            />
            <label
              htmlFor='attachments'
              style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant='outlined'
                color='default'
                size='small'
                component='span'
                aria-label='attach photos'
                className={classes.button}
                startIcon={<CloudUploadIcon />}>
                Add
              </Button>
            </label>
          </div>
          <Divider />
          {content?.item?.attachments?.length > 0 ? (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '116px 116px 116px 116px',
                columnGap: '4px',
                padding: '0px 12px',
              }}>
              {content.item.attachments.map((el) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <div>
                    <FileButton file={el} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: '0.8rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: '0.8rem',
                        textAlign: 'center',
                        height: '36px',
                        justifyContent: 'center',
                        overflowWrap: 'anywhere',
                      }}>
                      {el.filename.slice(0, 24)}
                    </div>
                    <span style={{ fontSize: '0.7rem', fontWeight: 700 }}>
                      <>
                        {el.filesize ? el.filesize : 'Unknown size.'}
                        {el?.createdBy?._id === userId && (
                          <Tooltip title='Delete'>
                            <DeleteForeverIcon
                              color='action'
                              fontSize='small'
                              className={classes.editIcons}
                              onClick={() =>
                                handleDeleteAttachment({
                                  projectId: projectId,
                                  milestoneId: content?.item?._id,
                                  attachmentId: el._id,
                                  embeddedField: 'attachments',
                                  component: 'sidebar',
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Typography
              component='div'
              variant='body2'
              className={classes.defaultMsg}
              color='textPrimary'>
              No Attachments.
            </Typography>
          )}
        </div>
        <div className={classes.cardContainer}>
          <div className={classes.photoHeader}>
            <Typography
              component='span'
              variant='h6'
              className={classes.contentHeader}
              color='textPrimary'>
              Notes
            </Typography>
            <label
              htmlFor='notes'
              style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant='outlined'
                color='default'
                size='small'
                component='span'
                aria-label='add note'
                onClick={toggleForm}
                className={classes.button}
                startIcon={<AddCommentIcon />}>
                {showForm ? 'Hide' : 'Add'}
              </Button>
            </label>
          </div>
          <div>
            {showForm ? (
              <div className={classes.modalFormContainer}>
                <DialogContent className={classes.content}>
                  <TextField
                    autoFocus={content?.noAutoFocus ? false : true}
                    multiline
                    minRows={4}
                    margin='dense'
                    id='name'
                    placeholder='Add a note'
                    type='text'
                    fullWidth
                    variant='outlined'
                    color='primary'
                    onChange={handleChange}
                    value={notes}
                  />
                </DialogContent>
                <DialogActions className={classes.buttonGroup}>
                  <Button
                    onClick={handleFormSubmit}
                    color='primary'
                    size='small'
                    className={classes.button}
                    variant='contained'>
                    Post
                  </Button>
                </DialogActions>
              </div>
            ) : null}
          </div>
          <Divider />
          {content?.item?.notes?.length > 0 ? (
            <List className={classes.notesContainer} disablePadding={true}>
              {content.item.notes.map((el) => (
                <React.Fragment key={`note_${el._id}`}>
                  <ListItem
                    alignItems='flex-start'
                    key={el._id}
                    className={classes.note}>
                    <ListItemAvatar className={classes.notesAvatar}>
                      <Avatar
                        variant='square'
                        alt={el.createdBy.firstName}
                        src={el.createdBy.photo}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <div className={classes.notesUser}>
                            {`${el?.createdBy?.firstName
                              ?.charAt(0)
                              .toUpperCase()}. ${el?.createdBy?.lastName}`}
                          </div>
                          <div className={classes.notesDate}>
                            {new Date(el.createdAt).toLocaleString(
                              'en-US',
                              dateOptionsNote
                            )}
                            {el?.createdBy?._id === userId && (
                              <Tooltip title='Delete'>
                                <DeleteForeverIcon
                                  color='action'
                                  fontSize='small'
                                  className={classes.deleteNoteIcon}
                                  onClick={() =>
                                    handleDeleteNote({
                                      projectId: projectId,
                                      milestoneId: content?.item?._id,
                                      noteId: el._id,
                                      embeddedField: 'notes',
                                    })
                                  }
                                />
                              </Tooltip>
                            )}
                          </div>
                          <div className={classes.notesText}>{el.text}</div>
                        </>
                      }
                    />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography
              component='div'
              variant='body2'
              className={classes.defaultMsg}
              color='textPrimary'>
              No Notes.
            </Typography>
          )}
        </div>
      </div>
    </Drawer>
  );
}

SidebarModal.propTypes = {
  sidebarNotes: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
  addNoteMilestoneStartDispatch: PropTypes.func.isRequired,
  deleteNoteMilestoneStartDispatch: PropTypes.func.isRequired,
  deleteAttachmentMilestoneStartDispatch: PropTypes.func.isRequired,
  addAttachmentMilestoneStartDispatch: PropTypes.func.isRequired,
  updateMilestoneStartDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  sidebarNotes: selectModalSidebar,
  userId: selectCurrentUserId,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
  updateMilestoneStartDispatch: (milestoneObj) =>
    dispatch(updateMilestoneStart(milestoneObj)),
  addNoteMilestoneStartDispatch: (updateObj) =>
    dispatch(addNoteMilestoneStart(updateObj)),
  deleteNoteMilestoneStartDispatch: (deleteObj) =>
    dispatch(deleteNoteMilestoneStart(deleteObj)),
  deleteAttachmentMilestoneStartDispatch: (deleteObj) =>
    dispatch(deleteAttachmentMilestoneStart(deleteObj)),
  addAttachmentMilestoneStartDispatch: (attachmentObj) =>
    dispatch(addAttachmentMilestoneStart(attachmentObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarModal);
