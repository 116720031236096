import React from 'react';

// Styles
import useStyles from './spinner-blank.styles.js';

const SpinnerBlank = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.spinnerOverlay}>
        Hold Tight, We Are Working on It!
      </div>
    </>
  );
};

export default SpinnerBlank;
