import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  issueboxPopup: {
    '& .MuiPaper-rounded': {
      minWidth: '212px',
      marginLeft: '9px',
      borderRadius: 0,
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  issueboxPopupItems: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  filterMenu: {
    padding: '18px',
  },
}));

export default useStyles;
