import React from 'react';

// Images
import spinner from './duckhunt.gif';

// Styles
import useStyles from './spinner.styles';

const Spinner = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.spinnerOverlay}>
        <img src={spinner} className={classes.spinner} alt='Loading...' />
        <div className={classes.text}>Fetching Data</div>
        <div className={classes.textSmall}>Hold tight while we get that!</div>
      </div>
    </>
  );
};

export default Spinner;
