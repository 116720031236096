import React from 'react';
import PropTypes from 'prop-types';

const TableColgroup = ({cols}) => {
  return (
    <colgroup>
    {cols.map((el,idx) => <col key={idx} width={`${el}%`} />)}
    </colgroup>
  )
}

TableColgroup.propTypes = {
  cols: PropTypes.array.isRequired,
}

export default TableColgroup
