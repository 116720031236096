import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useParams, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

// Material UI
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import InboxIcon from '@material-ui/icons/Inbox';
import ShareIcon from '@material-ui/icons/Share';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Snackbar from '@material-ui/core/Snackbar';

// Components
import MainContainer from '../../components/main-container/main-container.component';
import MapSingle from '../../components/map-single/map-single.component';
import NotesFullwidth from '../../components/notes-fullwidth/notes-fullwidth.component';
import EditSubmissionModal from '../../components/edit-submission-modal/edit-submission-modal.component';
import SharingTooltip from '../../components/sharing-tooltip/sharing-tooltip.component';

// Styles
import useStyles from './submission-single.styles';

// Actions
import {
  getSingleSubmissionStart,
  deleteSubmissionUpdateStart,
  updateSubmissionSeenStart,
  updateSubmissionStart,
  deleteSubmissionShareStart,
  createSubmissionShareStart,
} from '../../redux/submission/submission.actions';
import { toggleModal, setUpdateFilters } from '../../redux/ui/ui.actions';

// Selectors
import { selectSingleSubmission } from '../../redux/submission/submission.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectUpdateFilters } from '../../redux/ui/ui.selectors';

const dateOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

const SubmissionSingle = ({
  getSingleSubmissionStartDispatch,
  submission,
  toggleModalDispatch,
  user,
  deleteSubmissionUpdateStartDispatch,
  updateSubmissionSeenStartDispatch,
  updateSubmissionStartDispatch,
  deleteSubmissionShareStartDispatch,
  createSubmissionShareStartDispatch,
  setUpdateFiltersDispatch,
  filters,
}) => {
  const classes = useStyles();
  const { docId, projectId } = useParams();
  const [snackOpen, setSnackOpen] = useState(false);

  const dataToPhotos = (data) => {
    return data.map((el) => el.photos).flat();
  };

  const createdAt = submission?.createdAt
    ? new Date(submission.createdAt).toLocaleString('en-US', dateOptions)
    : '';

  useEffect(() => {
    getSingleSubmissionStartDispatch(projectId, docId);
  }, [getSingleSubmissionStartDispatch, projectId, docId]);

  const handleDeleteSubmissionUpdate = (idObj) => {
    deleteSubmissionUpdateStartDispatch({ ...idObj, projectId });
  };

  const handleDeleteSubmissionShare = (docId) => {
    deleteSubmissionShareStartDispatch({ docId, projectId });
  };

  const handleClickCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackOpen(true);
  };

  const handleCloseCopy = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  let photoCount = 0;
  const authed =
    user?.authorityLevel === 'admin' || user?.authorityLevel === 'superAdmin';

  const shareUrl =
    submission?.isShared && submission?.share?._id
      ? `${window.location.origin.toString()}/share/submission/${
          submission.share._id
        }`
      : '';

  const status = submission ? (
    submission.isDeleted ? (
      <Chip
        avatar={<DeleteIcon />}
        label={'Deleted'}
        size='small'
        className={classes.deleted}
      />
    ) : (
      <Chip
        avatar={<InboxIcon />}
        label={'Recieved'}
        size='small'
        className={classes.open}
      />
    )
  ) : (
    'Unknown'
  );

  const sharing = submission ? (
    submission.isShared ? (
      <>
        <SharingTooltip shareDoc={submission}>
          <Chip
            avatar={<PeopleAltIcon />}
            label={'Active'}
            size='small'
            className={classes.shared}
          />
        </SharingTooltip>
        <Tooltip title='Copy Share URL'>
          <FileCopyOutlinedIcon
            className={classes.shareCopy}
            fontSize='small'
            onClick={() => handleClickCopy(shareUrl)}
          />
        </Tooltip>
      </>
    ) : null
  ) : (
    'Unknown'
  );

  const severityChipGenerator = (issue) => {
    if (issue.currentCategorization === 4) {
      return (
        <div className={clsx(classes.pill, classes.offTrack)}>Off Track</div>
      );
    } else if (issue.currentCategorization === 3) {
      return <div className={clsx(classes.pill, classes.high)}>High</div>;
    } else if (issue.currentCategorization === 2) {
      return <div className={clsx(classes.pill, classes.medium)}>Medium</div>;
    } else if (issue.currentCategorization === 1) {
      return <div className={clsx(classes.pill, classes.low)}>Low</div>;
    } else if (issue.currentCategorization === 0) {
      return (
        <div className={clsx(classes.pill, classes.none)}>Uncategorized</div>
      );
    } else {
      return null;
    }
  };

  const lat = submission?.submittedFromLat ? submission?.submittedFromLat : 0;
  const lng = submission?.submittedFromLng ? submission?.submittedFromLng : 0;

  return (
    <>
      <MainContainer>
        {submission ? (
          <>
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.title}>
                <h1>{`${submission?.milestone?.name}: Field Submission`}</h1>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.header}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6} lg={4}>
                  <MapSingle
                    lat={lat}
                    lng={lng}
                    zoomLevel={14}
                    showLatLng={false}
                    height={'360px'}
                    width={'100%'}
                    padding={'0px 24px 12px 0px'}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                  <div className={classes.metaContainer}>
                    <div className={classes.metaRow}>
                      <div className={classes.metaRowHeader}>MILESTONE</div>
                      <div className={classes.metaRowContent}>
                        {submission?.milestone?.name}
                      </div>
                    </div>
                    <div className={classes.metaRow}>
                      <div className={classes.metaRowHeader}>SITE</div>
                      <div className={classes.metaRowContent}>
                        {submission?.site?.name}
                      </div>
                    </div>
                    <div className={classes.metaRow}>
                      <div className={classes.metaRowHeader}>REF ID</div>
                      <div className={classes.metaRowContent}>
                        {submission?.refId ? `S-${submission.refId}` : 'None'}
                      </div>
                    </div>
                    <div className={classes.metaRow}>
                      <div className={classes.metaRowHeader}>
                        SUBMISSION GPS
                      </div>
                      <div className={classes.metaRowContent}>
                        {`${lat}, ${lng}`}
                      </div>
                    </div>
                    <div className={classes.metaRow}>
                      <div className={classes.metaRowHeader}>SUBMITTED ON</div>
                      <div className={classes.metaRowContent}>{createdAt}</div>
                    </div>
                    <div className={classes.metaRow}>
                      <div className={classes.metaRowHeader}>SUBMITTED BY</div>
                      <div className={classes.metaRowContent}>
                        {`${submission?.createdBy?.firstName} ${submission?.createdBy?.lastName}`}
                      </div>
                    </div>
                    <div className={classes.metaRow}>
                      <div className={classes.metaRowHeader}>ISSUE COUNT</div>
                      <div className={classes.metaRowContent}>
                        {submission?.issues && submission?.issues.length
                          ? submission?.issues.length
                          : 0}
                      </div>
                    </div>
                    {submission?.isShared && (
                      <div className={classes.metaRow}>
                        <div className={classes.metaRowHeader}>SHARING</div>
                        <div className={classes.metaRowContent}>{sharing}</div>
                      </div>
                    )}
                    <div className={clsx(classes.metaRow, classes.buttonGroup)}>
                      <Button
                        variant='contained'
                        color='default'
                        className={clsx(classes.button, classes.editButton)}
                        startIcon={<EditIcon />}
                        onClick={() =>
                          toggleModalDispatch({
                            modalName: 'editSubmission',
                            modalContent: {
                              projectId: projectId,
                              submissionId: submission._id,
                              createdBy: submission.createdBy._id,
                              title: 'Edit Details for this Submission',
                              milestoneId: submission.milestone._id,
                              milestoneName: submission.milestone.name,
                              siteName: submission.site.name,
                              lat: lat,
                              lng: lng,
                              subDatetime: submission.createdAt,
                              currentUser: user,
                              dismissFunc: () => {},
                              doFetch: true,
                              urlFetch: `/api/v1/projects/${projectId}/milestones/${submission.milestone._id}/getAssociated?formId=${submission.form}`,
                            },
                          })
                        }>
                        Edit
                      </Button>
                      <Button
                        variant='contained'
                        color='default'
                        onClick={() =>
                          toggleModalDispatch({
                            modalName: 'exportOptions',
                            modalContent: {
                              data: submission ? [submission] : [{}],
                              type: 'submission',
                            },
                          })
                        }
                        className={clsx(classes.button, classes.exportButton)}
                        startIcon={<SaveIcon />}>
                        Export
                      </Button>
                      {submission?.isShared ? (
                        <Button
                          variant='contained'
                          color='primary'
                          className={classes.button}
                          startIcon={<ShareIcon />}
                          onClick={() =>
                            toggleModalDispatch({
                              modalName: 'shareDelete',
                              modalContent: {
                                shareUrl: shareUrl,
                                share: submission?.share,
                                dismissFunc: () =>
                                  handleDeleteSubmissionShare(submission._id),
                              },
                            })
                          }>
                          Stop Sharing
                        </Button>
                      ) : (
                        <Button
                          variant='contained'
                          color='primary'
                          className={classes.button}
                          startIcon={<ShareIcon />}
                          onClick={() =>
                            toggleModalDispatch({
                              modalName: 'shareCreate',
                              modalContent: {
                                docId: submission._id,
                                projectId: projectId,
                                createShare: createSubmissionShareStartDispatch,
                              },
                            })
                          }>
                          Share
                        </Button>
                      )}
                      {authed ? (
                        <Button
                          variant='contained'
                          color='secondary'
                          aria-label='resolve or reopen'
                          onClick={() =>
                            toggleModalDispatch({
                              modalName: 'submissionResolve',
                              modalContent: {
                                id: submission._id,
                                categorization: 0,
                                milestoneFlag: 0,
                                actionType: submission.isDeleted
                                  ? 'Restored'
                                  : 'Deleted',
                              },
                            })
                          }
                          className={classes.button}
                          startIcon={
                            submission.isDeleted ? (
                              <RestoreFromTrashIcon />
                            ) : (
                              <DeleteIcon />
                            )
                          }>
                          {submission.isDeleted ? 'Restore' : 'Delete'}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Divider variant='middle' className={classes.divider} />
            <h1 className={classes.textHeader}>Assessment Form</h1>
            {submission.data.map((el, idx) => (
              <Grid
                container
                spacing={0}
                className={classes.root}
                key={`grid-${idx}`}>
                <Grid
                  item
                  xs={12}
                  key={`answer-${idx}`}
                  className={clsx(
                    classes.answerContainer,
                    el.flagCode === 1 ? classes.issueBorder : null
                  )}>
                  <div
                    className={clsx(
                      el.flagCode === 1 ? classes.answerHeaderContainer : null
                    )}>
                    <h2
                      className={clsx(
                        classes.answerTitle,
                        el.flagCode === 1 ? classes.issueBg : null
                      )}>{`#${idx + 1}. ${el.question.name} - ${
                      el.question.questionText
                    }`}</h2>
                  </div>
                  {el.flagCode === 1 ? (
                    <div className={classes.issue}>
                      <Tooltip title='Click to open issue.'>
                        <RouterLink
                          className={classes.issueLink}
                          to={
                            el?.issueId?._id
                              ? `/projects/${projectId}/issues/${el.issueId._id}`
                              : `/projects/${projectId}/issues?submission=${docId}`
                          }>
                          Issue Identified
                        </RouterLink>
                      </Tooltip>
                      {el?.issueId ? severityChipGenerator(el?.issueId) : null}
                      {el?.issueId?.isResolved ? (
                        <Chip
                          avatar={<DoneIcon />}
                          label={'Resolved'}
                          size='small'
                          className={clsx(classes.chip, classes.isResolved)}
                        />
                      ) : (
                        <Chip
                          avatar={<AccessTimeIcon />}
                          label={'Open'}
                          size='small'
                          className={clsx(classes.chip, classes.isOpen)}
                        />
                      )}
                      {el?.issueId?.isDeleted ? (
                        <Chip
                          avatar={<DeleteIcon />}
                          label={'Deleted'}
                          size='small'
                          className={clsx(classes.chip, classes.isDeleted)}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  {el.answers[0] ? (
                    <h4 className={classes.answer}>{el.answers[0]}</h4>
                  ) : null}
                  {el.notes ? (
                    <h4 className={classes.notes}>{el.notes}</h4>
                  ) : null}
                  {el.photos.length > 0 ? (
                    <div className={classes.imageGrid}>
                      {el.photos.map((photo, idxPhoto) => {
                        const photoNum = photoCount;
                        photoCount++;
                        return (
                          <div
                            key={`photo-${idxPhoto}`}
                            className={classes.imageGridItem}>
                            <img
                              src={photo.url}
                              alt='submission'
                              className={classes.photo}
                              onClick={() =>
                                toggleModalDispatch({
                                  modalName: 'photoGallery',
                                  modalContent: {
                                    photos: dataToPhotos(submission.data),
                                    startPhoto: photoNum,
                                  },
                                })
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            ))}
            <Divider variant='middle' className={classes.divider} />
            <Grid item xs={12} className={classes.header}>
              <NotesFullwidth
                title='History & Comments'
                updates={submission.updates ? submission.updates : []}
                userId={user._id}
                docId={submission._id}
                toggleModalDispatch={toggleModalDispatch}
                handleDeleteUpdate={handleDeleteSubmissionUpdate}
                updateSeenStartDispatch={updateSubmissionSeenStartDispatch}
                updateStartDispatch={updateSubmissionStartDispatch}
                setUpdateFiltersDispatch={setUpdateFiltersDispatch}
                filters={filters}
              />
            </Grid>
          </>
        ) : (
          <span>Loading...</span>
        )}
      </MainContainer>
      <EditSubmissionModal />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        severity='success'
        onClose={handleCloseCopy}
        open={snackOpen}
        autoHideDuration={2000}
        message='Copied link to clipboard!'
      />
    </>
  );
};

SubmissionSingle.propTypes = {
  submission: PropTypes.object,
  getSingleSubmissionStartDispatch: PropTypes.func.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  deleteSubmissionUpdateStartDispatch: PropTypes.func.isRequired,
  updateSubmissionSeenStartDispatch: PropTypes.func.isRequired,
  updateSubmissionStartDispatch: PropTypes.func.isRequired,
  deleteSubmissionShareStartDispatch: PropTypes.func.isRequired,
  createSubmissionShareStartDispatch: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setUpdateFiltersDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  submission: selectSingleSubmission,
  user: selectCurrentUser,
  filters: selectUpdateFilters,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  getSingleSubmissionStartDispatch: (projectId, docId) =>
    dispatch(getSingleSubmissionStart({ projectId, docId })),
  deleteSubmissionUpdateStartDispatch: (idObj) =>
    dispatch(deleteSubmissionUpdateStart(idObj)),
  updateSubmissionSeenStartDispatch: (idObj) =>
    dispatch(updateSubmissionSeenStart(idObj)),
  updateSubmissionStartDispatch: (updateObj) =>
    dispatch(updateSubmissionStart(updateObj)),
  deleteSubmissionShareStartDispatch: (updateObj) =>
    dispatch(deleteSubmissionShareStart(updateObj)),
  createSubmissionShareStartDispatch: (updateObj) =>
    dispatch(createSubmissionShareStart(updateObj)),
  setUpdateFiltersDispatch: (filterObj) =>
    dispatch(setUpdateFilters(filterObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionSingle);
