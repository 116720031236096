import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';

// Components
import SubmissionCard from '../../components/submission-card/submission-card.component';

// Styles
import useStyles from './submission-card-container.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectActiveSiteSubmissions } from '../../redux/submission/submission.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';

const SubmissionCardContainer = ({
  submissions,
  toggleModalDispatch,
  user,
}) => {
  const classes = useStyles();

  // Check how many empty cards to generate in empty space
  const emptyCount =
    submissions.length === 0
      ? 3
      : submissions.length === 1 || submissions.length === 4
      ? 2
      : submissions.length === 2 || submissions.length === 5
      ? 1
      : 0;

  const emptyCards = Array.from({ length: emptyCount }, (e, idx) => {
    return (
      <Grid item xs={12} md={6} lg={4} key={`empty-${idx}`}>
        <div className={classes.empty}>
          No Additional
          <br />
          Submissions Found
        </div>
      </Grid>
    );
  });

  return (
    <Grid container spacing={3}>
      {submissions.map((sub, idx) => (
        <Grid item xs={12} md={6} lg={4} key={idx}>
          <SubmissionCard
            submission={sub}
            toggleModalDispatch={toggleModalDispatch}
            user={user}
          />
        </Grid>
      ))}
      {emptyCards}
    </Grid>
  );
};

SubmissionCardContainer.propTypes = {
  submissions: PropTypes.array.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  submissions: selectActiveSiteSubmissions,
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionCardContainer);
