import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Components
import CircularProgressLabeled from '../circular-progress-labeled/circular-progress-labeled.component';

// Material UI
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';

// Styles
import useStyles from './gantt-row-info.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';
import { removeMilestoneMods } from '../../redux/milestone/milestone.actions';
import { updateActiveSiteId } from '../../redux/site/site.actions';

// Selectors
import { selectGanttMods } from '../../redux/milestone/milestone.selectors';
import { selectCurrentUserId } from '../../redux/user/user.selectors';

const GanttRowInfo = ({
  el,
  activeSiteId,
  editMode,
  hideProgress,
  handleModIdsToggle,
  modIds,
  toggleModalDispatch,
  removeMilestoneModsDispatch,
  mods,
  currentUserId,
  updateActiveSiteIdDispatch,
}) => {
  const {
    _id,
    type,
    siteName,
    siteId,
    milestoneName,
    actualStatus,
    percentCompleted,
    project,
  } = el;

  const classes = useStyles({ actualStatus, activeSiteId });

  const handleToggleSite = (siteId, activeSiteId) => {
    if (activeSiteId === '-1') {
      updateActiveSiteIdDispatch(siteId);
    } else {
      updateActiveSiteIdDispatch('-1');
    }
  };

  const siteStatusClass =
    actualStatus === 'Completed'
      ? classes.siteStatusCompleted
      : actualStatus === 'Ongoing'
      ? classes.siteStatusOngoing
      : actualStatus === 'Stopped' || actualStatus === 'Off Track'
      ? classes.siteStatusOffTrack
      : classes.siteStatusNotStarted;

  const isModified = type === 'milestone' && Object.keys(mods).includes(_id);

  const nameElement = editMode ? (
    milestoneName
  ) : (
    <span
      className={classes.milestoneName}
      onClick={() =>
        toggleModalDispatch({
          modalName: 'sidebarNotes',
          modalContent: {
            id: _id,
            item: el,
            currentUserId: currentUserId,
            doFetch: true,
            urlFetch: `/api/v1/projects/${project}/milestones/${_id}/getAllMilestoneDetails`,
          },
        })
      }>
      {milestoneName}
    </span>
  );

  const milestoneElement = isModified ? (
    <Tooltip
      title={
        <>
          {mods[_id]?.tooltipStr}
          <br />
          <br />
          <em>Click to Undo Modification</em>
        </>
      }
      aria-label='add'
      arrow={true}>
      <span
        className={classes.milestoneModded}
        onClick={() =>
          toggleModalDispatch({
            modalName: 'confirmDialog',
            modalContent: {
              dismissFunc: () => removeMilestoneModsDispatch([_id]),
              title: 'Undo Changes?',
              desc: 'Do you wish to undo the modifications made to the milestone?',
            },
          })
        }>
        {nameElement}
      </span>
    </Tooltip>
  ) : (
    nameElement
  );
  return (
    <div
      className={clsx(
        activeSiteId === '-1' ? null : classes.titleSiteMode,
        type !== 'milestone' ? classes.title : classes.subtaskTitle
      )}>
      {type === 'site' ? (
        <>
          <span
            className={clsx(
              classes.progress,
              hideProgress ? classes.hide : null
            )}>
            <CircularProgressLabeled
              value={percentCompleted}
              type={actualStatus}
            />
          </span>
          <div className={classes.siteInfo}>
            <div
              className={clsx(
                classes.siteName,
                activeSiteId === '-1' ? null : classes.siteNameSiteMode
              )}
              onClick={() => handleToggleSite(siteId, activeSiteId)}>
              {siteName}
            </div>
            <div className={classes.siteStatus}>
              <span className={clsx(classes.siteStatusPill, siteStatusClass)}>
                {actualStatus.toUpperCase()}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div
          className={clsx(
            classes.subtask,
            isModified ? classes.subtaskModded : classes.subtaskUnmodded,
            editMode ? null : classes.subtaskNonEditMode
          )}>
          <Checkbox
            className={clsx(classes.checkbox, editMode ? null : classes.hide)}
            checked={modIds.includes(_id)}
            onChange={() => handleModIdsToggle(_id)}
            inputProps={{ 'aria-label': `${siteName} edit checkbox` }}
            size='small'
            color='primary'
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
          />
          {milestoneElement}
        </div>
      )}
    </div>
  );
};

GanttRowInfo.propTypes = {
  el: PropTypes.object,
  activeSiteId: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  hideProgress: PropTypes.bool.isRequired,
  handleModIdsToggle: PropTypes.func.isRequired,
  modIds: PropTypes.array.isRequired,
  mods: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  removeMilestoneModsDispatch: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  updateActiveSiteIdDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  mods: selectGanttMods,
  currentUserId: selectCurrentUserId,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  removeMilestoneModsDispatch: (idArr) => dispatch(removeMilestoneMods(idArr)),
  updateActiveSiteIdDispatch: (siteId) => dispatch(updateActiveSiteId(siteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GanttRowInfo);
