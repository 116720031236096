import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useParams, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

// Material UI
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ShareIcon from '@material-ui/icons/Share';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

// Components
import MainContainer from '../../components/main-container/main-container.component';
import MapSingle from '../../components/map-single/map-single.component';
import SeverityTag from '../../components/severity-tag/severity-tag.component';
import NotesFullwidth from '../../components/notes-fullwidth/notes-fullwidth.component';
import SharingTooltip from '../../components/sharing-tooltip/sharing-tooltip.component';
import IssueDetails from '../../components/issue-details/issue-details.component';
import HistoryContainer from '../../components/history-container/history-container.component';
import FeedContainer from '../../components/feed-container/feed-container.component';

// Hooks
import useJumpTo from '../../hooks/useJumpTo';

// Styles
import useStyles from './issue-single.styles';

// Actions
import {
  getSingleIssueStart,
  deleteIssueUpdateStart,
  updateIssueSeenStart,
  updateIssueStart,
  deleteIssueShareStart,
  createIssueShareStart,
} from '../../redux/issue/issue.actions';
import { toggleModal, setUpdateFilters } from '../../redux/ui/ui.actions';
import { startAlert } from '../../redux/alert/alert.actions';

// Selectors
import {
  selectSingleIssue,
  selectIssueFetchStatus,
} from '../../redux/issue/issue.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectUpdateFilters } from '../../redux/ui/ui.selectors';
import { selectCurrentProjectBuckets } from '../../redux/project/project.selectors';

// Utility Functions
import {
  severityNumToString,
  sortUpdatesByBuckets,
  sortDate,
} from '../../utils/utilityFns';

const dateOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

const IssueSingle = ({
  getSingleIssueStartDispatch,
  deleteIssueUpdateStartDispatch,
  updateIssueSeenStartDispatch,
  updateIssueStartDispatch,
  deleteIssueShareStartDispatch,
  createIssueShareStartDispatch,
  startAlertDispatch,
  issue,
  toggleModalDispatch,
  user,
  setUpdateFiltersDispatch,
  filters,
  fetchStatus,
  buckets,
}) => {
  const classes = useStyles();
  const { docId, projectId } = useParams();

  const [jumpToId, jumpToRef, setJumpToReady] = useJumpTo();

  useEffect(() => {
    getSingleIssueStartDispatch(projectId, docId);
  }, [getSingleIssueStartDispatch, projectId, docId]);

  const handleDeleteIssueUpdate = (idObj) => {
    deleteIssueUpdateStartDispatch({ ...idObj, projectId });
  };

  const handleDeleteIssueShare = (docId) => {
    deleteIssueShareStartDispatch({ docId, projectId });
  };

  const handleClickCopy = (text) => {
    navigator.clipboard.writeText(text);
    startAlertDispatch({
      msg: 'Copied sharing link to clipboard!',
      alertType: 'success',
    });
  };

  const handleResolveReopen = (issue) => {
    toggleModalDispatch({
      modalName: 'issueResolve',
      modalContent: {
        id: issue._id,
        categorization: issue.currentCategorization,
        milestoneFlag: 0,
        actionType: issue.isResolved ? 'Reopened' : 'Resolved',
      },
    });
  };

  const authed =
    user?.authorityLevel === 'admin' || user?.authorityLevel === 'superAdmin';

  const createdAt = new Date(issue?.createdAt).toLocaleString(
    'en-US',
    dateOptions
  );

  const shareUrl =
    issue?.isShared && issue?.share?._id
      ? `${window.location.origin.toString()}/share/issue/${issue.share._id}`
      : '';

  const status = issue ? (
    issue.isDeleted ? (
      <Chip
        avatar={<DeleteIcon />}
        label={'Deleted'}
        size='small'
        className={classes.deleted}
      />
    ) : issue.isResolved ? (
      <>
        <Tooltip
          title='Click to reopen issue'
          arrow
          placement='right'
          classes={{
            tooltip: classes.tooltipOverride,
          }}>
          <Chip
            avatar={<DoneIcon />}
            label={'Resolved'}
            size='small'
            className={classes.resolved}
            onClick={() => handleResolveReopen(issue)}
          />
        </Tooltip>
      </>
    ) : (
      <>
        <Tooltip
          title='Click to resolve issue'
          arrow
          placement='right'
          classes={{
            tooltip: classes.tooltipOverride,
          }}>
          <Chip
            avatar={<AccessTimeIcon />}
            label={'Open'}
            size='small'
            className={classes.open}
            onClick={() => handleResolveReopen(issue)}
          />
        </Tooltip>
      </>
    )
  ) : (
    'Unknown'
  );

  const sharing = issue ? (
    issue.isShared ? (
      <>
        <SharingTooltip shareDoc={issue}>
          <Chip
            avatar={<PeopleAltIcon />}
            label={'Active'}
            size='small'
            className={classes.shared}
          />
        </SharingTooltip>
        <Tooltip title='Copy Share URL'>
          <FileCopyOutlinedIcon
            className={classes.shareCopy}
            fontSize='small'
            onClick={() => handleClickCopy(shareUrl)}
          />
        </Tooltip>
      </>
    ) : null
  ) : (
    'Unknown'
  );

  const lat = issue?.submittedFromLat ? issue?.submittedFromLat : 0;
  const lng = issue?.submittedFromLng ? issue?.submittedFromLng : 0;

  const selectUpdates = issue?.updates
    ? issue.updates.filter(
        (el) =>
          el.actionType === 'Updated' || el.actionType === 'Share Link Comment'
      )
    : [];

  const sortedUpdates = sortUpdatesByBuckets(selectUpdates, buckets);

  const issueHistoryActions = [
    'Field Submission',
    'Recategorized',
    'Dismissed',
    'Resolved',
    'Reopened',
    'Deleted',
    'Restored',
  ];

  const sortedHistory = issue?.updates
    ? issue.updates
        .filter((el) => issueHistoryActions.includes(el.actionType))
        .sort(sortDate('createdAt', 'asc'))
    : [];

  return (
    <MainContainer>
      {issue && fetchStatus === 'success' ? (
        <>
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.title}>
              <h1>{issue?.question?.errorText}</h1>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.section}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} lg={4}>
                <MapSingle
                  lat={lat}
                  lng={lng}
                  zoomLevel={15}
                  showLatLng={false}
                  height={'360px'}
                  width={'100%'}
                  padding={'0px 24px 12px 0px'}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <div className={classes.metaContainer}>
                  <div className={classes.metaRow}>
                    <div className={classes.metaRowHeader}>MILESTONE</div>
                    <div className={classes.metaRowContent}>
                      {issue?.milestone?.name}
                    </div>
                  </div>
                  <div className={classes.metaRow}>
                    <div className={classes.metaRowHeader}>SITE</div>
                    <div className={classes.metaRowContent}>
                      {issue?.site?.name}
                    </div>
                  </div>
                  <div className={classes.metaRow}>
                    <div className={classes.metaRowHeader}>REF ID</div>
                    <div className={classes.metaRowContent}>
                      {issue?.refId ? `I-${issue.refId}` : 'None'}
                    </div>
                  </div>
                  <div className={classes.metaRow}>
                    <div className={classes.metaRowHeader}>SUBMISSION GPS</div>
                    <div className={classes.metaRowContent}>
                      {`${lat}, ${lng}`}
                    </div>
                  </div>
                  <div className={classes.metaRow}>
                    <div className={classes.metaRowHeader}>SUBMITTED ON</div>
                    <div className={classes.metaRowContent}>
                      <RouterLink
                        className={classes.submissionLink}
                        to={`/projects/${projectId}/submissions/${issue.submission}`}>
                        {createdAt}
                      </RouterLink>
                    </div>
                  </div>
                  <div className={classes.metaRow}>
                    <div className={classes.metaRowHeader}>SUBMITTED BY</div>
                    <div className={classes.metaRowContent}>
                      {`${issue?.createdBy?.firstName} ${issue?.createdBy?.lastName}`}
                    </div>
                  </div>
                  <div className={classes.metaRow}>
                    <div className={classes.metaRowHeader}>SEVERITY</div>
                    <div className={classes.metaRowContent}>
                      <Tooltip
                        title='Click to recategorize'
                        arrow
                        placement='right'
                        classes={{
                          tooltip: classes.tooltipOverride,
                        }}>
                        <span>
                          <SeverityTag
                            className={classes.severityOverrides}
                            docId={issue._id}
                            severityLevel={issue.currentCategorization}
                            site={issue.site.name}
                            block={true}
                            large={false}
                            midsize={true}
                            pill={true}>
                            {severityNumToString(issue.currentCategorization)}
                          </SeverityTag>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div className={classes.metaRow}>
                    <div className={classes.metaRowHeader}>STATUS</div>
                    <div className={classes.metaRowContent}>{status}</div>
                  </div>
                  {issue?.isShared && (
                    <div className={classes.metaRow}>
                      <div className={classes.metaRowHeader}>SHARING</div>
                      <div className={classes.metaRowContent}>{sharing}</div>
                    </div>
                  )}
                  <div className={clsx(classes.metaRow, classes.buttonGroup)}>
                    <Button
                      variant='contained'
                      color='default'
                      onClick={() =>
                        toggleModalDispatch({
                          modalName: 'exportOptions',
                          modalContent: {
                            data: issue ? [issue] : [{}],
                            type: 'issue',
                          },
                        })
                      }
                      className={clsx(classes.button, classes.exportButton)}
                      startIcon={<SaveIcon />}>
                      Export
                    </Button>
                    {issue?.isShared ? (
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        startIcon={<ShareIcon />}
                        onClick={() =>
                          toggleModalDispatch({
                            modalName: 'shareDelete',
                            modalContent: {
                              shareUrl: shareUrl,
                              share: issue?.share,
                              dismissFunc: () =>
                                handleDeleteIssueShare(issue._id),
                            },
                          })
                        }>
                        Stop Sharing
                      </Button>
                    ) : (
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        startIcon={<ShareIcon />}
                        onClick={() =>
                          toggleModalDispatch({
                            modalName: 'shareCreate',
                            modalContent: {
                              docId: issue._id,
                              projectId: projectId,
                              createShare: createIssueShareStartDispatch,
                            },
                          })
                        }>
                        Share
                      </Button>
                    )}
                    {authed ? (
                      <Button
                        variant='contained'
                        color='secondary'
                        aria-label='delete or restore'
                        onClick={() =>
                          toggleModalDispatch({
                            modalName: 'issueResolve',
                            modalContent: {
                              id: issue._id,
                              categorization: issue.currentCategorization,
                              milestoneFlag: 0,
                              actionType: issue.isDeleted
                                ? 'Restored'
                                : 'Deleted',
                            },
                          })
                        }
                        className={classes.button}
                        startIcon={
                          issue.isDeleted ? (
                            <RestoreFromTrashIcon />
                          ) : (
                            <DeleteIcon />
                          )
                        }>
                        {issue.isDeleted ? 'Restore' : 'Delete'}
                      </Button>
                    ) : null}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Divider variant='middle' className={classes.divider} />
          <Grid item xs={12} className={classes.section}>
            <IssueDetails
              title={issue?.question?.errorText}
              question={issue?.question}
              initialUpdate={issue?.updates[0]}
              submission={issue?.submission}
              toggleModalDispatch={toggleModalDispatch}
              currentCategorization={issue?.currentCategorization}
              isDeleted={issue?.isDeleted}
              isResolved={issue?.isResolved}
            />
          </Grid>
          {/* <Divider variant='middle' className={classes.divider} />
          <Grid item xs={12} className={classes.section}>
            <HistoryContainer sortedHistory={sortedHistory} user={user} />
          </Grid>
          <Divider variant='middle' className={classes.divider} />
          <Grid item xs={12} className={classes.section}>
            <FeedContainer
              ref={jumpToRef}
              sortedUpdates={sortedUpdates}
              jumpToId={jumpToId}
              setJumpToReady={setJumpToReady}
            />
          </Grid> */}
          <Divider variant='middle' className={classes.divider} />
          <Grid item xs={12} className={classes.section}>
            <NotesFullwidth
              ref={jumpToRef}
              title='History & Comments'
              updates={
                issue.updates
                  ? issue.updates.filter(
                      (el) =>
                        el.actionType === 'Updated' ||
                        el.actionType === 'Share Link Comment'
                    )
                  : []
              }
              userId={user._id}
              docId={issue._id}
              toggleModalDispatch={toggleModalDispatch}
              handleDeleteUpdate={handleDeleteIssueUpdate}
              updateSeenStartDispatch={updateIssueSeenStartDispatch}
              updateStartDispatch={updateIssueStartDispatch}
              setUpdateFiltersDispatch={setUpdateFiltersDispatch}
              filters={filters}
              jumpToId={jumpToId}
              setJumpToReady={setJumpToReady}
            />
          </Grid>
        </>
      ) : fetchStatus === 'fetching' ? (
        <span></span>
      ) : fetchStatus === 'error' ? (
        <h1>No Issue Found</h1>
      ) : (
        <span>An error has occured. Please refresh this page.</span>
      )}
    </MainContainer>
  );
};

IssueSingle.propTypes = {
  user: PropTypes.object,
  issue: PropTypes.object,
  toggleModalDispatch: PropTypes.func.isRequired,
  getSingleIssueStartDispatch: PropTypes.func.isRequired,
  deleteIssueUpdateStartDispatch: PropTypes.func.isRequired,
  updateIssueSeenStartDispatch: PropTypes.func.isRequired,
  updateIssueStartDispatch: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setUpdateFiltersDispatch: PropTypes.func.isRequired,
  deleteIssueShareStartDispatch: PropTypes.func.isRequired,
  createIssueShareStartDispatch: PropTypes.func.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
  fetchStatus: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  issue: selectSingleIssue,
  filters: selectUpdateFilters,
  fetchStatus: selectIssueFetchStatus,
  buckets: selectCurrentProjectBuckets,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  getSingleIssueStartDispatch: (projectId, docId) =>
    dispatch(getSingleIssueStart({ projectId, docId })),
  deleteIssueUpdateStartDispatch: (idObj) =>
    dispatch(deleteIssueUpdateStart(idObj)),
  updateIssueSeenStartDispatch: (idObj) =>
    dispatch(updateIssueSeenStart(idObj)),
  updateIssueStartDispatch: (updateObj) =>
    dispatch(updateIssueStart(updateObj)),
  setUpdateFiltersDispatch: (filterObj) =>
    dispatch(setUpdateFilters(filterObj)),
  deleteIssueShareStartDispatch: (updateObj) =>
    dispatch(deleteIssueShareStart(updateObj)),
  createIssueShareStartDispatch: (updateObj) =>
    dispatch(createIssueShareStart(updateObj)),
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueSingle);
