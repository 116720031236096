import React, { useEffect, forwardRef } from 'react';

const FeedJump = forwardRef(({ setJumpToReady }, ref) => {
  useEffect(() => {
    console.log('Enabling for Jump Reference');
    if (setJumpToReady) {
      setJumpToReady(true);
    }
  }, [setJumpToReady]);

  return <span ref={ref} />;
});

export default FeedJump;
