import React from 'react';
import PropTypes from 'prop-types';

// Styles
import useStyles from './update-mentions.styles';

const UpdateMentions = ({ refId, mentions, showMentions }) => {
  const classes = useStyles();

  if (mentions.length === 0 || showMentions === false) return null;

  return (
    <span>
      <span className={classes.mentionText}>{` and mentioned `}</span>
      {mentions.map((mention, idx) => (
        <React.Fragment key={`${refId}-mention-${idx}`}>
          <span
            className={
              classes.userText
            }>{`${mention.firstName} ${mention.lastName}`}</span>
          {idx + 1 !== mentions.length ? <span> and </span> : null}
        </React.Fragment>
      ))}
    </span>
  );
};

UpdateMentions.propTypes = {
  refId: PropTypes.string.isRequired,
  mentions: PropTypes.array.isRequired,
  showMentions: PropTypes.bool.isRequired,
};

export default UpdateMentions;
