import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Custom Hooks
import useQuery from '../../hooks/useQuery';

// Material UI
import Grid from '@material-ui/core/Grid';

// Components
import MainContainer from '../../components/main-container/main-container.component';
import HeaderSearch from '../../components/header-search/header-search.component';
import SubmissionTable from '../../components/submission-table/submission-table.component';
import ArchiveHeader from '../../components/archive-header/archive-header.component';

// Actions
import { getFilteredSubmissionsStart } from '../../redux/submission/submission.actions';
import { getProjectByIdStart } from '../../redux/project/project.actions';

// Selectors
import {
  selectNumSubmissions,
  selectNumSubmissionsDeleted,
} from '../../redux/submission/submission.selectors';

// Utilities
import { parseQueryParams } from '../../utils/apiFeatures';
import { DEFAULT_SUBMISSION_QUERY as defaultSubmissionQuery } from '../../utils/constants';

const Submissions = ({
  getFilteredSubmissionsStartDispatch,
  numSubmissions,
  numSubmissionsDeleted,
  getProjectByIdStartDispatch,
}) => {
  const { projectId } = useParams();

  const query = useQuery(); //FIXME: allow range and fields query params

  const defaultQueryObj = {
    ...defaultSubmissionQuery,
    limitObj: parseQueryParams(query),
  };
  const [queryObj, setQueryObj] = useState(defaultQueryObj);

  useEffect(() => {
    const fetchObj = {
      id: uuidv4(),
      name: 'submission archive',
      actionType: 'projects',
      projectId: projectId,
    };
    getProjectByIdStartDispatch(fetchObj);
    getFilteredSubmissionsStartDispatch(projectId, queryObj);
  }, [
    getFilteredSubmissionsStartDispatch,
    projectId,
    queryObj,
    getProjectByIdStartDispatch,
  ]);

  const resetQueryObj = (type) => {
    switch (type) {
      case 'search':
        setQueryObj((prev) => ({
          ...prev,
          searchStr: '',
        }));
        break;
      case 'filters':
        setQueryObj((prev) => ({
          ...prev,
          rangeArr: [],
          limitObj: {},
        }));
        break;
      default:
        setQueryObj(defaultQueryObj);
    }
  };

  return (
    <MainContainer>
      <HeaderSearch
        title='Search Submission Archive'
        queryObj={queryObj}
        setQueryObj={setQueryObj}
      />
      <Grid container spacing={0}>
        <ArchiveHeader
          title='Submission Archive'
          numItems={numSubmissions - numSubmissionsDeleted}
          queryObj={queryObj}
          setQueryObj={setQueryObj}
          resetQueryObj={resetQueryObj}
        />
        <SubmissionTable />
      </Grid>
    </MainContainer>
  );
};

Submissions.propTypes = {
  getFilteredSubmissionsStartDispatch: PropTypes.func.isRequired,
  numSubmissions: PropTypes.number.isRequired,
  numSubmissionsDeleted: PropTypes.number.isRequired,
  getProjectByIdStartDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  numSubmissions: selectNumSubmissions,
  numSubmissionsDeleted: selectNumSubmissionsDeleted,
});

const mapDispatchToProps = (dispatch) => ({
  getFilteredSubmissionsStartDispatch: (projectId, queryObj) =>
    dispatch(getFilteredSubmissionsStart({ projectId, queryObj })),
  getProjectByIdStartDispatch: (fetchObj) =>
    dispatch(getProjectByIdStart(fetchObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Submissions);
