import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useMap } from 'react-leaflet';

// Selectors
import { selectSiteBounds } from '../../redux/site/site.selectors';

const MapBounds = ({ bounds }) => {
  const map = useMap();
  console.log('Adjusted Map Bounds', bounds);

  useEffect(() => {
    map.fitBounds(bounds);
  }, [map, bounds]);

  return <></>;
};

MapBounds.propTypes = {
  bounds: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  bounds: selectSiteBounds,
});

export default connect(mapStateToProps)(MapBounds);
