import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Material UI
import Toolbar from '@material-ui/core/Toolbar';

// Styles
import useStyles from './header-general.styles';

const HeaderGeneral = ({ title, extraSpacing, children }) => {
  const classes = useStyles();
  return (
    <Toolbar
      className={clsx(
        classes.toolbar,
        extraSpacing ? classes.extraSpacing : null
      )}
      disableGutters>
      <div className={classes.toolbarStart}>
        <h1 className={classes.toolbarTitle}>{title}</h1>
      </div>
      <div className={classes.toolbarEnd}>{children}</div>
    </Toolbar>
  );
};

HeaderGeneral.propTypes = {
  title: PropTypes.string,
  extraSpacing: PropTypes.bool,
  children: PropTypes.node,
};

export default HeaderGeneral;
