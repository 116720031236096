import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Components
import DrawerItems from '../drawer-items/drawer-items.component';
import DrawerFooter from '../drawer-footer/drawer-footer.component';

// Material UI
import Drawer from '@material-ui/core/Drawer';

// Styles
import useStyles from './drawer-container.styles';

// Actions
import { toggleDrawer } from '../../redux/ui/ui.actions';
import { signOutStart } from '../../redux/user/user.actions';

// Selectors
import { selectDrawerPosition } from '../../redux/ui/ui.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';

function DrawerContainer({
  drawerOpen,
  toggleDrawerDispatch,
  signOutStartDispatch,
  user,
}) {
  const classes = useStyles({ drawerOpen });
  const { projectId } = useParams();
  const location = useLocation();
  const activePage = location.pathname.substring(35);
  return (
    <Drawer
      variant='persistent'
      anchor='left'
      onClose={toggleDrawerDispatch}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      open={drawerOpen}>
      <span>
        <div className={classes.drawerHeader} />
        <DrawerItems
          projectId={projectId}
          drawerOpen={drawerOpen}
          activePage={activePage}
        />
      </span>
      <DrawerFooter
        drawerOpen={drawerOpen}
        user={user}
        projectId={projectId}
        signOut={signOutStartDispatch}
      />
    </Drawer>
  );
}

DrawerContainer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  toggleDrawerDispatch: PropTypes.func.isRequired,
  signOutStartDispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  drawerOpen: selectDrawerPosition,
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDrawerDispatch: () => dispatch(toggleDrawer()),
  signOutStartDispatch: () => dispatch(signOutStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContainer);
