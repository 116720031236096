import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Avatar from '@material-ui/core/Avatar';

// Styles
import useStyles from './update-thumbnails.styles';

const UpdateThumbnails = ({ update, toggleModalDispatch }) => {
  const classes = useStyles();

  const photos =
    update?.content?.photos && update?.content?.photos.length > 0
      ? update.content.photos
      : false;

  if (!photos) return null;

  return (
    <div className={classes.root}>
      {photos.map((photo, idx) => (
        <Avatar
          className={classes.thumb}
          variant='square'
          key={`photo-${idx}`}
          alt='update photo'
          src={photo.url}
          onClick={() =>
            toggleModalDispatch({
              modalName: 'photoGallery',
              modalContent: {
                photos: photos,
                startPhoto: idx,
              },
            })
          }
        />
      ))}
    </div>
  );
};

UpdateThumbnails.propTypes = {
  update: PropTypes.object.isRequired,
};

export default UpdateThumbnails;
