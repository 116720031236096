import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '4px',
    padding: 0,
    width: '40px',
    height: '40px',
    justifySelf: 'center',
    alignSelf: 'center',
  },
  disabled: {
    cursor: 'not-allowed',
  },
  buttonGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    },
  },
  shareActive: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: theme.palette.success.main,
    },
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.success.main,
      boxShadow: `0 0 2px 2px #aef4af`,
      animation: '$glow 0.9s infinite alternate',
    },
  },
  '@keyframes glow': {
    from: {
      boxShadow: '0 0 2px 2px #aef4af',
    },
    to: {
      boxShadow: '0 0 0px 0px #aef4af',
    },
  },
}));

export default useStyles;
