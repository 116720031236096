import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Components
import UpdateDatetime from '../update-datetime/update-datetime.component';
import UpdateIcon from '../update-icon/update-icon.component';
import UpdateAction from '../update-action/update-action.component';
import UpdateNotes from '../update-notes/update-notes.component';
import UpdateAttachments from '../update-attachments/update-attachments.component';
import UpdateThumbnails from '../update-thumbnails/update-thumbnails.component';
import UpdateReactions from '../update-reactions/update-reactions.component';
import UpdateButtons from '../update-buttons/update-buttons.component';

// Styles
import useStyles from './update-item.styles';

const UpdateItem = ({
  docId,
  singleView = false,
  isReply = false,
  renderReplies = true,
  showMentions = false,
  datetimeType = 'fullDatetime',
  type,
  currentUserId,
  update,
  site,
  refId = '',
  milestone,
  toggleModalDispatch,
  handleDeleteUpdate,
  pathToUpdate = '',
}) => {
  const classes = useStyles();

  // use to delete replies, or toggle reactions
  pathToUpdate = isReply
    ? pathToUpdate.concat(`/reply/${update._id}`)
    : pathToUpdate.concat(`${type}/${docId}/updates/${update._id}`);
  console.log(pathToUpdate);

  // This needs to be somewhere else (selector?)
  const replies =
    update?.replies && Object.keys(update.replies).length > 0
      ? update.replies
      : [];

  const mentions =
    update?.mentions && Object.keys(update.mentions).length > 0
      ? update.mentions
      : [];

  const reactions = {
    love:
      update?.reactions && update.reactions?.love
        ? update?.reactions && update.reactions?.love
        : [],
    clap:
      update?.reactions && update.reactions?.clap
        ? update?.reactions && update.reactions?.clap
        : [],
    fire:
      update?.reactions && update.reactions?.fire
        ? update?.reactions && update.reactions?.fire
        : [],
    thumbsUp:
      update?.reactions && update.reactions?.thumbsUp
        ? update?.reactions && update.reactions?.thumbsUp
        : [],
    thumbsDown:
      update?.reactions && update.reactions?.thumbsDown
        ? update?.reactions && update.reactions?.thumbsDown
        : [],
    check:
      update?.reactions && update.reactions?.check
        ? update?.reactions && update.reactions?.check
        : [],
  };

  const categorization = update?.categorization ? update.categorization : 0;

  return (
    <div className={clsx(classes.update, isReply ? classes.updateReply : null)}>
      <div
        className={clsx(
          classes.iconContainer,
          isReply ? classes.replyIconContainer : null
        )}>
        <span className={clsx(classes.icon, isReply ? classes.hidden : null)}>
          <UpdateIcon update={update} />
        </span>
      </div>
      <div
        className={clsx(
          classes.contentContainer,
          isReply ? classes.contentContainerReply : null
        )}>
        <div className={classes.metaContainer}>
          <UpdateAction
            singleView={singleView}
            actionType={update.actionType}
            type={type}
            showMentions={showMentions}
            site={site}
            milestone={milestone}
            mentions={mentions}
            refId={refId}
            createdBy={update.createdBy}
            currentUserId={currentUserId}
            categorization={categorization}
          />
          <UpdateDatetime
            createdAt={update.createdAt}
            datetimeType={datetimeType}
          />
          <div className={classes.buttonContainer}>
            <UpdateButtons
              docId={docId}
              actionType={update.actionType}
              toggleModalDispatch={toggleModalDispatch}
              handleDeleteUpdate={handleDeleteUpdate}
              currentUserId={currentUserId}
              reactions={reactions}
              update={update}
            />
          </div>
        </div>
        <UpdateNotes update={update} mentions={mentions} />
        <UpdateAttachments update={update} />
        <UpdateThumbnails
          update={update}
          toggleModalDispatch={toggleModalDispatch}
        />
        <UpdateReactions
          reactions={reactions}
          toggleModalDispatch={toggleModalDispatch}
          currentUserId={currentUserId}
        />
        {renderReplies
          ? replies.map((update, idx) => (
              <UpdateItem
                docId={docId}
                key={`${idx}-${update._id}`}
                datetimeType={datetimeType}
                type={type}
                refId={refId}
                singleView={singleView}
                renderReplies={renderReplies}
                isReply={true}
                showMentions={showMentions}
                currentUserId={currentUserId}
                site={site}
                milestone={milestone}
                update={update}
                toggleModalDispatch={toggleModalDispatch}
                handleDeleteUpdate={handleDeleteUpdate}
                pathToUpdate={pathToUpdate}
              />
            ))
          : null}
      </div>
    </div>
  );
};

UpdateItem.propTypes = {
  docId: PropTypes.string.isRequired,
  singleView: PropTypes.bool.isRequired,
  isReply: PropTypes.bool,
  renderReplies: PropTypes.bool,
  showMentions: PropTypes.bool,
  datetimeType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  update: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
  milestone: PropTypes.object.isRequired,
  refId: PropTypes.string.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  handleDeleteUpdate: PropTypes.func.isRequired,
  pathToUpdate: PropTypes.string,
};

export default UpdateItem;
