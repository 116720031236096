import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '500px',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.custom.lightestgrey,
  },
  closeHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 24px 0px 24px',
    marginBottom: '-12px',
  },
  titleText: {
    margin: 0,
  },
  closeIcon: {
    marginRight: '8px',
  },
  cardContainer: {
    paddingTop: '8px',
    width: '100%',
    backgroundColor: theme.custom.lightestgrey,
  },
  numHidden: {
    paddingTop: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.custom.softGrey,
  },
  modalFormContainer: {
    width: '100%',
    backgroundColor: theme.custom.lightestgrey,
  },
}));

export default useStyles;
