import { createSelector } from 'reselect';

// Utilities
import { sortAlpha } from '../../utils/utilityFns';

const userSlice = (state) => state.user;

export const selectCurrentUser = createSelector(
  [userSlice],
  (user) => user.currentUser
);

export const selectUserAuthenticationStatus = createSelector(
  [userSlice],
  (user) => user.isAuthenticated
);

export const selectUserLoadingStatus = createSelector(
  [userSlice],
  (user) => user.loading
);

export const selectCurrentUserProjects = createSelector(
  [selectCurrentUser],
  (user) => user.projects
);

export const selectCurrentUserProjectList = createSelector(
  [selectCurrentUser],
  (user) => (user?.projectList ? user.projectList : [])
);

export const selectCurrentUserProjectsOnProjectList = createSelector(
  [selectCurrentUserProjectList, selectCurrentUserProjects],
  (projectList, projects) =>
    projects
      .filter((el) => projectList.includes(el._id))
      .sort(sortAlpha('shortName', 'asc'))
);

export const selectCurrentUserId = createSelector(
  [selectCurrentUser],
  (user) => user._id
);

export const selectLastLoginDateMs = createSelector(
  [userSlice],
  (user) => user.lastLoginDate
);
