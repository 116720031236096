import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: theme.palette.background.paper,
  },
  galleryWrapper: {
    maxWidth: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  close: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      color: theme.palette.grey[300],
      margin: '0 24px',
    },
  },
  closeText: {
    letterSpacing: '0.15em',
    fontSize: '0.74rem',
  },
  buttonWrapper: {
    padding: '0px 12px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1em',
    fontSize: '0.74rem',
    textTransform: 'uppercase',
    border: '1px solid #ffffff0a',
    borderRadius: '20px',
    backgroundColor: '#ffffff0d',
    '& button': {
      color: theme.palette.grey[300],
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#ffffff0a',
    },
  },
  buttonText: {
    cursor: 'pointer',
  },
  photoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  photo: {
    maxHeight: '60vh',
    maxWidth: '90vw',
  },
  caption: {
    display: 'flex',
    marginTop: '26px',
    color: theme.palette.grey[300],
  },
  hide: {
    display: 'none',
  },
  galleryControls: {
    display: 'flex',
    position: 'absolute',
    bottom: '60px',
  },
  spacer: {
    width: '100px',
  },
  toolbar: {
    display: 'flex',
    position: 'absolute',
    top: '60px',
    '& a, button': {
      color: theme.palette.grey[300],
      margin: '0 14px',
    },
  },
  issue: {
    marginLeft: '4px',
    fontSize: '0.7rem',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.light,
    color: '#333',
    borderRadius: '16px',
    padding: '0px 10px',
    fontWeight: '400',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  link: {
    color: '#FFF',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
