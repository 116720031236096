import {
  GET_FILTERED_SUBMISSIONS_START,
  GET_FILTERED_SUBMISSIONS_SUCCESS,
  GET_FILTERED_SUBMISSIONS_FAILURE,
  GET_SINGLE_SUBMISSION_START,
  GET_SINGLE_SUBMISSION_SUCCESS,
  GET_SINGLE_SUBMISSION_FAILURE,
  GET_SITE_SUBMISSIONS_SUCCESS,
  GET_SITE_SUBMISSIONS_FAILURE,
  UPDATE_SUBMISSION_START,
  UPDATE_SUBMISSION_SUCCESS,
  UPDATE_SUBMISSION_FAILURE,
  DELETE_SUBMISSION_UPDATE_START,
  DELETE_SUBMISSION_UPDATE_SUCCESS,
  DELETE_SUBMISSION_UPDATE_FAILURE,
  UPDATE_SUBMISSION_SEEN_START,
  UPDATE_SUBMISSION_SEEN_SUCCESS,
  UPDATE_SUBMISSION_SEEN_FAILURE,
  UPDATE_SUBMISSION_EDIT_START,
  UPDATE_SUBMISSION_EDIT_SUCCESS,
  UPDATE_SUBMISSION_EDIT_FAILURE,
  DELETE_SUBMISSION_SHARE_START,
  DELETE_SUBMISSION_SHARE_SUCCESS,
  DELETE_SUBMISSION_SHARE_FAILURE,
  CREATE_SUBMISSION_SHARE_START,
  CREATE_SUBMISSION_SHARE_SUCCESS,
  CREATE_SUBMISSION_SHARE_FAILURE,
} from '../types';

// Utility Functions
import { arrToObj } from '../../utils/utilityFns';

const INITIAL_STATE = {
  submissions: {},
  numSubmissions: 0,
};

const submissionReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_FILTERED_SUBMISSIONS_START:
    case GET_SINGLE_SUBMISSION_START:
      return {
        ...state,
        submissions: {},
        numSubmissions: 0,
      };
    case GET_SITE_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        submissions: { ...state.submissions, ...arrToObj(payload.docs, '_id') },
      };
    case GET_FILTERED_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        submissions: arrToObj(payload.docs, '_id'),
        numSubmissions: payload.totalDocs,
      };
    case GET_SINGLE_SUBMISSION_SUCCESS:
      return {
        ...state,
        submissions: arrToObj(payload.docs, '_id'),
        numSubmissions: 0, // hack FIXME
      };
    case GET_FILTERED_SUBMISSIONS_FAILURE:
    case GET_SINGLE_SUBMISSION_FAILURE:
      return {
        ...state,
        submissions: {},
        numSubmissions: 0,
      };
    case UPDATE_SUBMISSION_SUCCESS:
    case UPDATE_SUBMISSION_EDIT_SUCCESS:
    case DELETE_SUBMISSION_SHARE_SUCCESS:
    case CREATE_SUBMISSION_SHARE_SUCCESS:
      return {
        ...state,
        submissions: { ...state.submissions, [payload.id]: payload },
      };
    case DELETE_SUBMISSION_UPDATE_SUCCESS:
    case UPDATE_SUBMISSION_SEEN_SUCCESS:
      return {
        ...state,
        submissions: {
          ...state.submissions,
          [payload.docId]: {
            ...state.submissions[payload.docId],
            updates: payload.updates,
          },
        },
      };
    case UPDATE_SUBMISSION_START:
    case UPDATE_SUBMISSION_FAILURE:
    case DELETE_SUBMISSION_UPDATE_START:
    case DELETE_SUBMISSION_UPDATE_FAILURE:
    case UPDATE_SUBMISSION_SEEN_START:
    case UPDATE_SUBMISSION_SEEN_FAILURE:
    case UPDATE_SUBMISSION_EDIT_START:
    case UPDATE_SUBMISSION_EDIT_FAILURE:
    case DELETE_SUBMISSION_SHARE_START:
    case DELETE_SUBMISSION_SHARE_FAILURE:
    case CREATE_SUBMISSION_SHARE_START:
    case CREATE_SUBMISSION_SHARE_FAILURE:
    case GET_SITE_SUBMISSIONS_FAILURE:
      return {
        ...state,
      };
    default: {
      return state;
    }
  }
};

export default submissionReducer;
