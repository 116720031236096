import { SET_ALERT, REMOVE_ALERT, START_ALERT } from '../types';

// Utilities
import { createAlert } from '../../utils/utilityFns';

export const startAlert = (alertObj) => ({
  type: START_ALERT,
  payload: createAlert(alertObj),
});

export const setAlert = (alertObj) => ({
  type: SET_ALERT,
  payload: alertObj,
});

export const removeAlert = (alertId) => ({
  type: REMOVE_ALERT,
  payload: alertId,
});
