import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: '88px',
    padding: '4px 24px',
    [theme.breakpoints.down('md')]: {
      padding: '4px 6px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 24px',
    },
  },
  photo: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '4px 0px 4px 16px',
  },
  title: {
    display: 'flex',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: 1,
    padding: '5px 0px 2px 0px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    fontSize: '0.9rem',
    fontWeight: 400,
    lineHeight: 1.15,
  },
  latLng: {
    fontSize: '0.7rem',
    fontWeight: 300,
  },
  avatarLarge: {
    width: '64px',
    height: '64px',
  },
  link: {
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
