import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    zIndex: 1001,
    position: 'fixed',
    bottom: 0,
    borderTop: `1px solid ${theme.custom.lightgrey}`,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
