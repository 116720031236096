import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';

// Material UI
import { useTheme } from '@material-ui/core/styles';

// Component
import MapMarkerPopup from '../map-marker-popup/map-marker-popup.component';
import { generateMarkerType } from '../../utils/markerTypes';

// Actions
import { updateActiveSiteId } from '../../redux/site/site.actions';

const MapMarker = ({
  site,
  activeSiteId,
  actualStatus,
  issueCount,
  totalMilestones,
  metMilestones,
  updateActiveSiteIdDispatch,
}) => {
  const theme = useTheme();
  const markerRef = useRef();

  useEffect(() => {
    if (activeSiteId === site._id) {
      markerRef.current.openPopup();
    } else {
      markerRef.current.closePopup();
    }
  }, [site._id, activeSiteId]);

  let fillColor;
  if (actualStatus === 'Stopped' || actualStatus === 'Off Track') {
    fillColor = theme.custom.statusRed;
  } else if (actualStatus === 'Completed') {
    fillColor = theme.custom.statusGreen;
  } else if (actualStatus === 'Ongoing') {
    fillColor = theme.custom.statusOrange;
  } else if (actualStatus === 'Not Started') {
    fillColor = theme.custom.statusGrey;
  } else {
    fillColor = theme.custom.statusGrey;
  }

  const percentComplete =
    Math.round((metMilestones / totalMilestones) * 10) / 10;

  //const progressBar = 135 - 135 * percentComplete;

  let markerText;
  actualStatus === 'Completed' && issueCount === 0
    ? (markerText = theme.custom.symbols.check.hex)
    : (markerText = issueCount);

  const marker = generateMarkerType({
    markerName: 'ISSUE_COUNT',
    fillColor,
    issueCount: markerText,
  });

  const siteIcon = divIcon(marker);

  const handleMarkerClick = () => {
    //Check if clicked own marker
    if (markerRef.current.options.id === activeSiteId) {
      updateActiveSiteIdDispatch('-1');
    } else {
      updateActiveSiteIdDispatch(site._id);
    }
  };

  return (
    <>
      <Marker
        id={site._id}
        ref={markerRef}
        position={[site.lat, site.lng]}
        icon={siteIcon}
        eventHandlers={{ click: handleMarkerClick }}>
        <MapMarkerPopup
          name={site.name}
          region={site.region}
          percentComplete={percentComplete}
        />
      </Marker>
    </>
  );
};

MapMarker.propTypes = {
  site: PropTypes.object.isRequired,
  activeSiteId: PropTypes.string.isRequired,
  actualStatus: PropTypes.string.isRequired,
  issueCount: PropTypes.number.isRequired,
  totalMilestones: PropTypes.number.isRequired,
  metMilestones: PropTypes.number.isRequired,
  updateActiveSiteIdDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateActiveSiteIdDispatch: (siteId) => dispatch(updateActiveSiteId(siteId)),
});

export default connect(null, mapDispatchToProps)(MapMarker);
