import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import SvgFileIcon from '../svg-file-icon/svg-file-icon.component';

// Styles
import useStyles from './export-modal.styles';

// Actions
import { startAlert } from '../../redux/alert/alert.actions';
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectModalExportOptions } from '../../redux/ui/ui.selectors';

// Utilities
import {
  exportToCSVDownload,
  exportToJSONDownload,
} from '../../utils/exportFns';

function ExportModal({
  exportOptions: { isOpen, content },
  toggleModalDispatch,
  startAlertDispatch,
}) {
  const classes = useStyles();

  const handleListItemClick = (exportType) => {
    if (exportType === 'cancel') {
      toggleModalDispatch({ modalName: 'exportOptions', modalContent: {} });
    } else if (exportType === 'csv') {
      try {
        exportToCSVDownload(
          content.data,
          content.type,
          content.filename,
          document
        );
        startAlertDispatch({
          msg: `CSV File Created!`,
          alertType: 'success',
        });
        toggleModalDispatch({ modalName: 'exportOptions', modalContent: {} });
      } catch (err) {
        startAlertDispatch({
          msg: `Error creating CSV File. ${err.message}`,
          alertType: 'error',
        });
      }
    } else if (exportType === 'json') {
      try {
        exportToJSONDownload(
          content.data,
          content.type,
          content.filename,
          document
        );
        startAlertDispatch({
          msg: `JSON File Created!`,
          alertType: 'success',
        });
        toggleModalDispatch({ modalName: 'exportOptions', modalContent: {} });
      } catch (err) {
        startAlertDispatch({
          msg: `Error creating JSON File. ${err.message}`,
          alertType: 'error',
        });
      }
    } else if (exportType === 'pdf') {
      console.log('FIX ME');
    } else {
      toggleModalDispatch({ modalName: 'exportOptions', modalContent: {} });
    }
  };

  const handleClose = () => {
    toggleModalDispatch({ modalName: 'exportOptions', modalContent: {} });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='sharing-dialog-title'
      open={isOpen}
      className={classes.root}>
      <DialogTitle id='sharing-dialog-title' className={classes.title}>
        Exporting Options
      </DialogTitle>
      <Divider />
      <List>
        <ListItem
          button
          disabled={true}
          onClick={() => handleListItemClick('pdf')}>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <SvgFileIcon ext={'pdf'} iconSize='large' />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary='Export to PDF' />
        </ListItem>
        <ListItem button onClick={() => handleListItemClick('json')}>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <SvgFileIcon ext={'json'} iconSize='large' />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary='Export to JSON' className={classes.text} />
        </ListItem>
        <ListItem button onClick={() => handleListItemClick('csv')}>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <SvgFileIcon ext={'csv'} iconSize='large' />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary='Export to CSV' />
        </ListItem>
        <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick('cancel')}>
          <ListItemAvatar>
            <Avatar>
              <CloseIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary='Cancel' />
        </ListItem>
      </List>
    </Dialog>
  );
}

ExportModal.propTypes = {
  exportOptions: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  exportOptions: selectModalExportOptions,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
