import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import MainContainer from '../../components/main-container/main-container.component';
import HeaderGeneral from '../../components/header-general/header-general.component';
import NotificationMenu from '../../components/notification-menu/notification-menu.component';
import ActionMenu from '../../components/action-menu/action-menu.component';
import RefreshButton from '../../components/refresh-button/refresh-button.component';
import TableColgroup from '../../components/table-colgroup/table-colgroup.component';

// Material UI
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// Styles
import useStyles from './team.styles';

// Selectors
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectCurrentProject } from '../../redux/project/project.selectors';

const Team = ({ currentUser, project }) => {
  const classes = useStyles();
  const history = useHistory();
  const { projectId } = useParams();

  if (!project) {
    history.push(`/projects/${projectId}`);
    return null;
  }

  return (
    <MainContainer>
      <HeaderGeneral title='Project Team'>
        <>
          <RefreshButton />
          <ActionMenu />
          <NotificationMenu />
        </>
      </HeaderGeneral>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Grid item xs={12} className={classes.box}>
            <div className={classes.boxLayout}>
              <h1>Web Users</h1>
            </div>
          </Grid>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableColgroup cols={[20, 25, 15, 15, 15, 10]} />
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell>User Name</TableCell>
                  <TableCell>Contact Information</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Photo</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody></TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

Team.propTypes = {};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  project: selectCurrentProject,
});

export default connect(mapStateToProps)(Team);
