import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Material UI
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

// Components
import MainContainer from '../../components/main-container/main-container.component';
import MainBottomNav from '../../components/main-bottom-nav/main-bottom-nav.component';
import HeaderDashboard from '../../components/header-dashboard/header-dashboard.component';
import HeaderGeneral from '../../components/header-general/header-general.component';
import MapDashboard from '../../components/map-dashboard/map-dashboard.component';
import MapDashboardGrouped from '../../components/map-dashboard-grouped/map-dashboard-grouped.component';
import GanttContainer from '../../components/gantt-container/gantt-container.component';
import IssueBox from '../../components/issuebox/issuebox.component';
import SubmissionCardContainer from '../../components/submission-card-container/submission-card-container.component';
import RefreshButton from '../../components/refresh-button/refresh-button.component';
import SubmissionArchiveButton from '../../components/submission-archive-button/submission-archive-button.component';
import WidgetContainer from '../../components/widget-container/widget-container.component';

// Actions
import { getDashboardStart } from '../../redux/ui/ui.actions';

// Selectors
import { selectCurrentUserId } from '../../redux/user/user.selectors';
import { selectActiveSiteSubmissionHeaderDashboard } from '../../redux/site/site.selectors';
import { selectProjectMapDetails } from '../../redux/project/project.selectors';

// Custom Hooks
// import useQuery from '../../hooks/useQuery';

const Dashboard = ({
  getDashboardStartDispatch,
  userId,
  activeSiteInfo,
  projectMap,
}) => {
  const { projectId } = useParams();
  const [dashElems, setDashElems] = useState({
    map: true,
    gantt: true,
    submissions: true,
    issues: true,
    feed: false,
  });
  //const query = useQuery();
  //console.log(query.get('activeSiteId'));

  useEffect(() => {
    getDashboardStartDispatch({ projectId });
  }, [getDashboardStartDispatch, projectId]);

  const mapToRender =
    projectMap && projectMap?.format === 'groupedSites' ? (
      <MapDashboardGrouped />
    ) : (
      <MapDashboard />
    );

  return (
    <MainContainer>
      <HeaderDashboard>
        <>
          <RefreshButton />
        </>
      </HeaderDashboard>
      <Grid container spacing={0}>
        <Hidden xsDown>
          <WidgetContainer />
          <Grid item xs={12}>
            {mapToRender}
          </Grid>
          <Grid item xs={12}>
            <GanttContainer userId={userId} />
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <HeaderGeneral extraSpacing={true} title={activeSiteInfo.titleStr}>
            <SubmissionArchiveButton
              projectId={projectId}
              activeSiteInfo={activeSiteInfo}
            />
          </HeaderGeneral>
        </Grid>
        <SubmissionCardContainer />
        <IssueBox showResolved={false} title='Issuebox' />
      </Grid>
      {/* <Hidden smUp>
        <MainBottomNav dashElems={dashElems} setDashElems={setDashElems} />
      </Hidden> */}
    </MainContainer>
  );
};

// Proptypes
Dashboard.propTypes = {
  mainWidgetSelected: PropTypes.string,
  getDashboardStartDispatch: PropTypes.func,
  userId: PropTypes.string.isRequired,
  activeSiteInfo: PropTypes.object.isRequired,
  projectMap: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  userId: selectCurrentUserId,
  activeSiteInfo: selectActiveSiteSubmissionHeaderDashboard,
  projectMap: selectProjectMapDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboardStartDispatch: (projectId) =>
    dispatch(getDashboardStart(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
