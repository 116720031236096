import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    margin: 0,
    padding: '6px 8px 6px 16px',
    backgroundColor: theme.palette.primary.darkest,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    '& h1': {
      color: theme.custom.lightestgrey,
      margin: 0,
    },
    '& span': {
      color: theme.custom.lightestgrey,
    },
  },
  topSpacing: {
    margin: '40px 0 0 0',
  },
}));

export default useStyles;
