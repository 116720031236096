import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link as RouterLink, useParams } from 'react-router-dom';

// Material UI
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import BuildIcon from '@material-ui/icons/Build';
import FaceIcon from '@material-ui/icons/Face';

// Styles
import useStyles from './settings-menu.styles';

// Actions
import { signOutStart } from '../../redux/user/user.actions';

// Selectors
import { selectCurrentUser } from '../../redux/user/user.selectors';

const SettingsMenu = ({ user, signOutStartDispatch }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [language, setLanguage] = useState('en');
  const { projectId } = useParams();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    alert('This is a placeholder for future translation features.');
  };

  const handleAlertClose = () => {
    setAnchorEl(null);
    alert('This is a placeholder for a future feature.');
  };

  if (!user) return null;

  return (
    <>
      <Tooltip title='Settings'>
        <IconButton onClick={handleClick} className={classes.iconButton}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.popover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <div className={classes.root}>
          <div className={classes.userContent}>
            <Avatar
              alt={user.firstName}
              src={user.photo}
              className={classes.largeAvatar}
            />
            <div className={classes.user}>{`Hello, ${user.firstName}!`}</div>
          </div>
          <div className={classes.formRoot}>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel htmlFor='language-select' shrink={false}></InputLabel>
              <Select
                native
                className={classes.languageSelect}
                value={language}
                onChange={handleLanguageChange}
                id='language-select'>
                <option value={'en'}>🇺🇸 &nbsp;English</option>
                <option value={'uk'}>🇺🇦 &nbsp;Ukrainian</option>
              </Select>
            </FormControl>
          </div>
          <Divider />
          <List dense className={classes.list}>
            <ListItem
              component={RouterLink}
              key='settings'
              to={`#`}
              onClick={handleAlertClose}
              className={classes.listItem}>
              <ListItemIcon>
                <Avatar className={classes.small}>
                  <BuildIcon />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={'Project Settings'}
                className={classes.listText}
              />
            </ListItem>
            <Divider />
            <ListItem
              component={RouterLink}
              key='profile'
              to={`/projects/${projectId}/profile`}
              onClick={handleClose}
              className={classes.listItem}>
              <ListItemIcon>
                <Avatar className={classes.small}>
                  <FaceIcon />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={'My Profile'}
                className={classes.listText}
              />
            </ListItem>
            <Divider />
            <ListItem
              component={RouterLink}
              key='account'
              to={`#`}
              onClick={handleAlertClose}
              className={classes.listItem}>
              <ListItemIcon>
                <Avatar className={classes.small}>
                  <PersonIcon />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={'Account'} className={classes.listText} />
            </ListItem>
            <Divider />
          </List>
          <div className={classes.signout}>
            <Button
              onClick={signOutStartDispatch}
              variant='contained'
              size='small'
              color='secondary'
              className={classes.button}
              startIcon={<ExitToAppIcon />}>
              Sign Out
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

SettingsMenu.propTypes = {
  user: PropTypes.object,
  signOutStartDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  signOutStartDispatch: () => dispatch(signOutStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenu);
