import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actualTimelineWrapper: {
    display: 'grid',
    gridGap: '0px ',
    alignItems: 'center',
    gridTemplateColumns: (props) => props.actualTimelineGrid,
    margin: '0',
    zIndex: 2,
  },
  actualTimeline: {
    gridColumnStart: 2,
    fontWeight: 500,
    textAlign: 'right',
    height: '18px',
    backgroundColor: theme.custom.lightgreyGantt,
    position: 'relative',
    //cursor: 'pointer',
    borderRadius: '20px',
    backgroundImage: null,
    '&.completed': {
      backgroundColor: theme.custom.statusGreen,
    },
    '&.ongoing': {
      backgroundColor: theme.custom.statusOrangeAlt,
    },
    '&.stopped': {
      backgroundColor: theme.custom.statusRed,
    },
    '&.offtrack': {
      backgroundColor: theme.custom.statusRed,
    },
  },
  siteHeight: {
    height: '26px',
  },
  striped: {
    backgroundImage: `repeating-linear-gradient(45deg, transparent, transparent 5px, ${theme.custom.ganttStripes} 5px, ${theme.custom.ganttStripes} 12px)`,
  },
  gapStart: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 0,
  },
  gapEnd: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-start',
    height: 0,
  },
  dateStart: {
    zIndex: 4,
    display: 'flex',
    width: '0px',
    overflow: 'visible',
    transform: 'translate(-34px, -8px)',
    whiteSpace: 'nowrap',
  },
  dateEnd: {
    zIndex: 4,
    display: 'flex',
    width: '0px',
    overflow: 'visible',
    transform: 'translate(4px, -8px)',
    whiteSpace: 'nowrap',
  },
  date: {
    color: theme.custom.ganttGreyText,
    fontSize: '12px',
    letterSpacing: '-0.4px',
    fontWeight: 500,
    zIndex: 4,
    '&.completed': {
      color: theme.custom.statusGreen,
    },
    '&.ongoing': {
      color: theme.custom.statusOrangeAlt,
    },
    '&.stopped': {
      color: theme.custom.statusRed,
    },
    '&.offtrack': {
      color: theme.custom.statusRed,
    },
  },
  hide: {
    display: 'none',
  },
}));

export default useStyles;
