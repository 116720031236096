import { createSelector } from 'reselect';

const selectUiSlice = (state) => state.ui;

export const selectDrawerPosition = createSelector(
  [selectUiSlice],
  (ui) => ui.drawerOpen
);

export const selectUpdateFilters = createSelector(
  [selectUiSlice],
  (ui) => ui.updateFilters
);

export const selectIssueboxFilters = createSelector(
  [selectUiSlice],
  (ui) => ui.issueboxFilters
);

export const selectIssueboxSort = createSelector(
  [selectUiSlice],
  (ui) => ui.issueboxSort
);

export const selectModals = createSelector([selectUiSlice], (ui) => ui.modals);

export const selectModalIssueResolve = createSelector(
  [selectModals],
  (modals) => modals.issueResolve
);

export const selectModalSubmissionResolve = createSelector(
  [selectModals],
  (modals) => modals.submissionResolve
);

export const selectModalIssueNotes = createSelector(
  [selectModals],
  (modals) => modals.issueNotes
);

export const selectModalSubmissionNotes = createSelector(
  [selectModals],
  (modals) => modals.submissionNotes
);

export const selectModalSidebar = createSelector(
  [selectModals],
  (modals) => modals.sidebarNotes
);

export const selectModalConfirmDialog = createSelector(
  [selectModals],
  (modals) => modals.confirmDialog
);

export const selectModalExportOptions = createSelector(
  [selectModals],
  (modals) => modals.exportOptions
);

export const selectModalPhotoGallery = createSelector(
  [selectModals],
  (modals) => modals.photoGallery
);

export const selectModalEditSubmission = createSelector(
  [selectModals],
  (modals) => modals.editSubmission
);

export const selectModalFullscreenSubmission = createSelector(
  [selectModals],
  (modals) => modals.fullscreenSubmission
);

export const selectModalShareDelete = createSelector(
  [selectModals],
  (modals) => modals.shareDelete
);

export const selectModalShareCreate = createSelector(
  [selectModals],
  (modals) => modals.shareCreate
);

export const selectModalReactionMenu = createSelector(
  [selectModals],
  (modals) => modals.reactionMenu
);

export const selectModalComment = createSelector(
  [selectModals],
  (modals) => modals.comment
);

export const selectDashboardSlice = createSelector(
  [selectUiSlice],
  (ui) => ui.dashboard
);

export const selectDashboardLoading = createSelector(
  [selectDashboardSlice],
  (dashboard) => {
    return !Object.values(dashboard.loading).every((el) => el === false);
  }
);

export const selectDashboardLastRefresh = createSelector(
  [selectDashboardSlice],
  (dashboard) => dashboard.lastRefresh
);

export const selectDashboardWidgets = createSelector(
  [selectDashboardSlice],
  (dashboard) => dashboard.widgets
);

export const selectMainDashboardWidgets = createSelector(
  [selectDashboardWidgets],
  (widgets) => widgets.main
);

export const selectMainDashboardActiveWidget = createSelector(
  [selectMainDashboardWidgets],
  (main) => main.selected
);

export const selectIssueboxSlice = createSelector(
  [selectUiSlice],
  (ui) => ui.issuebox
);

export const selectIssueboxType = createSelector(
  [selectIssueboxSlice],
  (issuebox) => issuebox.type
);
