import React from 'react';
import { Link, useLocation } from 'react-router-dom';

//Material UI
import Grid from '@material-ui/core/Grid';

// Styles
import useStyles from './auth-nav.styles';

const navObj = {
  default: {
    textLeft: 'Back',
    linkLeft: '/signin',
    textRight: '',
    linkRight: '',
  },
  signup: {
    textLeft: '',
    linkLeft: '',
    textRight: 'Back',
    linkRight: '/signin',
  },
  signin: {
    textLeft: 'Forgot Password?',
    linkLeft: '/forgot-password',
    textRight: "Don't have an account? Sign Up",
    linkRight: '/signup',
  },
};

function AuthNav() {
  const classes = useStyles();
  const location = useLocation();

  const navLinks = navObj.hasOwnProperty(location.pathname.slice(1))
    ? navObj[location.pathname.slice(1)]
    : navObj.default;

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Link to={navLinks.linkLeft} className={classes.link}>
            {navLinks.textLeft}
          </Link>
        </Grid>
        <Grid item>
          <Link to={navLinks.linkRight} className={classes.link}>
            {navLinks.textRight}
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default AuthNav;
