import * as yup from 'yup';

// Initialize Function
export const projectInitialize = (project) => {
  return {
    name: project.name ? project.name : '',
    location: project.location ? project.location : '',
    population: project.population ? project.population : '',
    projectType: project.projectType ? project.projectType : '',
    sponser: project.sponser ? project.sponser : '',
    budget: project.budget ? project.budget : '',
    lat: project.lat ? project.lat : '',
    lng: project.lng ? project.lng : '',
    description: project.description ? project.description : '',
  };
};

// Project Validation Schema
export const projectValidationSchema = yup.object({
  name: yup
    .string('Enter a name for the project.')
    .required('This field is required.')
    .max(60, 'Max length of 60 characters exceeded.'),
  location: yup
    .string('Assign a location (e.g. country, region).')
    .required('This field is required.')
    .max(60, 'Max length of 60 characters exceeded.'),
  population: yup
    .string('Enter the estimated population.')
    .required('This field is required.')
    .max(30, 'Max length of 30 characters exceeded.'),
  projectType: yup
    .string('Enter the project type.')
    .required('This field is required.')
    .max(45, 'Max length of 45 characters exceeded.'),
  sponser: yup
    .string('Enter the project sponser(s).')
    .required('This field is required.')
    .max(60, 'Max length of 60 characters exceeded.'),
  budget: yup
    .string('Enter the project budget.')
    .required('This field is required.')
    .max(30, 'Max length of 30 characters exceeded.'),
  lat: yup
    .number('Enter the project latitude (degrees).')
    .moreThan(-90, 'Latitude must be greater than -90.')
    .lessThan(90, 'Latitude must be less than 90.')
    .required('This field is required.'),
  lng: yup
    .number('Enter the project longitude (degrees).')
    .moreThan(-90, 'Longitude must be greater than -90.')
    .lessThan(90, 'Longitude must be less than 90.')
    .required('This field is required.'),
  description: yup
    .string('Enter the project description.')
    .required('This field is required.')
    .max(500, 'Max length of 500 characters exceeded.'),
});

// Formik Form Generator Object
export const projectSchema = [
  {
    label: 'Project Name',
    name: 'name',
    type: 'text',
    elem: 'textField',
    required: true,
    fullWidth: true,
    style: {
      marginBottom: '18px',
      display: 'flex',
    },
  },
  {
    label: 'Project Location (e.g. region, country)',
    name: 'location',
    type: 'text',
    elem: 'textField',
    required: true,
    fullWidth: true,
    style: {
      marginBottom: '18px',
      display: 'flex',
    },
  },
  {
    label: 'Project Type (e.g. solar well)',
    name: 'projectType',
    type: 'text',
    elem: 'textField',
    fullWidth: true,
    required: true,
    style: {
      marginBottom: '18px',
      display: 'flex',
    },
  },
  {
    label: 'Sponser(s)',
    name: 'sponser',
    type: 'text',
    elem: 'textField',
    fullWidth: true,
    required: true,
    style: {
      marginBottom: '18px',
      display: 'flex',
    },
  },
  // {
  //   label: 'Population (estimate)',
  //   name: 'population',
  //   type: 'text',
  //   elem: 'textField',
  //   required: true,
  //   style: { marginBottom: '18px', width: '50%', paddingRight: '16px' },
  // },
  // {
  //   label: 'Budget ($)',
  //   name: 'budget',
  //   type: 'text',
  //   elem: 'textField',
  //   required: true,
  //   style: { marginBottom: '18px', width: '50%' },
  // },
  // {
  //   label: 'Latitude (deg)',
  //   name: 'lat',
  //   type: 'text',
  //   elem: 'textField',
  //   required: true,
  //   style: { marginBottom: '18px', width: '50%', paddingRight: '16px' },
  // },
  // {
  //   label: 'Longitude (deg)',
  //   name: 'lng',
  //   type: 'text',
  //   elem: 'textField',
  //   required: true,
  //   style: { marginBottom: '18px', width: '50%' },
  // },
  {
    label: 'Project Description',
    name: 'description',
    type: 'text',
    elem: 'textField',
    fullWidth: true,
    multiline: true,
    required: true,
    style: {
      marginBottom: '18px',
      display: 'flex',
    },
  },
];
