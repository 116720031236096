import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

// Styles
import useStyles from './header-dashboard.styles';

// Actions
import { updateActiveSiteId } from '../../redux/site/site.actions';

// Selectors
import {
  selectSiteAndProjectList,
  selectActiveSiteId,
} from '../../redux/site/site.selectors';

const HeaderDashboard = ({
  extraSpacing = false,
  children,
  items,
  activeSiteId,
  updateActiveSiteIdDispatch,
}) => {
  const classes = useStyles();

  const handleSiteListboxChange = (e) => {
    updateActiveSiteIdDispatch(e.target.value);
  };

  return (
    <Toolbar
      className={clsx(
        classes.toolbar,
        extraSpacing ? classes.extraSpacing : null
      )}
      disableGutters>
      <div className={classes.toolbarStart}>
        <FormControl className={classes.formControl}>
          <NativeSelect
            value={activeSiteId}
            onChange={handleSiteListboxChange}
            className={classes.select}
            inputProps={{ 'aria-label': 'Without label' }}>
            {items.map((item) => (
              <option key={item._id} value={item.value}>
                {item.value === '-1'
                  ? '🏡 Project Home'
                  : activeSiteId === item.value
                  ? `📍 ${item.name}`
                  : item.name}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </div>
      <div className={classes.toolbarEnd}>{children}</div>
    </Toolbar>
  );
};

HeaderDashboard.propTypes = {
  title: PropTypes.string,
  extraSpacing: PropTypes.bool,
  children: PropTypes.node,
};

const mapStateToProps = createStructuredSelector({
  items: selectSiteAndProjectList,
  activeSiteId: selectActiveSiteId,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveSiteIdDispatch: (siteId) => dispatch(updateActiveSiteId(siteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDashboard);
