import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

// Material UI
import Alert from '@material-ui/lab/Alert';

// Selectors
import { selectAlertMessage } from '../../redux/alert/alert.selectors';

// Styles
import useStyles from './alert-bar.styles';

const AlertBar = ({ alerts }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {alerts.map((alert) => (
        <Alert
          elevation={6}
          variant='filled'
          key={alert.alertId}
          severity={alert.alertType}>
          {alert.msg}
        </Alert>
      ))}
    </div>
  );
};

AlertBar.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  alerts: selectAlertMessage,
});

export default connect(mapStateToProps)(AlertBar);
