import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Material UI
import Popover from '@material-ui/core/Popover';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

// Components
import FileButton from '../file-button/file-button.component';

// Styles
import useStyles from './gantt-popover.styles';

// Constants
import {
  MAX_ATTACHMENTS_ISSUE,
  MAX_ATTACHMENT_SIZE,
} from '../../utils/constants';

//Utilities
import { formatDateTime } from '../../utils/utilityFns';

function GanttPopover({
  showPopover,
  anchorEl,
  handlePopoverClose,
  popoverId,
  milestoneEl,
  handleUpdateMilestone,
  startAlertDispatch,
  addAttachmentMilestoneStartDispatch,
  deleteAttachmentMilestoneStartDispatch,
  currentUserId,
  toggleModal,
}) {
  const classes = useStyles();
  const { projectId } = useParams();

  // const handleAttachmentAdd = (e) => {
  //   if (e.target.files.length > MAX_ATTACHMENTS_ISSUE) {
  //     startAlertDispatch({
  //       msg: `Max number of attachments (${MAX_ATTACHMENTS_ISSUE}) exceeded.`,
  //       alertType: 'error',
  //     });
  //     return;
  //   }
  //   for (let i = 0; i < e.target.files.length; i++) {
  //     if (e.target.files[i].size > MAX_ATTACHMENT_SIZE) {
  //       startAlertDispatch({
  //         msg: `Max filesize (${
  //           MAX_ATTACHMENT_SIZE / 1024 / 1024
  //         }mb) exceeded in one or more files.`,
  //         alertType: 'error',
  //       });
  //       return;
  //     }
  //   }

  //   let numFiles = e.target.files.length;

  //   let formData = new FormData();
  //   for (let i = 0; i < numFiles; i++) {
  //     formData.append('attachments', e.target.files[i]);
  //   }
  //   addAttachmentMilestoneStartDispatch({
  //     formData,
  //     projectId,
  //     milestoneId: milestoneEl._id,
  //   });
  // };

  // const handleAttachmentDelete = (id) => {
  //   if (id) {
  //     deleteAttachmentMilestoneStartDispatch({
  //       projectId,
  //       milestoneId: milestoneEl._id,
  //       attachmentId: id,
  //       embeddedField: 'attachments',
  //     });
  //   }
  // };

  return (
    <Popover
      open={showPopover}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      style={{ transform: 'translateY(2px)' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      {popoverId && milestoneEl && (
        <div className={classes.root}>
          <div className={classes.row}>
            <span className={classes.name}>
              {milestoneEl.type === 'milestone'
                ? `${milestoneEl.milestoneName}`
                : `${milestoneEl.siteName} Overview`}
            </span>
          </div>
          <div className={classes.row}>
            <div
              className={clsx(
                classes.status,
                milestoneEl.actualStatus.toLowerCase()
              )}>
              {milestoneEl.actualStatus}
            </div>
          </div>
          <div className={classes.row}>
            <span className={classes.description}>
              {milestoneEl.actualStartMessage}
            </span>
          </div>
          <div className={classes.row}>
            <span className={classes.description}>
              <strong>Scheduled Dates </strong>
              {`${formatDateTime(
                milestoneEl.datePlannedStart
              )} - ${formatDateTime(milestoneEl.datePlannedEnd)}`}
            </span>
          </div>
          {milestoneEl.daysOverdue > 0 && (
            <div className={classes.row}>
              <span className={classes.description}>
                <strong>Days Overdue</strong>
                {milestoneEl.daysOverdue}
              </span>
            </div>
          )}
          {milestoneEl.type === 'site' && (
            <div className={classes.row}>
              <div className={classes.contact}>
                <Avatar
                  alt={milestoneEl.poc.firstName}
                  src={milestoneEl.poc.photo}
                  className={classes.avatar}
                />
                <div className={classes.email}>
                  <span>{`${milestoneEl.poc.firstName} ${milestoneEl.poc.lastName}`}</span>
                  <Link href={`mailto:${milestoneEl.poc.email}`}>
                    {milestoneEl.poc.email}
                  </Link>
                </div>
              </div>
            </div>
          )}
          {milestoneEl.type === 'milestone' && (
            <div className={classes.row}>
              <span className={classes.description}>
                <strong>{`Last Updated ${formatDateTime(
                  milestoneEl.lastUpdateDate
                )}`}</strong>
                {milestoneEl.lastUpdateNotes}
              </span>
            </div>
          )}

          {/* {milestoneEl.type === 'milestone' && milestoneEl.photos.length > 0 && (
            <>
              <div className={classes.attachmentsHeader}>Photos</div>
              <AvatarGroup
                max={4}
                className={classes.photoAvatars}
                onClick={() =>
                  toggleModal({
                    modalName: 'photoGallery',
                    modalContent: {
                      photos: milestoneEl.photos,
                    },
                  })
                }>
                {milestoneEl.photos.map((el) => (
                  <Avatar key={el._id} alt='Milestone Photo' src={el.url} />
                ))}
              </AvatarGroup>
            </>
          )} */}
          {/* {milestoneEl.type === 'milestone' &&
            milestoneEl.attachments.length > 0 && (
              <>
                <div className={classes.attachmentsHeader}>Attachments</div>
                <List dense={true} className={classes.attachmentList}>
                  {milestoneEl.attachments.map((el) => (
                    <ListItem key={el._id} className={classes.listItem}>
                      <ListItemText
                        primary={el.filename}
                        secondary={
                          <>
                            {el.filesize ? el.filesize : 'Unknown size.'}
                            {el.createdBy._id === currentUserId && (
                              <Tooltip title='Delete'>
                                <DeleteForeverIcon
                                  color='action'
                                  fontSize='small'
                                  className={classes.editIcons}
                                  onClick={() => handleAttachmentDelete(el._id)}
                                />
                              </Tooltip>
                            )}
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <FileButton file={el} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </>
            )} */}
          {/* {milestoneEl.type === 'milestone' && (
            <div className={classes.actionRow}>
              <span className={classes.description}>
                <strong>Toggle Complete</strong>
                {milestoneEl.actualStatus !== 'Completed' ? (
                  <IconButton
                    className={classes.link}
                    onClick={() =>
                      handleUpdateMilestone({
                        update: {
                          actionType: 'Completed',
                          status: 'Completed',
                        },
                        projectId,
                        milestoneId: milestoneEl._id,
                      })
                    }>
                    <CheckCircleOutlineIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.link}
                    onClick={() =>
                      handleUpdateMilestone({
                        update: {
                          actionType: 'Reverted',
                          actualStatus: 'Ongoing',
                        },
                        projectId,
                        milestoneId: milestoneEl._id,
                      })
                    }>
                    <CheckCircleIcon className={classes.completed} />
                  </IconButton>
                )}
              </span>
              <span className={classes.description}>
                <strong>Add Attachment</strong>
                <input
                  className={classes.input}
                  id='attachments'
                  multiple
                  type='file'
                  onChange={(e) => handleAttachmentAdd(e)}
                />
                <label htmlFor='attachments'>
                  <IconButton className={classes.link} component='span'>
                    <AddCircleOutlineIcon />
                  </IconButton>
                </label>
              </span>
            </div>
          )} */}
        </div>
      )}
    </Popover>
  );
}

GanttPopover.propTypes = {
  showPopover: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  handlePopoverClose: PropTypes.func.isRequired,
  popoverId: PropTypes.string,
  milestoneEl: PropTypes.object,
  startAlertDispatch: PropTypes.func.isRequired,
  addAttachmentMilestoneStartDispatch: PropTypes.func.isRequired,
  deleteAttachmentMilestoneStartDispatch: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
};

export default GanttPopover;
