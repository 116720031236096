import React from 'react';
import PropTypes from 'prop-types';

// Components
import UpdateFormPhoto from '../../forms/components/update-form-photo/update-form-photo.component';

// Material UI
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

// Styles
import useStyles from './form-container.styles';

const FormContainer = ({ title, headerPhoto, children }) => {
  const classes = useStyles();
  // FIXME: if no header photo load dummy photo with add message
  return (
    <Paper elevation={0}>
      <Typography variant='h6' component='h6' className={classes.header}>
        {title}
      </Typography>
      {headerPhoto && headerPhoto !== '' && (
        <UpdateFormPhoto
          currentPhoto={headerPhoto}
          startAlert={() => console.log('Hi')}
          updatePhoto={() => console.log('Hi')}
        />
      )}
      <Box className={classes.form}>{children}</Box>
    </Paper>
  );
};

FormContainer.propTypes = {
  title: PropTypes.string.isRequired,
  headerPhoto: PropTypes.string,
  children: PropTypes.element,
};

export default FormContainer;
