import { makeStyles } from '@material-ui/core/styles';

// Constants
import { GANTT_COL_1 as gc1, GANTT_COL_2 as gc2 } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'grid',
    gridTemplateColumns: `${gc1} ${gc2} 1fr`,
    backgroundColor: 'transparent',
    borderTop: `1px dotted ${theme.custom.ganttGrid}`,
    height: '32px',
  },
  milestoneRow: {
    '&:hover': {
      backgroundColor: theme.custom.ganttMarker,
    },
  },
  milestoneName: {
    '&:hover': {
      color: 'rgba(85,85,85,0.9)',
      cursor: 'pointer',
      textDecoration: 'underline',
      textDecorationColor: 'rgba(85,85,85,0.9)',
      textDecorationStyle: 'dotted',
    },
  },
  subtask: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: 1.1,
  },
  subtaskUnmodded: {
    borderLeft: '4px solid transparent',
  },
  subtaskNonEditMode: {
    paddingLeft: '4px',
  },
  titleSiteMode: {
    padding: '4px 0px 8px 8px',
    '& svg > text': {
      fill: '#fff',
    },
  },
  subtaskTitle: {
    display: 'flex',
    height: '100%',
    border: 0,
    padding: '0px 0px 0px 0px',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    color: theme.custom.mediumgrey,
  },
}));

export default useStyles;
