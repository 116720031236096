import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontSize: '2rem',
    paddingTop: '12px',
    fontWeight: 700,
    lineHeight: 1.2,
  },
  read: {
    fontSize: '1rem',
    fontColor: '#333',
    paddingTop: '6px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitle: { lineHeight: 1 },
  count: {
    backgroundColor: '#888',
    color: 'white',
    fontWeight: 500,
    padding: '1px 6px 1px 5px',
    borderRadius: '6px',
  },
}));

export default useStyles;
