import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import clsx from 'clsx';
import JSConfetti from 'js-confetti';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';

// Styles
import useStyles from './reaction-modal.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectModalReactionMenu } from '../../redux/ui/ui.selectors';

function ReactionModal({
  reactionMenu: { isOpen, content },
  toggleModalDispatch,
}) {
  const classes = useStyles();
  const jsConfettiRef = useRef(null);

  useEffect(() => {
    if (!jsConfettiRef.current) {
      jsConfettiRef.current = new JSConfetti();
    }

    return () => {
      jsConfettiRef.current = null;
    };
  }, []);

  if (Object.keys(content).length === 0) return null;

  const isToggled = (typeKey) =>
    content.reactions[typeKey].filter((el) => el._id === content.currentUserId)
      .length === 1;

  // TODO can make this a useCallback
  const handleListItemClick = (reactionType) => {
    if (reactionType === 'fire') {
      if (isToggled('fire')) {
        console.log('remove fire');
      } else {
        jsConfettiRef.current.addConfetti({
          emojis: ['🔥'],
          emojiSize: 120,
        });
      }
    } else if (reactionType === 'clap') {
      jsConfettiRef.current.addConfetti({
        emojis: ['👏'],
        emojiSize: 120,
      });
    } else if (reactionType === 'love') {
      jsConfettiRef.current.addConfetti({
        emojis: ['❤️'],
        emojiSize: 120,
      });
    } else if (reactionType === 'thumbsUp') {
      jsConfettiRef.current.addConfetti({
        emojis: ['👍'],
        emojiSize: 120,
      });
    } else if (reactionType === 'thumbsDown') {
      jsConfettiRef.current.addConfetti({
        emojis: ['👎'],
        emojiSize: 120,
      });
    } else if (reactionType === 'check') {
      jsConfettiRef.current.addConfetti({
        emojis: ['✅'],
        emojiSize: 120,
      });
    }
    toggleModalDispatch({ modalName: 'reactionMenu', modalContent: {} });
  };

  const handleClose = () => {
    toggleModalDispatch({ modalName: 'reactionMenu', modalContent: {} });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='reaction-dialog-title'
      open={isOpen}
      className={classes.root}>
      <div className={classes.emojiContainer}>
        <Avatar
          className={clsx(
            classes.avatar,
            isToggled('love') ? classes.toggled : null
          )}
          onClick={() => handleListItemClick('love')}>
          ❤️
        </Avatar>
        <Avatar
          className={clsx(
            classes.avatar,
            isToggled('clap') ? classes.toggled : null
          )}
          onClick={() => handleListItemClick('clap')}>
          👏
        </Avatar>
        <Avatar
          className={clsx(
            classes.avatar,
            isToggled('fire') ? classes.toggled : null
          )}
          onClick={() => handleListItemClick('fire')}>
          🔥
        </Avatar>
        <Avatar
          className={clsx(
            classes.avatar,
            isToggled('thumbsUp') ? classes.toggled : null
          )}
          onClick={() => handleListItemClick('thumbsUp')}>
          👍
        </Avatar>
        <Avatar
          className={clsx(
            classes.avatar,
            isToggled('thumbsDown') ? classes.toggled : null
          )}
          onClick={() => handleListItemClick('thumpsDown')}>
          👎
        </Avatar>
        <Avatar
          className={clsx(
            classes.avatar,
            isToggled('check') ? classes.toggled : null
          )}
          onClick={() => handleListItemClick('check')}>
          ✅
        </Avatar>
      </div>
    </Dialog>
  );
}

ReactionModal.propTypes = {
  reactionMenu: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  reactionMenu: selectModalReactionMenu,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReactionModal);
