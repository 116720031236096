import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  content: {
    paddingTop: '0!important',
    paddingBottom: '0!important',
  },
  buttonGroup: {
    paddingRight: '16px',
  },
  input: {
    display: 'none',
  },
  fullWidthForm: {
    paddingLeft: '0px!important',
    paddingRight: '0px!important',
    paddingTop: '4px!important',
  },
  fullWidthBtns: {
    justifyContent: 'flex-start',
    transform: 'translateX(-16px)',
  },
});

export default useStyles;
