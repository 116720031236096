import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';

// Material UI
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';

// Components
import ModalForm from '../modal-form/modal-form.component';
import UpdateCard from '../update-card/update-card.component';
import UpdatesFilter from '../updates-filters/updates-filters.component';

// Styles
import useStyles from './issue-notes-modal.styles';

// Actions
import { toggleModal, setUpdateFilters } from '../../redux/ui/ui.actions';
import {
  deleteIssueUpdateStart,
  updateIssueSeenStart,
  updateIssueStart,
} from '../../redux/issue/issue.actions';

// Selectors
import {
  selectModalIssueNotes,
  selectUpdateFilters,
} from '../../redux/ui/ui.selectors';
import { selectCurrentUserId } from '../../redux/user/user.selectors';

// Utilities
import { sortDate } from '../../utils/utilityFns';

function IssueNotesModal({
  issueNotes: { isOpen, content },
  toggleModalDispatch,
  deleteIssueUpdateStartDispatch,
  updateIssueSeenStartDispatch,
  updateIssueStartDispatch,
  userId,
  filters,
  setUpdateFiltersDispatch,
}) {
  const classes = useStyles();
  const { projectId } = useParams();

  useEffect(() => {
    if (
      content &&
      content.id &&
      content.updates.reduce(
        (prev, acc) =>
          !acc.seenBy.includes(userId) ? (prev = prev + 1) : prev,
        0
      ) > 0
    ) {
      updateIssueSeenStartDispatch({ docId: content.id, projectId });
    }
  }, [projectId, content, updateIssueSeenStartDispatch, userId]);

  const handleClose = () => {
    toggleModalDispatch({ modalName: 'issueNotes', modalContent: {} });
  };

  const handleSubmit = () => {};

  const handleDeleteIssueUpdate = (idObj) => {
    deleteIssueUpdateStartDispatch({ ...idObj, projectId });
  };

  const filteredUpdates =
    isOpen && content.hasOwnProperty('updates')
      ? content.updates
          .filter((el) => filters[el.actionType])
          .sort(sortDate('date', 'asc'))
      : [];

  const numHidden =
    isOpen && content.hasOwnProperty('updates')
      ? content.updates.reduce(
          (acc, cur) => (filters[cur.actionType] ? acc : acc + 1),
          0
        )
      : 0;

  return (
    <Drawer anchor='right' open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.closeHeader}>
            <Tooltip title='Close Sidebar'>
              <IconButton
                size='small'
                className={classes.closeIcon}
                aria-label='close sidebar'
                onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <h1 className={classes.titleText}>Notes</h1>
          </div>
          <UpdatesFilter
            filters={filters}
            setUpdateFiltersDispatch={setUpdateFiltersDispatch}
          />
        </div>
        <div className={classes.cardContainer}>
          {filteredUpdates.map((el) => (
            <UpdateCard
              key={el._id}
              actionType={el.actionType}
              categorization={el.categorization}
              date={new Date(el.createdAt)}
              user={el.createdBy}
              currentUserId={userId}
              content={el.content}
              docId={content.id}
              contentId={el._id}
              toggleModal={toggleModalDispatch}
              handleDeleteUpdate={handleDeleteIssueUpdate}
            />
          ))}
          {numHidden > 0 ? (
            <div
              className={
                classes.numHidden
              }>{`${numHidden} notes hidden via filters.`}</div>
          ) : null}
        </div>

        <div className={classes.modalFormContainer}>
          <ModalForm
            updateStartDispatch={updateIssueStartDispatch}
            content={content}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            color='primary'
          />
        </div>
      </div>
    </Drawer>
  );
}

IssueNotesModal.propTypes = {
  issueNotes: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  deleteIssueUpdateStartDispatch: PropTypes.func.isRequired,
  updateIssueSeenStartDispatch: PropTypes.func.isRequired,
  updateIssueStartDispatch: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setUpdateFiltersDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  issueNotes: selectModalIssueNotes,
  userId: selectCurrentUserId,
  filters: selectUpdateFilters,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  deleteIssueUpdateStartDispatch: (idObj) =>
    dispatch(deleteIssueUpdateStart(idObj)),
  updateIssueSeenStartDispatch: (idObj) =>
    dispatch(updateIssueSeenStart(idObj)),
  updateIssueStartDispatch: (updateObj) =>
    dispatch(updateIssueStart(updateObj)),
  setUpdateFiltersDispatch: (filterObj) =>
    dispatch(setUpdateFilters(filterObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueNotesModal);
