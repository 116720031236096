import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: '8px',
    fontSize: '0.85rem',
    color: theme.palette.primary.main,
    lineHeight: 1.1,
    alignItems: 'center',
    fontWeight: '500',
  },
  textButton: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.custom.primaryHover,
    },
  },
  hidden: {
    display: 'none',
  },
}));

export default useStyles;
