import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-rounded': {
      minWidth: '300px',
    },
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
    width: '48px',
    height: '48px',
  },
  title: {
    textAlign: 'center',
  },
  text: {
    color: 'rgba(0,0,0,0.87)',
    textDecoration: 'none',
    '&:hover': {
      color: 'rgba(0,0,0,0.87)',
      textDecoration: 'none',
    },
    '&:visited': {
      color: 'rgba(0,0,0,0.87)',
      textDecoration: 'none',
    },
  },
});

export default useStyles;
