import {
  GET_ALL_MILESTONES_START,
  GET_ALL_MILESTONES_SUCCESS,
  GET_ALL_MILESTONES_FAILURE,
  UPDATE_MILESTONE_START,
  UPDATE_MILESTONE_SUCCESS,
  UPDATE_MILESTONE_FAILURE,
  ADD_NOTE_MILESTONE_START,
  ADD_NOTE_MILESTONE_SUCCESS,
  ADD_NOTE_MILESTONE_FAILURE,
  DELETE_NOTE_MILESTONE_START,
  DELETE_NOTE_MILESTONE_SUCCESS,
  DELETE_NOTE_MILESTONE_FAILURE,
  ADD_ATTACHMENT_MILESTONE_START,
  ADD_ATTACHMENT_MILESTONE_SUCCESS,
  ADD_ATTACHMENT_MILESTONE_FAILURE,
  DELETE_ATTACHMENT_MILESTONE_START,
  DELETE_ATTACHMENT_MILESTONE_SUCCESS,
  DELETE_ATTACHMENT_MILESTONE_FAILURE,
  TOGGLE_GANTT_EDIT_MODE,
  ADD_MILESTONE_MODS,
  REMOVE_MILESTONE_MODS,
  REMOVE_ALL_MILESTONE_MODS,
  UPDATE_MANY_MILESTONES_START,
  UPDATE_MANY_MILESTONES_SUCCESS,
  UPDATE_MANY_MILESTONES_FAILURE,
} from '../types';

// Utility Functions
import { addMods, removeMods } from './milestone.utils';
import { arrToObj } from '../../utils/utilityFns';

const INITIAL_STATE = {
  milestones: {},
  mods: {},
  editMode: false,
};

const milestoneReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_MILESTONES_START:
    case GET_ALL_MILESTONES_FAILURE:
      return {
        ...state,
        milestones: {},
      };
    case TOGGLE_GANTT_EDIT_MODE:
      return {
        ...state,
        editMode: payload,
        mods: {},
      };
    case GET_ALL_MILESTONES_SUCCESS:
      return {
        ...state,
        milestones: arrToObj(payload, '_id'),
      };
    case ADD_MILESTONE_MODS:
      return {
        ...state,
        mods: { ...addMods(state.mods, payload) },
      };
    case REMOVE_MILESTONE_MODS:
      return {
        ...state,
        mods: { ...removeMods(state.mods, payload) },
      };
    case REMOVE_ALL_MILESTONE_MODS:
      return {
        ...state,
        mods: {},
      };
    case UPDATE_MANY_MILESTONES_SUCCESS:
      return {
        ...state,
        milestones: { ...state.milestones, ...arrToObj(payload, '_id') },
        mods: {},
        editMode: false,
      };
    case UPDATE_MILESTONE_SUCCESS:
    case ADD_NOTE_MILESTONE_SUCCESS:
    case DELETE_NOTE_MILESTONE_SUCCESS:
    case ADD_ATTACHMENT_MILESTONE_SUCCESS:
    case DELETE_ATTACHMENT_MILESTONE_SUCCESS:
      return {
        ...state,
        milestones: { ...state.milestones, [payload.id]: payload },
      };
    case UPDATE_MILESTONE_START:
    case UPDATE_MILESTONE_FAILURE:
    case UPDATE_MANY_MILESTONES_START:
    case UPDATE_MANY_MILESTONES_FAILURE:
    case ADD_NOTE_MILESTONE_START:
    case ADD_NOTE_MILESTONE_FAILURE:
    case DELETE_NOTE_MILESTONE_START:
    case DELETE_NOTE_MILESTONE_FAILURE:
    case ADD_ATTACHMENT_MILESTONE_START:
    case ADD_ATTACHMENT_MILESTONE_FAILURE:
    case DELETE_ATTACHMENT_MILESTONE_START:
    case DELETE_ATTACHMENT_MILESTONE_FAILURE:
    default: {
      return state;
    }
  }
};

export default milestoneReducer;
