import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  searchPopoverItems: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  searchPopoverRoot: {
    '& .MuiPaper-rounded': {
      minWidth: '212px',
      marginTop: '12px',
    },
  },
  searchPopoverSubheader: {
    padding: '8px 0px',
    textAlign: 'center',
    lineHeight: '1rem',
    color: theme.palette.primary.main,
  },
  searchPopoverListItem: {
    paddingTop: '0px',
    paddingBottom: '2px',
    '& .MuiListItemIcon-root': {
      minWidth: '48px',
    },
  },
}));

export default useStyles;
