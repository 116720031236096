import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

// Components
import MainContainer from '../../components/main-container/main-container.component';
import HeaderGeneral from '../../components/header-general/header-general.component';
import NotificationMenu from '../../components/notification-menu/notification-menu.component';
import ActionMenu from '../../components/action-menu/action-menu.component';
import RefreshButton from '../../components/refresh-button/refresh-button.component';
import DeletedTable from '../../components/deleted-table/deleted-table.component';

// Actions
import { getFilteredSubmissionsStart } from '../../redux/submission/submission.actions';
import { getFilteredIssuesStart } from '../../redux/issue/issue.actions';
import { getProjectByIdStart } from '../../redux/project/project.actions';

// Selectors
import {
  selectNumSubmissions,
  selectSubmissionsDeleted,
} from '../../redux/submission/submission.selectors';
import {
  selectNumIssues,
  selectIssuesDeleted,
} from '../../redux/issue/issue.selectors';

// Utilities
import {
  DEFAULT_ISSUE_TRASH_QUERY as issueTrashQueryObj,
  DEFAULT_SUBMISSION_TRASH_QUERY as submissionTrashQueryObj,
} from '../../utils/constants';

const RecentlyDeleted = ({
  getProjectByIdStartDispatch,
  getFilteredSubmissionsStartDispatch,
  getFilteredIssuesStartDispatch,
  issues,
  numIssues,
  submissions,
  numSubmissions,
}) => {
  const { projectId } = useParams();
  const [issueQueryObj, setIssueQueryObj] = useState(issueTrashQueryObj);
  const [submissionQueryObj, setSubmissionQueryObj] = useState(
    submissionTrashQueryObj
  );

  useEffect(() => {
    const fetchObj = {
      id: uuidv4(),
      name: 'project trash',
      actionType: 'projects',
      projectId: projectId,
    };
    getProjectByIdStartDispatch(fetchObj);
  }, [projectId, getProjectByIdStartDispatch]);

  useEffect(() => {
    getFilteredSubmissionsStartDispatch(projectId, submissionQueryObj);
  }, [getFilteredSubmissionsStartDispatch, projectId, submissionQueryObj]);

  useEffect(() => {
    getFilteredIssuesStartDispatch(projectId, issueQueryObj);
  }, [getFilteredIssuesStartDispatch, projectId, issueQueryObj]);

  return (
    <MainContainer>
      <HeaderGeneral title='Trash' />
      <div style={{ marginBottom: '12px' }}>
        Items in the trash are permenantly removed from the project{' '}
        <strong>90</strong> days after they were initially deleted.
      </div>
      <DeletedTable
        title='Submissions'
        topSpacing={false}
        numItems={numSubmissions}
        queryObj={submissionQueryObj}
        setQueryObj={setSubmissionQueryObj}
        items={submissions}
        linkPath='submissions'
        tooltipText='Open Submission'
      />
      <DeletedTable
        title='Issues'
        topSpacing={true}
        numItems={numIssues}
        queryObj={issueQueryObj}
        setQueryObj={setIssueQueryObj}
        items={issues}
        linkPath='issues'
        tooltipText='Open Issue'
      />
    </MainContainer>
  );
};

RecentlyDeleted.propTypes = {
  getFilteredSubmissionsStartDispatch: PropTypes.func.isRequired,
  numSubmissions: PropTypes.number.isRequired,
  getProjectByIdStartDispatch: PropTypes.func.isRequired,
  getFilteredIssuesStartDispatch: PropTypes.func.isRequired,
  numIssues: PropTypes.number.isRequired,
  issues: PropTypes.array.isRequired,
  submissions: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  numSubmissions: selectNumSubmissions,
  submissions: selectSubmissionsDeleted,
  numIssues: selectNumIssues,
  issues: selectIssuesDeleted,
});

const mapDispatchToProps = (dispatch) => ({
  getFilteredSubmissionsStartDispatch: (projectId, queryObj) =>
    dispatch(getFilteredSubmissionsStart({ projectId, queryObj })),
  getFilteredIssuesStartDispatch: (projectId, queryObj) =>
    dispatch(getFilteredIssuesStart({ projectId, queryObj })),
  getProjectByIdStartDispatch: (fetchObj) =>
    dispatch(getProjectByIdStart(fetchObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyDeleted);
