import * as yup from 'yup';

// Initialize Function
export const userInitialize = (user) => {
  return {
    firstName: user.firstName ? user.firstName : '',
    lastName: user.lastName ? user.lastName : '',
    position: user.position ? user.position : '',
    organization: user.organization ? user.organization : '',
    phoneNumber: user.phoneNumber ? user.phoneNumber : '',
    email: user.email ? user.email : '',
  };
};

// User Validation Schema
export const userValidationSchema = yup.object({
  firstName: yup
    .string('Enter your first name.')
    .required('This field is required.')
    .max(30, 'Max length of 30 characters exceeded.'),
  lastName: yup
    .string('Enter your last name.')
    .required('This field is required.')
    .max(30, 'Max length of 30 characters exceeded.'),
  position: yup
    .string('Enter your position.')
    .required('This field is required.')
    .max(30, 'Max length of 30 characters exceeded.'),
  organization: yup
    .string('Enter your organization.')
    .required('This field is required.')
    .max(30, 'Max length of 30 characters exceeded.'),
  phoneNumber: yup
    .string('Enter your phone number.')
    .required('This field is required.')
    .max(30, 'Max length of 30 characters exceeded.'),
});

// Formik Form Generator Object
export const userSchema = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    elem: 'textField',
    required: true,
    style: { marginBottom: '12px', marginRight: '12px' },
  },
  {
    label: 'Last Name',
    name: 'lastName',
    type: 'text',
    elem: 'textField',
    required: true,
    style: { marginBottom: '12px', marginRight: '12px' },
  },
  {
    label: 'Email',
    name: 'email',
    type: 'text',
    elem: 'dummyTextField',
    required: true,
    disabled: true,
    style: {
      width: '350px',
      marginBottom: '12px',
      display: 'flex',
    },
  },
  {
    label: 'Position',
    name: 'position',
    type: 'text',
    elem: 'textField',
    required: true,
    style: {
      width: '350px',
      marginBottom: '12px',
      display: 'flex',
    },
  },
  {
    label: 'Organization',
    name: 'organization',
    type: 'text',
    elem: 'textField',
    required: true,
    style: {
      width: '350px',
      marginBottom: '12px',
      display: 'flex',
    },
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
    type: 'text',
    elem: 'textField',
    required: true,
    style: {
      width: '350px',
      marginBottom: '12px',
      display: 'flex',
    },
  },
];
