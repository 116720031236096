import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';

// Styles
import useStyles from './share-notes-comment.sytles';

// Constants
import {
  MAX_ATTACHMENTS_ISSUE,
  MAX_ATTACHMENT_SIZE,
} from '../../utils/constants';

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const ShareNotesComment = ({ setContent, startAlert }) => {
  const classes = useStyles();
  const { shareId } = useParams();
  const [attachmentsToUpload, setAttachmentsToUpload] = useState(null);
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentObj, setCommentObj] = useState({
    firstName: '',
    lastName: '',
    email: '',
    notes: '',
  });

  const handleClear = () => {
    setCommentObj({
      firstName: '',
      lastName: '',
      email: '',
      notes: '',
    });
    setAttachmentsToUpload(null);
  };

  const handleCommentChange = (e) => {
    setCommentObj({ ...commentObj, [e.target.name]: e.target.value });
  };

  const handleCommentSubmit = async () => {
    setCommentLoading(true);

    try {
      let numFiles = attachmentsToUpload
        ? attachmentsToUpload.length < MAX_ATTACHMENTS_ISSUE
          ? attachmentsToUpload.length
          : MAX_ATTACHMENTS_ISSUE
        : 0;

      let formData = new FormData();
      for (let i = 0; i < numFiles; i++) {
        formData.append('attachments', attachmentsToUpload[i]);
      }
      formData.append('update', JSON.stringify(commentObj));

      const res = await axios.patch(
        `/api/v1/shares/public/${shareId}/createNote`,
        formData
      );
      if (res?.data?.data?.docs) {
        setContent(res.data.data.docs);
        startAlert({
          msg: 'Success!',
          alertType: 'success',
        });
      }
    } catch (err) {
      startAlert({
        msg: err.response.data.message,
        alertType: 'error',
      });
    } finally {
      setCommentObj({
        firstName: '',
        lastName: '',
        email: '',
        notes: '',
      });
      setAttachmentsToUpload(null);
      setCommentLoading(false);
    }
  };

  const handleAttachmentChange = (e) => {
    if (e.target.files.length > MAX_ATTACHMENTS_ISSUE) {
      startAlert({
        msg: `Max number of attachments (${MAX_ATTACHMENTS_ISSUE}) exceeded.`,
        alertType: 'error',
      });
      setAttachmentsToUpload(null);
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > MAX_ATTACHMENT_SIZE) {
        startAlert({
          msg: `Max filesize (${
            MAX_ATTACHMENT_SIZE / 1024 / 1024
          }mb) exceeded in one or more files.`,
          alertType: 'error',
        });
        setAttachmentsToUpload(null);
        return;
      }
    }
    setAttachmentsToUpload(e.target.files);
  };

  const disableSubmit = Boolean(
    commentObj.firstName === '' ||
      commentObj.lastName === '' ||
      commentObj.email === '' ||
      !commentObj.email.toLowerCase().match(emailRegex) ||
      commentObj.notes === '' ||
      commentLoading
  );

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Add a comment</h1>
      <TextField
        autoFocus={false}
        margin='dense'
        id='firstName'
        name='firstName'
        label='First Name'
        type='text'
        fullWidth
        disabled={commentLoading}
        variant='outlined'
        color='primary'
        value={commentObj.firstName}
        onChange={handleCommentChange}
      />
      <TextField
        autoFocus={false}
        margin='dense'
        id='lastName'
        name='lastName'
        label='Last Name'
        type='text'
        fullWidth
        disabled={commentLoading}
        variant='outlined'
        color='primary'
        value={commentObj.lastName}
        onChange={handleCommentChange}
      />
      <TextField
        autoFocus={false}
        margin='dense'
        id='email'
        name='email'
        label='Email Address'
        type='email'
        fullWidth
        disabled={commentLoading}
        variant='outlined'
        color='primary'
        value={commentObj.email}
        onChange={handleCommentChange}
      />
      <TextField
        multiline
        minRows={5}
        margin='dense'
        id='notes'
        name='notes'
        label='Comment'
        type='text'
        fullWidth
        disabled={commentLoading}
        variant='outlined'
        value={commentObj.notes}
        onChange={handleCommentChange}
      />
      <FormHelperText className={classes.helperText}>
        Note: all fields are required. Once a comment in made it can only be
        deleted by an official user. To add photos or files have the project
        admin add you as an official user.
      </FormHelperText>
      <input
        className={classes.input}
        id='attachments'
        multiple
        type='file'
        onChange={(e) => handleAttachmentChange(e)}
      />
      <label htmlFor='attachments'>
        <IconButton color='primary' aria-label='attach files' component='span'>
          <Badge
            badgeContent={attachmentsToUpload ? attachmentsToUpload.length : 0}
            color='secondary'
            overlap='circular'>
            <AttachFileIcon />
          </Badge>
        </IconButton>
      </label>
      <Button
        color='primary'
        variant='outlined'
        onClick={handleCommentSubmit}
        disabled={disableSubmit}
        startIcon={
          commentLoading ? (
            <CircularProgress size={20} className={classes.loadingSpinner} />
          ) : null
        }>
        Submit
      </Button>
      <Button
        className={classes.button}
        color='primary'
        variant='outlined'
        onClick={handleClear}>
        Clear
      </Button>
    </div>
  );
};

ShareNotesComment.propTypes = {
  setContent: PropTypes.func.isRequired,
  startAlert: PropTypes.func.isRequired,
};

export default ShareNotesComment;
