import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

// Action Types
import { GET_ALL_SITES_START } from '../types';

// Actions
import { getAllSitesSuccess, getAllSitesFailure } from './site.actions';
import { startAlert } from '../alert/alert.actions';
import { startFetchAction, stopFetchAction } from '../loading/loading.actions';

/**
 * Workers
 */
export function* getAllSites({ payload }) {
  try {
    yield put(startFetchAction(payload));
    const res = yield axios.get(`/api/v1/projects/${payload.projectId}/sites`);
    yield put(getAllSitesSuccess(res.data.data.docs));
    yield put(stopFetchAction({ id: payload.id, status: 'success' }));
  } catch (err) {
    yield put(getAllSitesFailure());
    yield put(stopFetchAction({ id: payload.id, status: 'error' }));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

/**
 * Watchers
 */
export function* onGetAllSitesStart() {
  yield takeLatest(GET_ALL_SITES_START, getAllSites);
}

/**
 * Export Saga
 */
export function* siteSagas() {
  yield all([call(onGetAllSitesStart)]);
}
