import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContainer: {
    width: '100%',
  },
  modalFormContainer: {
    width: '100%',
    marginTop: '18px',
    marginBottom: '48px',
  },
  textHeader: {
    marginTop: 0,
    marginBottom: '18px',
  },
}));

export default useStyles;
