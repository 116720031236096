import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerImage: {
    width: '100%',
    maxHeight: '340px',
    borderLeft: `4px solid ${theme.palette.primary.contrastText}`,
    borderRight: `4px solid ${theme.palette.primary.contrastText}`,
  },
  input: {
    display: 'none',
  },
  container: {
    position: 'relative',
    '& label': {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  iconButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.custom.bg,
    '&:hover': {
      backgroundColor: theme.custom.bg,
    },
  },
}));

export default useStyles;
