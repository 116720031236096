import React from 'react';
import PropTypes from 'prop-types';

// Styles
import useStyles from './feed-breadcrumb.styles';

const FeedBreadcrumb = ({ update }) => {
  const classes = useStyles();
  if (update.type === 'issue')
    return (
      <div className={classes.locationContainer}>
        <div className={classes.crumb}>{update.site.name}</div>
        <span className={classes.arrow}>→</span>
        <div className={classes.crumb}>{update.milestone.name}</div>
        <span className={classes.arrow}>→</span>
        <div className={classes.crumb}>Submission #S2</div>
        <span className={classes.arrow}>→</span>
        <div className={classes.target}>Issue #I2A</div>
      </div>
    );
  if (update.type === 'submission')
    return (
      <div className={classes.locationContainer}>
        <div className={classes.crumb}>{update.site.name}</div>
        <span className={classes.arrow}>→</span>
        <div className={classes.crumb}>{update.milestone.name}</div>
        <span className={classes.arrow}>→</span>
        <div className={classes.target}>Submission #S2</div>
      </div>
    );
  if (update.type === 'milestone')
    return (
      <div className={classes.locationContainer}>
        <div className={classes.crumb}>{update.site.name}</div>
        <span className={classes.arrow}>→</span>
        <div className={classes.target}>{update.milestone.name}</div>
      </div>
    );
  return null;
};

FeedBreadcrumb.propTypes = {
  update: PropTypes.object.isRequired,
};

export default FeedBreadcrumb;
