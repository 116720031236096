import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

//Components
import GanttToolbar from '../gantt-toolbar/gantt-toolbar.component';
import GanttChart from '../gantt-chart/gantt-chart.component';
import GanttPopover from '../gantt-popover/gantt-popover.component';
import GanttOptions from '../gantt-options/gantt-options.component';

// Material UI
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Button from '@material-ui/core/Button';

// Styles
import useStyles from './gantt-container.styles';

// Actions
import {
  updateMilestoneStart,
  addAttachmentMilestoneStart,
  deleteAttachmentMilestoneStart,
  toggleGanttEditMode,
  removeAllMilestoneMods,
  updateManyMilestonesStart,
} from '../../redux/milestone/milestone.actions';
import { startAlert } from '../../redux/alert/alert.actions';
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import {
  selectGanttOverview,
  selectGanttEditMode,
  selectGanttModCount,
  selectGanttMods,
} from '../../redux/milestone/milestone.selectors';
import { selectActiveSiteId } from '../../redux/site/site.selectors';

const GanttContainer = ({
  ganttOverview,
  activeSiteId,
  startAlertDispatch,
  updateMilestoneStartDispatch,
  addAttachmentMilestoneStartDispatch,
  deleteAttachmentMilestoneStartDispatch,
  toggleModalDispatch,
  userId,
  toggleGanttEditModeDispatch,
  removeAllMilestoneModsDispatch,
  updateManyMilestonesStartDispatch,
  editMode,
  modCount,
  mods,
}) => {
  const [showExpand, setShowExpand] = useState(true);
  const [modIds, setModIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverId, setPopoverId] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [showSettingsPopover, setShowSettingsPopover] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { projectId } = useParams();

  useEffect(() => {
    setModIds([]);
    toggleGanttEditModeDispatch(false);
  }, [activeSiteId, toggleGanttEditModeDispatch]);

  const classes = useStyles({
    showExpand: showExpand,
  });

  const [hideCalElements, setHideCalElements] = useState({
    hidePlannedTimeline: false,
    hideYears: false,
    hideMonths: false,
    hideWeeks: true,
    hideProgress: false,
    hideToday: false,
    hideMonth: false,
    hideHighlightRow: true,
  });

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleHideCalendarEl = (el) => {
    setHideCalElements({
      ...hideCalElements,
      [el.currentTarget.id]: !hideCalElements[el.currentTarget.id],
    });
  };

  const handlePopoverToggle = (e, options) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    options === 'settings'
      ? setShowSettingsPopover(!showSettingsPopover)
      : setShowPopover(!showPopover);
    setPopoverId(e.target.id);
  };

  const handlePopoverClose = () => {
    setShowPopover(false);
    setShowSettingsPopover(false);
    setAnchorEl(null);
    setPopoverId(null);
  };

  const handleUpdateMilestone = (obj) => {
    updateMilestoneStartDispatch(obj);
  };

  const undoAllModsAndExitEditmode = () => {
    removeAllMilestoneModsDispatch();
    toggleGanttEditModeDispatch(false);
    setModIds([]);
  };

  const pushAllModsAndExitEditmode = () => {
    const updateArr = Object.keys(mods).map((mId) => mods[mId].updateObj);
    updateManyMilestonesStartDispatch({ projectId, updateArr });
    setModIds([]);
  };

  const handleToggleEditMode = () => {
    if (editMode === true) {
      if (modCount > 0) {
        toggleModalDispatch({
          modalName: 'confirmDialog',
          modalContent: {
            dismissFunc: undoAllModsAndExitEditmode,
            title: 'Exit Without Saving?',
            desc: `${modCount} unsaved changes will be lost. Do you wish to continue?`,
          },
        });
      } else {
        toggleGanttEditModeDispatch(false);
        setModIds([]);
      }
    } else {
      toggleGanttEditModeDispatch(true);
    }
  };

  const handleToggleSave = () => {
    if (editMode === true) {
      if (modCount > 0) {
        toggleModalDispatch({
          modalName: 'confirmDialog',
          modalContent: {
            dismissFunc: pushAllModsAndExitEditmode,
            title: 'Make Changes to Milestones?',
            desc: `${modCount} changes will be pushed to the database. Do you wish to continue?`,
          },
        });
      }
    }
  };

  const handleModIdsToggle = (id) => {
    setModIds((cur) => {
      if (!cur.includes(id)) {
        return [...cur, id];
      } else {
        return cur.filter((el) => el !== id);
      }
    });
  };

  if (
    !ganttOverview ||
    !ganttOverview.hasOwnProperty('ganttArr') ||
    ganttOverview.ganttArr.length === 0
  )
    return null;

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.header}>
          <span>
            <h1 className={classes.title}>Schedule</h1>
          </span>
          <div className={classes.controls}>
            <GanttToolbar
              milestones={ganttOverview.ganttArr}
              activeSiteId={activeSiteId}
              modIds={modIds}
              setModIds={setModIds}
              editMode={editMode}
            />
            <div style={{ display: 'flex' }}>
              <Tooltip title='Edit Mode'>
                <span
                  className={clsx(
                    activeSiteId === '-1' ? classes.hide : classes.editButton
                  )}>
                  <IconButton
                    aria-label='milestone edit mode'
                    onClick={handleToggleEditMode}>
                    <EditIcon
                      className={clsx(
                        classes.edit,
                        editMode ? classes.editMode : null
                      )}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={modCount === 0 ? 'No Changes to Save' : 'Save Changes'}>
                <span
                  className={clsx(
                    activeSiteId === '-1' || !editMode
                      ? classes.hide
                      : classes.editButton,
                    modCount === 0 ? classes.disabled : null
                  )}>
                  <IconButton
                    disabled={modCount === 0}
                    aria-label='milestone save changes'
                    onClick={handleToggleSave}>
                    <SaveIcon
                      className={clsx(
                        classes.saveButton,
                        modCount === 0 ? classes.disabled : null
                      )}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title='Settings'>
                <span style={{ display: 'flex' }}>
                  <IconButton
                    aria-label='milestone edit'
                    onClick={(e) => handlePopoverToggle(e, 'settings')}>
                    <SettingsIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </Grid>
      <GanttChart
        ganttOverview={ganttOverview}
        activeSiteId={activeSiteId}
        editMode={editMode}
        hideCalElements={hideCalElements}
        handlePopoverToggle={handlePopoverToggle}
        handleModIdsToggle={handleModIdsToggle}
        modIds={modIds}
        expanded={expanded}
        setShowExpand={setShowExpand}
      />
      <div className={classes.expandButton}>
        <Button
          onClick={handleExpand}
          variant='outlined'
          color='default'
          startIcon={
            expanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />
          }>
          {expanded ? 'Shrink Gantt' : 'Expand Gantt'}
        </Button>
      </div>
      <GanttPopover
        showPopover={showPopover}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        handleUpdateMilestone={handleUpdateMilestone}
        popoverId={popoverId}
        milestoneEl={ganttOverview.ganttArr.find((el) => el._id === popoverId)}
        startAlertDispatch={startAlertDispatch}
        addAttachmentMilestoneStartDispatch={
          addAttachmentMilestoneStartDispatch
        }
        deleteAttachmentMilestoneStartDispatch={
          deleteAttachmentMilestoneStartDispatch
        }
        currentUserId={userId}
        toggleModal={toggleModalDispatch}
      />
      <GanttOptions
        showPopover={showSettingsPopover}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        hideCalElements={hideCalElements}
        handleHideCalendarEl={handleHideCalendarEl}
      />
    </>
  );
};

GanttContainer.propType = {
  ganttOverview: PropTypes.object.isRequired,
  activeSiteId: PropTypes.string.isRequired,
  updateMilestoneStartDispatch: PropTypes.func.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
  addAttachmentMilestoneStartDispatch: PropTypes.func.isRequired,
  deleteAttachmentMilestoneStartDispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  removeMilestoneModsDispatch: PropTypes.func.isRequired,
  removeAllMilestoneModsDispatch: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  modCount: PropTypes.number.isRequired,
  mods: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  ganttOverview: selectGanttOverview,
  activeSiteId: selectActiveSiteId,
  editMode: selectGanttEditMode,
  modCount: selectGanttModCount,
  mods: selectGanttMods,
});

const mapDispatchToProps = (dispatch) => ({
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
  updateMilestoneStartDispatch: (milestoneObj) =>
    dispatch(updateMilestoneStart(milestoneObj)),
  addAttachmentMilestoneStartDispatch: (attachmentObj) =>
    dispatch(addAttachmentMilestoneStart(attachmentObj)),
  deleteAttachmentMilestoneStartDispatch: (attachmentObj) =>
    dispatch(deleteAttachmentMilestoneStart(attachmentObj)),
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  toggleGanttEditModeDispatch: (val) => dispatch(toggleGanttEditMode(val)),
  removeAllMilestoneModsDispatch: () => dispatch(removeAllMilestoneMods()),
  updateManyMilestonesStartDispatch: (updateObj) =>
    dispatch(updateManyMilestonesStart(updateObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GanttContainer);
