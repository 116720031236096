import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Components
import UpdateCard from '../update-card/update-card.component';
import ModalForm from '../modal-form/modal-form.component';
import UpdatesFilter from '../updates-filters/updates-filters.component';

// Styles
import useStyles from './notes-fullwidth.styles';

// Utilities
import { sortDate } from '../../utils/utilityFns';

const NotesFullwidth = forwardRef(
  (
    {
      updates,
      title,
      userId,
      docId,
      toggleModalDispatch,
      handleDeleteUpdate,
      updateSeenStartDispatch,
      updateStartDispatch,
      filters,
      setUpdateFiltersDispatch,
      jumpToId,
      setJumpToReady,
    },
    ref
  ) => {
    const classes = useStyles();
    const { projectId } = useParams();

    const idxUpdateFinal = updates.length - 1;

    const formObj = {
      actionType: 'Updated',
      categorization: updates[idxUpdateFinal]?.categorization,
      id: docId,
      noAutoFocus: true,
    };

    useEffect(() => {
      console.log('Loaded');
      if (setJumpToReady) {
        setJumpToReady(true);
      }
    }, [setJumpToReady]);

    useEffect(() => {
      if (
        docId &&
        updates.reduce(
          (prev, acc) =>
            !acc.seenBy.includes(userId) ? (prev = prev + 1) : prev,
          0
        ) > 0
      ) {
        updateSeenStartDispatch({ docId, projectId });
      }
    }, [projectId, docId, updates, updateSeenStartDispatch, userId]);

    const handleSubmit = () => {};
    const handleClose = () => {};

    const filteredUpdates = updates
      .filter((el) => filters[el.actionType])
      .sort(sortDate('date', 'asc'));

    const numHidden = updates.reduce(
      (acc, cur) => (filters[cur.actionType] ? acc : acc + 1),
      0
    );

    return (
      <>
        <div className={classes.header}>
          <h1 className={classes.textHeader}>{title}</h1>
          <UpdatesFilter
            filters={filters}
            setUpdateFiltersDispatch={setUpdateFiltersDispatch}
          />
        </div>
        <div className={classes.root}>
          <div className={classes.cardContainer}>
            {filteredUpdates.map((el) => {
              return (
                <React.Fragment key={`jp-${el._id}`}>
                  {el._id === jumpToId && <span ref={ref} />}
                  <UpdateCard
                    key={el._id}
                    actionType={el.actionType}
                    categorization={el.categorization}
                    date={new Date(el.createdAt)}
                    user={el.createdBy}
                    currentUserId={userId}
                    content={el.content}
                    docId={docId}
                    contentId={el._id}
                    toggleModal={toggleModalDispatch}
                    handleDeleteUpdate={handleDeleteUpdate}
                    fullWidth={true}
                    highlighted={el._id === jumpToId}
                  />
                </React.Fragment>
              );
            })}
            {numHidden > 0 ? (
              <div
                className={
                  classes.numHidden
                }>{`${numHidden} notes hidden via filters.`}</div>
            ) : null}
          </div>
          <div className={classes.modalFormContainer}>
            <ModalForm
              updateStartDispatch={updateStartDispatch}
              content={formObj}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              color='primary'
              fullWidth={true}
            />
          </div>
        </div>
      </>
    );
  }
);

NotesFullwidth.propTypes = {
  title: PropTypes.string.isRequired,
  updates: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  docId: PropTypes.string.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  handleDeleteUpdate: PropTypes.func.isRequired,
  updateSeenStartDispatch: PropTypes.func.isRequired,
  updateStartDispatch: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setUpdateFiltersDispatch: PropTypes.func.isRequired,
};

export default NotesFullwidth;
