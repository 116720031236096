// Utilities
import { collator } from './utilityFns';

const CSV_FILE_TYPE = 'text/csv;charset=utf-8;';

const JSON_FILE_TYPE = 'text/json;charset=utf-8;';

const BYTE_ORDER_MARK = '\ufeff';

const DELIMITER = ',';

const ISSUE_HEADER_KEYS = [
  '_id',
  'project',
  'site._id',
  'site.name',
  'milestone._id',
  'milestone.name',
  'milestone.description',
  'submission',
  'submittedFromLat',
  'submittedFromLng',
  'createdAt',
  'createdBy._id',
  'createdBy.firstName',
  'createdBy.lastName',
  'createdBy.email',
  'createdBy.photo',
  'question._id',
  'question.name',
  'question.questionText',
  'question.errorName',
  'question.errorText',
  'currentCategorization',
  'isResolved',
  'isDeleted',
  'isShared',
];

const SUBMISSION_HEADER_KEYS = [
  '_id',
  'project',
  'site._id',
  'site.name',
  'milestone._id',
  'milestone.name',
  'milestone.description',
  'submittedFromLat',
  'submittedFromLng',
  'createdAt',
  'createdBy._id',
  'createdBy.firstName',
  'createdBy.lastName',
  'createdBy.email',
  'createdBy.photo',
  'isDeleted',
  'isShared',
  'issueCount',
];

const MILESTONE_MSPROJECT_HEADER_KEYS = [
  '_id',
  'actualStatus',
  'taskId',
  'dateActualStart',
  'dateActualEnd',
];

const createFilename = (filename, exportType) => {
  const name = filename ? filename : `export-${new Date().getTime()}`;
  if (exportType === 'csv') return name + '.csv';
  if (exportType === 'json') return name + '.json';
};

const dataArrToStringHeaders = (arr) => {
  let dataHeaders = [];
  arr.forEach((arrEl) => {
    let objDataHeaders = arrEl.data.map((el, idx) => {
      const questionStrArr = [
        `data.${idx}.question.questionText`,
        `data.${idx}.answers.0`,
        `data.${idx}.flagCode`,
      ];
      const photoStrArr = el.photos.map(
        (el, jdx) => `data.${idx}.photos.${jdx}.url`
      );
      return [...questionStrArr, ...photoStrArr];
    });
    const newHeaders = objDataHeaders
      .flat()
      .filter((el) => !dataHeaders.includes(el));
    dataHeaders = [...dataHeaders, ...newHeaders];
  });
  return dataHeaders.sort(collator.compare);
};

const dotObj = (obj, str) =>
  str
    .split('.')
    .reduce((o, key) => (o[key] !== undefined ? o[key] : 'NULL'), obj);

const csvGenerateRow = (rowObj, headerKeys, DELIMITER) =>
  headerKeys
    .map((fieldName) =>
      typeof rowObj[fieldName] === 'number' ||
      typeof rowObj[fieldName] === 'boolean'
        ? rowObj[fieldName]
        : `"${String(dotObj(rowObj, fieldName)).replace(/"/g, '""')}"`
    )
    .join(DELIMITER);

const csvGenerate = (objArr, headerKeys, DELIMITER) => {
  const csv = objArr.map((row) => csvGenerateRow(row, headerKeys, DELIMITER));
  csv.unshift(headerKeys.join(DELIMITER));
  return csv.join('\r\n');
};

const createBlobAndDownload = (document, filename, bArr, bObj) => {
  const blob = new Blob(bArr, bObj);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blob);
};

export const exportToCSVDownload = (arr, type, filename, document) => {
  let headerKeys = [];
  const file = createFilename(filename, 'csv');

  if (type === 'issue') {
    headerKeys = ISSUE_HEADER_KEYS;
  } else if (type === 'submission') {
    const dataHeaders = dataArrToStringHeaders(arr);
    headerKeys = [...SUBMISSION_HEADER_KEYS, ...dataHeaders];
  } else if (type === 'milestoneMsProject') {
    headerKeys = MILESTONE_MSPROJECT_HEADER_KEYS;
  }
  const csvAsString = csvGenerate(arr, headerKeys, DELIMITER);
  createBlobAndDownload(document, file, [BYTE_ORDER_MARK, csvAsString], {
    type: CSV_FILE_TYPE,
  });
};

export const exportToJSONDownload = (arr, type, filename, document) => {
  const file = createFilename(filename, 'json');
  const jsonAsString = JSON.stringify(arr);
  createBlobAndDownload(document, file, [jsonAsString], {
    type: JSON_FILE_TYPE,
  });
};
