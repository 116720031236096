import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';

// Styles
import useStyles from './reactions-emoji.styles';

// Constants
import { EMOJI_TYPES as emojis } from '../../utils/constants';

const ReactionsEmoji = ({ reactions, type, hideEmoji = false }) => {
  const classes = useStyles();

  if (!Object.keys(emojis).includes(type)) return null;
  if (!reactions[type] || reactions[type].length <= 0) return null;

  const count =
    reactions[type] && reactions[type].length ? reactions[type].length : 0;

  const tooltipStr = reactions[type].map((el, idx) => (
    <span
      className={classes.tooltipStr}
      key={`${type}-emoji-${idx}`}>{`${el.firstName} ${el.lastName}`}</span>
  ));

  tooltipStr.unshift(emojis[type]);

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
      }}
      title={tooltipStr}>
      <span className={classes.emoji}>
        <span
          className={clsx(
            classes.count,
            count > 1 ? null : classes.hidden
          )}>{`${count}x`}</span>
        {count > 0 ? (
          <span className={clsx(hideEmoji ? classes.hidden : null)}>
            {emojis[type]}
          </span>
        ) : null}
      </span>
    </Tooltip>
  );
};

ReactionsEmoji.propTypes = {
  reactions: PropTypes.object.isRequired,
  type: PropTypes.oneOf([
    'love',
    'clap',
    'fire',
    'thumbsUp',
    'thumbsDown',
    'check',
  ]).isRequired,
  hideEmoji: PropTypes.bool.isRequired,
};

export default ReactionsEmoji;
