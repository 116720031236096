import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import AuthContainer from '../../components/auth-container/auth-container.component';
import AuthNav from '../../components/auth-nav/auth-nav.component';

// Material UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

// Actions
import { forgotPasswordStart } from '../../redux/user/user.actions';

const ForgotPassword = ({ forgotPasswordStartDispatch, history }) => {
  const [userCredentials, setUserCredentials] = useState({
    email: '',
  });

  const { email } = userCredentials;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    forgotPasswordStartDispatch(email, history);
  };

  return (
    <AuthContainer>
      <Typography>
        A reset password link will be sent to the email address entered below.
      </Typography>
      <ValidatorForm onSubmit={handleSubmit}>
        <TextValidator
          variant='outlined'
          color='primary'
          margin='normal'
          validators={['required', 'isEmail']}
          errorMessages={[
            'This field is required.',
            'You must enter a valid email address.',
          ]}
          fullWidth
          id='email'
          label='Email Address'
          name='email'
          autoComplete='email'
          autoFocus
          onChange={handleChange}
          value={email}
        />
        <Box mt={3} mb={2}>
          <Button type='submit' variant='contained' color='primary' fullWidth>
            Reset Password
          </Button>
        </Box>
      </ValidatorForm>
      <AuthNav />
    </AuthContainer>
  );
};

ForgotPassword.propTypes = {
  forgotPasswordStartDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  forgotPasswordStartDispatch: (email) =>
    dispatch(forgotPasswordStart({ email, ownProps })),
});

export default connect(null, mapDispatchToProps)(ForgotPassword);
