import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-leaflet';

// Styles
import useStyles from './map-marker-popup.styles';

const MapMarkerPopup = ({ name, region, percentComplete }) => {
  const classes = useStyles();
  return (
    <>
      <Popup closeButton={false} autoClose={false} closeOnClick={false}>
        <div className={classes.root}>
          <span style={{ fontSize: '1rem', fontWeight: 600 }}>{name}</span>
          <br />
          <strong>Region:&nbsp;</strong>
          {region}
          <br />
          <strong>Milestones Met:&nbsp;</strong>
          {`${percentComplete * 100}%`}
        </div>
      </Popup>
    </>
  );
};

MapMarkerPopup.propTypes = {
  name: PropTypes.string,
  region: PropTypes.string,
  percentComplete: PropTypes.number,
};

export default MapMarkerPopup;
