export const addDaysToDate = (date, daysToAdd) => {
  const newDateMS = date.setDate(date.getDate() + daysToAdd);
  return new Date(newDateMS);
};

export const adjustGanttDates = (milestone, state, daysToAdd) => {
  const ongoing =
    milestone.actualStatus === 'Off Track' ||
    milestone.actualStatus === 'Ongoing';
  if (typeof daysToAdd === 'number') {
    let datePlannedStart = new Date(milestone.datePlannedStart);
    let datePlannedEnd = new Date(milestone.datePlannedEnd);
    let dateActualStart = new Date(milestone.dateActualStart);
    let dateActualEnd = new Date(milestone.dateActualEnd);
    let exitFlag = false;

    if (state.plannedCheck && state.actualCheck) {
      if (state.startCheck && state.endCheck) {
        datePlannedStart = addDaysToDate(datePlannedStart, daysToAdd);
        datePlannedEnd = addDaysToDate(datePlannedEnd, daysToAdd);
        dateActualStart = addDaysToDate(dateActualStart, daysToAdd);
        dateActualEnd = ongoing
          ? dateActualEnd
          : addDaysToDate(dateActualEnd, daysToAdd);
      } else if (state.startCheck && !state.endCheck) {
        datePlannedStart = addDaysToDate(datePlannedStart, daysToAdd);
        dateActualStart = addDaysToDate(dateActualStart, daysToAdd);
      } else if (state.endCheck && !state.startCheck) {
        datePlannedEnd = addDaysToDate(datePlannedEnd, daysToAdd);
        dateActualEnd = ongoing
          ? dateActualEnd
          : addDaysToDate(dateActualEnd, daysToAdd);
      } else {
        exitFlag = true;
      }
    } else if (state.plannedCheck && !state.actualCheck) {
      if (state.startCheck && state.endCheck) {
        datePlannedStart = addDaysToDate(datePlannedStart, daysToAdd);
        datePlannedEnd = addDaysToDate(datePlannedEnd, daysToAdd);
      } else if (state.startCheck && !state.endCheck) {
        datePlannedStart = addDaysToDate(datePlannedStart, daysToAdd);
      } else if (state.endCheck && !state.startCheck) {
        datePlannedEnd = addDaysToDate(datePlannedEnd, daysToAdd);
      } else {
        exitFlag = true;
      }
    } else if (state.actualCheck && !state.plannedCheck) {
      if (state.startCheck && state.endCheck) {
        dateActualStart = addDaysToDate(dateActualStart, daysToAdd);
        dateActualEnd = ongoing
          ? dateActualEnd
          : addDaysToDate(dateActualEnd, daysToAdd);
      } else if (state.startCheck && !state.endCheck) {
        dateActualStart = addDaysToDate(dateActualStart, daysToAdd);
      } else if (state.endCheck && !state.startCheck) {
        dateActualEnd = ongoing
          ? dateActualEnd
          : addDaysToDate(dateActualEnd, daysToAdd);
      } else {
        exitFlag = true;
      }
    } else {
      exitFlag = true;
    }

    if (
      datePlannedStart <= datePlannedEnd &&
      dateActualStart <= dateActualEnd &&
      !exitFlag
    ) {
      return {
        datePlannedStart: datePlannedStart.toISOString(), // Should we remove the time component?
        datePlannedEnd: datePlannedEnd.toISOString(),
        dateActualStart: dateActualStart.toISOString(),
        dateActualEnd: dateActualEnd.toISOString(),
      };
    }
    return false;
  }
  return false;
};
