import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';

// Components
import TableColgroup from '../../components/table-colgroup/table-colgroup.component';
import SubmissionActionButtons from '../submission-action-buttons/submission-action-buttons.component';

// Styles
import useStyles from './submission-table.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectSubmissionsNotDeleted } from '../../redux/submission/submission.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';

const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

const dataToPhotos = (data) => {
  return data.map((el) => el.photos).flat();
};

const SubmissionTable = ({ submissions, toggleModalDispatch, user }) => {
  const classes = useStyles();
  const { projectId } = useParams();

  return (
    <Grid item xs={12}>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableColgroup cols={[14, 12, 16, 16, 6, 10, 24]} />
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell>Submitted By</TableCell>
              <TableCell>Date Recieved</TableCell>
              <TableCell>Site</TableCell>
              <TableCell>Milestone</TableCell>
              <TableCell align='center'>Issue Count</TableCell>
              <TableCell align='center'>Photos</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {submissions.map((submission) => (
              <TableRow key={submission._id} className={classes.tableRow}>
                <TableCell>{`${submission.createdBy.firstName} ${submission.createdBy.lastName}`}</TableCell>
                <TableCell>
                  {new Date(submission.createdAt).toLocaleString(
                    'en-US',
                    dateOptions
                  )}
                </TableCell>
                <TableCell>{submission.site.name}</TableCell>
                <TableCell>{submission.milestone.name}</TableCell>
                <TableCell align='center' className={classes.issueCount}>
                  {submission.issueCount > 0 ? (
                    <RouterLink
                      className={classes.link}
                      to={`/projects/${projectId}/issues?submission=${submission._id}`}>
                      {submission.issueCount}
                    </RouterLink>
                  ) : (
                    0
                  )}
                </TableCell>
                <TableCell>
                  <div className={classes.photoCell}>
                    <Avatar
                      src={dataToPhotos(submission.data)[0]?.url}
                      variant='rounded'
                      className={classes.large}
                      onClick={() =>
                        toggleModalDispatch({
                          modalName: 'photoGallery',
                          modalContent: {
                            photos: dataToPhotos(submission.data),
                          },
                        })
                      }
                    />
                    {dataToPhotos(submission.data).length > 1 && (
                      <span className={classes.photoBadge}>
                        {dataToPhotos(submission.data).length}
                      </span>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <SubmissionActionButtons
                    submission={submission}
                    user={user}
                    toggleModalDispatch={toggleModalDispatch}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

SubmissionTable.propTypes = {
  submissions: PropTypes.array,
  toggleModalDispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  submissions: selectSubmissionsNotDeleted,
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionTable);
