import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  updateDate: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  updateDateShort: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  datetimeContainer: {
    fontSize: '0.85rem',
    color: '#555',
    lineHeight: 1.1,
  },
  hidden: {
    display: 'none',
  },
}));

export default useStyles;
