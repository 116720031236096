import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import clsx from 'clsx';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Snackbar from '@material-ui/core/Snackbar';

// Styles
import useStyles from './share-delete-modal.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectModalShareDelete } from '../../redux/ui/ui.selectors';

const ShareDeleteModal = ({
  shareDelete: { isOpen, content },
  toggleModalDispatch,
}) => {
  const classes = useStyles();
  const [snackOpen, setSnackOpen] = useState(false);

  const handleClickCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackOpen(true);
  };

  const handleCloseCopy = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const handleClose = () => {
    toggleModalDispatch({ modalName: 'shareDelete', modalContent: {} });
  };

  const handleSubmit = () => {
    content.dismissFunc();
    toggleModalDispatch({ modalName: 'shareDelete', modalContent: {} });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        transitionDuration={{ appear: 225, enter: 225, exit: 0 }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent>
          <div className={classes.stopSharing}>
            This Content is Currently Being Shared
          </div>
          <DialogContentText id='alert-dialog-description' component='div'>
            Anyone with this shared link will be able to view this content.
            Please ensure it does not contain sensitive information.
          </DialogContentText>
          {content.shareUrl ? (
            <div>
              <span className={classes.shareHeader}>
                <strong>Sharing Link</strong>
              </span>
              <div className={classes.shareUrlContainer}>
                <div className={classes.shareUrl}>
                  {content.shareUrl.replace(/(^\w+:|^)\/\//, '')}
                </div>
                <div className={classes.shareIconContainer}>
                  <Tooltip title='Click to copy link'>
                    <FileCopyOutlinedIcon
                      fontSize='small'
                      className={classes.shareCopy}
                      onClick={() => handleClickCopy(content.shareUrl)}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          ) : null}
          {content.share ? (
            <div className={classes.sharePermissions}>
              <span className={classes.shareHeader}>
                <strong>Share Link Permissions</strong>
              </span>
              <div className={classes.options}>
                <div className={classes.optionContainer}>
                  <span className={classes.optionTitle}>Exporting</span>
                  <span
                    className={clsx(
                      classes.pill,
                      content.share.canExport
                        ? classes.enabled
                        : classes.disabled
                    )}>
                    {content.share.canExport ? 'Enabled' : 'Disabled'}
                  </span>
                </div>
                <div className={classes.optionContainer}>
                  <span className={classes.optionTitle}>Comment</span>
                  <span
                    className={clsx(
                      classes.pill,
                      content.share.canComment
                        ? classes.enabled
                        : classes.disabled
                    )}>
                    {content.share.canComment ? 'Enabled' : 'Disabled'}
                  </span>
                </div>
                <div className={classes.optionContainer}>
                  <span className={classes.optionTitle}>Show History</span>
                  <span
                    className={clsx(
                      classes.pill,
                      content.share.showUpdates
                        ? classes.enabled
                        : classes.disabled
                    )}>
                    {content.share.showUpdates ? 'Enabled' : 'Disabled'}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          <div className={classes.stopSharing}>Stop Sharing this Content?</div>
          <DialogContentText id='alert-dialog-description' component='div'>
            Warning, all people that rely on the shared link to view this
            content will immediately lose access. Do you wish to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            No
          </Button>
          <Button onClick={handleSubmit} color='primary' autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        severity='success'
        onClose={handleCloseCopy}
        open={snackOpen}
        autoHideDuration={2000}
        message='Copied link to clipboard!'
      />
    </>
  );
};

ShareDeleteModal.propTypes = {
  shareDelete: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  shareDelete: selectModalShareDelete,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareDeleteModal);
