import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontSize: '0.9rem',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '12px',
    paddingBottom: '6px',
  },
  fileContainer: {
    display: 'flex',
    gap: '6px',
    backgroundColor: '#58b2ff2b', //'#8fc5f442', //'rgba(0,0,0,0.1)', //'#ff625221', //'rgba(0,0,0,0.03)',
    borderRadius: '6px',
    padding: '6px 6px',
    alignItems: 'center',
    width: '236px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#8fc5f47a', //'rgba(0,0,0,0.15)', // '#ff62523b',
    },
  },
  metaContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  attachText: {
    fontSize: '0.9rem',
    fontWeight: 700,
    color: '#444',
    lineHeight: 1,
    textDecorationLine: 'underline',
    textDecorationColor: '#9e9e9e',
  },
  fileIcon: {
    display: 'flex',
  },
  fileName: {
    fontSize: '0.85rem',
    lineHeight: 1,
    color: '#555',
  },
  filesize: { fontSize: '0.8rem', fontWeight: 500, color: '#444' },
}));

export default useStyles;
