import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.9rem',
    lineHeight: 1.3,
  },
}));

export default useStyles;
