import {
  TOGGLE_DRAWER,
  TOGGLE_MODAL,
  UPDATE_DASHBOARD_MAIN_WIDGET,
  SET_ISSUEBOX_TYPE,
  GET_DASHBOARD_START,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  UPDATE_ISSUE_NOTES_MODAL,
  UPDATE_SUBMISSION_NOTES_MODAL,
  UPDATE_SIDEBAR_MODAL,
  UPDATE_PHOTO_MODAL,
  SET_UPDATE_FILTER_OBJ,
  SET_ISSUEBOX_FILTER_OBJ,
  SET_ISSUEBOX_SORT_OBJ,
} from '../types';

export const getDashboardStart = (projectId) => ({
  type: GET_DASHBOARD_START,
  payload: projectId,
});

export const getDashboardSuccess = (updateObj) => ({
  type: GET_DASHBOARD_SUCCESS,
  payload: updateObj,
});

export const getDashboardFailure = () => ({
  type: GET_DASHBOARD_FAILURE,
});

export const toggleDrawer = () => ({
  type: TOGGLE_DRAWER,
});

export const toggleModal = (modalObj) => ({
  type: TOGGLE_MODAL,
  payload: modalObj,
});

export const updateDashboardMainWidget = (selection) => ({
  type: UPDATE_DASHBOARD_MAIN_WIDGET,
  payload: selection,
});

export const setIssueboxType = (selection) => ({
  type: SET_ISSUEBOX_TYPE,
  payload: selection,
});

export const setUpdateFilters = (filterObj) => ({
  type: SET_UPDATE_FILTER_OBJ,
  payload: filterObj,
});

export const setIssueboxFilters = (filterObj) => ({
  type: SET_ISSUEBOX_FILTER_OBJ,
  payload: filterObj,
});

export const setIssueboxSort = (sortObj) => ({
  type: SET_ISSUEBOX_SORT_OBJ,
  payload: sortObj,
});

export const updateIssueNotesModal = (updateArr) => ({
  type: UPDATE_ISSUE_NOTES_MODAL,
  payload: updateArr,
});

export const updateSubmissionNotesModal = (updateArr) => ({
  type: UPDATE_SUBMISSION_NOTES_MODAL,
  payload: updateArr,
});

export const updateSidebarModal = (updateArr) => ({
  type: UPDATE_SIDEBAR_MODAL,
  payload: updateArr,
});

export const updatePhotoModal = (updateArr) => ({
  type: UPDATE_PHOTO_MODAL,
  payload: updateArr,
});
