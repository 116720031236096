import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-rounded': {
      padding: '32px 24px 16px 24px',
      minWidth: '420px',
      maxWidth: '680px',
    },
  },
  update: {
    pointerEvents: 'none',
  },
  container: {
    padding: 0,
  },
  content: {
    padding: 0,
  },
  textField: {
    borderRadius: 0,
    '& .MuiInputBase-root': {
      borderRadius: '4px',
    },
  },
  buttonGroup: {
    paddingRight: '0px',
  },
  input: {
    display: 'none',
  },
});

export default useStyles;
