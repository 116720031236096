import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heartIcon: {
    transform: 'translateY(4px)',
    marginRight: theme.spacing(1),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  toolTip: {
    borderBottom: '1px dotted #000',
    cursor: 'pointer',
  },
}));

export default useStyles;
