import React from 'react';
import PropTypes from 'prop-types';

// Components
import FileIcon from '../file-icon/file-icon.component';

// Material UI
import SvgIcon from '@material-ui/core/SvgIcon';

// Styles
import defaultStyles from './svg-file-icon.styles';

const SvgFileIcon = ({ iconSize, ext }) => {
  return (
    <SvgIcon fontSize={iconSize}>
      {defaultStyles.hasOwnProperty(ext) ? (
        <FileIcon extension={ext} {...defaultStyles[ext]} />
      ) : (
        <FileIcon extension={ext} />
      )}
    </SvgIcon>
  );
};

SvgFileIcon.propTypes = {
  iconSize: PropTypes.oneOf(['inherit', 'small', 'default', 'large']),
  ext: PropTypes.string.isRequired,
};

SvgFileIcon.defaultProps = {
  iconSize: 'default',
};

export default SvgFileIcon;
