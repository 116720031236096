import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducers
import alertReducer from './alert/alert.reducer';
import userReducer from './user/user.reducer';
import projectReducer from './project/project.reducer';
import siteReducer from './site/site.reducer';
import milestoneReducer from './milestone/milestone.reducer';
import issueReducer from './issue/issue.reducer';
import submissionReducer from './submission/submission.reducer';
import uiReducer from './ui/ui.reducer';
import loadingReducer from './loading/loading.reducer';
import feedReducer from './feed/feed.reducer';

const uiPersistConfig = {
  key: 'ui',
  storage: storage,
  whitelist: [
    'drawerOpen',
    'issuebox',
    'updateFilters',
    'issueboxFilters',
    'issueboxSort',
  ],
};

const rootReducer = combineReducers({
  alert: alertReducer,
  user: userReducer,
  project: projectReducer,
  site: siteReducer,
  milestone: milestoneReducer,
  issue: issueReducer,
  submission: submissionReducer,
  ui: persistReducer(uiPersistConfig, uiReducer),
  loading: loadingReducer,
  feed: feedReducer,
});

export default rootReducer; //persistReducer(persistConfig, rootReducer); FIXME: If we want to have root config see https://github.com/rt2zz/redux-persist#nested-persists
