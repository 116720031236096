import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Grid from '@material-ui/core/Grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

// Components
import IssueboxSection from '../issuebox-section/issuebox-section.component';
import TableHeader from '../table-header/table-header.component';
import HeaderGeneral from '../../components/header-general/header-general.component';

// Styles
import useStyles from './issuebox.styles';

// Selectors
import { selectActiveSiteIssues } from '../../redux/issue/issue.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import {
  selectIssueboxFilters,
  selectIssueboxSort,
} from '../../redux/ui/ui.selectors';

// Actions
import {
  toggleModal,
  setIssueboxFilters,
  setIssueboxSort,
} from '../../redux/ui/ui.actions';

export const IssueBox = ({
  title,
  issues,
  toggleModalDispatch,
  user,
  filters,
  setIssueboxFiltersDispatch,
  sortObj,
  setIssueboxSortDispatch,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSwitch = (_, selection) => {
    setIssueboxFiltersDispatch({ [selection]: !filters[selection] });
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (e) => {
    switch (e.target.value) {
      case 'createdByasc':
        setIssueboxSortDispatch({ vector: 'createdBy', order: 'asc' });
        break;
      case 'createdBydesc':
        setIssueboxSortDispatch({ vector: 'createdBy', order: 'desc' });
        break;
      case 'currentCategorizationasc':
        setIssueboxSortDispatch({
          vector: 'currentCategorization',
          order: 'asc',
        });
        break;
      case 'currentCategorizationdesc':
        setIssueboxSortDispatch({
          vector: 'currentCategorization',
          order: 'desc',
        });
        break;
      default:
        break;
    }
  };

  const open = Boolean(anchorEl);

  const filteredIssues = issues.filter(
    (el) => filters[el.currentCategorization]
  );

  const numHidden = issues.reduce(
    (acc, cur) => (filters[cur.currentCategorization] ? acc : acc + 1),
    0
  );

  const subheading =
    numHidden > 0 ? `${numHidden} issues hidden via filters.` : false;

  //console.log(sortObj);

  return (
    <>
      <Grid item xs={12}>
        <HeaderGeneral extraSpacing={true} title={'Unresolved Issues'}>
          <Tooltip title='Filters'>
            <IconButton aria-label='hide options' onClick={handleClick}>
              {anchorEl ? <MoreVertIcon color='primary' /> : <MoreHorizIcon />}
            </IconButton>
          </Tooltip>
        </HeaderGeneral>
        {numHidden > 0 ? (
          <div className={classes.numHidden}>{subheading}</div>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <IssueboxSection
          issues={filteredIssues}
          toggleModal={toggleModalDispatch}
          lastOfType={true}
          user={user}
        />
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.issueboxPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <List
          component='nav'
          className={classes.issueboxPopupItems}
          subheader={<ListSubheader>Select Issues to Display</ListSubheader>}>
          <ListItem>
            <ListItemText primary='Uncategorized' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                onChange={(e) => handleSwitch(e, 0)}
                checked={filters[0]}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary='Low' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                onChange={(e) => handleSwitch(e, 1)}
                checked={filters[1]}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary='Medium' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                onChange={(e) => handleSwitch(e, 2)}
                checked={filters[2]}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary='High' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                onChange={(e) => handleSwitch(e, 3)}
                checked={filters[3]}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary='Off Track' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                onChange={(e) => handleSwitch(e, 4)}
                checked={filters[4]}
              />
            </ListItemSecondaryAction>
          </ListItem>
          {/* <ListSubheader>Sorting Options</ListSubheader>
          <FormControl variant='standard' className={classes.formControl}>
            <Select
              native
              value={`${sortObj.vector}${sortObj.order}`}
              onChange={handleSortChange}
              label='Sort By'
              inputProps={{
                name: 'sort',
                id: 'sort-options',
              }}>
              <option value={'createdByasc'}>Date (Old to New)</option>
              <option value={'createdBydesc'}>Date (New to Old)</option>
              <option value={'currentCategorizationasc'}>
                Severity (Low to High)
              </option>
              <option value={'currentCategorizationdesc'}>
                Severity (High to Low)
              </option>
            </Select>
          </FormControl> */}
        </List>
      </Popover>
    </>
  );
};

IssueBox.propTypes = {
  title: PropTypes.string,
  issues: PropTypes.array.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  issues: selectActiveSiteIssues,
  user: selectCurrentUser,
  filters: selectIssueboxFilters,
  sortObj: selectIssueboxSort,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  setIssueboxFiltersDispatch: (filterObj) =>
    dispatch(setIssueboxFilters(filterObj)),
  setIssueboxSortDispatch: (sortObj) => dispatch(setIssueboxSort(sortObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueBox);
