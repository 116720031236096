import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Styles
import useStyles from './code-entry.styles';

const CodeEntry = () => {
  const classes = useStyles();

  const [pCode, setPCode] = useState(Array(6).fill(''));
  const inputRefs = useRef([]);
  const history = useHistory();

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleChange = (e, idx) => {
    const value = e.target.value;
    if (/^[0-9a-zA-Z]$/.test(value)) {
      // Only allow alphanumeric characters
      const newPcode = [...pCode];
      newPcode[idx] = value;
      setPCode(newPcode);

      // Move to the next input if available
      if (idx < pCode.length - 1) {
        inputRefs.current[idx + 1].focus();
      }

      // If all digits are filled, navigate to the new page
      if (newPcode.every((digit) => digit !== '')) {
        const p = newPcode.join('');
        history.push(`/code-user?p=${p}`);
      }
    }
  };

  const handleKeyDown = (e, idx) => {
    if (e.key === 'Backspace') {
      const newPCode = [...pCode];
      if (newPCode[idx] === '') {
        // Move to the previous input if available
        if (idx > 0) {
          newPCode[idx - 1] = '';
          setPCode(newPCode);
          inputRefs.current[idx - 1].focus();
        }
      } else {
        newPCode[idx] = '';
        setPCode(newPCode);
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          style={{ fontSize: '3rem', fontWeight: 900, paddingBottom: '12px' }}>
          Enter Project Code
        </div>
        <div className={classes.codeGrid}>
          {pCode.map((data, idx) => (
            <input
              key={idx}
              ref={(el) => (inputRefs.current[idx] = el)}
              className={classes.codeDigit}
              type='text'
              maxLength='1'
              value={data}
              onChange={(e) => handleChange(e, idx)}
              onKeyDown={(e) => handleKeyDown(e, idx)}
            />
          ))}
        </div>
        <div className={classes.help}>
          Your project manager should have provided a code for you.
        </div>
        <div className={classes.inTouch}>
          <a className={classes.link} href='mailto:mike@aol.com'>
            Need a code? Get in touch 📬
          </a>
        </div>
      </div>
    </div>
  );
};

export default CodeEntry;
