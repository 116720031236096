import { createSelector } from 'reselect';

// External Selections
import { selectActiveSiteId } from '../site/site.selectors';

const selectIssueSlice = (state) => state.issue;

export const selectIssues = createSelector(
  [selectIssueSlice],
  (issue) => issue.issues
);

export const selectNumIssues = createSelector(
  [selectIssueSlice],
  (issue) => issue.numIssues
);

export const selectIssueFetchStatus = createSelector(
  [selectIssueSlice],
  (issue) => issue.status
);

export const selectIssuesDeleted = createSelector([selectIssues], (issues) =>
  Object.values(issues).filter((el) => el.isDeleted === true)
);

export const selectIssuesNotDeleted = createSelector([selectIssues], (issues) =>
  Object.values(issues).filter((el) => el.isDeleted === false)
);

export const selectNumIssuesDeleted = createSelector(
  [selectIssuesDeleted],
  (issues) => issues.length
);

export const selectIssuesResolved = createSelector([selectIssues], (issues) =>
  Object.values(issues).filter((el) => el.isResolved === true)
);

export const selectIssuesOutstanding = createSelector(
  [selectIssues],
  (issues) =>
    Object.values(issues).filter(
      (el) => el.isResolved === false && el.isDeleted === false
    )
);

export const selectActiveSiteIssues = createSelector(
  [selectIssuesOutstanding, selectActiveSiteId],
  (issues, siteId) =>
    issues
      .filter((el) => (siteId === '-1' ? el : el.site?._id === siteId))
      .sort((a, b) => b.currentCategorization - a.currentCategorization)
);

export const selectIssuesOutstandingIssues = createSelector(
  [selectIssuesOutstanding, selectActiveSiteId],
  (issues, siteId) =>
    issues
      .filter((el) => (siteId === '-1' ? el : el.site?._id === siteId))
      .filter((el) => el.currentCategorization >= 2)
      .sort((a, b) => b.currentCategorization - a.currentCategorization)
);

export const selectIssuesOutstandingUncategorized = createSelector(
  [selectIssuesOutstanding, selectActiveSiteId],
  (issues, siteId) =>
    issues
      .filter((el) => (siteId === '-1' ? el : el.site?._id === siteId))
      .filter((el) => el.currentCategorization === 0)
);

export const selectIssuesOutstandingUncategorizedAll = createSelector(
  [selectIssuesOutstanding],
  (issues) => issues.filter((el) => el.currentCategorization === 0)
);

export const selectIssuesOutstandingPunchlist = createSelector(
  [selectIssuesOutstanding, selectActiveSiteId],
  (issues, siteId) =>
    issues
      .filter((el) => (siteId === '-1' ? el : el.site?._id === siteId))
      .filter((el) => el.currentCategorization === 1)
);

export const selectSingleIssue = createSelector(
  [selectIssues],
  (issues) => Object.values(issues)[0]
);
