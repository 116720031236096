import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// Action Types
import {
  GET_ALL_PROJECTS_START,
  GET_PROJECT_BY_ID_START,
  ADD_ATTACHMENT_PROJECT_START,
  DELETE_ATTACHMENT_PROJECT_START,
} from '../types';

// Actions
import {
  getAllProjectsSuccess,
  getAllProjectsFailure,
  getProjectByIdSuccess,
  getProjectByIdFailure,
  addAttachmentProjectSuccess,
  addAttachmentProjectFailure,
  deleteAttachmentProjectSuccess,
  deleteAttachmentProjectFailure,
} from './project.actions';
import { startAlert } from '../alert/alert.actions';
import { startFetchAction, stopFetchAction } from '../loading/loading.actions';

/**
 * Workers
 */
export function* getAllProjects() {
  const fetchObj = {
    id: uuidv4(),
    name: 'projects',
    actionType: 'projects',
  };

  try {
    yield put(startFetchAction(fetchObj));
    const res = yield axios.get(`/api/v1/projects`);
    yield put(getAllProjectsSuccess(res.data.data.docs));
    yield put(stopFetchAction({ id: fetchObj.id, status: 'success' }));
  } catch (err) {
    yield put(getAllProjectsFailure());
    yield put(stopFetchAction({ id: fetchObj.id, status: 'error' }));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* getProjectById({ payload }) {
  try {
    yield put(startFetchAction(payload));
    const res = yield axios.get(`/api/v1/projects/${payload.projectId}`);
    yield put(getProjectByIdSuccess(res.data.data.docs));
    yield put(stopFetchAction({ id: payload.id, status: 'success' }));
  } catch (err) {
    yield put(getProjectByIdFailure());
    yield put(stopFetchAction({ id: payload.id, status: 'error' }));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* addAttachment({ payload: { formData, projectId } }) {
  try {
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/addAttachments`,
      formData
    );
    yield put(addAttachmentProjectSuccess(res.data.data.docs));
    yield put(
      startAlert({ msg: 'File successfully uploaded!', alertType: 'success' })
    );
  } catch (err) {
    yield put(addAttachmentProjectFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* deleteAttachment({
  payload: { projectId, attachmentId, embeddedField },
}) {
  try {
    const res = yield axios.delete(
      `/api/v1/projects/${projectId}/embeddedField/${embeddedField}/embeddedId/${attachmentId}`
    );
    yield put(deleteAttachmentProjectSuccess(res.data.data.docs));
    yield put(
      startAlert({ msg: 'File successfully removed!', alertType: 'success' })
    );
  } catch (err) {
    yield put(deleteAttachmentProjectFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

/**
 * Watchers
 */
export function* onGetAllProjectsStart() {
  yield takeLatest(GET_ALL_PROJECTS_START, getAllProjects);
}

export function* onGetProjectByIdStart() {
  yield takeLatest(GET_PROJECT_BY_ID_START, getProjectById);
}

export function* onAddAttachmentProjectStart() {
  yield takeLatest(ADD_ATTACHMENT_PROJECT_START, addAttachment);
}

export function* onDeleteAttachmentProjectStart() {
  yield takeLatest(DELETE_ATTACHMENT_PROJECT_START, deleteAttachment);
}

/**
 * Export Saga
 */
export function* projectSagas() {
  yield all([
    call(onGetAllProjectsStart),
    call(onGetProjectByIdStart),
    call(onAddAttachmentProjectStart),
    call(onDeleteAttachmentProjectStart),
  ]);
}
