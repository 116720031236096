import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

// Styles
import useStyles from './update-main-photo.styles';

// Constants
import { MAX_ATTACHMENT_SIZE } from '../../../utils/constants';

export const UpdateMainPhoto = ({ current, startAlert, updatePhoto }) => {
  const classes = useStyles();

  const handleAttachmentChange = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    if (e.target.files[0].size > MAX_ATTACHMENT_SIZE) {
      startAlert({
        msg: `Max image filesize (${MAX_ATTACHMENT_SIZE / 1024 / 1024}mb) exceeded.`,
        alertType: 'error',
      });
      return;
    }
    let formData = new FormData();
    formData.append('image', e.target.files[0]);
    updatePhoto(formData);
  };

  return (
    <>
      <Avatar
        alt='Photo of current image.'
        variant='square'
        src={current}
        className={classes.avatar}
      />
      <input
        className={classes.input}
        accept='image/*'
        id='attach-profile-photo'
        type='file'
        onChange={(e) => handleAttachmentChange(e)}
      />
      <label htmlFor='attach-profile-photo'>
        <Button
          variant='outlined'
          size='small'
          startIcon={<PhotoCameraIcon />}
          component='span'>
          Update Photo
        </Button>
      </label>
    </>
  );
};

UpdateMainPhoto.propTypes = {
  current: PropTypes.string.isRequired,
  startAlert: PropTypes.func.isRequired,
  updatePhoto: PropTypes.func.isRequired,
};

export default UpdateMainPhoto;
