import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Styles
import useStyles from './gantt-row-actual.styles';

const GanttRowActual = ({
  el: {
    _id,
    actualStatus,
    actualTimelineGrid,
    type,
    actualStartStr,
    actualEndStr,
  },
  editMode,
}) => {
  const classes = useStyles({
    actualTimelineGrid,
    type,
    actualStatus,
  });

  const styleStatus = actualStatus.toLowerCase().replace(/\s/g, '');
  const addStripes =
    actualStatus === 'Ongoing' ||
    actualStatus === 'Stopped' ||
    actualStatus === 'Off Track';

  return (
    <div className={classes.actualTimelineWrapper}>
      <div className={clsx(classes.gapStart, editMode ? null : classes.hide)}>
        <span className={clsx(classes.dateStart, classes.date, styleStatus)}>
          {actualStartStr}
        </span>
      </div>
      <div
        id={_id}
        className={clsx(
          classes.actualTimeline,
          styleStatus,
          type === 'site' ? classes.siteHeight : null,
          addStripes ? classes.striped : null
        )}></div>
      <div className={clsx(classes.gapEnd, editMode ? null : classes.hide)}>
        <span className={clsx(classes.dateEnd, classes.date, styleStatus)}>
          {actualEndStr}
        </span>
      </div>
    </div>
  );
};

GanttRowActual.propTypes = {
  el: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
};

export default GanttRowActual;
