import { takeEvery, call, all, put, delay } from 'redux-saga/effects';
import { START_ALERT } from '../types';
import { setAlert, removeAlert } from './alert.actions';

/**
 * Workers
 */
export function* alert({ payload: alertObj }) {
  const { alertDuration, alertId } = alertObj;
  yield put(setAlert(alertObj));
  yield delay(alertDuration);
  yield put(removeAlert(alertId));
}

/**
 * Watchers
 */
export function* onStartAlert() {
  yield takeEvery(START_ALERT, alert);
}

/**
 * Export Saga
 */
export function* alertSagas() {
  yield all([call(onStartAlert)]);
}
