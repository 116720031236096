import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: '8px',
    fontSize: '0.95rem',
  },
  tooltipOverride: {
    borderRadius: '4px',
  },
  addIconContainer: {
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    '&::before': {
      width: '10px',
      height: '10px',
      content: `'+'`,
      color: '#666',
      position: 'absolute',
      top: '-5px',
      left: '-3px',
      fontSize: '0.7rem',
      fontWeight: 500,
    },
  },
  addIcon: {
    color: '#666',
    width: '1em',
    height: '1em',
    fontSize: '1.4rem',
    transform: 'translateY(-2px)',
    '&:hover': {
      color: '#464646',
    },
  },
}));

export default useStyles;
