import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& button': {
      padding: '6px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  extraSpacing: {
    marginTop: '20px',
  },
  toolbarStart: {
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      width: '100%',
    },
  },
  toolbarEnd: {
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  // selectTitle: {
  //   color: '#fff',
  //   fontWeight: 500,
  //   border: '1px dotted rgba(158,158,158,0.22)',
  //   borderRadius: '16px',
  //   padding: '0px 12px',
  //   backgroundColor: theme.palette.primary.darkest,
  //   '& .MuiSelect-icon': {
  //     color: '#fff',
  //     right: '12px',
  //   },
  //   [theme.breakpoints.down('xs')]: {
  //     borderRadius: '0px',
  //     border: 0,
  //     paddingTop: '2px',
  //     paddingBottom: '2px',
  //   },
  // },
  formControl: {
    minWidth: '344px',
    padding: '4px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& div:first-of-type': {
      paddingBottom: 0,
    },
  },
  // select: {
  //   borderTopLeftRadius: 0,
  //   borderTopRightRadius: 0,
  //   borderTop: '1px solid #bbb',
  //   '& ul': {
  //     paddingTop: '4px',
  //     paddingBottom: '4px',
  //   },
  //   '& li': {
  //     color: '#000',
  //     fontSize: '0.9rem',
  //     paddingTop: '4px',
  //     paddingBottom: '4px',
  //     '&:hover': {
  //       backgroundColor: `${theme.custom.issueUncatBg}!important`,
  //     },
  //   },
  // },
}));

export default useStyles;
