import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  emoji: {
    cursor: 'default',
  },
  count: { color: '#888', fontSize: '0.85rem' },
  hidden: {
    display: 'none',
  },
  tooltip: {
    borderRadius: '8px',
  },
  tooltipStr: {
    display: 'block',
  },
}));

export default useStyles;
