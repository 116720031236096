import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';

// Material UI
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import InboxIcon from '@material-ui/icons/Inbox';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// Components
import MapSingle from '../../components/map-single/map-single.component';
import Spinner from '../../components/spinner/spinner.component';
import PhotoModal from '../../components/photo-modal/photo-modal.component';
import ExportModal from '../../components/export-modal/export-modal.component';
import ShareNotesFullwidth from '../../components/share-notes-fullwidth/share-notes-fullwidth.component';
import Copyright from '../../components/copyright/copyright.component';

// Styles
import useStyles from './submission-share.styles';

// Selectors
import { selectUpdateFilters } from '../../redux/ui/ui.selectors';

// Actions
import { startAlert } from '../../redux/alert/alert.actions';
import { toggleModal, setUpdateFilters } from '../../redux/ui/ui.actions';

const dateOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

const SubmissionShare = ({
  toggleModalDispatch,
  startAlertDispatch,
  setUpdateFiltersDispatch,
  filters,
}) => {
  const classes = useStyles();
  const { shareId } = useParams();
  const [submission, setSubmission] = useState(null);

  useEffect(() => {
    if (shareId) {
      async function fetchSubmission() {
        try {
          const res = await axios.get(`/api/v1/shares/public/${shareId}`);
          if (res?.data?.data?.docs) {
            setSubmission(res.data.data.docs);
          }
        } catch (err) {
          setSubmission({});
        }
      }
      fetchSubmission();
    }
  }, [shareId]);

  if (!submission) return <Spinner />;

  const dataToPhotos = (data) => {
    return data.map((el) => el.photos).flat();
  };

  const createdAt = submission?.createdAt
    ? new Date(submission.createdAt).toLocaleString('en-US', dateOptions)
    : '';

  let photoCount = 0;

  const status = submission ? (
    submission.isDeleted ? (
      <Chip
        avatar={<DeleteIcon />}
        label={'Deleted'}
        size='small'
        className={classes.deleted}
      />
    ) : (
      <Chip
        avatar={<InboxIcon />}
        label={'Recieved'}
        size='small'
        className={classes.open}
      />
    )
  ) : (
    'Unknown'
  );

  const severityChipGenerator = (issue) => {
    if (issue.currentCategorization === 4) {
      return (
        <div className={clsx(classes.pill, classes.offTrack)}>Off Track</div>
      );
    } else if (issue.currentCategorization === 3) {
      return <div className={clsx(classes.pill, classes.high)}>High</div>;
    } else if (issue.currentCategorization === 2) {
      return <div className={clsx(classes.pill, classes.medium)}>Medium</div>;
    } else if (issue.currentCategorization === 1) {
      return <div className={clsx(classes.pill, classes.low)}>Low</div>;
    } else if (issue.currentCategorization === 0) {
      return (
        <div className={clsx(classes.pill, classes.none)}>Uncategorized</div>
      );
    } else {
      return null;
    }
  };

  const lat = submission?.submittedFromLat ? submission?.submittedFromLat : 0;
  const lng = submission?.submittedFromLng ? submission?.submittedFromLng : 0;

  return (
    <>
      <div className={classes.root}>
        <main className={classes.content}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant='h6' className={classes.appBarTitle}>
                Global Build Solutions
              </Typography>
            </Toolbar>
          </AppBar>
          <Container maxWidth='lg' className={classes.container}>
            {Object.keys(submission).length > 0 ? (
              <>
                <Grid container spacing={0}>
                  <Grid item xs={12} className={classes.title}>
                    <h1>{`${submission?.milestone?.name}: Field Submission`}</h1>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.header}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={6} lg={4}>
                      <MapSingle
                        lat={lat}
                        lng={lng}
                        zoomLevel={14}
                        showLatLng={false}
                        height={'360px'}
                        width={'100%'}
                        padding={'0px 24px 12px 0px'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={8}>
                      <div className={classes.metaContainer}>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>MILESTONE</div>
                          <div className={classes.metaRowContent}>
                            {submission?.milestone?.name}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>SITE</div>
                          <div className={classes.metaRowContent}>
                            {submission?.site?.name}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>REF ID</div>
                          <div className={classes.metaRowContent}>
                            {submission?.refId
                              ? `S-${submission.refId}`
                              : 'None'}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>
                            SUBMISSION GPS
                          </div>
                          <div className={classes.metaRowContent}>
                            {`${lat}, ${lng}`}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>
                            SUBMITTED ON
                          </div>
                          <div className={classes.metaRowContent}>
                            {createdAt}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>
                            SUBMITTED BY
                          </div>
                          <div className={classes.metaRowContent}>
                            {`${submission?.createdBy?.firstName} ${submission?.createdBy?.lastName}`}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>
                            ISSUE COUNT
                          </div>
                          <div className={classes.metaRowContent}>
                            {submission?.issues && submission?.issues.length
                              ? submission?.issues.length
                              : 0}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>STATUS</div>
                          <div className={classes.metaRowContent}>{status}</div>
                        </div>
                        {submission?.share?.canExport ? (
                          <div
                            className={clsx(
                              classes.metaRow,
                              classes.buttonGroup
                            )}>
                            <Button
                              variant='contained'
                              color='default'
                              onClick={() =>
                                toggleModalDispatch({
                                  modalName: 'exportOptions',
                                  modalContent: {
                                    data: submission ? [submission] : [{}],
                                    type: 'submission',
                                  },
                                })
                              }
                              className={clsx(
                                classes.button,
                                classes.exportButton
                              )}
                              startIcon={<SaveIcon />}>
                              Export
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider variant='middle' className={classes.divider} />
                <h1 className={classes.textHeader}>Assessment Form</h1>
                {submission.data.map((el, idx) => (
                  <Grid
                    container
                    spacing={0}
                    className={classes.submission}
                    key={`grid-${idx}`}>
                    <Grid
                      item
                      xs={12}
                      key={`answer-${idx}`}
                      className={clsx(
                        classes.answerContainer,
                        el.flagCode === 1 ? classes.issueBorder : null
                      )}>
                      <div
                        className={clsx(
                          el.flagCode === 1
                            ? classes.answerHeaderContainer
                            : null
                        )}>
                        <h2
                          className={clsx(
                            classes.answerTitle,
                            el.flagCode === 1 ? classes.issueBg : null
                          )}>{`#${idx + 1}. ${el.question.name} - ${
                          el.question.questionText
                        }`}</h2>
                      </div>
                      {el.flagCode === 1 ? (
                        <div className={classes.issue}>
                          <div className={classes.issueLink}>
                            Issue Identified
                          </div>
                          {el?.issueId
                            ? severityChipGenerator(el?.issueId)
                            : null}
                          {el?.issueId?.isResolved ? (
                            <Chip
                              avatar={<DoneIcon />}
                              label={'Resolved'}
                              size='small'
                              className={clsx(classes.chip, classes.isResolved)}
                            />
                          ) : (
                            <Chip
                              avatar={<AccessTimeIcon />}
                              label={'Open'}
                              size='small'
                              className={clsx(classes.chip, classes.isOpen)}
                            />
                          )}
                          {el?.issueId?.isDeleted ? (
                            <Chip
                              avatar={<DeleteIcon />}
                              label={'Deleted'}
                              size='small'
                              className={clsx(classes.chip, classes.isDeleted)}
                            />
                          ) : null}
                        </div>
                      ) : null}
                      {el.answers[0] ? (
                        <h4 className={classes.answer}>{el.answers[0]}</h4>
                      ) : null}
                      {el.notes ? (
                        <h4 className={classes.notes}>{el.notes}</h4>
                      ) : null}
                      {el.photos.length > 0 ? (
                        <div className={classes.imageGrid}>
                          {el.photos.map((photo, idxPhoto) => {
                            const photoNum = photoCount;
                            photoCount++;
                            return (
                              <div
                                key={`photo-${idxPhoto}`}
                                className={classes.imageGridItem}>
                                <img
                                  src={photo.url}
                                  alt='submission'
                                  className={classes.photo}
                                  onClick={() =>
                                    toggleModalDispatch({
                                      modalName: 'photoGallery',
                                      modalContent: {
                                        photos: dataToPhotos(submission.data),
                                        startPhoto: photoNum,
                                      },
                                    })
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                ))}
                {submission?.share?.showUpdates ? (
                  <>
                    <Divider variant='middle' className={classes.divider} />
                    <Grid item xs={12} className={classes.header}>
                      <ShareNotesFullwidth
                        title='History & Additional Notes'
                        updates={submission.updates ? submission.updates : []}
                        toggleModalDispatch={toggleModalDispatch}
                        canComment={submission?.share?.canComment}
                        setContent={setSubmission}
                        startAlert={startAlertDispatch}
                        filters={filters}
                        setUpdateFiltersDispatch={setUpdateFiltersDispatch}
                      />
                    </Grid>
                  </>
                ) : null}
              </>
            ) : (
              <Grid container spacing={0}>
                <Grid item xs={12} className={classes.invalid}>
                  <h1>
                    The sharing link provided is either invalid or expired.
                    Please disregard this link and ask your project point of
                    contact for an updated version.
                  </h1>
                </Grid>
              </Grid>
            )}
            <Copyright />
          </Container>
        </main>
      </div>
      <ExportModal />
      <PhotoModal />
    </>
  );
};

SubmissionShare.propTypes = {
  toggleModalDispatch: PropTypes.func.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
  setUpdateFiltersDispatch: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  filters: selectUpdateFilters,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
  setUpdateFiltersDispatch: (filterObj) =>
    dispatch(setUpdateFilters(filterObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionShare);
