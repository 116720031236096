import React from 'react';

// Material UI
import Grid from '@material-ui/core/Grid';

// Components
import WidgetInfo from '../../components/widget-info/widget-info.component';
import WidgetStats from '../../components/widget-stats/widget-stats.component';
import WidgetPOC from '../../components/widget-poc/widget-poc.component';

// Styles
import useStyles from './widget-container.styles';

const WidgetContainer = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={12} md={4}>
        <WidgetInfo />
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetStats />
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetPOC />
      </Grid>
    </Grid>
  );
};

export default WidgetContainer;
