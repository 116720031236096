import { createSelector } from 'reselect';

// Selectors
import { selectLastLoginDateMs } from '../user/user.selectors';

// Utilities
import { createBuckets } from '../../utils/utilityFns';

const selectProjectSlice = (state) => state.project;

export const selectProjects = createSelector(
  [selectProjectSlice],
  (projectSlice) => projectSlice.projects
);

export const selectCurrentProject = createSelector(
  [selectProjectSlice],
  (projectSlice) => Object.values(projectSlice.projects)[0]
);

export const selectProjectDocuments = createSelector(
  [selectCurrentProject],
  (project) => {
    if (!project?.attachments || project.attachments.length === 0) {
      return [];
    }
    return project.attachments.map((el) => ({
      ...el,
      type: 'Project',
      meta: {},
      deletionObj: {
        projectId: project._id,
        attachmentId: el._id,
        embeddedField: 'attachments',
      },
    }));
  }
);

export const selectProjectMapDetails = createSelector(
  [selectCurrentProject],
  (project) => {
    if (!project || !project.mapDetails || !project.mapDetails.format) {
      return { format: 'default', renderArr: [] };
    }
    return project.mapDetails;
  }
);

export const selectCurrentProjectBuckets = createSelector(
  [selectCurrentProject, selectLastLoginDateMs],
  (project, lastLoginDateMs) =>
    createBuckets(
      lastLoginDateMs,
      project?.createdAt ? project.createdAt : '1/1/2000'
    )
);
