import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

// Components
import MainContainer from '../../components/main-container/main-container.component';
import UpdateMainPhoto from '../../forms/components/update-main-photo/update-main-photo.component';
import UpdatePassword from '../../forms/components/update-password/update-password.component';
import UpdateProfile from '../../forms/components/update-profile/update-profile.component';

// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Actions
import { startAlert } from '../../redux/alert/alert.actions';
import { updateProfilePhotoStart } from '../../redux/user/user.actions';

// Selectors
import { selectCurrentUser } from '../../redux/user/user.selectors';

// Utilities
import { formatDateTime } from '../../utils/utilityFns';

function Profile({
  user,
  startAlertDispatch,
  updateProfilePhotoStartDispatch,
}) {
  return (
    <MainContainer>
      <Grid container spacing={3} style={{ margin: 0 }}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom={true}>
            My Profile
          </Typography>
          <Typography variant='overline' display='block' gutterBottom>
            {`Member Since ${formatDateTime(user.createdAt, 'default')}`}
          </Typography>
          <UpdateMainPhoto
            current={user.photo}
            startAlert={startAlertDispatch}
            updatePhoto={updateProfilePhotoStartDispatch}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UpdateProfile user={user} />
        </Grid>
        <Grid item xs={12} md={6}>
          <UpdatePassword />
        </Grid>
      </Grid>
    </MainContainer>
  );
}

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  updateProfilePhotoStartDispatch: PropTypes.func.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
  updateProfilePhotoStartDispatch: (formData) =>
    dispatch(updateProfilePhotoStart(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
