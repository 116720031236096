import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '20px',
  },
  box: {
    paddingTop: 0,
  },
  boxLayout: {
    margin: '20px 0 0 0',
    padding: '8px 8px 8px 16px',
    backgroundColor: theme.palette.primary.darkest,
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      color: '#fff',
      margin: 0,
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
    borderBottomRightRadius: '16px',
    borderBottomLeftRadius: '16px',
  },
  table: {
    '& tr > td': {
      borderColor: theme.custom.lightestgrey,
      lineHeight: '1rem',
    },
    '& tr:last-of-type > td': {
      borderBottom: 0,
    },
  },
  tableRow: {
    '& > td': {
      paddingTop: '2px',
      paddingBottom: '2px',
    },
  },
  head: {
    backgroundColor: theme.custom.issueboxHeadingBackground,
    '& tr > th': {
      lineHeight: '1rem',
      padding: '8px 16px',
    },
  },
}));

export default useStyles;
