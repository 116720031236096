import { makeStyles } from '@material-ui/core/styles';

// Constants
import { DRAWER_WIDTH as drawerWidth } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
  },
  container: {
    paddingBottom: '64px',
  },
  content: {
    overflow: 'auto',
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: '#fafafa', //theme.custom.bg,
    height: '100vh',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      marginLeft: -drawerWidth,
    },
  },
}));

export default useStyles;
