import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    padding: '16px',
    borderRadius: '20px',
  },
  title: {
    margin: '8px',
  },
  helperText: {
    marginBottom: '8px',
  },
  loadingSpinner: {
    width: '20px!important',
    height: '20px!important',
  },
  input: {
    display: 'none',
  },
  button: {
    marginLeft: '8px',
  },
}));

export default useStyles;
