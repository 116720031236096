import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { v4 as uuidv4 } from 'uuid';

// Constants
import { ALERT_DURATION } from './constants';

// Setup Time Ago
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

const severityMap = {
  0: 'UNCATEGORIZED',
  1: 'LOW',
  2: 'MEDIUM',
  3: 'HIGH',
  4: 'OFF TRACK',
};

function leapyear(year) {
  return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
}

export const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

export const isEmptyObj = (obj) => {
  return Object.keys(obj).length <= 0;
};

export const severityNumToString = (val) => {
  return severityMap[val];
};

export const createAlert = (alertObj) => {
  const alertId = uuidv4();
  return { ...alertObj, alertId, alertDuration: ALERT_DURATION };
};

export const arrToObj = (arr, key) => {
  let obj = {};
  if (Array.isArray(arr)) {
    arr.forEach((el) => {
      Object.assign(obj, {
        [el[key]]: el,
      });
    });
  } else if (arr.hasOwnProperty(key)) {
    Object.assign(obj, {
      [arr[key]]: arr,
    });
  }
  return obj;
};

export const sortAlpha = (field, order) => (a, b) => {
  if (field === false) {
    if (a < b) {
      return order === 'desc' ? 1 : -1;
    }
    if (a > b) {
      return order === 'desc' ? -1 : 1;
    }
  }
  if (a[field] < b[field]) {
    return order === 'desc' ? 1 : -1;
  }
  if (a[field] > b[field]) {
    return order === 'desc' ? -1 : 1;
  }
  return 0;
};

export const sortDate = (field, order) => (a, b) => {
  if (order === 'asc') {
    return new Date(a[field]) - new Date(b[field]);
  } else {
    return new Date(b[field]) - new Date(a[field]);
  }
};

export const getWeekNumber = (d) => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

export const getMonthDiff = (dateFrom, dateTo) => {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

export const getYearDiff = (dateFrom, dateTo) => {
  return dateTo.getFullYear() - dateFrom.getFullYear() + 1;
};

export const monthsList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const monthsObj = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

export const abbrMonthsList = [
  'J',
  'F',
  'M',
  'A',
  'M',
  'J',
  'J',
  'A',
  'S',
  'O',
  'N',
  'D',
];

export const daysInMonth = (m, y) => {
  switch (m) {
    case 'Jan':
      return 31;
    case 'Feb':
      return leapyear(y) ? 29 : 28;
    case 'Mar':
      return 31;
    case 'Apr':
      return 30;
    case 'May':
      return 31;
    case 'Jun':
      return 30;
    case 'Jul':
      return 31;
    case 'Aug':
      return 31;
    case 'Sep':
      return 30;
    case 'Oct':
      return 31;
    case 'Nov':
      return 30;
    case 'Dec':
      return 31;
    default:
      return null;
  }
};

export const dateToTimeAgo = (date, format) => {
  try {
    let dateAgo;
    const tempDate = new Date(date);
    /* eslint-disable-next-line*/
    tempDate != 'Invalid Date'
      ? (dateAgo = timeAgo.format(tempDate, format))
      : (dateAgo = '-');
    return dateAgo;
  } catch (err) {
    return '';
  }
};

export const formatDateTime = (dateStr, format) => {
  // Date Formats
  const dateOptions = {
    default: { year: 'numeric', month: '2-digit', day: '2-digit' },
    noYear: { month: '2-digit', day: '2-digit' },
    moShortYear: { month: 'short', day: '2-digit' },
  };

  const date = new Date(dateStr);
  /* eslint-disable-next-line */
  if (date.getTime() === date.getTime()) {
    if (dateOptions.hasOwnProperty(format)) {
      return date.toLocaleString('en-US', dateOptions[format]);
    } else {
      return date.toLocaleString('en-US', dateOptions['default']);
    }
  } else {
    return 'Inavlid Date';
  }
};

export const isValidDate = (dateStr) => {
  const date = new Date(dateStr);
  /* eslint-disable-next-line */
  return date.getTime() === date.getTime();
};

export const formatDateBox = (date) => {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
    '0' + date.getDate()
  ).slice(-2)}`;
};

export const findDuplicates = (arr) => {
  return new Set(arr).size !== arr.length;
};

export const capitalizeFirstLetter = (str) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const truncate = (str, len, decor) => {
  switch (decor) {
    case 'ellipses': {
      return str.length > len ? str.substring(0, len - 3) + '...' : str;
    }
    case 'email': {
      return str.length > len ? str.substring(0, len - 7) + '....com' : str;
    }
    default: {
      return str.length > len ? str.substring(0, len - 3) + '...' : str;
    }
  }
};

export const createDateFunc = (date, days, type) => {
  if (days && days !== 0) {
    date.setDate(date.getDate() + days);
  }

  const dMonth = date.getMonth() + 1;
  const dDay = date.getDate();
  const dYear = date.getFullYear();
  const startT = '00:00:00';
  const endT = '23:59:59';
  const todayStart = `${dMonth}/${dDay}/${dYear} ${startT}`;
  const todayEnd = `${dMonth}/${dDay}/${dYear} ${endT}`;
  if (type === 'string') {
    return [todayStart, todayEnd];
  }
  return [new Date(todayStart), new Date(todayEnd)];
};

export const createBuckets = (todayStrOrMs, listStartStrOrMs) => {
  const buckets = [];

  const today = new Date(todayStrOrMs);
  const todayMs = today.getTime(); // epoch-time

  const listStart = new Date(listStartStrOrMs);
  const listMs = listStart.getTime(); // epoch-time

  if (listMs >= todayMs) {
    return buckets;
  }

  const DAY = 24 * 60 * 60 * 1000;
  const todayStart = today.setHours(0, 0, 0, 0);
  const todayEnd = today.setHours(23, 59, 59, 999);

  buckets.push({
    dateKey: 'Today',
    dateRangeStart: todayStart,
    dateRangeEnd: todayEnd,
  });

  buckets.push({
    dateKey: 'Yesterday',
    dateRangeStart: todayStart - DAY,
    dateRangeEnd: todayEnd - DAY,
  });

  buckets.push({
    dateKey: 'Last 7 Days',
    dateRangeStart: todayStart - 7 * DAY,
    dateRangeEnd: todayEnd - 2 * DAY,
  });

  buckets.push({
    dateKey: 'Last 30 Days',
    dateRangeStart: todayStart - 30 * DAY,
    dateRangeEnd: todayEnd - 8 * DAY,
  });

  if (listMs >= todayStart - 30 * DAY) {
    return buckets;
  }

  buckets.push({
    dateKey: 'Earlier',
    dateRangeStart: listMs,
    dateRangeEnd: todayEnd - 31 * DAY,
  });

  // May want to add Month, 20XX but for now just using Earlier is OK

  return buckets;
};

export const sortUpdatesByBuckets = (updates, buckets) => {
  const sortedUpdates = [];
  console.log('Sorting Updates by Date Buckets');
  buckets.forEach((bucket) => {
    const bucketUpdates = updates.filter(
      (update) =>
        new Date(update.createdAt).getTime() >= bucket.dateRangeStart &&
        new Date(update.createdAt).getTime() <= bucket.dateRangeEnd
    );
    if (bucketUpdates.length > 0) {
      sortedUpdates.push({
        dateKey: bucket.dateKey,
        updates: bucketUpdates.sort(sortDate('createdAt', 'desc')),
      });
    }
  });

  return sortedUpdates;
};
