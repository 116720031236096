import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';

// Material UI
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import InboxIcon from '@material-ui/icons/Inbox';
import SaveIcon from '@material-ui/icons/Save';

// Components
import MapSingle from '../../components/map-single/map-single.component';
import Spinner from '../../components/spinner/spinner.component';
import PhotoModal from '../../components/photo-modal/photo-modal.component';
import ExportModal from '../../components/export-modal/export-modal.component';
import ShareNotesFullwidth from '../../components/share-notes-fullwidth/share-notes-fullwidth.component';
import Copyright from '../../components/copyright/copyright.component';

// Styles
import useStyles from './issue-share.styles';

// Selectors
import { selectUpdateFilters } from '../../redux/ui/ui.selectors';

// Actions
import { startAlert } from '../../redux/alert/alert.actions';
import { toggleModal, setUpdateFilters } from '../../redux/ui/ui.actions';

const dateOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

const IssueShare = ({
  toggleModalDispatch,
  startAlertDispatch,
  setUpdateFiltersDispatch,
  filters,
}) => {
  const classes = useStyles();
  const { shareId } = useParams();
  const [issue, setIssue] = useState(null);

  useEffect(() => {
    if (shareId) {
      async function fetchIssue() {
        try {
          const res = await axios.get(`/api/v1/shares/public/${shareId}`);
          if (res?.data?.data?.docs) {
            setIssue(res.data.data.docs);
          }
        } catch (err) {
          setIssue({});
        }
      }
      fetchIssue();
    }
  }, [shareId]);

  if (!issue) return <Spinner />;

  const createdAt = issue?.createdAt
    ? new Date(issue.createdAt).toLocaleString('en-US', dateOptions)
    : '';

  let photoCount = 0;

  const status = issue ? (
    issue.isDeleted ? (
      <Chip
        avatar={<DeleteIcon />}
        label={'Deleted'}
        size='small'
        className={classes.deleted}
      />
    ) : (
      <Chip
        avatar={<InboxIcon />}
        label={'Recieved'}
        size='small'
        className={classes.open}
      />
    )
  ) : (
    'Unknown'
  );

  const severityChipGenerator = (currentCategorization) => {
    if (currentCategorization === 4) {
      return (
        <div className={clsx(classes.pill, classes.offTrack)}>Off Track</div>
      );
    } else if (currentCategorization === 3) {
      return <div className={clsx(classes.pill, classes.high)}>High</div>;
    } else if (currentCategorization === 2) {
      return <div className={clsx(classes.pill, classes.medium)}>Medium</div>;
    } else if (currentCategorization === 1) {
      return <div className={clsx(classes.pill, classes.low)}>Low</div>;
    } else if (currentCategorization === 0) {
      return (
        <div className={clsx(classes.pill, classes.none)}>Uncategorized</div>
      );
    } else {
      return null;
    }
  };

  const lat = issue?.submittedFromLat ? issue?.submittedFromLat : 0;
  const lng = issue?.submittedFromLng ? issue?.submittedFromLng : 0;

  return (
    <>
      <div className={classes.root}>
        <main className={classes.content}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant='h6' className={classes.appBarTitle}>
                Global Build Solutions
              </Typography>
            </Toolbar>
          </AppBar>
          <Container maxWidth='lg' className={classes.container}>
            {Object.keys(issue).length > 0 ? (
              <>
                <Grid container spacing={0}>
                  <Grid item xs={12} className={classes.title}>
                    <h1>{`${issue?.question?.errorName} Identified on Field Submission`}</h1>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.header}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={6} lg={4}>
                      <MapSingle
                        lat={lat}
                        lng={lng}
                        zoomLevel={14}
                        showLatLng={false}
                        height={'360px'}
                        width={'100%'}
                        padding={'0px 24px 12px 0px'}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={8}>
                      <div className={classes.metaContainer}>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>MILESTONE</div>
                          <div className={classes.metaRowContent}>
                            {issue?.milestone?.name}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>SITE</div>
                          <div className={classes.metaRowContent}>
                            {issue?.site?.name}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>REF ID</div>
                          <div className={classes.metaRowContent}>
                            {issue?.refId ? `I-${issue.refId}` : 'None'}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>
                            SUBMISSION GPS
                          </div>
                          <div className={classes.metaRowContent}>
                            {`${lat}, ${lng}`}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>
                            SUBMITTED ON
                          </div>
                          <div className={classes.metaRowContent}>
                            {createdAt}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>
                            SUBMITTED BY
                          </div>
                          <div className={classes.metaRowContent}>
                            {`${issue?.createdBy?.firstName} ${issue?.createdBy?.lastName}`}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>
                            ISSUE SEVERITY
                          </div>
                          <div className={classes.metaRowContent}>
                            {severityChipGenerator(
                              issue?.currentCategorization
                            )}
                          </div>
                        </div>
                        <div className={classes.metaRow}>
                          <div className={classes.metaRowHeader}>STATUS</div>
                          <div className={classes.metaRowContent}>{status}</div>
                        </div>
                        {issue?.share?.canExport ? (
                          <div
                            className={clsx(
                              classes.metaRow,
                              classes.buttonGroup
                            )}>
                            <Button
                              variant='contained'
                              color='default'
                              onClick={() =>
                                toggleModalDispatch({
                                  modalName: 'exportOptions',
                                  modalContent: {
                                    data: issue ? [issue] : [{}],
                                    type: 'issue',
                                  },
                                })
                              }
                              className={clsx(
                                classes.button,
                                classes.exportButton
                              )}
                              startIcon={<SaveIcon />}>
                              Export
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider variant='middle' className={classes.divider} />
                <Grid item xs={12} className={classes.header}>
                  <h1 className={classes.textHeader}>Issue Details</h1>
                  <div className={classes.issueDetails}>
                    <div className={classes.issueTitle}>
                      <strong>Title:</strong> {issue?.question?.errorName}
                    </div>
                    <div>
                      <strong>Error:</strong> {issue?.question?.errorText}
                    </div>
                    <div>
                      <strong>Notes:</strong>{' '}
                      {issue?.updates[0]?.content?.notes}
                    </div>
                  </div>
                </Grid>
                <Divider variant='middle' className={classes.divider} />
                <Grid item xs={12} className={classes.header}>
                  <h1 className={classes.textHeader}>
                    Issue Photos from Field Submission
                  </h1>
                  {issue.updates[0].content.photos.length > 0 ? (
                    <div className={classes.imageGrid}>
                      {issue.updates[0].content.photos.map(
                        (photo, idxPhoto) => {
                          const photoNum = photoCount;
                          photoCount++;
                          return (
                            <div
                              key={`photo-${idxPhoto}`}
                              className={classes.imageGridItem}>
                              <img
                                src={photo.url}
                                alt='submission'
                                className={classes.photo}
                                onClick={() =>
                                  toggleModalDispatch({
                                    modalName: 'photoGallery',
                                    modalContent: {
                                      photos: issue.updates[0].content.photos,
                                      startPhoto: photoNum,
                                    },
                                  })
                                }
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : null}
                </Grid>
                {issue?.share?.showUpdates ? (
                  <>
                    <Divider variant='middle' className={classes.divider} />
                    <Grid item xs={12} className={classes.header}>
                      <ShareNotesFullwidth
                        title='History & Additional Notes'
                        updates={issue.updates ? issue.updates : []}
                        toggleModalDispatch={toggleModalDispatch}
                        canComment={issue?.share?.canComment}
                        setContent={setIssue}
                        startAlert={startAlertDispatch}
                        filters={filters}
                        setUpdateFiltersDispatch={setUpdateFiltersDispatch}
                      />
                    </Grid>
                  </>
                ) : null}
              </>
            ) : (
              <Grid container spacing={0}>
                <Grid item xs={12} className={classes.invalid}>
                  <h1>
                    The sharing link provided is either invalid or expired.
                    Please disregard this link and ask your project point of
                    contact for an updated version.
                  </h1>
                </Grid>
              </Grid>
            )}
            <Copyright />
          </Container>
        </main>
      </div>
      <ExportModal />
      <PhotoModal />
    </>
  );
};

IssueShare.propTypes = {
  toggleModalDispatch: PropTypes.func.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
  setUpdateFiltersDispatch: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  filters: selectUpdateFilters,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
  setUpdateFiltersDispatch: (filterObj) =>
    dispatch(setUpdateFilters(filterObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueShare);
