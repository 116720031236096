import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textHeader: {
    marginTop: 0,
    marginBottom: 0,
  },
  noPhotosText: {
    marginTop: '8px',
  },
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '8px',
    width: '100%',
  },
  withPadding: {
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '8px',
  },
  imageGridItem: { display: 'grid', margin: 0, padding: 0 },
  photo: { maxWidth: '100%', cursor: 'pointer' },
}));

export default useStyles;
