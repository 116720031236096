import {
  CHECK_USER_SESSION,
  USER_SESSION_FOUND,
  USER_SESSION_NOT_FOUND,
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_IN_START,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_START,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_PHOTO_START,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_PROFILE_PHOTO_FAILURE,
  UPDATE_DEFAULT_PROJECT_START,
  UPDATE_DEFAULT_PROJECT_SUCCESS,
  UPDATE_DEFAULT_PROJECT_FAILURE,
  UPDATE_PROJECT_LIST_START,
  UPDATE_PROJECT_LIST_SUCCESS,
  UPDATE_PROJECT_LIST_FAILURE,
  SET_LAST_LOGIN_DATE,
} from '../types';

export const checkUserSession = () => ({
  type: CHECK_USER_SESSION,
});

export const userSessionFound = (userData) => ({
  type: USER_SESSION_FOUND,
  payload: userData,
});

export const userSessionNotFound = () => ({
  type: USER_SESSION_NOT_FOUND,
});

export const signUpStart = (formData) => ({
  type: SIGN_UP_START,
  payload: formData,
});

export const signUpSuccess = (userData) => ({
  type: SIGN_UP_SUCCESS,
  payload: userData,
});

export const signUpFailure = () => ({
  type: SIGN_UP_FAILURE,
});

export const signInStart = (userCredentials) => ({
  type: SIGN_IN_START,
  payload: userCredentials,
});

export const signInSuccess = (userData) => ({
  type: SIGN_IN_SUCCESS,
  payload: userData,
});

export const signInFailure = () => ({
  type: SIGN_IN_FAILURE,
});

export const signOutStart = () => ({
  type: SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
});

export const signOutFailure = () => ({
  type: SIGN_OUT_FAILURE,
});

export const forgotPasswordStart = (emailObj) => ({
  type: FORGOT_PASSWORD_START,
  payload: emailObj,
});

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordFailure = () => ({
  type: FORGOT_PASSWORD_FAILURE,
});

export const resetPasswordStart = (passwordObj) => ({
  type: RESET_PASSWORD_START,
  payload: passwordObj,
});

export const resetPasswordSuccess = (userData) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: userData,
});

export const resetPasswordFailure = () => ({
  type: RESET_PASSWORD_FAILURE,
});

export const updatePasswordStart = (passwordObj) => ({
  type: UPDATE_PASSWORD_START,
  payload: passwordObj,
});

export const updatePasswordSuccess = (passwordObj) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: passwordObj,
});

export const updatePasswordFailure = (passwordObj) => ({
  type: UPDATE_PASSWORD_FAILURE,
  payload: passwordObj,
});

export const updateProfileStart = (profileObj) => ({
  type: UPDATE_PROFILE_START,
  payload: profileObj,
});

export const updateProfileSuccess = (profileObj) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: profileObj,
});

export const updateProfileFailure = (profileObj) => ({
  type: UPDATE_PROFILE_FAILURE,
  payload: profileObj,
});

export const updateProfilePhotoStart = (formData) => ({
  type: UPDATE_PROFILE_PHOTO_START,
  payload: formData,
});

export const updateProfilePhotoSuccess = (profileObj) => ({
  type: UPDATE_PROFILE_PHOTO_SUCCESS,
  payload: profileObj,
});

export const updateProfilePhotoFailure = () => ({
  type: UPDATE_PROFILE_PHOTO_FAILURE,
});

export const updateDefaultProjectStart = (profileObj) => ({
  type: UPDATE_DEFAULT_PROJECT_START,
  payload: profileObj,
});

export const updateDefaultProjectSuccess = (profileObj) => ({
  type: UPDATE_DEFAULT_PROJECT_SUCCESS,
  payload: profileObj,
});

export const updateDefaultProjectFailure = () => ({
  type: UPDATE_DEFAULT_PROJECT_FAILURE,
});

export const updateProjectlistStart = (profileObj) => ({
  type: UPDATE_PROJECT_LIST_START,
  payload: profileObj,
});

export const updateProjectlistSuccess = (profileObj) => ({
  type: UPDATE_PROJECT_LIST_SUCCESS,
  payload: profileObj,
});

export const updateProjectlistFailure = () => ({
  type: UPDATE_PROJECT_LIST_FAILURE,
});

export const setLastLoginDate = (todayMs) => ({
  type: SET_LAST_LOGIN_DATE,
  payload: todayMs,
});
