import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mention: {
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    letterSpacing: '1px',
    padding: '1px 2px 1px 3px',
    borderRadius: '4px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
  },
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '0.8rem',
    borderRadius: '4px',
    padding: '6px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  default: {
    backgroundColor: '#ffde9b',
    '&:hover': {
      backgroundColor: '#f7ce7c',
    },
  },
  defaultTooltip: {
    border: '2px solid #f7ce7c',
  },
  defaultArrow: {
    color: '#f7ce7c',
  },
  user: {
    backgroundColor: '#ffde9b',
    '&:hover': {
      backgroundColor: '#f7ce7c',
    },
  },
  userTooltip: {
    border: '2px solid #f7ce7c',
  },
  userArrow: {
    color: '#f7ce7c',
  },
  issue: {
    backgroundColor: '#fcaf79',
    '&:hover': {
      backgroundColor: '#f69a59',
    },
  },
  issueTooltip: {
    border: '2px solid #f69a59',
  },
  issueArrow: {
    color: '#f69a59',
  },
  submission: {
    backgroundColor: '#ff9b9b',
    '&:hover': {
      backgroundColor: '#fb8080',
    },
  },
  submissionTooltip: {
    border: '2px solid #fb8080',
  },
  submissionArrow: {
    color: '#fb8080',
  },
  milestone: {
    backgroundColor: '#d6c0f5',
    '&:hover': {
      backgroundColor: '#c7a4f7',
    },
  },
  milestoneTooltip: {
    border: '2px solid #c7a4f7',
  },
  milestoneArrow: {
    color: '#c7a4f7',
  },
}));

export default useStyles;
