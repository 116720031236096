import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

// Styles
import useStyles from './widget-poc.styles';

// Selectors
import { selectActiveSiteOrProject } from '../../redux/site/site.selectors';

const WidgetPOC = ({ selection }) => {
  const classes = useStyles();

  if (!selection) return null;

  return (
    <div className={classes.root}>
      <div className={classes.pocPhoto}>
        <Avatar
          src={selection.poc.photo}
          className={classes.avatarLarge}
          variant='circular'
        />
      </div>
      <div className={classes.details}>
        <div className={classes.title}>Point of Contact</div>
        <div className={classes.poc}>
          <span
            className={
              classes.pocName
            }>{`${selection.poc.firstName} ${selection.poc.lastName}`}</span>
          <Tooltip title={selection.poc.email}>
            <a href={`mailto:${selection.poc.email}`} className={classes.link}>
              Email the POC
            </a>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

WidgetPOC.propTypes = {
  selection: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  selection: selectActiveSiteOrProject,
});

export default connect(mapStateToProps)(WidgetPOC);
