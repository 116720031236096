import {
  GET_OUTSTANDING_ISSUES_START,
  GET_OUTSTANDING_ISSUES_SUCCESS,
  GET_OUTSTANDING_ISSUES_FAILURE,
  GET_FILTERED_ISSUES_START,
  GET_FILTERED_ISSUES_SUCCESS,
  GET_FILTERED_ISSUES_FAILURE,
  UPDATE_ISSUE_START,
  UPDATE_ISSUE_SUCCESS,
  UPDATE_ISSUE_FAILURE,
  DELETE_ISSUE_UPDATE_START,
  DELETE_ISSUE_UPDATE_SUCCESS,
  DELETE_ISSUE_UPDATE_FAILURE,
  UPDATE_ISSUE_SEEN_START,
  UPDATE_ISSUE_SEEN_SUCCESS,
  UPDATE_ISSUE_SEEN_FAILURE,
  GET_SINGLE_ISSUE_START,
  GET_SINGLE_ISSUE_SUCCESS,
  GET_SINGLE_ISSUE_FAILURE,
  DELETE_ISSUE_SHARE_START,
  DELETE_ISSUE_SHARE_SUCCESS,
  DELETE_ISSUE_SHARE_FAILURE,
  CREATE_ISSUE_SHARE_START,
  CREATE_ISSUE_SHARE_SUCCESS,
  CREATE_ISSUE_SHARE_FAILURE,
} from '../types';

// Utility Functions
import { arrToObj } from '../../utils/utilityFns';

const INITIAL_STATE = {
  issues: {},
  numIssues: 0,
  status: 'idle',
};

const issueReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_OUTSTANDING_ISSUES_START:
    case GET_FILTERED_ISSUES_START:
      return {
        ...state,
        issues: {},
      };
    case GET_SINGLE_ISSUE_START:
      return {
        ...state,
        issues: {},
        status: 'fetching',
      };
    case UPDATE_ISSUE_START:
    case UPDATE_ISSUE_FAILURE:
    case DELETE_ISSUE_UPDATE_START:
    case DELETE_ISSUE_UPDATE_FAILURE:
    case UPDATE_ISSUE_SEEN_START:
    case UPDATE_ISSUE_SEEN_FAILURE:
    case DELETE_ISSUE_SHARE_START:
    case DELETE_ISSUE_SHARE_FAILURE:
    case CREATE_ISSUE_SHARE_START:
    case CREATE_ISSUE_SHARE_FAILURE:
      return {
        ...state,
      };
    case GET_OUTSTANDING_ISSUES_SUCCESS:
    case GET_FILTERED_ISSUES_SUCCESS:
      return {
        ...state,
        issues: arrToObj(payload.docs, '_id'),
        numIssues: payload.totalDocs,
      };
    case GET_SINGLE_ISSUE_SUCCESS:
      return {
        ...state,
        issues: arrToObj(payload.docs, '_id'),
        numIssues: 0, // hack FIXME
        status: 'success',
      };
    case GET_OUTSTANDING_ISSUES_FAILURE:
    case GET_FILTERED_ISSUES_FAILURE:
      return {
        ...state,
        issues: {},
        numIssues: 0,
      };
    case GET_SINGLE_ISSUE_FAILURE:
      return {
        ...state,
        issues: {},
        numIssues: 0,
        status: 'error',
      };
    case UPDATE_ISSUE_SUCCESS:
    case DELETE_ISSUE_SHARE_SUCCESS:
    case CREATE_ISSUE_SHARE_SUCCESS:
      return {
        ...state,
        issues: { ...state.issues, [payload.id]: payload },
      };
    case DELETE_ISSUE_UPDATE_SUCCESS:
    case UPDATE_ISSUE_SEEN_SUCCESS:
      return {
        ...state,
        issues: {
          ...state.issues,
          [payload.docId]: {
            ...state.issues[payload.docId],
            updates: payload.updates,
          },
        },
      };
    default: {
      return state;
    }
  }
};

export default issueReducer;
