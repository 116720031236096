import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import PhotoIcon from '@material-ui/icons/Photo';

// Components
import SubmissionActionButtons from '../submission-action-buttons/submission-action-buttons.component';

// Images
import no_image_placeholder from '../../assets/no_image_placeholder.png';

// Styles
import useStyles from './submission-card.styles';

const dateOptionsDay = { day: '2-digit' };
const dateOptionsMonth = { month: 'long' };

const SubmissionCard = ({ submission, toggleModalDispatch, user }) => {
  const classes = useStyles();

  const dataToPhotos = (data) => {
    return data.map((el) => el.photos).flat();
  };

  const firstPhotoUrl = (flatPhotoArr) => {
    return flatPhotoArr.find((el) => el.url);
  };

  const togglePhotomodal = (numPhotos) => {
    if (numPhotos > 0) {
      toggleModalDispatch({
        modalName: 'photoGallery',
        modalContent: {
          photos: photoArr,
        },
      });
    }
  };

  const submissionDate = new Date(submission.createdAt);
  const photoArr = dataToPhotos(submission.data ? submission.data : []);
  const numPhotos = photoArr.length;

  const issueLink =
    submission.issues && submission.issues.length > 1
      ? `/projects/${submission.project}/issues?submission=${submission._id}`
      : submission.issues && submission.issues.length === 1
      ? `/projects/${submission.project}/issues/${submission.issues[0]}`
      : null;

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Avatar aria-label='recipe' className={classes.avatar}>
            {submission.site.name.slice(0, 1)}
          </Avatar>
        }
        action={
          <span className={classes.calContainer}>
            <div className={classes.calMonth}>
              {submissionDate
                .toLocaleString('en-US', dateOptionsMonth)
                .slice(0, 3)}
            </div>
            <div className={classes.calDay}>
              {submissionDate.toLocaleString('en-US', dateOptionsDay)}
            </div>
          </span>
        }
        title={submission.site.name}
        subheader={submission.milestone.name}
      />
      <CardMedia
        className={clsx(
          classes.media,
          numPhotos === 0 ? classes.hideMedia : null
        )}
        image={
          numPhotos > 0 ? firstPhotoUrl(photoArr).url : no_image_placeholder
        }
        title='Click for photo gallery.'
        onClick={() => togglePhotomodal(numPhotos)}
      />
      <div
        className={clsx(
          classes.photoBadgeContainer,
          numPhotos === 0 ? classes.photoBadgeNoPhotoSmallAdjustment : null
        )}>
        {submission.issueCount > 0 && (
          <RouterLink className={classes.link} to={issueLink}>
            <div className={classes.issueBadgeLarge}>
              {`${submission.issueCount} issue${
                submission.issueCount > 1 ? 's' : ''
              }`}
            </div>
          </RouterLink>
        )}
        {numPhotos > 1 && (
          <div className={classes.photoBadgeLarge}>
            <span className={classes.photoCountNumber}>{`${numPhotos} `}</span>
            <PhotoIcon className={classes.photoIcon} />
          </div>
        )}
      </div>
      <CardActions className={classes.cardActions}>
        <SubmissionActionButtons
          submission={submission}
          user={user}
          toggleModalDispatch={toggleModalDispatch}
        />
      </CardActions>
    </Card>
  );
};

SubmissionCard.propTypes = {
  submission: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default SubmissionCard;
