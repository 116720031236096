import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

// Styles
import useStyles from './issue-action-buttons.styles';

// Actions
import {
  deleteIssueShareStart,
  createIssueShareStart,
} from '../../redux/issue/issue.actions';

const IssueActionButtons = ({
  issue,
  user,
  toggleModalDispatch,
  deleteIssueShareStartDispatch,
  createIssueShareStartDispatch,
}) => {
  const classes = useStyles();
  const { projectId } = useParams();

  const shareUrl =
    issue?.isShared && issue?.share?._id
      ? `${window.location.origin.toString()}/share/issue/${issue.share._id}`
      : '';

  const handleDeleteIssueShare = (docId) => {
    deleteIssueShareStartDispatch({ docId, projectId });
  };

  const authed =
    user?.authorityLevel === 'admin' || user?.authorityLevel === 'superAdmin';

  const resolveButton = (
    <Tooltip title='Resolve'>
      <span style={{ textAlign: 'center' }}>
        <IconButton
          fontSize='small'
          className={classes.button}
          aria-label='resolve'
          disabled={issue.isResolved ? true : false}
          onClick={() =>
            toggleModalDispatch({
              modalName: 'issueResolve',
              modalContent: {
                id: issue._id,
                categorization: issue.currentCategorization,
                milestoneFlag: issue.currentCategorization === 4 ? -1 : 0,
                actionType: 'Resolved',
              },
            })
          }>
          <CheckCircleIcon />
        </IconButton>
      </span>
    </Tooltip>
  );

  const notesButton = (
    <Tooltip title='Notes'>
      <IconButton
        fontSize='small'
        className={classes.button}
        aria-label='notes'
        onClick={() =>
          toggleModalDispatch({
            modalName: 'issueNotes',
            modalContent: {
              id: issue._id,
              categorization: issue.currentCategorization,
              milestoneFlag: 0,
              actionType: 'Updated',
              updates: issue.updates,
            },
          })
        }>
        <Badge
          color='secondary'
          badgeContent={issue.updates.reduce(
            (prev, acc) =>
              !acc.seenBy.includes(user._id) ? (prev = prev + 1) : prev,
            0
          )}
          overlap='circular'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}>
          <AssignmentIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );

  const exportButton = (
    <Tooltip title='Export'>
      <IconButton
        fontSize='small'
        className={classes.button}
        aria-label='export'
        onClick={() =>
          toggleModalDispatch({
            modalName: 'exportOptions',
            modalContent: {
              data: issue ? [issue] : [{}],
              type: 'issue',
            },
          })
        }>
        <SaveIcon />
      </IconButton>
    </Tooltip>
  );

  const shareButton =
    authed && issue?.isShared ? (
      <Tooltip title='Share'>
        <IconButton
          fontSize='small'
          className={classes.button}
          aria-label='delete share'
          onClick={() =>
            toggleModalDispatch({
              modalName: 'shareDelete',
              modalContent: {
                shareUrl: shareUrl,
                share: issue?.share,
                dismissFunc: () => handleDeleteIssueShare(issue._id),
              },
            })
          }>
          <Badge
            className={classes.shareActive}
            variant='dot'
            badgeContent={1}
            overlap='circular'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <SupervisedUserCircleIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title='Share'>
        <IconButton
          fontSize='small'
          className={classes.button}
          aria-label='share issue'
          onClick={() =>
            toggleModalDispatch({
              modalName: 'shareCreate',
              modalContent: {
                docId: issue._id,
                projectId: projectId,
                createShare: createIssueShareStartDispatch,
              },
            })
          }>
          <SupervisedUserCircleIcon />
        </IconButton>
      </Tooltip>
    );

  const deleteButton = authed ? (
    <Tooltip
      title='Delete'
      className={issue.currentCategorization !== 4 ? classes.disabled : null}>
      <span style={{ textAlign: 'center' }}>
        <IconButton
          fontSize='small'
          className={classes.button}
          aria-label='delete issue'
          disabled={issue.currentCategorization === 4 ? true : false}
          onClick={() =>
            toggleModalDispatch({
              modalName: 'issueResolve',
              modalContent: {
                id: issue._id,
                milestoneFlag: 0,
                categorization: issue.currentCategorization,
                actionType: 'Deleted',
              },
            })
          }>
          <DeleteIcon />
        </IconButton>
      </span>
    </Tooltip>
  ) : null;

  const openButton = (
    <Tooltip title='Open Issue'>
      <IconButton
        fontSize='small'
        className={classes.button}
        aria-label='open issue'
        href={`/projects/${issue.project}/issues/${issue._id}`}>
        <LaunchIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <div className={classes.buttonGroup}>
      <Hidden smUp>
        <>{openButton}</>
      </Hidden>
      <>{notesButton}</>
      <>{exportButton}</>
      <>{shareButton}</>
      <>{resolveButton}</>
      <>{deleteButton}</>
    </div>
  );
};

IssueActionButtons.propTypes = {
  issue: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  deleteIssueShareStartDispatch: PropTypes.func.isRequired,
  createIssueShareStartDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  deleteIssueShareStartDispatch: (updateObj) =>
    dispatch(deleteIssueShareStart(updateObj)),
  createIssueShareStartDispatch: (updateObj) =>
    dispatch(createIssueShareStart(updateObj)),
});

export default connect(null, mapDispatchToProps)(IssueActionButtons);
