export const data = [
  {
    _id: 'abc',
    update_id: '66b37dd9e9e2aec44c763f88',
    createdAt: '2024-08-13T13:59:53.231Z',
    categorization: 0,
    createdBy: {
      firstName: 'Michael',
      lastName: 'Newman',
      _id: '5ffe0bb7ea960a514ea0e4cc',
    },
    type: 'issue',
    actionType: 'Updated',
    project: 'p123',
    site: {
      _id: 's1',
      name: 'Karabunga',
    },
    milestone: {
      _id: 'm1',
      name: 'Preliminary Assessment',
    },
    content: {
      notes: 'Testing a multi photo and file comment.',
      photos: [
        {
          url: 'https://globalbuildtestnet.s3.us-east-2.amazonaws.com/e5a6a2c4703bc59806471a75a5e95a0b',
        },
      ],
      attachments: [
        {
          filename: 'b2373b44fbe6f51d2d2a6365410aa0f7.txt',
          extension: 'txt',
          url: 'https://globalbuildtestnet.s3.us-east-2.amazonaws.com/69a5f8ed4056d55ac2bdb6269a087361',
          filesize: '606 B',
          _id: '66b37dd9e9e2aec44c763f89',
        },
        {
          filename: '0e07155a53b753002640cea52fc54a9a.pdf',
          extension: 'pdf',
          url: 'https://globalbuildtestnet.s3.us-east-2.amazonaws.com/486f24de01aa41d4cc33edac0a96122f',
          filesize: '1.94 MB',
          _id: '66b37dd9e9e2aec44c763f8a',
        },
      ],
    },
    unread: true,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '8/13/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '1234',
    },
    type: 'issue',
    actionType: 'Updated',
    project: 'p123',
    site: {
      _id: 's1',
      name: 'Karabunga',
    },
    milestone: {
      _id: 'm1',
      name: 'Preliminary Assessment',
    },
    content: { notes: 'Here is more text!' },
    unread: true,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '8/12/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'issue',
    actionType: 'Updated',
    project: 'p123',
    site: {
      _id: 's1',
      name: 'Karabunga',
    },
    milestone: {
      _id: 'm1',
      name: 'Preliminary Assessment',
    },
    content: {
      notes:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
    unread: true,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '8/11/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'issue',
    actionType: 'Updated',
    project: 'p123',
    site: {
      _id: 's1',
      name: 'Karabunga',
    },
    milestone: {
      _id: 'm1',
      name: 'Preliminary Assessment',
    },
    content: {
      notes:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
    isDeleted: true,
    unread: true,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '6/1/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'issue',
    actionType: 'Field Submission',
    project: 'p123',
    refId: 'I2A',
    site: {
      _id: 's1',
      name: 'Karabunga',
    },
    milestone: {
      _id: 'm1',
      name: 'Preliminary Assessment',
    },
    content: {
      notes:
        'The site as debris and needs to be cleaned up in line with requirements of the EMMP',
    },
    unread: false,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '6/1/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'submission',
    actionType: 'Field Submission',
    project: 'p123',
    refId: 'S2',
    site: {
      _id: 's1',
      name: 'Karabunga',
    },
    milestone: {
      _id: 'm1',
      name: 'Preliminary Assessment',
    },
    content: {},
    unread: false,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '6/1/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'issue',
    categorization: 1,
    actionType: 'Recategorized',
    project: 'p123',
    refId: 'I2A',
    site: {
      _id: 's1',
      name: 'Karabunga',
    },
    milestone: {
      _id: 'm1',
      name: 'Preliminary Assessment',
    },
    content: {},
    unread: false,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '6/1/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'issue',
    categorization: 1,
    actionType: 'Restored',
    project: 'p123',
    refId: 'I2A',
    site: {
      _id: 's1',
      name: 'Madigascar',
    },
    milestone: {
      _id: 'm1',
      name: 'Tow Truck Operation',
    },
    content: {},
    unread: false,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '6/1/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'issue',
    categorization: 1,
    actionType: 'Deleted',
    project: 'p123',
    refId: 'I2A',
    site: {
      _id: 's1',
      name: 'Madigascar',
    },
    milestone: {
      _id: 'm1',
      name: 'Tow Truck Operation',
    },
    content: {},
    unread: false,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '6/11/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'issue',
    actionType: 'Share Link Comment',
    project: 'p123',
    site: {
      _id: 's1',
      name: 'Karabunga',
    },
    milestone: {
      _id: 'm1',
      name: 'Preliminary Assessment',
    },
    content: {
      notes:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    },
    isDeleted: false,
    unread: true,
  },
  {
    _id: 'defg',
    update_id: 'u101',
    createdAt: '6/1/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'issue',
    categorization: 1,
    actionType: 'Resolved',
    project: 'p123',
    refId: 'I2A',
    site: {
      _id: 's1',
      name: 'Madigascar',
    },
    milestone: {
      _id: 'm1',
      name: 'Tow Truck Operation',
    },
    content: {},
    unread: false,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '6/1/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'issue',
    categorization: 1,
    actionType: 'Reopened',
    project: 'p123',
    refId: 'I2A',
    site: {
      _id: 's1',
      name: 'Madigascar',
    },
    milestone: {
      _id: 'm1',
      name: 'Tow Truck Operation',
    },
    content: {},
    unread: false,
  },
  {
    _id: 'abc',
    update_id: 'u101',
    createdAt: '5/1/2024',
    createdBy: {
      firstName: 'Mike',
      lastName: 'Newman',
      _id: '5678',
    },
    type: 'milestone',
    categorization: 1,
    actionType: 'Status Change',
    status: 'Ongoing',
    project: 'p123',
    refId: 'M-TOW',
    site: {
      _id: 's1',
      name: 'Madigascar',
    },
    milestone: {
      _id: 'm1',
      name: 'Tow Truck',
    },
    content: {},
    unread: false,
  },
];
