// Utility Functions
import {
  getMonthDiff,
  getYearDiff,
  monthsList,
  daysInMonth,
  sortDate,
  formatDateTime,
} from '../../utils/utilityFns';

// Constants
import {
  WEEK,
  DAY,
  GANTT_ABBR_MOS as abbrMo,
  GANTT_ABBR_WKS as abbrWk,
  GANTT_COL_1 as gc1,
  GANTT_COL_2 as gc2,
  GANTT_TODAY as gT,
} from '../../utils/constants';

export const removeMods = (mods, idArr) => {
  idArr.forEach((id) => {
    delete mods[id];
  });
  return mods;
};

export const addMods = (mods, newModsObj) => {
  Object.keys(newModsObj).forEach((modId) => {
    const nothingChanged =
      newModsObj[modId].originalDatePlannedStart ===
        newModsObj[modId].datePlannedStart &&
      newModsObj[modId].originalDatePlannedEnd ===
        newModsObj[modId].datePlannedEnd &&
      newModsObj[modId].originalDateActualStart ===
        newModsObj[modId].dateActualStart &&
      newModsObj[modId].originalDateActualEnd ===
        newModsObj[modId].dateActualEnd &&
      newModsObj[modId].originalActualStatus ===
        newModsObj[modId].actualStatus &&
      newModsObj[modId].originalIsCompleted === newModsObj[modId].isCompleted;

    // If nothing changed, just return mods object as it stands
    if (nothingChanged) {
      return mods;
    }

    if (!mods.hasOwnProperty(modId)) {
      // New Mod? Add to Mod Array including original values
      const { updateType, tooltipStr } = genGanttUpdateTooltipString(
        newModsObj[modId]
      );

      // Create Update Object
      const temp = {
        _id: modId,
        datePlannedStart: newModsObj[modId].datePlannedStart,
        datePlannedEnd: newModsObj[modId].datePlannedEnd,
        dateActualStart: newModsObj[modId].dateActualStart,
        dateActualEnd: newModsObj[modId].dateActualEnd,
        actualStatus: newModsObj[modId].actualStatus,
        update: {
          actionType: updateType,
          actualStatus: newModsObj[modId].actualStatus,
          statusChangeMeta: {
            oldStatus: newModsObj[modId].originalActualStatus,
            newStatus: newModsObj[modId].actualStatus,
          },
          dateChangeMeta: {
            oldDateObj: {
              datePlannedStart: newModsObj[modId].originalDatePlannedStart,
              datePlannedEnd: newModsObj[modId].originalDatePlannedEnd,
              dateActualStart: newModsObj[modId].originalDateActualStart,
              dateActualEnd: newModsObj[modId].originalDatePlannedEnd,
            },
            newDateObj: {
              datePlannedStart: newModsObj[modId].datePlannedStart,
              datePlannedEnd: newModsObj[modId].datePlannedEnd,
              dateActualStart: newModsObj[modId].dateActualStart,
              dateActualEnd: newModsObj[modId].dateActualEnd,
            },
          },
        },
      };

      // if (updateType === 'Date Change') {
      //   delete temp.update.statusChangeMeta;
      //   delete temp.actualStatus;
      // } else if (updateType === 'Status Change') {
      //   delete temp.update.dateChangeMeta;
      //   delete temp.datePlannedStart;
      //   delete temp.datePlannedEnd;
      //   delete temp.dateActualStart;
      //   delete temp.dateActualEnd;
      // }

      Object.assign(mods, {
        [modId]: { ...newModsObj[modId], tooltipStr, updateObj: temp },
      });
    } else if (
      // Mod Reverts to Original State? Remove from Array
      mods[modId].originalDatePlannedStart ===
        newModsObj[modId].datePlannedStart &&
      mods[modId].originalDatePlannedEnd === newModsObj[modId].datePlannedEnd &&
      mods[modId].originalDateActualStart ===
        newModsObj[modId].dateActualStart &&
      mods[modId].originalDateActualEnd === newModsObj[modId].dateActualEnd &&
      mods[modId].originalActualStatus === newModsObj[modId].actualStatus &&
      mods[modId].originalIsCompleted === newModsObj[modId].isCompleted // TODO DO WE NEED THIS
    ) {
      delete mods[modId];
    } else {
      // Mod Makes a Change? Overwrite new values, leave originals unchanged
      mods[modId].datePlannedStart = newModsObj[modId].datePlannedStart;
      mods[modId].datePlannedEnd = newModsObj[modId].datePlannedEnd;
      mods[modId].dateActualStart = newModsObj[modId].dateActualStart;
      mods[modId].dateActualEnd = newModsObj[modId].dateActualEnd;
      mods[modId].isCompleted = newModsObj[modId].isCompleted;
      mods[modId].actualStatus = newModsObj[modId].actualStatus;

      const { updateType, tooltipStr } = genGanttUpdateTooltipString(
        mods[modId]
      );

      // Update Tooltip
      mods[modId].tooltipStr = tooltipStr;

      // Update Update Object
      const temp = {
        _id: modId,
        datePlannedStart: mods[modId].datePlannedStart,
        datePlannedEnd: mods[modId].datePlannedEnd,
        dateActualStart: mods[modId].dateActualStart,
        dateActualEnd: mods[modId].dateActualEnd,
        actualStatus: mods[modId].actualStatus,
        update: {
          actionType: updateType,
          actualStatus: mods[modId].actualStatus,
          statusChangeMeta: {
            oldStatus: mods[modId].originalActualStatus,
            newStatus: mods[modId].actualStatus,
          },
          dateChangeMeta: {
            oldDateObj: {
              datePlannedStart: mods[modId].originalDatePlannedStart,
              datePlannedEnd: mods[modId].originalDatePlannedEnd,
              dateActualStart: mods[modId].originalDateActualStart,
              dateActualEnd: mods[modId].originalDatePlannedEnd,
            },
            newDateObj: {
              datePlannedStart: mods[modId].datePlannedStart,
              datePlannedEnd: mods[modId].datePlannedEnd,
              dateActualStart: mods[modId].dateActualStart,
              dateActualEnd: mods[modId].dateActualEnd,
            },
          },
        },
      };

      // if (updateType === 'Date Change') {
      //   delete temp.update.statusChangeMeta;
      //   delete temp.actualStatus;
      // } else if (updateType === 'Status Change') {
      //   delete temp.update.dateChangeMeta;
      //   delete temp.datePlannedStart;
      //   delete temp.datePlannedEnd;
      //   delete temp.dateActualStart;
      //   delete temp.dateActualEnd;
      // }

      mods[modId].updateObj = temp;
    }
  });

  return mods;
};

export const calculateProjectMilestoneOverview = (milestones) => {
  const projectStatus = milestonesBySite(milestones);

  const completed = Object.values(milestones).filter(
    (milestone) => milestone.actualStatus === 'Completed'
  ).length;

  const ongoing = Object.values(milestones).filter(
    (milestone) => milestone.actualStatus === 'Ongoing'
  ).length;

  const offTrack = Object.values(milestones).filter(
    (milestone) =>
      milestone.actualStatus === 'Off Track' ||
      milestone.actualStatus === 'Stopped'
  ).length;

  const notStarted = Object.values(milestones).filter(
    (milestone) => milestone.actualStatus === 'Not Started'
  ).length;

  const total = Object.keys(milestones).length;

  let obj = {
    totalMilestones: total,
    metMilestones: completed,
    ongoingMilestones: ongoing,
    offTrackMilestones: offTrack,
    notStartedMilestones: notStarted,
    completedSites: projectStatus.filter((el) => el === 'Completed').length,
    stoppedSites: projectStatus.filter(
      (el) => el === 'Stopped' || el === 'Off Track'
    ).length,
    ongoingSites: projectStatus.filter((el) => el === 'Ongoing').length,
    notStartedSites: projectStatus.filter((el) => el === 'Not Started').length,
    progressPercent: total === 0 ? 0 : Math.round((completed / total) * 100),
    type: 'Project',
  };
  return obj;
};

export const calculateSiteMilestoneOverview = (milestones) => {
  let obj = {};

  const uniqueSites = [
    ...new Set(Object.values(milestones).map((el) => el.site)),
  ];

  uniqueSites.forEach((siteId) => {
    const siteMilestones = Object.values(milestones).filter(
      (el) => el.site === siteId
    );
    const siteMilestoneStatuses = siteMilestones.map(
      (milestone) => milestone.actualStatus
    );

    const completed = siteMilestones.filter(
      (milestone) => milestone.actualStatus === 'Completed'
    ).length;

    const ongoing = siteMilestones.filter(
      (milestone) => milestone.actualStatus === 'Ongoing'
    ).length;

    const offTrack = siteMilestones.filter(
      (milestone) =>
        milestone.actualStatus === 'Off Track' ||
        milestone.actualStatus === 'Stopped'
    ).length;

    const notStarted = siteMilestones.filter(
      (milestone) => milestone.actualStatus === 'Not Started'
    ).length;

    const total = siteMilestones.length;

    Object.assign(obj, {
      [siteId]: {
        totalMilestones: total,
        metMilestones: completed,
        ongoingMilestones: ongoing,
        offTrackMilestones: offTrack,
        notStartedMilestones: notStarted,
        actualStatus: calcSiteStatus(siteMilestoneStatuses),
        progressPercent:
          total === 0 ? 0 : Math.round((completed / total) * 100),
        type: 'Site',
        siteId: siteId,
      },
    });
  });

  return obj;
};

export const calculateSiteMilestoneDetails = (milestones, siteObj) => {
  let obj = {};

  const uniqueSites = [
    ...new Set(Object.values(milestones).map((el) => el.site)),
  ];

  uniqueSites.forEach((siteId) => {
    const siteMilestones = Object.values(milestones).filter(
      (el) => el.site === siteId
    );

    // Need to add status to each milestone
    const siteMilestoneDetails = siteMilestones.map((el) => {
      const details = generateTextDateString(el);
      return { ...el, ...details, milestoneName: el.name };
    });

    const siteMilestoneStatuses = siteMilestones.map(
      (milestone) => milestone.actualStatus
    );

    const siteName = siteObj.hasOwnProperty(siteId)
      ? siteObj[siteId]
      : 'No Site';

    Object.assign(obj, {
      [siteId]: {
        siteName: siteName,
        allMilestones: siteMilestoneDetails,
        totalMilestones: siteMilestones.length,
        metMilestones: siteMilestones.filter(
          (milestone) => milestone.isCompleted === true
        ).length,
        actualStatus: calcSiteStatus(siteMilestoneStatuses),
      },
    });
  });

  return obj;
};

const genGanttUpdateTooltipString = (mods) => {
  /*
    1. Planned timeline moved back/forward from DATE to DATE and extended or shortened by X days.
    2. Actual moved back/forward from DATE to DATE and extended or shorted by X days.
    3. Status changed from X to Y
    if click, alert, do you want to undo this change? Yes/No
  */
  let tooltipStr = '';
  let updateType = '';
  let statusChange = false;
  let dateChange = false;
  const datePlannedStart = new Date(mods.datePlannedStart);
  const datePlannedEnd = new Date(mods.datePlannedEnd);
  const dateActualStart = new Date(mods.dateActualStart);
  const dateActualEnd = new Date(mods.dateActualEnd);
  const originalDatePlannedStart = new Date(mods.originalDatePlannedStart);
  const originalDatePlannedEnd = new Date(mods.originalDatePlannedEnd);
  const originalDateActualStart = new Date(mods.originalDateActualStart);
  const originalDateActualEnd = new Date(mods.originalDateActualEnd);
  const originalActualStatus = mods.originalActualStatus;
  const currentActualStatus = mods.actualStatus;

  // Status
  if (originalActualStatus !== currentActualStatus) {
    tooltipStr += `Status changed from ${originalActualStatus} to ${currentActualStatus}. `;
    statusChange = true;
  }

  // Actual Start Date
  let actualStartMoved = false;
  const daysMovedActual = Math.ceil(
    (dateActualStart - originalDateActualStart) / (1000 * 60 * 60 * 24)
  );
  if (daysMovedActual !== 0) {
    actualStartMoved = true;
    if (daysMovedActual < 0) {
      tooltipStr += `Actual start moved back ${Math.abs(
        daysMovedActual
      )} days from ${formatDateTime(
        mods.originalDateActualStart,
        'default'
      )} to ${formatDateTime(mods.dateActualStart, 'default')}`;
    } else if (daysMovedActual > 0) {
      tooltipStr += `Actual start moved forward ${Math.abs(
        daysMovedActual
      )} days from ${formatDateTime(
        mods.originalDateActualStart,
        'noYear'
      )} to ${formatDateTime(mods.dateActualStart, 'default')}`;
    }
    dateChange = true;
  }

  // Actual Duration
  const daysAdjustedActual = Math.ceil(
    (dateActualEnd -
      dateActualStart -
      (originalDateActualEnd - originalDateActualStart)) /
      (1000 * 60 * 60 * 24)
  );

  if (daysAdjustedActual !== 0) {
    if (!actualStartMoved) {
      tooltipStr += 'Actual';
    } else {
      tooltipStr += ' and';
    }

    if (daysAdjustedActual < 0) {
      tooltipStr += ` duration shortened by ${Math.abs(
        daysAdjustedActual
      )} days. `;
    } else if (daysAdjustedActual > 0) {
      tooltipStr += ` duration extended by ${Math.abs(
        daysAdjustedActual
      )} days. `; // from X to Y days.
    }
    dateChange = true;
  } else if (actualStartMoved) {
    tooltipStr += '. ';
  }

  // Planned Start Date
  let planStartMoved = false;
  const daysMovedPlanned = Math.ceil(
    (datePlannedStart - originalDatePlannedStart) / (1000 * 60 * 60 * 24)
  );
  if (daysMovedPlanned !== 0) {
    planStartMoved = true;
    if (daysMovedPlanned < 0) {
      tooltipStr += `Planned start moved back ${Math.abs(
        daysMovedPlanned
      )} days from ${formatDateTime(
        mods.originalDatePlannedStart,
        'default'
      )} to ${formatDateTime(mods.datePlannedStart, 'default')}`;
    } else if (daysMovedPlanned > 0) {
      tooltipStr += `Planned start moved forward ${Math.abs(
        daysMovedPlanned
      )} days from ${formatDateTime(
        mods.originalDatePlannedStart,
        'noYear'
      )} to ${formatDateTime(mods.datePlannedStart, 'default')}`;
    }
    dateChange = true;
  }

  // Planned Duration
  const daysAdjustedPlanned = Math.ceil(
    (datePlannedEnd -
      datePlannedStart -
      (originalDatePlannedEnd - originalDatePlannedStart)) /
      (1000 * 60 * 60 * 24)
  );

  if (daysAdjustedPlanned !== 0) {
    if (!planStartMoved) {
      tooltipStr += 'Planned';
    } else {
      tooltipStr += ' and';
    }

    if (daysAdjustedPlanned < 0) {
      tooltipStr += ` duration shortened by ${Math.abs(
        daysAdjustedPlanned
      )} days. `;
    } else if (daysAdjustedPlanned > 0) {
      tooltipStr += ` duration extended by ${Math.abs(
        daysAdjustedPlanned
      )} days. `;
    }
    dateChange = true;
  } else if (planStartMoved) {
    tooltipStr += '.';
  }

  if (dateChange && statusChange) {
    updateType = 'Date and Status Change';
  } else if (dateChange) {
    updateType = 'Date Change';
  } else if (statusChange) {
    updateType = 'Status Change';
  } else {
    updateType = 'None';
  }

  return { updateType, tooltipStr };
};

const generateTextDateString = (el) => {
  if (el.hasOwnProperty('hideFromGantt') && el.hideFromGantt === true) {
    return {
      daysOverdue: 0,
      daysAhead: 0,
      daysLate: 0,
      daysEarly: 0,
      actualStartMessage: 'No dates associated with this milestone.',
    };
  }

  const now = new Date();
  const daysOverdue =
    Date.parse(el.dateActualEnd) > Date.parse(el.datePlannedEnd)
      ? Math.floor(
          (Date.parse(el.dateActualEnd) - Date.parse(el.datePlannedEnd)) / DAY
        )
      : 0;

  const daysAhead =
    Date.parse(el.dateActualEnd) < Date.parse(el.datePlannedEnd)
      ? Math.floor(
          (Date.parse(el.datePlannedEnd) - Date.parse(el.dateActualEnd)) / DAY
        )
      : 0;

  const daysLate =
    Date.parse(el.dateActualStart) > Date.parse(el.datePlannedStart)
      ? Math.floor(
          (Date.parse(el.dateActualStart) - Date.parse(el.datePlannedStart)) /
            DAY
        )
      : 0;

  const daysEarly =
    Date.parse(el.dateActualStart) < Date.parse(el.datePlannedStart)
      ? Math.floor(
          (Date.parse(el.datePlannedStart) - Date.parse(el.dateActualStart)) /
            DAY
        )
      : 0;

  // Generate Text String
  let actualStartMessage;
  if (el.actualStatus !== 'Not Started') {
    if (daysOverdue > 0) {
      if (daysEarly > 0) {
        // Overdue with early start still ongoing or completed
        actualStartMessage =
          el.actualStatus === 'Completed'
            ? `Started ${daysEarly} days early and completed ${daysOverdue} days late.`
            : `Started ${daysEarly} days early and is now late by ${daysOverdue} days.`;
      } else if (daysLate > 0) {
        // Overdue with late start still ongoing or completed
        actualStartMessage =
          el.actualStatus === 'Completed'
            ? `Started ${daysLate} days late and completed ${daysOverdue} days late.`
            : `Started ${daysLate} days late and is now late by ${daysOverdue} days.`;
      } else {
        // Overdue with ontime start still ongoing or completed
        actualStartMessage =
          el.actualStatus === 'Completed'
            ? `Started on time and completed ${daysOverdue} days late.`
            : `Started on time and is now late by ${daysOverdue} days.`;
      }
    } else if (daysAhead > 0) {
      // On Time or Ongoing with early start
      if (daysEarly > 0) {
        actualStartMessage =
          el.actualStatus === 'Completed'
            ? `Started ${daysEarly} days early and completed ${daysAhead} days ahead of schedule.`
            : `Started ${daysEarly} days early and scheduled for another ${
                Math.ceil(
                  (Date.parse(el.datePlannedEnd) - Date.parse(now)) / DAY
                ) + 1
              } days.`;
      } else {
        // On Time or Ongoing with normal start
        actualStartMessage =
          el.actualStatus === 'Completed'
            ? `Started on time and completed ${daysAhead} days ahead of schedule.`
            : `Started on time and scheduled for another ${
                Math.ceil(
                  (Date.parse(el.datePlannedEnd) - Date.parse(now)) / DAY
                ) + 1
              } days.`;
      }
    } else if (daysEarly > 0) {
      actualStartMessage =
        el.actualStatus === 'Completed'
          ? `Started ${daysEarly} days early and completed on time.`
          : `Started ${daysEarly} days early and scheduled for another ${
              Math.ceil(
                (Date.parse(el.datePlannedEnd) - Date.parse(now)) / DAY
              ) + 1
            } days.`; // DUPLICATE
    } else if (daysLate > 0) {
      actualStartMessage =
        el.actualStatus === 'Completed'
          ? `Started ${daysLate} days late and completed on time.`
          : `Started ${daysLate} days late and scheduled for another ${
              Math.ceil(
                (Date.parse(el.datePlannedEnd) - Date.parse(now)) / DAY
              ) + 1
            } days.`;
    } else {
      actualStartMessage = 'Completed on schedule.';
    }
  } else {
    // Not Started and Upcoming
    if (Date.parse(el.datePlannedStart) > Date.parse(now)) {
      actualStartMessage = `Scheduled to begin in ${
        Math.ceil((Date.parse(el.datePlannedStart) - Date.parse(now)) / DAY) + 1
      } days.`;
    } else {
      // Not Started and Late
      actualStartMessage = `Scheduled to begin ${
        Math.ceil((Date.parse(now) - Date.parse(el.datePlannedStart)) / DAY) + 1
      } days ago.`;
    }
  }

  return {
    daysOverdue,
    daysAhead,
    daysLate,
    daysEarly,
    actualStartMessage,
  };
};

const checkGanttDates = (milestone) => {
  return (
    milestone.hasOwnProperty('datePlannedStart') &&
    milestone.hasOwnProperty('datePlannedEnd') &&
    milestone.hasOwnProperty('dateActualStart') &&
    milestone.hasOwnProperty('dateActualEnd') &&
    Date.parse(milestone.datePlannedStart) <=
      Date.parse(milestone.datePlannedEnd) &&
    Date.parse(milestone.dateActualStart) <= Date.parse(milestone.dateActualEnd)
  );
};

const calcGanttDays = (arr, numDays, startDateGantt, now) => {
  const newArr = arr
    .filter((n) => n)
    .map((el) => {
      const dayPlannedStart = Math.ceil(
        (Date.parse(el.datePlannedStart) - startDateGantt) / DAY + 1
      );
      const dayPlannedEnd =
        Math.ceil((Date.parse(el.datePlannedEnd) - startDateGantt) / DAY) + 1;

      const dayActualStart =
        Math.ceil((Date.parse(el.dateActualStart) - startDateGantt) / DAY) + 1;
      const dayActualEnd =
        Math.ceil((Date.parse(el.dateActualEnd) - startDateGantt) / DAY) + 1;

      const daysOverdue =
        Date.parse(el.dateActualEnd) > Date.parse(el.datePlannedEnd)
          ? Math.floor(
              (Date.parse(el.dateActualEnd) - Date.parse(el.datePlannedEnd)) /
                DAY
            )
          : 0;

      const daysAhead =
        Date.parse(el.dateActualEnd) < Date.parse(el.datePlannedEnd)
          ? Math.floor(
              (Date.parse(el.datePlannedEnd) - Date.parse(el.dateActualEnd)) /
                DAY
            )
          : 0;

      const daysLate =
        Date.parse(el.dateActualStart) > Date.parse(el.datePlannedStart)
          ? Math.floor(
              (Date.parse(el.dateActualStart) -
                Date.parse(el.datePlannedStart)) /
                DAY
            )
          : 0;

      const daysEarly =
        Date.parse(el.dateActualStart) < Date.parse(el.datePlannedStart)
          ? Math.floor(
              (Date.parse(el.datePlannedStart) -
                Date.parse(el.dateActualStart)) /
                DAY
            )
          : 0;

      // Generate Text String
      let actualStartMessage;
      if (el.hasOwnProperty('hideFromGantt') && el.hideFromGantt === true) {
        actualStartMessage = 'No dates associated with this milestone.';
      } else {
        if (el.actualStatus !== 'Not Started') {
          if (daysOverdue > 0) {
            if (daysEarly > 0) {
              // Overdue with early start still ongoing or completed
              actualStartMessage =
                el.actualStatus === 'Completed'
                  ? `Started ${daysEarly} days early and completed ${daysOverdue} days late.`
                  : `Started ${daysEarly} days early and is now late by ${daysOverdue} days.`;
            } else if (daysLate > 0) {
              // Overdue with late start still ongoing or completed
              actualStartMessage =
                el.actualStatus === 'Completed'
                  ? `Started ${daysLate} days late and completed ${daysOverdue} days late.`
                  : `Started ${daysLate} days late and is now late by ${daysOverdue} days.`;
            } else {
              // Overdue with ontime start still ongoing or completed
              actualStartMessage =
                el.actualStatus === 'Completed'
                  ? `Started on time and completed ${daysOverdue} days late.`
                  : `Started on time and is now late by ${daysOverdue} days.`;
            }
          } else if (daysAhead > 0) {
            // On Time or Ongoing with early start
            if (daysEarly > 0) {
              actualStartMessage =
                el.actualStatus === 'Completed'
                  ? `Started ${daysEarly} days early and completed ${daysAhead} days ahead of schedule.`
                  : `Started ${daysEarly} days early and scheduled for another ${
                      Math.ceil(
                        (Date.parse(el.datePlannedEnd) - Date.parse(now)) / DAY
                      ) + 1
                    } days.`;
            } else {
              // On Time or Ongoing with normal start
              actualStartMessage =
                el.actualStatus === 'Completed'
                  ? `Started on time and completed ${daysAhead} days ahead of schedule.`
                  : `Started on time and scheduled for another ${
                      Math.ceil(
                        (Date.parse(el.datePlannedEnd) - Date.parse(now)) / DAY
                      ) + 1
                    } days.`;
            }
          } else if (daysEarly > 0) {
            actualStartMessage =
              el.actualStatus === 'Completed'
                ? `Started ${daysEarly} days early and completed on time.`
                : `Started ${daysEarly} days early and scheduled for another ${
                    Math.ceil(
                      (Date.parse(el.datePlannedEnd) - Date.parse(now)) / DAY
                    ) + 1
                  } days.`; // DUPLICATE
          } else if (daysLate > 0) {
            actualStartMessage =
              el.actualStatus === 'Completed'
                ? `Started ${daysLate} days late and completed on time.`
                : `Started ${daysLate} days late and scheduled for another ${
                    Math.ceil(
                      (Date.parse(el.datePlannedEnd) - Date.parse(now)) / DAY
                    ) + 1
                  } days.`;
          } else {
            actualStartMessage = 'Completed on schedule.';
          }
        } else {
          // Not Started and Upcoming
          if (Date.parse(el.datePlannedStart) > Date.parse(now)) {
            actualStartMessage = `Scheduled to begin in ${
              Math.ceil(
                (Date.parse(el.datePlannedStart) - Date.parse(now)) / DAY
              ) + 1
            } days.`;
          } else {
            // Not Started and Late
            actualStartMessage = `Scheduled to begin ${
              Math.ceil(
                (Date.parse(now) - Date.parse(el.datePlannedStart)) / DAY
              ) + 1
            } days ago.`;
          }
        }
      }

      const actualTimelineGrid = `${dayActualStart - 1}fr ${
        dayActualEnd - dayActualStart + 1
      }fr ${numDays - dayActualEnd}fr`;

      const plannedTimelineGrid = `${dayPlannedStart - 1}fr ${
        dayPlannedEnd - dayPlannedStart + 1
      }fr ${numDays - dayPlannedEnd}fr`;

      return {
        ...el,
        planStartStr: formatDateTime(el.datePlannedStart, 'noYear'),
        planEndStr: formatDateTime(el.datePlannedEnd, 'noYear'),
        actualStartStr: formatDateTime(el.dateActualStart, 'noYear'),
        actualEndStr: formatDateTime(el.dateActualEnd, 'noYear'),
        dayPlannedStart,
        dayPlannedEnd,
        dayActualStart,
        dayActualEnd,
        daysOverdue,
        daysAhead,
        daysLate,
        daysEarly,
        actualStartMessage,
        actualTimelineGrid,
        plannedTimelineGrid,
        durationPlanned: dayPlannedEnd - dayPlannedStart + 1,
        durationActual: dayActualEnd - dayActualStart + 1,
      };
    });
  return newArr;
};

const milestonesBySite = (milestones) => {
  const uniqueSites = [
    ...new Set(Object.values(milestones).map((el) => el.site)),
  ];
  const actualStatus = uniqueSites.map((siteId) => {
    const siteMilestones = Object.values(milestones)
      .filter((el) => el.site === siteId)
      .map((el) => el.actualStatus);
    return calcSiteStatus(siteMilestones);
  });
  return actualStatus;
};

const calcSiteStatus = (siteMilestones) => {
  let actualStatus;
  const n = siteMilestones.length;
  if (n === 0 || n === undefined) {
    actualStatus = 'Not Started';
  } else if (
    siteMilestones.some((el) => el === 'Stopped' || el === 'Off Track')
  ) {
    actualStatus = 'Off Track';
  } else if (siteMilestones.every((el) => el === 'Not Started')) {
    actualStatus = 'Not Started';
  } else if (siteMilestones.every((el) => el === 'Completed')) {
    actualStatus = 'Completed';
  } else {
    actualStatus = 'Ongoing';
  }
  return actualStatus;
};

// Calculate Gantt Chart Requirements from array of site and milestone objects
export const calcMilestonesGantt = (
  milestonesAll,
  sitesAll,
  editMode,
  mods
) => {
  // 0. Check milestones for proper dates
  const now = new Date();
  Object.keys(milestonesAll).forEach((id) => {
    if (checkGanttDates(milestonesAll[id]) === false) {
      delete milestonesAll[id];
      console.log('Bad Gantt Date Skipping Milestone', id);
    }
  });

  // 1. Create Milestone Array, Create Hidden Milestone Array, Remove Unnescessary Fields
  const hiddenArrTemp = Object.values(milestonesAll)
    .filter((el) => el.hideFromGantt)
    .map(
      ({ updates, images, componentCount, form, id, name, site, ...keys }) => ({
        ...keys,
        type: 'milestone',
        toggleId: site,
        milestoneId: id,
        milestoneName: name,
        siteId: site,
        siteName: '',
      })
    );

  const milestones = Object.values(milestonesAll)
    .filter((el) => !el.hideFromGantt)
    .map(
      ({ updates, images, componentCount, form, id, name, site, ...keys }) => ({
        ...keys,
        type: 'milestone',
        toggleId: site,
        milestoneId: id,
        milestoneName: name,
        siteId: site,
        siteName: '',
      })
    );

  // 1b. Check if editMode, if TRUE, apply mods
  if (editMode === true) {
    Object.keys(mods).forEach((modId) => {
      const idx = milestones.findIndex((m) => m._id === modId);
      if (idx >= 0) {
        milestones[idx].datePlannedStart = mods[modId].datePlannedStart;
        milestones[idx].datePlannedEnd = mods[modId].datePlannedEnd;
        milestones[idx].dateActualStart = mods[modId].dateActualStart;
        milestones[idx].dateActualEnd = mods[modId].dateActualEnd;
        milestones[idx].isCompleted = mods[modId].isCompleted; //Do we actually need to do this? YES!!!! unless we stop using isCompleted and check for actualStatus === Completed
        milestones[idx].actualStatus = mods[modId].actualStatus;
      }
    });
  }

  // 2. Calculate Site Parameters
  const sites = Object.values(sitesAll).map((site) => {
    const siteMilestones = milestones.filter(
      (milestone) => milestone.siteId === site._id
    );

    if (siteMilestones.length === 0) {
      return null;
    }

    // Calculate Percent Completed for Site
    const numCompleted = siteMilestones.reduce((acc, el) => {
      return el.isCompleted ? acc + 1 : acc;
    }, 0);
    const numMilestones = siteMilestones.length;
    const percentCompleted =
      numMilestones > 0
        ? Math.round((numCompleted / numMilestones) * 1000) / 10
        : 0;

    // Calculate Site Status from Milestones
    const actualStatus = calcSiteStatus(
      siteMilestones.map((el) => el.actualStatus)
    );

    // Calculate Key Dates for Site
    const [{ datePlannedStart }] = siteMilestones.sort(
      sortDate('datePlannedStart', 'asc')
    );

    const [{ datePlannedEnd }] = siteMilestones.sort(
      sortDate('datePlannedEnd', 'desc')
    );
    const [{ dateActualStart }] = siteMilestones.sort(
      sortDate('dateActualStart', 'asc')
    );
    let [{ dateActualEnd }] = siteMilestones.sort(
      sortDate('dateActualEnd', 'desc')
    );

    // If a site is ongoing override the actualEnd date to NOW
    if (actualStatus === 'Ongoing') {
      dateActualEnd = now;
    }

    return {
      _id: site._id,
      siteId: site._id,
      siteName: site.shortName ? site.shortName : site.name,
      poc: site.poc,
      datePlannedStart,
      datePlannedEnd,
      dateActualStart,
      dateActualEnd,
      actualStatus,
      percentCompleted,
      toggleId: site._id,
      type: 'site',
    };
  });

  // 3. Calculate Gantt Chart Start and End Dates and Other Properties
  const mNow = now.getMonth();
  const yNow = now.getFullYear();
  const startDateCal = new Date(
    milestones.reduce((pre, cur) => {
      let dateCur =
        cur.dateActualStart < cur.datePlannedStart
          ? cur.dateActualStart
          : cur.datePlannedStart;
      return Date.parse(pre) > Date.parse(dateCur) ? dateCur : pre;
    }, now)
  );

  const endDateCal = new Date(
    milestones.reduce((pre, cur) => {
      let dateCur =
        cur.dateActualEnd > cur.datePlannedEnd
          ? cur.dateActualEnd
          : cur.datePlannedEnd;
      return Date.parse(pre) < Date.parse(dateCur) ? dateCur : pre;
    }, now)
  );

  // Calculate Num Years (account for partial start/ending years) FIXME not right since adding months end but may be OK
  const numYears = getYearDiff(startDateCal, endDateCal);
  const startYearCal = startDateCal.getFullYear();
  const calYearArr = [...Array(numYears).keys()].map((el) => el + startYearCal);

  // Remember: Months are index 0 - 11
  const numMonths = getMonthDiff(startDateCal, endDateCal);
  const startMonthCal = startDateCal.getMonth();
  const calMonthArray = Array(numMonths)
    .fill(monthsList)
    .flat()
    .slice(startMonthCal, startMonthCal + numMonths + 2); // Note: This adds a month to the end

  let yearStr = `${gc1} ${gc2}`;
  for (let idx = 0; idx < calYearArr.length; idx++) {
    if (idx === 0) {
      yearStr += ` ${12 - startMonthCal}fr`;
    } else if (idx === calYearArr.length - 1) {
      yearStr += ` ${
        numMonths - (12 - startMonthCal) - (numYears - 2) * 12 + 1
      }fr`;
    } else {
      yearStr += ` 12fr`;
    }
  }

  let yNum = 0;
  let mNum = startMonthCal;
  let highlightMonth = 0;
  let monthStr = `${gc1} ${gc2}`;
  let numDays = 0;

  calMonthArray.forEach((el) => {
    if (el === 'Feb') {
      monthStr += ` ${daysInMonth(el, calYearArr[yNum])}fr`;
      numDays += daysInMonth(el, calYearArr[yNum]);
    } else {
      monthStr += ` ${daysInMonth(el)}fr`;
      numDays += daysInMonth(el);
    }

    if (monthsList[mNow] === el && yNow === calYearArr[yNum]) {
      highlightMonth = mNum - startMonthCal;
    }

    if (mNum > yNum * 12 + 12) {
      yNum++;
    }

    mNum++;
  });

  const calMonthArr =
    calMonthArray.length > abbrMo
      ? calMonthArray.map((el) => el.slice(0, 1))
      : calMonthArray;

  const startDateGantt = new Date(`${startMonthCal + 1}/1/${startYearCal}`);
  const daysTillToday = Math.ceil((now - startDateGantt) / DAY + 1);
  const daysAfterToday = numDays - daysTillToday;
  const todayLineGrid = `${gc1} ${gc2} ${daysTillToday}fr ${gT} 0px ${daysAfterToday}fr`;

  // Calculate Num Weeks with proper offset array
  const totalWeeks = Math.ceil(numDays / 7);
  const numWeeksOffset = Math.floor(
    Math.abs(startDateGantt - startDateCal) / WEEK
  );
  const weeks = totalWeeks - numWeeksOffset;
  const mapFunc =
    totalWeeks > abbrWk
      ? totalWeeks > abbrWk * 2
        ? (el) => ((el + 1) % 4 === 0 ? el + 1 : '')
        : (el) => ((el + 1) % 2 === 0 ? '·' : el + 1)
      : (el) => el + 1;
  const offsetArray = [...Array(numWeeksOffset).keys()].map((el) => '');
  const weekArr = [...Array(weeks > 0 ? weeks : 0).keys()].map(mapFunc);
  const calWeekArr = [...offsetArray, ...weekArr];

  const weekRepeater = `repeat(${calWeekArr.length},1fr)`;
  const weekStr = `${gc1} ${gc2} ${weekRepeater}`;

  // 4. Loop over milestone array and assign proper week numbers for each Milestone
  const milestoneArr = calcGanttDays(milestones, numDays, startDateGantt, now);
  const hiddenArr = calcGanttDays(hiddenArrTemp, numDays, startDateGantt, now);

  // 5. Loop over site array and assign proper week numbers for each site
  const siteArr = calcGanttDays(sites, numDays, startDateGantt, now);

  // 6. Sort and assemble array of sites and milestones
  const ganttArr = [...milestoneArr, ...siteArr]
    .filter((el) => el.type === 'site')
    .sort(sortDate('datePlannedStart', 'asc'))
    .map((site) => {
      return [
        site,
        milestoneArr
          .filter(
            (milestone) =>
              milestone.type === 'milestone' && milestone.siteId === site._id
          )
          .sort(sortDate('datePlannedStart', 'asc')),
      ];
    })
    .flat(2);

  console.log('Calculated Milestone Gantt Data');
  return {
    calWeekArr,
    calMonthArr,
    calYearArr,
    weekStr,
    monthStr,
    yearStr,
    todayLineGrid,
    highlightMonth,
    ganttArr,
    hiddenArr,
  };
};
