import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer } from 'react-leaflet';

// Components
import MapMarker from '../map-marker/map-marker.component';
import LegendBar from '../../components/legend-bar/legend-bar.component';
import MapBounds from '../../components/map-bounds/map-bounds.component';

// Styles
import useStyles from './map-dashboard.styles';

// API Key via Configuration File
import config from '../../utils/config';

// Constants
import { MAP_BOUNDS } from '../../utils/constants';

// Selectors
import {
  selectActiveSiteId,
  selectAllSites,
} from '../../redux/site/site.selectors';
import { selectSiteMilestoneOverview } from '../../redux/milestone/milestone.selectors';
import { selectIssuesOutstanding } from '../../redux/issue/issue.selectors';

const MapDashboard = ({ activeSiteId, sites, milestones, issues }) => {
  const classes = useStyles();

  return (
    <>
      <MapContainer
        bounds={MAP_BOUNDS}
        scrollWheelZoom={false}
        closePopupOnClick={false}
        className={classes.mapContainer}>
        <TileLayer
          attribution={''}
          url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}`}
          id={config.MAPBOX_OUTDOORS_MAP_V1}
          tileSize={512}
          zoomOffset={-1}
          accessToken={config.MAPBOX_API_ACCESS_TOKEN}
        />
        {Object.values(sites).map((site) => {
          return (
            <MapMarker
              site={site}
              key={site._id}
              activeSiteId={activeSiteId}
              issueCount={
                issues.filter(
                  (el) =>
                    el.site._id === site._id && el.currentCategorization > 1
                ).length
              }
              actualStatus={
                milestones[site._id] !== undefined &&
                milestones[site._id].hasOwnProperty('actualStatus')
                  ? milestones[site._id].actualStatus
                  : ''
              }
              totalMilestones={
                milestones[site._id] !== undefined &&
                milestones[site._id].hasOwnProperty('totalMilestones')
                  ? milestones[site._id].totalMilestones
                  : 0
              }
              metMilestones={
                milestones[site._id] !== undefined &&
                milestones[site._id].hasOwnProperty('metMilestones')
                  ? milestones[site._id].metMilestones
                  : 0
              }
            />
          );
        })}
        <MapBounds />
        <LegendBar />
      </MapContainer>
    </>
  );
};

MapDashboard.propTypes = {
  activeSiteId: PropTypes.string.isRequired,
  sites: PropTypes.object.isRequired,
  milestones: PropTypes.object.isRequired,
  issues: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  activeSiteId: selectActiveSiteId,
  sites: selectAllSites,
  milestones: selectSiteMilestoneOverview,
  issues: selectIssuesOutstanding,
});

export default connect(mapStateToProps)(MapDashboard);
