import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// Action Types
import {
  GET_FILTERED_SUBMISSIONS_START,
  GET_SINGLE_SUBMISSION_START,
  UPDATE_ACTIVE_SITE_ID,
  UPDATE_SUBMISSION_START,
  DELETE_SUBMISSION_UPDATE_START,
  UPDATE_SUBMISSION_SEEN_START,
  UPDATE_SUBMISSION_EDIT_START,
  DELETE_SUBMISSION_SHARE_START,
  CREATE_SUBMISSION_SHARE_START,
} from '../types';

// Action Creators
import {
  getFilteredSubmissionsSuccess,
  getFilteredSubmissionsFailure,
  getSingleSubmissionSuccess,
  getSingleSubmissionFailure,
  getSiteSubmissionsSuccess,
  getSiteSubmissionsFailure,
  updateSubmissionSuccess,
  updateSubmissionFailure,
  deleteSubmissionUpdateSuccess,
  deleteSubmissionUpdateFailure,
  updateSubmissionSeenSuccess,
  updateSubmissionSeenFailure,
  updateSubmissionEditSuccess,
  updateSubmissionEditFailure,
  deleteSubmissionShareSuccess,
  deleteSubmissionShareFailure,
  createSubmissionShareSuccess,
  createSubmissionShareFailure,
} from './submission.actions';
import { startAlert } from '../alert/alert.actions';
import {
  startFetchAction,
  stopFetchAction,
  startUiAction,
  stopUiAction,
} from '../loading/loading.actions';
import { updateSubmissionNotesModal } from '../ui/ui.actions';

// Selector
import { selectSubmissions } from './submission.selectors';
import { selectCurrentProject } from '../project/project.selectors';

// Utility Functions
import { generateQueryStr } from '../../utils/apiFeatures';

/**
 * Workers
 */
export function* getFilteredSubmissions({ payload: { projectId, queryObj } }) {
  const fetchObj = {
    id: uuidv4(),
    name: 'filtered submission',
    actionType: 'submissions',
    projectId: projectId,
  };

  const queryStr = yield call(generateQueryStr, queryObj);
  try {
    yield put(startFetchAction(fetchObj));
    const resSubmission = yield axios.get(
      `/api/v1/projects/${projectId}/submissions${queryStr}`
    );
    yield put(getFilteredSubmissionsSuccess(resSubmission.data.data));
    yield put(stopFetchAction({ id: fetchObj.id, status: 'success' }));
  } catch (err) {
    yield put(getFilteredSubmissionsFailure());
    yield put(stopFetchAction({ id: fetchObj.id, status: 'error' }));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* getSingleSubmission({ payload }) {
  const { projectId, docId } = payload;
  const fetchId = uuidv4();
  const fetchObj = {
    id: fetchId,
    name: 'single submission',
    actionType: 'submissions',
  };

  try {
    yield put(startFetchAction(fetchObj));
    const resSubmission = yield axios.get(
      `/api/v1/projects/${projectId}/submissions/${docId}`
    );
    yield put(getSingleSubmissionSuccess(resSubmission.data.data));
    yield put(stopFetchAction({ id: fetchId, status: 'success' }));
  } catch (err) {
    yield put(getSingleSubmissionFailure());
    yield put(stopFetchAction({ id: fetchId, status: 'error' }));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* selectLatestSubmissionsForSite({ payload }) {
  if (payload !== '-1') {
    const subs = yield select(selectSubmissions);
    const project = yield select(selectCurrentProject);
    const numSubs = Object.values(subs).filter(
      (el) => el.site?._id === payload
    ).length;

    if (numSubs < 6) {
      const queryObj = {
        pageTitle: 'submission',
        page: 1,
        perPage: 6,
        sortArr: [{ order: 'desc', vector: 'createdAt', title: 'Date' }],
        rangeArr: [],
        fieldArr: [],
        limitObj: {
          'site._id': [payload],
        },
        searchStr: '',
      };

      const queryStr = yield call(generateQueryStr, queryObj);
      const fetchId = uuidv4();
      const fetchObj = {
        id: fetchId,
        name: 'site submission',
        actionType: 'submissions',
      };

      try {
        yield put(startFetchAction(fetchObj));
        const resSubmission = yield axios.get(
          `/api/v1/projects/${project._id}/submissions${queryStr}`
        );
        yield put(getSiteSubmissionsSuccess(resSubmission.data.data));
        yield put(stopFetchAction({ id: fetchId, status: 'success' }));
      } catch (err) {
        yield put(getSiteSubmissionsFailure());
        yield put(stopFetchAction({ id: fetchId, status: 'success' }));
        yield put(
          startAlert({ msg: err.response.data.message, alertType: 'error' })
        );
      }
    }
  }
}

export function* updateSubmission({
  payload: { docId, formData, actionType, projectId },
}) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'submission',
        actionType: actionType,
        params: { docId: docId },
      })
    );
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/submissions/${docId}/update`,
      formData
    );
    yield put(updateSubmissionSuccess(res.data.data.docs));
    yield put(stopUiAction(id));
    if (actionType === 'Updated') {
      yield put(updateSubmissionNotesModal(res.data.data.docs.updates));
    }
    yield put(startAlert({ msg: 'Success', alertType: 'success' }));
  } catch (err) {
    yield put(updateSubmissionFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* deleteUpdate({ payload: { docId, embeddedId, projectId } }) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'submission updates',
        actionType: 'submission',
        params: { docId, embeddedId },
      })
    );
    const res = yield axios.delete(
      `/api/v1/projects/${projectId}/submissions/${docId}/embeddedField/updates/embeddedId/${embeddedId}`
    );
    yield put(
      deleteSubmissionUpdateSuccess({
        docId,
        updates: res.data.data.docs.updates,
      })
    );
    yield put(updateSubmissionNotesModal(res.data.data.docs.updates));
    yield put(
      startAlert({
        msg: 'Success!',
        alertType: 'success',
      })
    );
    yield put(stopUiAction(id));
  } catch (err) {
    yield put(deleteSubmissionUpdateFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updateSeen({ payload: { docId, projectId } }) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'submission seen',
        actionType: 'submission',
        params: { docId },
      })
    );
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/submissions/${docId}/seen`
    );
    yield put(
      updateSubmissionSeenSuccess({
        docId,
        updates: res.data.data.docs.updates,
      })
    );
    yield put(stopUiAction(id));
  } catch (err) {
    yield put(updateSubmissionSeenFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updateEdit({ payload: { docId, projectId, edits, update } }) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'submission edit',
        actionType: 'submission',
        params: { docId },
      })
    );
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/submissions/${docId}/edit`,
      { edits, update }
    );
    yield put(updateSubmissionEditSuccess(res.data.data.docs));
    yield put(
      startAlert({ msg: 'Successfully Edited!', alertType: 'success' })
    );
    yield put(stopUiAction(id));
  } catch (err) {
    yield put(updateSubmissionEditFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* deleteShare({ payload: { docId, projectId } }) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'delete share',
        actionType: 'submission',
        params: { docId },
      })
    );
    const res = yield axios.delete(
      `/api/v1/projects/${projectId}/submissions/${docId}/deleteShare`
    );
    yield put(deleteSubmissionShareSuccess(res.data.data.docs));
    yield put(
      startAlert({
        msg: 'Successfully Removed Sharing Link!',
        alertType: 'success',
      })
    );
    yield put(stopUiAction(id));
  } catch (err) {
    yield put(deleteSubmissionShareFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* createShare({ payload: { docId, projectId, optionsObj } }) {
  const id = uuidv4();
  try {
    yield put(
      startUiAction({
        id: id,
        name: 'create share',
        actionType: 'submission',
        params: { docId },
      })
    );
    const res = yield axios.patch(
      `/api/v1/projects/${projectId}/submissions/${docId}/createShare`,
      optionsObj
    );
    yield put(createSubmissionShareSuccess(res.data.data.docs));
    yield put(
      startAlert({
        msg: 'Successfully Created Sharing Link!',
        alertType: 'success',
      })
    );
    yield put(stopUiAction(id));
  } catch (err) {
    yield put(createSubmissionShareFailure());
    yield put(stopUiAction(id));
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

/**
 * Watchers
 */
export function* onGetFilteredSubmissionsStart() {
  yield takeLatest(GET_FILTERED_SUBMISSIONS_START, getFilteredSubmissions);
}

export function* onGetSingleSubmissionStart() {
  yield takeLatest(GET_SINGLE_SUBMISSION_START, getSingleSubmission);
}

export function* onGetLatestSubmissionsForSite() {
  yield takeLatest(UPDATE_ACTIVE_SITE_ID, selectLatestSubmissionsForSite);
}

export function* onUpdateSubmissionStart() {
  yield takeLatest(UPDATE_SUBMISSION_START, updateSubmission);
}

export function* onDeleteSubmissionUpdateStart() {
  yield takeLatest(DELETE_SUBMISSION_UPDATE_START, deleteUpdate);
}

export function* onUpdateSubmissionSeenStart() {
  yield takeLatest(UPDATE_SUBMISSION_SEEN_START, updateSeen);
}

export function* onUpdateSubmissionEditStart() {
  yield takeLatest(UPDATE_SUBMISSION_EDIT_START, updateEdit);
}

export function* onDeleteSubmissionShareStart() {
  yield takeLatest(DELETE_SUBMISSION_SHARE_START, deleteShare);
}

export function* onCreateSubmissionShareStart() {
  yield takeLatest(CREATE_SUBMISSION_SHARE_START, createShare);
}

/**
 * Export Saga
 */
export function* submissionSagas() {
  yield all([
    call(onGetFilteredSubmissionsStart),
    call(onGetSingleSubmissionStart),
    call(onGetLatestSubmissionsForSite),
    call(onUpdateSubmissionStart),
    call(onDeleteSubmissionUpdateStart),
    call(onUpdateSubmissionSeenStart),
    call(onUpdateSubmissionEditStart),
    call(onDeleteSubmissionShareStart),
    call(onCreateSubmissionShareStart),
  ]);
}
