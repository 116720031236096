import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: '4px',
  },
  defaultText: {
    fontSize: '0.85rem',
    fontWeight: 400,
    color: '#000',
    lineHeight: 1.1,
  },
  actionText: {
    fontSize: '0.85rem',
    fontWeight: 700,
    color: '#464646',
    lineHeight: 1.1,
  },
  verboseText: {
    fontSize: '0.85rem',
    fontWeight: 400,
    lineHeight: 1.1,
  },
  authorText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '0.85rem',
    lineHeight: 1.1,
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '0.85rem',
    lineHeight: 1.1,
    cursor: 'pointer',
    '&:hover': {
      color: theme.custom.primaryHover,
    },
  },
  hidden: {
    display: 'none',
  },
}));

export default useStyles;
