import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

// Material UI
import Popover from '@material-ui/core/Popover';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

// Styles
import useStyles from './severity-tag.styles';

// Actions
import { updateIssueStart } from '../../redux/issue/issue.actions';
import { toggleModal } from '../../redux/ui/ui.actions';

function SeverityTag({
  docId,
  severityLevel,
  block,
  updateIssueStartDispatch,
  toggleModalDispatch,
  children,
  site,
  large,
  midsize,
  pill,
}) {
  const { projectId } = useParams();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const loading = false; // FIXME: best way to do this?
  const severityClass =
    severityLevel === 0
      ? classes.none
      : severityLevel === 1
      ? classes.low
      : severityLevel === 2
      ? classes.medium
      : severityLevel === 3
      ? classes.high
      : severityLevel === 4
      ? classes.offTrack
      : classes.none;

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSeverityChangeClick = (e) => {
    const newCategorization = Number(
      e.target.closest('.MuiButtonBase-root').value
    );
    if (severityLevel !== newCategorization) {
      const update = {
        actionType: 'Recategorized',
        categorization: e.target.closest('.MuiButtonBase-root').value,
      };
      const milestoneFlag =
        newCategorization === 4 ? 1 : severityLevel === 4 ? -1 : 0;

      if (milestoneFlag !== 1) {
        let formData = new FormData();
        formData.append('update', JSON.stringify(update));
        updateIssueStartDispatch(
          docId,
          formData,
          milestoneFlag,
          update.actionType,
          projectId
        );
      } else {
        toggleModalDispatch({
          modalName: 'issueResolve',
          modalContent: {
            id: docId,
            categorization: newCategorization,
            milestoneFlag: milestoneFlag,
            actionType: update.actionType,
            site: site,
          },
        });
      }
    }
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'severity-popover' : undefined;

  return (
    <>
      <span
        aria-describedby={id}
        className={clsx(
          classes.root,
          severityClass,
          loading ? classes.buttonLoading : null,
          !block ? classes.rootDisplay : null,
          large ? classes.large : null,
          midsize ? classes.midsize : null,
          pill ? classes.pill : null
        )}
        onClick={loading ? null : handleClick}>
        {children}
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popover}>
        <ButtonGroup
          orientation='vertical'
          color='primary'
          aria-label='severity selection list'
          variant='text'
          size='small'
          onClick={handleSeverityChangeClick}
          data-issue-id={docId}
          className={classes.buttonGroup}>
          <Button className={classes.none} value={0}>
            Uncategorized
          </Button>
          <Button className={classes.low} value={1}>
            <div>
              Low
              <span className={classes.punchlist}>Punchlist</span>
            </div>
          </Button>
          <Button className={classes.medium} value={2}>
            Medium
          </Button>
          <Button className={classes.high} value={3}>
            High
          </Button>
          <Button className={classes.offTrack} value={4}>
            Off Track
          </Button>
        </ButtonGroup>
      </Popover>
    </>
  );
}

SeverityTag.propTypes = {
  children: PropTypes.node.isRequired,
  severityLevel: PropTypes.number.isRequired,
  docId: PropTypes.string.isRequired,
  updateIssueStartDispatch: PropTypes.func.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,
  block: PropTypes.bool,
  large: PropTypes.bool,
  pill: PropTypes.bool,
  midsize: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  updateIssueStartDispatch: (
    docId,
    formData,
    milestoneFlag,
    actionType,
    projectId
  ) =>
    dispatch(
      updateIssueStart({
        docId,
        formData,
        milestoneFlag,
        actionType,
        projectId,
      })
    ),
});

export default connect(null, mapDispatchToProps)(SeverityTag);
