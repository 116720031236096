import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Components
import HomeContainer from '../../components/home-container/home-container.component';
import ProjectCard from '../../components/project-card/project-card.component';

// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

// Styles
import useStyles from './projects.styles';

// Actions
import { getAllProjectsStart } from '../../redux/project/project.actions';
import {
  signOutStart,
  updateDefaultProjectStart,
  updateProjectlistStart,
} from '../../redux/user/user.actions';

// Selectors
import { selectProjects } from '../../redux/project/project.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';

const Projects = ({
  projects,
  getAllProjectsStartDispatch,
  signOutStartDispatch,
  user,
  updateDefaultProjectStartDispatch,
  updateProjectlistStartDispatch,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getAllProjectsStartDispatch();
  }, [getAllProjectsStartDispatch]);

  return (
    <HomeContainer signOutStartDispatch={signOutStartDispatch}>
      <Typography variant='h2' component='h1'>
        My Projects
      </Typography>
      <Typography variant='h5' component='h2' gutterBottom>
        {'Select open to go to the project dashboard.'}
        {
          'You can also edit or update a specific project, view a detailed summary, or create a new project using the tools below.'
        }
      </Typography>
      <Typography variant='body1' gutterBottom>
        <FavoriteBorderIcon color='secondary' className={classes.heartIcon} />
        Click the heart icon to set the selected project as your{' '}
        <Tooltip title='The default project will automatically open upon login to the website.'>
          <span className={classes.toolTip}>default project</span>
        </Tooltip>
        .
      </Typography>
      <Typography variant='body1' gutterBottom>
        <BookmarkBorderIcon color='primary' className={classes.heartIcon} />
        Click the bookmark icon to add the selected project to your{' '}
        <Tooltip title='The project list will allow you to rapidly change project contexts from the dashboard.'>
          <span className={classes.toolTip}>project list</span>
        </Tooltip>
        .
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.actionButtons}>
          <Button
            variant='contained'
            color='secondary'
            disabled={true}
            href={`/`}
            className={classes.button}
            startIcon={<AddIcon />}>
            Create New Project
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        {Object.keys(projects).map((id, idx) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={idx}
              className={classes.cardContainer}>
              <ProjectCard
                project={projects[id]}
                user={user}
                updateDefaultProjectStartDispatch={
                  updateDefaultProjectStartDispatch
                }
                updateProjectlistStartDispatch={updateProjectlistStartDispatch}
              />
            </Grid>
          );
        })}
      </Grid>
    </HomeContainer>
  );
};

Projects.propTypes = {
  projects: PropTypes.object.isRequired,
  getAllProjectsStartDispatch: PropTypes.func.isRequired,
  signOutStartDispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  updateDefaultProjectStartDispatch: PropTypes.func.isRequired,
  updateProjectlistStartDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  projects: selectProjects,
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  getAllProjectsStartDispatch: () => dispatch(getAllProjectsStart()),
  signOutStartDispatch: () => dispatch(signOutStart()),
  updateDefaultProjectStartDispatch: (profileObj) =>
    dispatch(updateDefaultProjectStart(profileObj)),
  updateProjectlistStartDispatch: (profileObj) =>
    dispatch(updateProjectlistStart(profileObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
