import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import AuthContainer from '../../components/auth-container/auth-container.component';
import AuthNav from '../../components/auth-nav/auth-nav.component';

// Material UI
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

// Actions
import { resetPasswordStart } from '../../redux/user/user.actions';

const ResetPassword = ({ resetPasswordStartDispatch, match: { params } }) => {
  const { resetToken } = params;

  const [userCredentials, setUserCredentials] = useState({
    password: '',
    passwordConfirm: '',
  });

  const { password, passwordConfirm } = userCredentials;

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== password) {
        return false;
      }
      return true;
    });
    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch');
    };
  }, [password]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPasswordStartDispatch(resetToken, password, passwordConfirm);
  };

  return (
    <AuthContainer>
      <ValidatorForm onSubmit={handleSubmit}>
        <TextValidator
          variant='outlined'
          color='primary'
          margin='normal'
          validators={['required', 'minStringLength:8']}
          errorMessages={[
            'This field is required.',
            'Password must be at least 8 characters.',
          ]}
          fullWidth
          id='password'
          label='New Password'
          type='password'
          name='password'
          autoComplete='password'
          onChange={handleChange}
          value={password}
        />
        <TextValidator
          variant='outlined'
          color='primary'
          margin='normal'
          validators={['required', 'minStringLength:8', 'isPasswordMatch']}
          errorMessages={[
            'This field is required.',
            'Password must be at least 8 characters.',
            'Passwords do not match.',
          ]}
          fullWidth
          name='passwordConfirm'
          label='Password Confirmation'
          type='password'
          id='passwordConfirm'
          onChange={handleChange}
          value={passwordConfirm}
        />
        <Box mt={3} mb={2}>
          <Button type='submit' variant='contained' color='primary' fullWidth>
            Reset Password
          </Button>
        </Box>
      </ValidatorForm>
      <AuthNav />
    </AuthContainer>
  );
};

ResetPassword.propTypes = {
  resetPasswordStartDispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  resetPasswordStartDispatch: (resetToken, password, passwordConfirm) =>
    dispatch(resetPasswordStart({ resetToken, password, passwordConfirm })),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
