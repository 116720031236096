import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Popover from '@material-ui/core/Popover';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Styles
import useStyles from './updates-filters.styles';

const UpdatesFilter = ({ filters, setUpdateFiltersDispatch }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenFilterMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event) => {
    switch (event.target.name) {
      case 'Recategorized':
        setUpdateFiltersDispatch({
          Recategorized: event.target.checked,
        });
        break;
      case 'Updated':
        setUpdateFiltersDispatch({
          Updated: event.target.checked,
          'Share Link Comment': event.target.checked,
        });
        break;
      case 'Edited':
        setUpdateFiltersDispatch({
          Edited: event.target.checked,
        });
        break;
      case 'Sharing':
        setUpdateFiltersDispatch({
          'Started Sharing': event.target.checked,
          'Stopped Sharing': event.target.checked,
          Sharing: event.target.checked,
        });
        break;
      case 'Resolutions':
        setUpdateFiltersDispatch({
          Dismissed: event.target.checked,
          Resolved: event.target.checked,
          Reopened: event.target.checked,
          Restored: event.target.checked,
          Deleted: event.target.checked,
          Resolutions: event.target.checked,
        });
        break;
      default:
        console.log('Incorrect Filter Selection');
    }
  };

  const filterMenuOpen = Boolean(anchorEl);

  return (
    <>
      <span>
        <Tooltip title='Set Filters'>
          <IconButton
            aria-label='filter options'
            onClick={handleOpenFilterMenu}>
            {anchorEl ? <MoreVertIcon color='primary' /> : <MoreHorizIcon />}
          </IconButton>
        </Tooltip>
      </span>
      <Popover
        open={filterMenuOpen}
        anchorEl={anchorEl}
        onClose={handleCloseFilterMenu}
        className={classes.issueboxPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <FormControl component='div' className={classes.filterMenu}>
          <FormLabel
            component='div'
            focused={false}
            style={{ marginBottom: '8px' }}>
            Select notes to display:
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={filters['Sharing']}
                  onChange={handleFilterChange}
                  name='Sharing'
                />
              }
              label='Sharing Events'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={filters['Updated']}
                  onChange={handleFilterChange}
                  name='Updated'
                />
              }
              label='Updates, Comments, Photos & Attachments'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={filters['Recategorized']}
                  onChange={handleFilterChange}
                  name='Recategorized'
                />
              }
              label='Recategorizations'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={filters['Resolutions']}
                  onChange={handleFilterChange}
                  name='Resolutions'
                />
              }
              label='Resolution Events (e.g. delete, restore)'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={filters['Edited']}
                  onChange={handleFilterChange}
                  name='Edited'
                />
              }
              label='Edits & Changes'
            />
          </FormGroup>
          <FormHelperText>
            Note: these settings are saved and applied throughout the <br />
            application.
          </FormHelperText>
        </FormControl>
      </Popover>
    </>
  );
};

UpdatesFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  setUpdateFiltersDispatch: PropTypes.func.isRequired,
};

export default UpdatesFilter;
