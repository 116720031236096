import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  avatar: {
    width: '200px',
    height: '200px',
    marginBottom: '12px',
  },
  input: {
    display: 'none',
  },
});

export default useStyles;
