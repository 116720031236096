import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  statusFilters: {
    paddingRight: '6px',
  },
  siteFilters: {
    paddingLeft: '6px',
  },
  chip: { marginRight: '4px', marginBottom: '2px' },
  chipContainer: { paddingTop: '6px' },
  statusPill: {
    width: '18px',
    height: '18px',
    display: 'inline-flex',
    color: '#FFF',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    fontsize: '0.8rem',
    fontWeight: 900,
    cursor: 'pointer',
    marginRight: '2px',
    backgroundColor: '#ccc',
    border: '1px solid #bbb',
  },
  notStarted: {
    backgroundColor: theme.custom.statusGreyBorder,
    border: 0,
  },
  ongoing: {
    backgroundColor: theme.custom.statusOrange,
    border: 0,
  },
  offTrack: {
    backgroundColor: theme.custom.statusRed,
    border: 0,
  },
  completed: {
    backgroundColor: theme.custom.statusGreen,
    border: 0,
  },
}));

export default useStyles;
