import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginTop: '20px',
    '& button': {
      padding: '6px',
    },
  },
  toolbarStart: {
    display: 'flex',
    flex: 0,
    [theme.breakpoints.down('xs')]: {
      flex: 1,
    },
  },
  toolbarTitle: {
    color: theme.custom.mediumgrey,
    margin: 0,
  },
  root: {
    padding: '6px 8px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '400px',
    flex: 0,
    [theme.breakpoints.down('xs')]: {
      flex: 1,
    },
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default useStyles;
