import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { useTheme } from '@material-ui/core/styles';

function CircularProgressLabeled({ value, type, size = 'small' }) {
  const theme = useTheme();
  const progress = 100.5 - 100.5 * (value / 100);

  let strokeColor;

  switch (type) {
    case 'Completed':
      strokeColor = theme.custom.statusGreen;
      break;
    case 'Ongoing':
      strokeColor = theme.custom.statusOrangeAlt;
      break;
    case 'Stopped':
    case 'Off Track':
      strokeColor = theme.custom.statusRed;
      break;
    default:
      strokeColor = theme.palette.primary.darkest;
      break;
  }

  let sizeParams = {
    width: 40,
    height: 40,
    cx: 20,
    cy: 20,
    r: 16,
    strokeWidth: 5,
    dy: '0.36em',
    fontSize: '0.7em',
    fontWeight: 400,
    fontColor: '#000',
    strokeBgColor: theme.custom.progressCircleBg,
  };

  switch (size) {
    case 'small':
      break;
    case 'medium':
      sizeParams.width = 60;
      sizeParams.height = 60;
      sizeParams.cx = 30;
      sizeParams.cy = 30;
      sizeParams.r = 24;
      sizeParams.strokeWidth = 8;
      sizeParams.fontSize = '1em';
      sizeParams.fontWeight = 900;
      sizeParams.strokeBgColor = '#fff';
      sizeParams.fontColor = '#444';
      break;
    case 'large':
      sizeParams.width = 80;
      sizeParams.height = 80;
      sizeParams.cx = 40;
      sizeParams.cy = 40;
      sizeParams.r = 32;
      sizeParams.strokeWidth = 10;
      sizeParams.fontSize = '1.2em';
      sizeParams.fontWeight = 900;
      sizeParams.strokeBgColor = '#fff';
      sizeParams.fontColor = '#444';
      break;
    default:
      break;
  }

  return (
    <svg width={sizeParams.width} height={sizeParams.height}>
      <circle
        cx={sizeParams.cx}
        cy={sizeParams.cy}
        r={sizeParams.r}
        stroke={sizeParams.strokeBgColor}
        strokeWidth={sizeParams.strokeWidth - 1}
        fill='none'
      />
      <circle
        cx={sizeParams.cx}
        cy={sizeParams.cy}
        r={sizeParams.r}
        stroke={strokeColor}
        fill='none'
        strokeWidth={sizeParams.strokeWidth}
        strokeLinecap='round'
        strokeDasharray={'100.5'}
        strokeDashoffset={progress}
        pathLength={100}
      />
      <text
        x='50%'
        y='50%'
        textAnchor='middle'
        dy={sizeParams.dy}
        fontSize={sizeParams.fontSize}
        fontWeight={sizeParams.fontWeight}
        fill={sizeParams.fontColor}>
        {`${Math.round(value)}%`}
      </text>
    </svg>
  );
}

CircularProgressLabeled.propTpyes = {
  value: PropTypes.number.isRequired,
  type: PropTypes.string,
};

export default CircularProgressLabeled;
