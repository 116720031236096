import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

const InfoTooltip = ({ text }) => {
  return (
    <Tooltip title={text}>
      <InfoIcon fontSize='small' style={{ transform: 'translateY(3px)' }} />
    </Tooltip>
  );
};

InfoTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

export default InfoTooltip;
