import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import FormGenerator from '../../form-generator.component';

// Material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// Actions
import { updateProfileStart } from '../../../redux/user/user.actions';

// Form Schema
import {
  userSchema,
  userInitialize,
  userValidationSchema,
} from '../../schemas/user.schema';

const UpdateProfile = ({ updateProfileStartDispatch, user }) => {
  const initialValues = userInitialize(user);
  return (
    <>
      <Typography variant='h6' display='block' gutterBottom>
        Personal Information
      </Typography>
      <FormGenerator
        initialValues={initialValues}
        schema={userSchema}
        validationSchema={userValidationSchema}
        submitFunc={updateProfileStartDispatch}
        submitElem={
          <Button
            type='submit'
            variant='outlined'
            size='small'
            startIcon={<AccountCircleIcon />}>
            Update My Profile
          </Button>
        }
      />
    </>
  );
};

UpdateProfile.propTypes = {
  updateProfileStartDispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateProfileStartDispatch: (profileObj) => dispatch(updateProfileStart(profileObj)),
});

export default connect(null, mapDispatchToProps)(UpdateProfile);
