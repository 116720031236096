import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '500px',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.custom.lightestgrey,
  },
  closeSidebar: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButon: {
    color: '#FFF',
    marginRight: '8px',
  },
  cardContainer: {
    paddingBottom: '8px',
    paddingTop: '8px',
    width: '100%',
    backgroundColor: theme.custom.lightestgrey,
  },
  subheader: {
    lineHeight: '1rem',
    paddingBottom: '4px',
  },
  listItem: {
    borderTop: `1px solid ${theme.custom.lightergrey}`,
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  infoContainer: {
    width: '100%',
    backgroundColor: theme.custom.lightgreyGantt,
    '&.completed': {
      backgroundColor: theme.custom.statusGreen,
    },
    '&.ongoing': {
      backgroundColor: theme.custom.statusOrange,
    },
    '&.stopped': {
      backgroundColor: theme.custom.statusRed,
    },
  },
  homeOfficeText: {
    color: theme.palette.primary.main,
  },
  homeOffice: {
    border: `4px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: `3px solid ${theme.palette.primary.main}!important`,
    },
  },
  morePhotos: {
    width: '72px',
    height: '72px',
    borderRadius: 8,
    margin: 8,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    border: '4px dashed rgba(50,50,50,0.66)',
    fontSize: '0.85rem',
    fontWeight: 700,
    color: 'rgba(50,50,50,0.90)',
    lineHeight: 1.2,
  },
  infoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '12px',
    paddingTop: '12px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  fileContainer: {
    paddingBottom: '8px',
    paddingTop: '8px',
    width: '100%',
    backgroundColor: theme.custom.lightestgrey,
  },
  photoContainer: {
    paddingBottom: '6px',
    paddingTop: '6px',
    width: '100%',
    backgroundColor: theme.custom.lightestgrey,
  },
  topPad: {
    paddingTop: '16px',
  },
  modalFormContainer: {
    width: '100%',
    backgroundColor: theme.custom.lightestgrey,
  },
  rootChat: {
    width: '100%',
    backgroundColor: theme.custom.lightestgrey,
    paddingTop: 0,
  },
  inline: {
    display: 'inline',
  },
  header: {
    color: theme.custom.statusContrastText,
  },
  contentHeader: {
    color: '#0f0f0f',
  },
  defaultMsg: {
    paddingLeft: '16px',
    paddingTop: '4px',
    paddingBottom: '8px',
  },
  photos: {
    display: 'flex',
    padding: 12,
    justifyContent: 'flex-start',
    flexFlow: 'wrap',
  },
  submissions: {
    display: 'grid',
    padding: '2px 16px',
  },
  submissionRow: {
    display: 'grid',
    padding: '0px 0px',
    gridTemplateColumns: '48px 86px 2fr 1fr',
  },
  issueRow: {
    display: 'grid',
    padding: '2px 0px',
    gridTemplateColumns: '48px 86px 1fr 100px',
  },
  link: {
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  photo: {
    width: '72px',
    height: '72px',
    borderRadius: 8,
    margin: 8,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.custom.photoBorderHover}`,
    },
  },
  photoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 16px',
  },
  content: {
    paddingTop: '0px!important',
    paddingBottom: '0!important',
    paddingRight: '16px!important',
    paddingLeft: '16px!important',
  },
  button: {
    padding: '1px 9px',
    fontSize: '0.75rem',
    marginBottom: '1px',
  },
  buttonGroup: {
    padding: '0px 16px 8px 0px',
  },
  editIcons: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    fontSize: '0.85rem',
    transform: 'translateY(2px)',
  },
  deleteNoteIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    fontSize: '0.85rem',
    transform: 'translateY(-1px)',
  },
  input: {
    display: 'none',
  },
  status: {
    border: `2px solid ${theme.custom.statusContrastText}`,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    borderRadius: '20px',
    padding: '0px 16px',
    textAlign: 'center',
    color: theme.custom.statusContrastText,
    fontSize: '0.8rem',
    lineHeight: '0.8rem',
    textTransform: 'uppercase',
    fontWeight: 900,
    backgroundColor: theme.custom.lightgreyGantt,
    '&.completed': {
      backgroundColor: theme.custom.statusGreen,
    },
    '&.ongoing': {
      backgroundColor: theme.custom.statusOrange,
    },
    '&.stopped': {
      backgroundColor: theme.custom.statusRed,
    },
  },
  completed: {
    color: theme.custom.statusGreen,
  },
  notesContainer: {},
  note: {
    padding: '4px 16px',
    borderBottom: '1px solid #ccc',
    '&:last-of-type': {
      marginBottom: '48px',
    },
  },
  notesAvatar: {
    alignSelf: 'flex-start',
    marginTop: '6px',
    '& div': {
      borderRadius: '8px',
    },
  },
  notesUser: {
    lineHeight: 1.2,
    fontWeight: 700,
    fontSize: '0.8rem',
    display: 'flex',
  },
  notesText: {
    lineHeight: 1.1,
    fontSize: '0.9rem',
    display: 'flex',
    paddingTop: '6px',
  },
  notesDate: {
    lineHeight: 1,
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
  },
  issuePill: {
    marginLeft: '4px',
    fontSize: '0.7rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.custom.statusRed,
    color: '#fff',
    borderRadius: '16px',
    padding: '0px 10px',
    fontWeight: '400',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    height: '18px',
    width: '76px',
    justifySelf: 'flex-end',
  },
  hide: {
    display: 'none',
  },
  paid: {
    backgroundColor: theme.custom.statusGreen,
    color: '#fff',
    '& svg': {
      fill: '#fff',
    },
  },
  none: {
    backgroundColor: theme.custom.issueUncatBg,
    '&:hover': {
      backgroundColor: theme.custom.issueUncatBgHover,
    },
  },
  low: {
    backgroundColor: theme.custom.issueLowBg,
    '&:hover': {
      backgroundColor: theme.custom.issueLowBgHover,
    },
    lineHeight: 1,
  },
  medium: {
    backgroundColor: theme.custom.issueMediumBg,
    '&:hover': {
      backgroundColor: theme.custom.issueMediumBgHover,
    },
  },
  high: {
    backgroundColor: theme.custom.issueHighBg,
    '&:hover': {
      backgroundColor: theme.custom.issueHighBgHover,
    },
  },
  offTrack: {
    backgroundColor: theme.custom.issueOffTrackBg,
    fontSize: '0.55rem',
    '&:hover': {
      backgroundColor: theme.custom.issueOffTrackBgHover,
    },
  },
  resolved: {
    backgroundColor: theme.custom.statusGreen,
  },
}));

export default useStyles;
