import React from 'react';
import PropTypes from 'prop-types';

// Components
import UpdateMentions from '../update-mentions/update-mentions.component';
import UpdateActionIssue from '../update-action-issue/update-action-issue.component';

// Styles
import useStyles from './update-action.styles';

const UpdateAction = ({
  singleView = false,
  actionType,
  type,
  showMentions,
  site,
  milestone,
  mentions,
  refId = '',
  createdBy,
  currentUserId,
  categorization,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {type === 'issue' ? (
        <>
          <UpdateActionIssue
            actionType={actionType}
            createdBy={createdBy}
            currentUserId={currentUserId}
            categorization={categorization}
            refId={refId}
            site={site}
            milestone={milestone}
            singleView={singleView}
          />
          <UpdateMentions
            refId={refId}
            mentions={mentions}
            showMentions={showMentions}
          />
        </>
      ) : null}
    </div>
  );
};

UpdateAction.propTypes = {
  singleView: PropTypes.bool.isRequired,
  actionType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  showMentions: PropTypes.bool.isRequired,
  refId: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
  milestone: PropTypes.object.isRequired,
  mentions: PropTypes.array.isRequired,
  createdBy: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  categorization: PropTypes.number,
};

export default UpdateAction;
