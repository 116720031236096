import {
  GET_ALL_MILESTONES_START,
  GET_ALL_MILESTONES_SUCCESS,
  GET_ALL_MILESTONES_FAILURE,
  UPDATE_MILESTONE_START,
  UPDATE_MILESTONE_SUCCESS,
  UPDATE_MILESTONE_FAILURE,
  ADD_ATTACHMENT_MILESTONE_START,
  ADD_ATTACHMENT_MILESTONE_SUCCESS,
  ADD_ATTACHMENT_MILESTONE_FAILURE,
  DELETE_ATTACHMENT_MILESTONE_START,
  DELETE_ATTACHMENT_MILESTONE_SUCCESS,
  DELETE_ATTACHMENT_MILESTONE_FAILURE,
  ADD_NOTE_MILESTONE_START,
  ADD_NOTE_MILESTONE_SUCCESS,
  ADD_NOTE_MILESTONE_FAILURE,
  DELETE_NOTE_MILESTONE_START,
  DELETE_NOTE_MILESTONE_SUCCESS,
  DELETE_NOTE_MILESTONE_FAILURE,
  TOGGLE_GANTT_EDIT_MODE,
  ADD_MILESTONE_MODS,
  REMOVE_ALL_MILESTONE_MODS,
  REMOVE_MILESTONE_MODS,
  UPDATE_MANY_MILESTONES_START,
  UPDATE_MANY_MILESTONES_SUCCESS,
  UPDATE_MANY_MILESTONES_FAILURE,
} from '../types';

export const getAllMilestonesStart = (fetchObj) => ({
  type: GET_ALL_MILESTONES_START,
  payload: fetchObj,
});

export const getAllMilestonesSuccess = (milestoneData) => ({
  type: GET_ALL_MILESTONES_SUCCESS,
  payload: milestoneData,
});

export const getAllMilestonesFailure = () => ({
  type: GET_ALL_MILESTONES_FAILURE,
});

export const updateMilestoneStart = (updateObj) => ({
  type: UPDATE_MILESTONE_START,
  payload: updateObj,
});

export const updateMilestoneSuccess = (updatedMilestoneObj) => ({
  type: UPDATE_MILESTONE_SUCCESS,
  payload: updatedMilestoneObj,
});

export const updateMilestoneFailure = () => ({
  type: UPDATE_MILESTONE_FAILURE,
});

export const addNoteMilestoneStart = (updateObj) => ({
  type: ADD_NOTE_MILESTONE_START,
  payload: updateObj,
});

export const addNoteMilestoneSuccess = (updatedMilestoneObj) => ({
  type: ADD_NOTE_MILESTONE_SUCCESS,
  payload: updatedMilestoneObj,
});

export const addNoteMilestoneFailure = () => ({
  type: ADD_NOTE_MILESTONE_FAILURE,
});

export const addAttachmentMilestoneStart = (attachmentsObj) => ({
  type: ADD_ATTACHMENT_MILESTONE_START,
  payload: attachmentsObj,
});

export const addAttachmentMilestoneSuccess = (updatedAttachmentsObj) => ({
  type: ADD_ATTACHMENT_MILESTONE_SUCCESS,
  payload: updatedAttachmentsObj,
});

export const addAttachmentMilestoneFailure = () => ({
  type: ADD_ATTACHMENT_MILESTONE_FAILURE,
});

export const deleteAttachmentMilestoneStart = (attachmentsObj) => ({
  type: DELETE_ATTACHMENT_MILESTONE_START,
  payload: attachmentsObj,
});

export const deleteAttachmentMilestoneSuccess = (updatedAttachmentsObj) => ({
  type: DELETE_ATTACHMENT_MILESTONE_SUCCESS,
  payload: updatedAttachmentsObj,
});

export const deleteAttachmentMilestoneFailure = () => ({
  type: DELETE_ATTACHMENT_MILESTONE_FAILURE,
});

export const deleteNoteMilestoneStart = (deleteObj) => ({
  type: DELETE_NOTE_MILESTONE_START,
  payload: deleteObj,
});

export const deleteNoteMilestoneSuccess = (updatedMilestoneObj) => ({
  type: DELETE_NOTE_MILESTONE_SUCCESS,
  payload: updatedMilestoneObj,
});

export const deleteNoteMilestoneFailure = () => ({
  type: DELETE_NOTE_MILESTONE_FAILURE,
});

export const toggleGanttEditMode = (val) => ({
  type: TOGGLE_GANTT_EDIT_MODE,
  payload: val,
});

export const addMilestoneMods = (modsObj) => ({
  type: ADD_MILESTONE_MODS,
  payload: modsObj,
});

export const removeMilestoneMods = (idArr) => ({
  type: REMOVE_MILESTONE_MODS,
  payload: idArr,
});

export const removeAllMilestoneMods = () => ({
  type: REMOVE_ALL_MILESTONE_MODS,
});

export const updateManyMilestonesStart = (updateObj) => ({
  type: UPDATE_MANY_MILESTONES_START,
  payload: updateObj,
});

export const updateManyMilestonesSuccess = (updatedMilestoneArr) => ({
  type: UPDATE_MANY_MILESTONES_SUCCESS,
  payload: updatedMilestoneArr,
});

export const updateManyMilestonesFailure = () => ({
  type: UPDATE_MANY_MILESTONES_FAILURE,
});
