import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {},
  divider: {
    margin: '20px 0px',
  },
  issueDetails: {
    fontSize: '1rem',
  },
  issueTitle: {
    fontSize: '1.1rem',
  },
  metaContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  metaHeader: {
    fontSize: '1.4rem',
    fontWeight: 700,
    color: '#333',
    paddingBottom: '12px',
  },
  metaRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '12px',
    fontSize: '0.925rem',
  },
  metaRowHeader: {
    fontWeight: 700,
    minWidth: '164px',
    maxWidth: '164px',
    display: 'flex',
    alignItems: 'center',
  },
  metaRowContent: {
    lineHeight: 1.1,
    display: 'flex',
    alignItems: 'center',
  },
  submissionLink: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  severityOverrides: {
    height: '26px',
  },
  tooltipOverride: {
    borderRadius: '4px',
  },
  resolved: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    height: '26px',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '0.85rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    '& svg': {
      color: '#FFF!important',
    },
    '&:hover': {
      backgroundColor: '#2acb61',
    },
  },
  deleted: {
    backgroundColor: theme.palette.error.main,
    color: '#FFF',
    height: '26px',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '0.85rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    '& svg': {
      color: '#FFF!important',
    },
  },
  open: {
    backgroundColor: theme.custom.statusGrey,
    color: '#FFF',
    height: '26px',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '0.85rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    '& svg': {
      color: '#FFF!important',
    },
    '&:hover': {
      backgroundColor: theme.custom.issueUncatBgHover,
    },
  },
  shared: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    height: '26px',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '0.85rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    '& svg': {
      color: '#FFF!important',
    },
  },
  shareCopy: {
    cursor: 'pointer',
    color: theme.custom.ganttPlannedTimeline,
    marginLeft: '8px',
    '&:hover': {
      color: theme.custom.softGrey,
    },
  },
  resolveReopen: {
    cursor: 'pointer',
    transform: 'translateY(-1px)',
    color: theme.custom.ganttPlannedTimeline,
    marginLeft: '6px',
    '&:hover': {
      color: theme.custom.softGrey,
    },
  },
  button: {
    marginRight: theme.spacing(1),
    borderRadius: '4px',
    minWidth: 'unset',
    marginBottom: '8px',
  },
  buttonGroup: {
    marginTop: '24px',
    flexWrap: 'wrap',
  },
  resolveButton: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    '& svg': {
      color: '#FFFFFF!important',
    },
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  editButton: {
    backgroundColor: theme.custom.buttonPurple,
    color: '#fff',
    '& svg': {
      color: '#FFFFFF!important',
    },
    '&:hover': {
      backgroundColor: theme.custom.buttonPurple,
      cursor: 'not-allowed',
    },
  },
  exportButton: {
    backgroundColor: theme.custom.exportBtnOrange,
    color: '#fff',
    '& svg': {
      color: '#FFFFFF!important',
    },
    '&:hover': {
      backgroundColor: theme.custom.exportBtnOrangeHover,
    },
  },
  textHeader: {
    marginTop: 0,
    marginBottom: '6px',
  },
}));

export default useStyles;
