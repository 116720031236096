import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

// Components TODO: add other form elements here as we need them.
import FormikTextField from './fields/formik-textfield.component';
import FormikDummyTextField from './fields/formik-dummy-textfield.component';

const FormGenerator = ({
  initialValues,
  validationSchema,
  submitFunc,
  schema,
  submitElem,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        submitFunc(values);
      }}>
      <Form>
        {schema.map((data, i) => {
          switch (data.elem) {
            case 'textField': {
              return <FormikTextField {...data} key={i} />;
            }
            case 'dummyTextField': {
              return <FormikDummyTextField {...data} key={i} />;
            }
            default: {
              return null;
            }
          }
        })}
        {submitElem}
      </Form>
    </Formik>
  );
};

FormGenerator.propTypes = {
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  submitFunc: PropTypes.func.isRequired,
  schema: PropTypes.array.isRequired,
  submitElem: PropTypes.object.isRequired,
};

export default FormGenerator;
