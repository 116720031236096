import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
    borderBottomRightRadius: '16px',
    borderBottomLeftRadius: '16px',
  },
  table: {
    overflow: 'hidden',
    '& tr > td': {
      borderColor: theme.custom.lightestgrey,
      lineHeight: '1rem',
    },
    '& tr:last-of-type > td': {
      borderBottom: 0,
    },
  },
  tableRow: {
    '& > td': {
      paddingTop: '2px',
      paddingBottom: '2px',
    },
  },
  head: {
    backgroundColor: theme.custom.issueboxHeadingBackground,
    '& tr > th': {
      lineHeight: '1rem',
      padding: '8px 16px',
    },
  },
  large: {
    width: '120px',
    height: '120px',
    borderRadius: 0,
    cursor: 'pointer',
    '&:hover': {
      border: `2px solid ${theme.custom.photoBorderHover}`,
    },
  },
  photoCell: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  photoBadge: {
    backgroundColor: theme.palette.primary.darkest,
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    color: theme.custom.lightestgrey,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontWeight: 700,
    marginLeft: '-12px',
    zIndex: 2,
    fontSize: '0.85rem',
    lineHeight: 1,
    border: `2px solid ${theme.palette.background.paper}`,
    position: 'absolute',
    bottom: '-4px',
    right: '-10px',
  },
  issueCount: {
    fontSize: '1.5rem',
    fontWeight: 700,
    color: theme.custom.statusContrastTextAlt,
  },
  issueCountYes: {
    color: theme.custom.statusRed,
  },
  link: {
    textDecoration: 'none',
    color: theme.custom.statusRed,
    '&:hover': {
      color: theme.custom.statusRedBorderAlt,
    },
  },
}));

export default useStyles;
