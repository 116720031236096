import React from 'react';
import PropTypes from 'prop-types';

const NotFound = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
      <div style={{ fontSize: '3rem', fontWeight: 900 }}>404 Not Found</div>
      <div style={{ fontSize: '2rem' }}>Something went wrong.</div>
      <div>
        <a style={{ color: '#38f' }} href='https://globalbuildsolutions.com/'>
          globalbuildsolutions.com
        </a>
      </div>
    </div>
  );
};

NotFound.propTypes = {};

export default NotFound;
