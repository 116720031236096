import { makeStyles } from '@material-ui/core/styles';

// Constants
import { GANTT_COL_1 as gc1, GANTT_COL_2 as gc2 } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'grid',
    gridTemplateColumns: `${gc1} ${gc2} 1fr`,
    backgroundColor: 'transparent',
    borderTop: `1px dotted ${theme.custom.ganttGrid}`,
  },
  milestoneRow: {
    '&:hover': {
      backgroundColor: theme.custom.ganttMarker,
    },
  },
  nonMilestoneRow: {
    backgroundColor: theme.custom.ganttHeaderBg,
    borderTop: 'none',
  },
  timelineWrapper: {
    height: 'auto',
    alignSelf: 'center',
    padding: '4px 0px 3px 0px',
  },
  timelineWrapperEditMode: {
    padding: '4px 0px 3px 0px',
  },
}));

export default useStyles;
