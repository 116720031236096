import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';

// Components
import SortMenu from '../sort-menu/sort-menu.component';

// Styles
import useStyles from './sort-button.styles';

const SortButton = ({ queryObj, setQueryObj }) => {
  const classes = useStyles();
  const [anchorElSort, setAnchorElSort] = useState(null);
  const sortOpen = Boolean(anchorElSort);

  const handleSortMenuClose = () => {
    setAnchorElSort(null);
  };

  const handleSortMenuClick = (e) => {
    setAnchorElSort(e.currentTarget);
  };

  return (
    <>
      <IconButton
        color={queryObj.sortArr.length !== 0 ? 'primary' : 'default'}
        className={classes.iconButton}
        aria-label='directions'
        onClick={handleSortMenuClick}>
        <SortByAlphaIcon fontSize='small' />
      </IconButton>
      <SortMenu
        handleSortMenuClose={handleSortMenuClose}
        anchorElSort={anchorElSort}
        sortOpen={sortOpen}
        queryObj={queryObj}
        setQueryObj={setQueryObj}
      />
    </>
  );
};

SortButton.propTypes = {
  queryObj: PropTypes.object.isRequired,
  setQueryObj: PropTypes.func.isRequired,
};

SortButton.defaultProps = {
  queryObj: {},
};

export default SortButton;
