import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer } from 'react-leaflet';

// Components
import LegendBar from '../../components/legend-bar/legend-bar.component';
import MapBounds from '../../components/map-bounds/map-bounds.component';
import MapMarkerGrouped from '../map-marker-grouped/map-marker-grouped.component';

// Styles
import useStyles from './map-dashboard-grouped.styles';

// API Key via Configuration File
import config from '../../utils/config';

// Constants
import { MAP_BOUNDS } from '../../utils/constants';

// Selectors
import { selectActiveSiteId } from '../../redux/site/site.selectors';

import { selectMapRenderArr } from '../../redux/milestone/milestone.selectors';

// Actions
import { updateActiveSiteId } from '../../redux/site/site.actions';

const MapDashboardGrouped = ({
  activeSiteId,
  updateActiveSiteIdDispatch,
  mapRenderArr,
}) => {
  const classes = useStyles();
  return (
    <MapContainer
      bounds={MAP_BOUNDS}
      scrollWheelZoom={false}
      closePopupOnClick={false}
      doubleClickZoom={false}
      className={classes.mapContainer}>
      <TileLayer
        attribution={''}
        url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}`}
        id={config.MAPBOX_OUTDOORS_MAP_V1}
        tileSize={512}
        zoomOffset={-1}
        accessToken={config.MAPBOX_API_ACCESS_TOKEN}
      />
      {mapRenderArr.map((el) => (
        <MapMarkerGrouped
          el={el}
          key={el.groupName}
          activeSiteId={activeSiteId}
          updateActiveSiteIdDispatch={updateActiveSiteIdDispatch}
        />
      ))}
      <MapBounds />
      <LegendBar />
    </MapContainer>
  );
};

MapDashboardGrouped.propTypes = {
  activeSiteId: PropTypes.string.isRequired,
  updateActiveSiteIdDispatch: PropTypes.func.isRequired,
  mapRenderArr: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  activeSiteId: selectActiveSiteId,
  mapRenderArr: selectMapRenderArr,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveSiteIdDispatch: (siteId) => dispatch(updateActiveSiteId(siteId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapDashboardGrouped);
