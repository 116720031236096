import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

// Material UI
import Popover from '@material-ui/core/Popover';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Styles
import useStyles from './project-menu.styles';

// Selectors
import { selectCurrentUserProjectsOnProjectList } from '../../redux/user/user.selectors';
import { selectCurrentProject } from '../../redux/project/project.selectors';

const ProjectMenu = ({ projects, currentProject }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!currentProject) return null;

  const currentProjectAvatar = currentProject.avatarPhoto ? (
    <Avatar className={classes.small} src={currentProject.photo} />
  ) : (
    <Avatar className={classes.small}>
      {currentProject.shortName ? currentProject.shortName.slice(0, 1) : 'P'}
    </Avatar>
  );

  return (
    <>
      <Tooltip title='Change Project'>
        <div className={classes.projectMenu} onClick={handleClick}>
          <div className={classes.projectCode}>
            {currentProject.code ? currentProject.code.slice(0, 9) : 'PROJECT'}
          </div>
          {currentProjectAvatar}
        </div>
      </Tooltip>
      <Popover
        className={classes.popover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <div className={classes.root}>
          <List dense>
            {projects.map((el, idx) => (
              <ListItem
                key={el._id}
                component={RouterLink}
                to={`/projects/${el._id}`}
                className={clsx(
                  el._id === currentProject._id
                    ? classes.listItemActive
                    : classes.listItem
                )}
                onClick={handleClose}>
                <ListItemIcon>
                  <Avatar src={el.photo} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    el._id === currentProject._id
                      ? `${el.shortName} ✍🏻`
                      : el.shortName
                  }
                  secondary={
                    <>
                      <span
                        className={clsx(
                          classes.chip,
                          idx + 1 > 0 ? classes.chipUpdates : null
                        )}>
                        {0}
                      </span>
                      <span> Notifications TBD</span>
                    </>
                  }
                />
              </ListItem>
            ))}
            <ListItem
              component={RouterLink}
              key='allProjects'
              to={`/projects`}
              className={classes.listItemAll}
              onClick={handleClose}>
              <ListItemText primary='See All Projects' />
            </ListItem>
          </List>
        </div>
      </Popover>
    </>
  );
};

ProjectMenu.propTypes = {
  projects: PropTypes.array.isRequired,
  currentProject: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  projects: selectCurrentUserProjectsOnProjectList,
  currentProject: selectCurrentProject,
});

export default connect(mapStateToProps)(ProjectMenu);
