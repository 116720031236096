import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import axios from 'axios';

// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';
import { updateSubmissionEditStart } from '../../redux/submission/submission.actions';

// Selectors
import { selectModalEditSubmission } from '../../redux/ui/ui.selectors';
import { startAlert } from '../../redux/alert/alert.actions';

const dateOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

const EditSubmissionModal = ({
  editSubmission: { isOpen, content },
  toggleModalDispatch,
  updateSubmissionEditStartDispatch,
  startAlertDispatch,
}) => {
  const {
    doFetch,
    urlFetch,
    projectId,
    milestoneId,
    submissionId,
    milestoneName,
    siteName,
    lat,
    lng,
    subDatetime,
    createdBy,
    currentUser,
  } = content;

  const [extraData, setExtraData] = useState([]);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [editedLat, setEditedLat] = useState(0);
  const [editedLng, setEditedLng] = useState(0);
  const [editedDate, setEditedDate] = useState(null);
  const [editedTime, setEditedTime] = useState(null);
  const [userList, setUserList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    if (doFetch && urlFetch) {
      async function fetchExtraData() {
        const res = await axios.get(urlFetch);
        if (res?.data?.data?.docs) {
          setExtraData(res.data.data.docs);
        }
      }
      fetchExtraData();
    }
    return function cleanup() {
      setExtraData([]);
    };
  }, [doFetch, urlFetch]);

  useEffect(() => {
    if (doFetch && projectId) {
      async function fetchUsers() {
        const res = await axios.get(
          `/api/v1/users?projects[in]=${projectId}&fields=firstName,lastName,email,photo,authorityLevel&authorityLevel[eq]=appenateUser`
        );
        if (res?.data?.data?.docs) {
          setUserList(res.data.data.docs);
        }
      }
      fetchUsers();
    }
  }, [doFetch, projectId]);

  useEffect(() => {
    if (milestoneId) {
      setSelectedMilestone(milestoneId);
    }
  }, [milestoneId]);

  useEffect(() => {
    if (siteName) {
      setSelectedSite(siteName);
    }
  }, [siteName]);

  useEffect(() => {
    if (lat) {
      setEditedLat(lat);
    }
  }, [lat]);

  useEffect(() => {
    if (lng) {
      setEditedLng(lng);
    }
  }, [lng]);

  useEffect(() => {
    if (createdBy) {
      setSelectedUserId(createdBy);
    }
  }, [createdBy]);

  useEffect(() => {
    if (subDatetime) {
      // Date
      const datetime = new Date(subDatetime);
      // Parse Date
      const submissionDate = datetime
        .toISOString()
        .substring(0, 19)
        .replace('T', ' ')
        .split(' ')[0];

      const submissionTime = `${('0' + datetime.getHours()).slice(-2)}:${(
        '0' + datetime.getMinutes()
      ).slice(-2)}:${('0' + datetime.getSeconds()).slice(-2)}`;

      setEditedDate(submissionDate ? submissionDate : '2020-12-31');
      setEditedTime(submissionTime ? submissionTime : '11:59');
    }
  }, [subDatetime]);

  const handleMilestoneChange = (e) => {
    const milestone = extraData
      .map((el) => el.milestones)
      .flat()
      .find((el) => el.id === e.target.value);

    if (milestone) {
      setSelectedMilestone(milestone.id);
      setSelectedSite(milestone.siteName);
    }
  };

  const handleLatLngChange = (e) => {
    if (
      e.target.id === 'lat' &&
      e.target.value <= 90 &&
      e.target.value >= -90
    ) {
      setEditedLat(e.target.value);
    } else if (
      e.target.id === 'lng' &&
      e.target.value <= 180 &&
      e.target.value >= -180
    ) {
      setEditedLng(e.target.value);
    }
  };

  const handleDatetimeChange = (e) => {
    if (e.target.id === 'date') {
      setEditedDate(e.target.value);
    } else if (e.target.id === 'time') {
      setEditedTime(e.target.value);
    }
  };

  const handleUserChange = (e) => {
    if (e.target.value) {
      setSelectedUserId(e.target.value);
    }
  };

  const handleSubmit = () => {
    // Whats Changes
    const changes = {
      milestone: 0,
      site: 0,
      lat: 0,
      lng: 0,
      datetime: 0,
      user: 0,
    };

    // Build Update
    let edits = {};
    if (
      extraData &&
      selectedMilestone &&
      selectedSite &&
      milestoneId !== selectedMilestone
    ) {
      const milestoneUpdate = extraData
        .map((el) => el.milestones)
        .flat()
        .find((el) => el.id === selectedMilestone);

      Object.assign(edits, {
        milestone: {
          _id: milestoneUpdate.id,
          name: milestoneUpdate.name,
          description: milestoneUpdate.description,
        },
        site: {
          _id: milestoneUpdate.siteId,
          name: milestoneUpdate.siteName,
        },
      });

      if (siteName !== edits.site.name) changes.site = 1;
      if (milestoneName !== edits.milestone.name) changes.milestone = 1;
    }

    if (lat !== editedLat) {
      Object.assign(edits, {
        submittedFromLat: editedLat,
      });
      changes.lat = 1;
    }

    if (lng !== editedLng) {
      Object.assign(edits, {
        submittedFromLng: editedLng,
      });
      changes.lng = 1;
    }

    const oldDate = new Date(subDatetime);
    const newDate = new Date(editedDate + ' ' + editedTime);

    if (
      oldDate.getTime().toString().substring(0, 9) !==
      newDate.getTime().toString().substring(0, 9)
    ) {
      changes.datetime = 1;
      Object.assign(edits, {
        createdAt: newDate.toLocaleString('en-US', dateOptions),
      });
    }

    if (createdBy !== selectedUserId) {
      const newUser = userList.find((el) => el._id === selectedUserId);
      Object.assign(edits, {
        createdBy: {
          _id: newUser._id,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          email: newUser.email,
          photo: newUser.photo,
        },
      });
      changes.user = 1;
    }

    // Build Update String;
    let updateString = '';
    if (changes.milestone === 1) {
      updateString += `Updated milestone from ${milestoneName} to ${edits.milestone.name}. `;
    }
    if (changes.site === 1) {
      updateString += `Changed submission site from ${siteName} to ${edits.site.name}. `;
    }
    if (changes.lat === 1) {
      updateString += `Submission latitude relocated from ${lat} deg to ${editedLat} deg. `;
    }
    if (changes.lng === 1) {
      updateString += `Submission longitude relocated from ${lng} deg to ${editedLng} deg. `;
    }
    if (changes.datetime === 1) {
      updateString += `Date changed from ${oldDate.toLocaleString(
        'en-US',
        dateOptions
      )} to ${newDate.toLocaleString('en-US', dateOptions)}. `;
    }
    if (changes.user === 1) {
      const oldUser = userList.find((el) => el._id === createdBy);
      const newUser = userList.find((el) => el._id === selectedUserId);
      updateString += `Author changed from ${oldUser.firstName} ${oldUser.lastName} to ${newUser.firstName} ${newUser.lastName}. `;
    }

    updateString = updateString.trimEnd();

    // Build Update Object to push into Update Array
    const update = {
      createdBy: {
        _id: currentUser._id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        photo: currentUser.photo,
      },
      seenBy: [currentUser._id],
      actionType: 'Edited',
      categorization: 0,
      content: {
        notes: updateString,
      },
    };

    // Dispatch Update
    const numChanges = Object.values(changes).reduce((a, c) => a + c, 0);
    if (numChanges > 0) {
      updateSubmissionEditStartDispatch({
        projectId: projectId,
        docId: submissionId,
        edits: edits,
        update: update,
      });
    } else {
      startAlertDispatch({
        msg: `Nothing changed! But here is a happy alert :)`,
        alertType: 'success',
      });
    }
    toggleModalDispatch({ modalName: 'editSubmission', modalContent: {} });
  };

  const handleDismiss = () => {
    content.dismissFunc(); // Write this!
    toggleModalDispatch({ modalName: 'editSubmission', modalContent: {} });
  };

  const dataFound =
    extraData && Array.isArray(extraData) && extraData.length > 0;

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth={'md'}
      onClose={handleDismiss}
      transitionDuration={{ appear: 225, enter: 225, exit: 0 }}>
      <DialogTitle style={{ padding: '12px 36px' }}>
        {content.title}
      </DialogTitle>
      <DialogContent style={{ padding: '0px 36px 0px 36px' }}>
        {dataFound ? (
          <>
            <div style={{ margin: '0px 0px 24px 0px' }}>
              <InputLabel htmlFor='milestones' shrink={true}>
                Milestone
              </InputLabel>
              <Select
                native
                value={selectedMilestone}
                onChange={handleMilestoneChange}
                id='milestones'
                fullWidth={true}
                size={20}>
                {extraData.map((siteOpt) => {
                  return (
                    <optgroup key={siteOpt.siteId} label={siteOpt.siteName}>
                      {siteOpt.milestones.map((milestoneOpt) => (
                        <option key={milestoneOpt.id} value={milestoneOpt.id}>
                          {milestoneOpt.name}
                        </option>
                      ))}
                    </optgroup>
                  );
                })}
              </Select>
            </div>
            <div style={{ margin: '24px 0px' }}>
              <InputLabel htmlFor='site' shrink={true}>
                Site
              </InputLabel>
              <TextField
                id='site'
                disabled={true}
                value={selectedSite}
                fullWidth={true}
              />
              <FormHelperText>
                The site is determined by the milestone, and cannot be
                independently set.
              </FormHelperText>
            </div>
            <div style={{ margin: '24px 0px', width: '100%', display: 'flex' }}>
              <TextField
                id='lat'
                type='number'
                inputProps={{ min: -90, max: 90 }}
                onChange={handleLatLngChange}
                value={editedLat}
                label='Submission Latitude (-90 to 90)'
                style={{ flexGrow: 0.5, marginRight: '8px' }}
              />
              <TextField
                id='lng'
                type='number'
                inputProps={{ min: -180, max: 180 }}
                onChange={handleLatLngChange}
                value={editedLng}
                label='Submission Longitude (-180 to 180)'
                style={{ flexGrow: 0.5, marginLeft: '8px' }}
              />
            </div>

            <div style={{ margin: '24px 0px' }}>
              {userList.length > 0 ? (
                <>
                  <InputLabel htmlFor='userlist' shrink={true}>
                    Submitted By
                  </InputLabel>
                  <Select
                    native
                    value={selectedUserId}
                    onChange={handleUserChange}
                    id='userlist'
                    fullWidth={true}
                    size={20}>
                    {userList.map((user) => (
                      <option
                        key={user._id}
                        value={
                          user._id
                        }>{`${user.firstName} ${user.lastName}`}</option>
                    ))}
                  </Select>
                </>
              ) : null}
            </div>
            <div style={{ margin: '24px 0px', width: '100%', display: 'flex' }}>
              <TextField
                id='date'
                type='date'
                onChange={handleDatetimeChange}
                value={editedDate}
                label='Submission Date'
                style={{ flexGrow: 0.5, marginRight: '8px' }}
              />
              <TextField
                id='time'
                type='time'
                onChange={handleDatetimeChange}
                value={editedTime}
                label='Submission Time'
                style={{ flexGrow: 0.5, marginLeft: '8px' }}
              />
            </div>
            <div>
              Note that all issues associated with this submission will also be
              updated to reflect the above information.
            </div>
          </>
        ) : (
          <div>This submission cannot be edited.</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDismiss} color='primary' autoFocus>
          Close
        </Button>
        <Button onClick={handleSubmit} color='primary'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditSubmissionModal.propTypes = {
  editSubmission: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  updateSubmissionEditStartDispatch: PropTypes.func.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  editSubmission: selectModalEditSubmission,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  updateSubmissionEditStartDispatch: (updateObj) =>
    dispatch(updateSubmissionEditStart(updateObj)),
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSubmissionModal);
