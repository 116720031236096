import { makeStyles } from '@material-ui/core/styles';

// Constants
import { DASHBOARD_WIDGET_MAIN_HEIGHT as mapHeight } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    height: mapHeight,
    width: '100%',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: theme.custom.bg,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
