import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

// Components
import FeedBreadcrumb from '../feed-breadcrumb/feed-breadcrumb.component';
import UpdateIcon from '../update-icon/update-icon.component';
import UpdateThumbnails from '../update-thumbnails/update-thumbnails.component';
import UpdateAttachments from '../update-attachments/update-attachments.component';

// Icons
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// Styles
import useStyles from './feed-item.styles';

// Selectors
import { selectCurrentUser } from '../../redux/user/user.selectors';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Utilities
import { dateToTimeAgo } from '../../utils/utilityFns';

// Constants
import { DATE_FORMAT_DATETIME_COMPLETE as datetimeComplete } from '../../utils/constants';
import FeedAction from '../feed-action/feed-action.component';

const FeedItem = ({
  update,
  user,
  toggleModalDispatch,
  hideIcon = false,
  hideBreadcrumbs = false,
  highlighted = false,
  clickFunc,
}) => {
  const classes = useStyles();

  // Fetching
  if (!user) return <div>Fetching...</div>;

  // Type Check
  if (!update || Object.keys(update).length === 0) return null;

  // Deleted
  if (update?.isDeleted)
    return (
      <div className={clsx(classes.root, classes.deletedRoot)}>
        <div className={classes.iconContainer}>
          <DeleteForeverIcon className={classes.icon} />
        </div>
        <div className={classes.contentContainer}>
          <span className={classes.deleted}>This update has been deleted.</span>
        </div>
      </div>
    );

  // Utility Variables
  const refId = update?.refId ? `#${update.refId}` : '#XX';

  // Date String
  const actionDate = new Date(update.createdAt);
  const dateTimeStringComplete = actionDate.toLocaleString(
    'en-US',
    datetimeComplete
  );
  const dateTimeStringAgo = dateToTimeAgo(update.createdAt, 'round-minute');

  // Author String
  const authorStr =
    user._id === update.createdBy._id
      ? 'You'
      : `${update.createdBy.firstName.slice(0, 1).toUpperCase()}. ${
          update.createdBy.lastName
        }`;

  const handleClick = () => {
    if (clickFunc) {
      clickFunc(update);
    }
  };

  return (
    <div
      className={clsx(
        classes.root,
        highlighted ? classes.highlighted : null,
        clickFunc ? classes.rootClickable : null
      )}
      onClick={handleClick}>
      <div
        className={clsx(
          classes.iconContainer,
          hideIcon ? classes.hidden : null
        )}>
        <UpdateIcon update={update} />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.metaContainer}>
          <span
            className={clsx(
              update?.unread ? classes.inlineBadge : classes.hidden
            )}></span>
          <FeedAction update={update} refId={refId} />
          <span className={classes.regularText}>by</span>
          <div className={classes.author}>{authorStr}</div>
          <div className={classes.date} title={dateTimeStringComplete}>
            {dateTimeStringAgo}
          </div>
        </div>
        {!hideBreadcrumbs ? <FeedBreadcrumb update={update} /> : null}
        {update?.content?.notes && update.content.notes.length > 0 ? (
          <div className={classes.notes}>{update.content.notes}</div>
        ) : null}
        <UpdateAttachments update={update} />
        <UpdateThumbnails
          update={update}
          toggleModalDispatch={toggleModalDispatch}
        />
      </div>
    </div>
  );
};

FeedItem.propTypes = {
  update: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  hideIcon: PropTypes.bool,
  hideBreadcrumbs: PropTypes.bool,
  highlighted: PropTypes.bool,
  clickFunc: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedItem);
