import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

// Styles
import useStyles from './photo-grid.styles';

const PhotoGrid = ({
  title = 'Photos',
  type = 'item',
  photos = [],
  toggleModalDispatch,
  showTitle = false,
  padGrid = false,
  noPhotosText = undefined,
  addPhotoFunc,
}) => {
  const classes = useStyles();

  let photoCount = 0;

  return (
    <>
      {showTitle ? (
        <div className={classes.header}>
          <h1 className={classes.textHeader}>{title}</h1>
          {addPhotoFunc ? (
            <Tooltip title={`Add a photo to this ${type}`}>
              <IconButton aria-label='add photo' onClick={addPhotoFunc}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      ) : null}
      {photos.length > 0 ? (
        <div
          className={clsx(
            classes.imageGrid,
            padGrid ? classes.withPadding : null
          )}>
          {photos.map((photo, idxPhoto) => {
            const photoNum = photoCount;
            photoCount++;
            return (
              <div key={`photo-${idxPhoto}`} className={classes.imageGridItem}>
                <img
                  src={photo.url}
                  alt='issue'
                  className={classes.photo}
                  onClick={() =>
                    toggleModalDispatch({
                      modalName: 'photoGallery',
                      modalContent: {
                        photos: photos,
                        startPhoto: photoNum,
                      },
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      ) : noPhotosText ? (
        <div className={classes.noPhotosText}>{noPhotosText}</div>
      ) : null}
    </>
  );
};

FileList.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  photos: PropTypes.array.isRequired,
  togglePhotoModal: PropTypes.func.isRequired,
  padGrid: PropTypes.bool,
  showTitle: PropTypes.bool.isRequired,
  noPhotosText: PropTypes.string,
  addPhotoFunc: PropTypes.func,
};

export default PhotoGrid;
