import React from 'react';
import PropTypes from 'prop-types';

// Components
import SvgFileIcon from '../svg-file-icon/svg-file-icon.component';

// Styles
import useStyles from './update-attachments.styles';

const UpdateAttachments = ({ update }) => {
  const classes = useStyles();

  const attachments =
    update?.content?.attachments && update?.content?.attachments.length > 0
      ? update.content.attachments
      : false;

  if (!attachments) return null;

  const handleOpenNewTab = (path) => {
    window.open(path, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={classes.root}>
      {attachments.map((file, idx) => (
        <div
          onClick={() => handleOpenNewTab(file.url)}
          key={`attachment-${idx}`}
          className={classes.fileContainer}
          title={file.filename + '.' + file.extension}>
          <span className={classes.fileIcon}>
            <SvgFileIcon ext={file.extension} iconSize='large' />
          </span>
          <div className={classes.metaContainer}>
            <span className={classes.fileName}>
              {`${
                file.filename.length > 20
                  ? file.filename.slice(0, 20) + '.' + file.extension
                  : file.filename
              }`}
            </span>
            <span className={classes.filesize}>{file.filesize}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

UpdateAttachments.propTypes = {
  update: PropTypes.object.isRequired,
};

export default UpdateAttachments;
