import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.custom.bg,
    height: '100vh',
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.darkest,
  },
  title: {
    flex: 1,
  },
}));

export default useStyles;
