import React from 'react';
import PropTypes from 'prop-types';

// Components
import MentionsParagraph from '../mentions-paragraph/mentions-paragraph.component';

// Styles
import useStyles from './update-notes.styles';

const UpdateNotes = ({ update, mentions }) => {
  const classes = useStyles();

  const notes =
    update?.content?.notes && update?.content?.notes.length > 0
      ? update.content.notes
      : false;

  if (!notes) return null;

  if (!mentions || mentions.length === 0)
    return <div className={classes.root}>{notes}</div>;

  return (
    <div className={classes.root}>
      <MentionsParagraph text={notes} mentions={mentions} fancy={true} />
    </div>
  );
};

UpdateNotes.propTypes = {
  update: PropTypes.object.isRequired,
  mentions: PropTypes.array.isRequired,
};

export default UpdateNotes;
