import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectModalConfirmDialog } from '../../redux/ui/ui.selectors';

const ConfirmModal = ({
  confirmDialog: { isOpen, content },
  toggleModalDispatch,
}) => {
  const handleClose = () => {
    toggleModalDispatch({ modalName: 'confirmDialog', modalContent: {} });
  };

  const handleDismiss = () => {
    content.dismissFunc(); // Write this!
    toggleModalDispatch({ modalName: 'confirmDialog', modalContent: {} });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      transitionDuration={{ appear: 225, enter: 225, exit: 0 }}
      aria-labelledby='confirm-dialog-title'
      aria-describedby='confirm-dialog-description'>
      <DialogTitle id='confirm-dialog-title'>{content.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='confirm-dialog-description'>
          {content.desc}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          No
        </Button>
        <Button onClick={handleDismiss} color='primary' autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  confirmDialog: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  confirmDialog: selectModalConfirmDialog,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
