import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
  },
  table: {
    '& tr > td': {
      borderColor: theme.custom.lightestgrey,
    },
    '& tr:last-of-type > td': {
      borderBottom: 0,
    },
  },
  head: {
    backgroundColor: theme.custom.issueboxHeadingBackground,
  },
  small: {
    width: '24px',
    height: '24px',
  },
  large: {
    width: '80px',
    height: '80px',
    borderRadius: 0,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.custom.photoBorderHover}`,
    },
  },
  tableRow: {
    '& > td': {
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
  status: {
    marginLeft: '4px',
    borderRadius: '20px',
    maxWidth: '96px',
    padding: '4px 8px',
    textAlign: 'center',
    color: theme.custom.statusContrastText,
    fontSize: '0.8rem',
    textTransform: 'capitalize',
    backgroundColor: theme.custom.lightgreyGantt,
    '&.completed': {
      backgroundColor: theme.custom.statusGreen,
    },
    '&.ongoing': {
      backgroundColor: theme.custom.statusOrange,
    },
    '&.stopped': {
      backgroundColor: theme.custom.statusRed,
    },
  },
  photoCell: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  fileCell: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  photoBadge: {
    backgroundColor: theme.custom.mediumgrey,
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    color: theme.custom.lightestgrey,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontWeight: 700,
    marginLeft: '-12px',
    zIndex: 2,
    fontSize: '0.75rem',
    lineHeight: 1,
    border: `1px solid ${theme.palette.background.paper}`,
  },
  fileBadge: {
    backgroundColor: theme.custom.mediumgrey,
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    color: theme.custom.lightestgrey,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontWeight: 700,
    marginLeft: '-12px',
    zIndex: 2,
    fontSize: '0.75rem',
    lineHeight: 1,
    transform: 'translateY(4px)',
    border: `1px solid ${theme.palette.background.paper}`,
  },
  photo: {
    width: '36px',
    height: '36px',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.custom.photoBorderHover}`,
    },
  },
  sortable: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.custom.statusGreyBorder,
    },
    sortableActive: {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
