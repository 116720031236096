import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    padding: '18px 0',
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.custom.drawerDivider}`,
  },
  footerOpenContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.8s',
    visibility: 'visible',
    opacity: 1,
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
  },
  largeAvatar: {
    width: '56px',
    height: '56px',
    backgroundColor: theme.custom.lightSlateBlue,
    color: '#FFF',
    fontWeight: 900,
    fontSize: '1.75rem',
  },
  gbs: {
    padding: '12px 0 8px 0',
    fontWeight: 700,
    color: theme.custom.statusContrastTextAlt,
  },
  buttons: {
    '& button, a': {
      color: theme.custom.statusContrastTextAlt,
      fontWeight: 400,
      fontSize: '0.7rem',
      '&:first-of-type': {
        marginRight: '8px',
      },
    },
  },
}));

export default useStyles;
