import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

// Styles
import useStyles from '../submission-action-buttons/submission-action-buttons.styles';

// Actions
import {
  deleteSubmissionShareStart,
  createSubmissionShareStart,
} from '../../redux/submission/submission.actions';

const SubmissionActionButtons = ({
  submission,
  user,
  toggleModalDispatch,
  deleteSubmissionShareStartDispatch,
  createSubmissionShareStartDispatch,
}) => {
  const classes = useStyles();
  const { projectId } = useParams();

  const handleDeleteSubmissionShare = (docId) => {
    deleteSubmissionShareStartDispatch({ docId, projectId });
  };

  const authed =
    user?.authorityLevel === 'admin' || user?.authorityLevel === 'superAdmin';

  const shareUrl =
    submission?.isShared && submission?.share?._id
      ? `${window.location.origin.toString()}/share/submission/${
          submission.share._id
        }`
      : '';

  const notesButton = (
    <Tooltip title='Notes'>
      <IconButton
        size='small'
        className={classes.button}
        aria-label='notes'
        onClick={() =>
          toggleModalDispatch({
            modalName: 'submissionNotes',
            modalContent: {
              id: submission._id,
              categorization: 0,
              milestoneFlag: 0,
              actionType: 'Updated',
              updates: submission.updates ? submission.updates : [],
            },
          })
        }>
        <Badge
          color='secondary'
          size='small'
          variant='dot'
          badgeContent={submission.updates.reduce(
            (prev, acc) =>
              !acc.seenBy.includes(user._id) ? (prev = prev + 1) : prev,
            0
          )}
          overlap='circular'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}>
          <AssignmentIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );

  const shareButton =
    authed && submission?.isShared ? (
      <Tooltip title='Share'>
        <IconButton
          size='small'
          className={classes.button}
          aria-label='delete share'
          onClick={() =>
            toggleModalDispatch({
              modalName: 'shareDelete',
              modalContent: {
                shareUrl: shareUrl,
                share: submission?.share,
                dismissFunc: () => handleDeleteSubmissionShare(submission._id),
              },
            })
          }>
          <Badge
            className={classes.shareActive}
            variant='dot'
            badgeContent={1}
            overlap='circular'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <SupervisedUserCircleIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title='Share'>
        <IconButton
          size='small'
          className={classes.button}
          aria-label='share submission'
          onClick={() =>
            toggleModalDispatch({
              modalName: 'shareCreate',
              modalContent: {
                docId: submission._id,
                projectId: projectId,
                createShare: createSubmissionShareStartDispatch,
              },
            })
          }>
          <SupervisedUserCircleIcon />
        </IconButton>
      </Tooltip>
    );

  const deleteButton =
    authed && !submission.isDeleted ? (
      <Tooltip title='Delete'>
        <IconButton
          size='small'
          className={classes.button}
          aria-label='delete submission'
          onClick={() =>
            toggleModalDispatch({
              modalName: 'submissionResolve',
              modalContent: {
                id: submission._id,
                milestoneFlag: 0,
                categorization: 0,
                actionType: submission.isDeleted ? 'Restored' : 'Deleted',
              },
            })
          }>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    ) : null;

  const exportButton = (
    <Tooltip title='Export'>
      <IconButton
        size='small'
        className={classes.button}
        aria-label='export'
        onClick={() =>
          toggleModalDispatch({
            modalName: 'exportOptions',
            modalContent: {
              data: submission ? [submission] : [{}],
              type: 'submission',
            },
          })
        }>
        <SaveIcon />
      </IconButton>
    </Tooltip>
  );

  const openButton = (
    <Tooltip title='Open Submission'>
      <IconButton
        size='small'
        className={classes.button}
        aria-label='open submission'
        href={`/projects/${submission.project}/submissions/${submission._id}`}>
        <LaunchIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <>{openButton}</>
      <>{notesButton}</>
      <>{exportButton}</>
      <>{shareButton}</>
      <>{deleteButton}</>
    </React.Fragment>
  );
};

SubmissionActionButtons.propTypes = {
  submission: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  deleteSubmissionShareStartDispatch: PropTypes.func.isRequired,
  createSubmissionShareStartDispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  deleteSubmissionShareStartDispatch: (updateObj) =>
    dispatch(deleteSubmissionShareStart(updateObj)),
  createSubmissionShareStartDispatch: (updateObj) =>
    dispatch(createSubmissionShareStart(updateObj)),
});

export default connect(null, mapDispatchToProps)(SubmissionActionButtons);
