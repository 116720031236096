import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

// Styles
import useStyles from './map-single.styles';

// Config
import config from '../../utils/config';

const MapSingle = ({
  lat,
  lng,
  zoomLevel,
  showLatLng,
  width = '400px',
  height = '400px',
  padding = '0px 0px 0px 0px',
}) => {
  const classes = useStyles({ width, height, padding });
  const markerRef = useRef();
  const gpsPercision = 10000;
  return (
    <div className={classes.root}>
      <MapContainer
        center={[lat, lng]}
        zoom={zoomLevel}
        scrollWheelZoom={false}
        closePopupOnClick={false}
        className={classes.mapContainer}>
        <TileLayer
          attribution={''}
          url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}`}
          id={config.MAPBOX_SAT_MAP_V1}
          tileSize={512}
          zoomOffset={-1}
          accessToken={config.MAPBOX_API_ACCESS_TOKEN}
        />
        <Marker id={'single-sub-1'} ref={markerRef} position={[lat, lng]} />
      </MapContainer>
      {showLatLng && (
        <div className={classes.latlng}>
          <span>📍 Submitted From</span>
          <span>
            <strong>Latitude: </strong>
            {Math.ceil(lat * gpsPercision) / gpsPercision}
          </span>
          <span>
            <strong>Longitude: </strong>
            {Math.ceil(lng * gpsPercision) / gpsPercision}
          </span>
        </div>
      )}
    </div>
  );
};

MapSingle.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  showLatLng: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
};

export default MapSingle;
