/**
 * Dashboard
 */
export const DRAWER_WIDTH = 200;

export const DASHBOARD_WIDGET_MAIN_HEIGHT = 600;

export const ISSUE_PREVIEW_LENGTH = 133;

/**
 * Alerts
 * DURATION in milliseconds
 */
export const ALERT_DURATION = 2000;

/**
 * Gantt
 */
export const GANTT_COL_1 = '260px';

export const GANTT_COL_2 = '20px';

export const GANTT_TODAY = '2px';

export const GANTT_ABBR_MOS = 22;

export const GANTT_ABBR_WKS = 40;

export const DAY = 24 * 60 * 60 * 1000;

export const WEEK = 7 * DAY;

export const YEAR = 365 * DAY;

/**
 * Files and photos
 */
export const MAX_PHOTOS_ISSUE = 5;

export const MAX_ATTACHMENTS_ISSUE = 5;

export const MAX_PHOTO_SIZE = 5 * 1024 * 1024;

export const MAX_ATTACHMENT_SIZE = 5 * 1024 * 1024;

export const ISSUE_UPLOAD_TYPES =
  '5Mb limit per file. Supported image formats .jpg, .jpeg, .gif, .bmp, .png. Most non-executable file formats are also permitted.';

/**
 * Maps
 */
// prettier-ignore
export const MAP_BOUNDS = [[0, 0],[10, 10]];

/**
 *
 */
export const EMOJI_TYPES = {
  love: '❤️',
  clap: '👏',
  fire: '🔥',
  thumbsUp: '👍',
  thumbsDown: '👎',
  check: '✅',
};

/**
 * Date Formats
 */
export const DATE_FORMAT_DATETIME_COMPLETE = {
  weekday: 'long', // Full name of the day of the week
  year: 'numeric',
  month: 'long', // Full name of the month
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: true, // Use 12-hour time format
};

export const DATE_FORMAT_DATETIME_LONG = {
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

export const DATE_FORMAT_DATE_LONG = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};
export const DATE_FORMAT_TIME_LONG = {
  hour: 'numeric',
  minute: 'numeric',
};

export const DATE_FORMAT_DATE_SHORT = {
  month: '2-digit',
  day: '2-digit',
};

/**
 * File Icon
 */
export const FILEICON_VIEWBOX = {
  WIDTH: 40,
  HEIGHT: 48,
};

export const FILEICON_ICON = {
  WIDTH: FILEICON_VIEWBOX.WIDTH,
  HEIGHT: FILEICON_VIEWBOX.HEIGHT,
  X_OFFSET: 0,
};

export const FILEICON_FOLD = {
  HEIGHT: 12,
};

export const FILEICON_LABEL_HEIGHT = 14;

/**
 * Sort Filter Search Menu
 */
export const SEVERITY_RANGE_LOW = 0;

export const SEVERITY_RANGE_HIGH = 4;

export const DEFAULT_ISSUE_QUERY = {
  pageTitle: 'issue',
  page: 1,
  perPage: 20,
  sortArr: [
    { order: 'desc', vector: 'createdAt', title: 'Date' },
    { order: 'desc', vector: 'isResolved', title: 'Resolved' },
  ],
  rangeArr: [],
  fieldArr: [],
  searchStr: '',
};

export const DEFAULT_ISSUE_TRASH_QUERY = {
  pageTitle: 'issue',
  page: 1,
  perPage: 20,
  sortArr: [{ order: 'desc', vector: 'deletedAt', title: 'Deleted At' }],
  rangeArr: [],
  fieldArr: [],
  searchStr: '',
  limitObj: { isDeleted: ['true'] },
};

export const DEFAULT_SUBMISSION_QUERY = {
  pageTitle: 'submission',
  page: 1,
  perPage: 20,
  sortArr: [{ order: 'desc', vector: 'createdAt', title: 'Date' }],
  rangeArr: [],
  fieldArr: [],
  searchStr: '',
};

export const DEFAULT_SUBMISSION_TRASH_QUERY = {
  pageTitle: 'submission',
  page: 1,
  perPage: 20,
  sortArr: [{ order: 'desc', vector: 'deletedAt', title: 'Deleted At' }],
  rangeArr: [],
  fieldArr: [],
  searchStr: '',
  limitObj: { isDeleted: ['true'] },
};

export const DASHBOARD_SUBMISSION_QUERY = {
  pageTitle: 'dashboard',
  page: 1,
  perPage: 12,
  sortArr: [{ order: 'desc', vector: 'createdAt', title: 'Date' }],
  rangeArr: [],
  fieldArr: [], // { vector: 'data', hide: true }
  searchStr: '',
};

export const SORT_OPTIONS = {
  issue: [
    { vector: 'createdAt', order: 'desc', title: 'Date' },
    {
      vector: 'currentCategorization',
      order: 'desc',
      title: 'Severity',
    },
    { vector: 'site.name', order: 'desc', title: 'Site' },
    { vector: 'isResolved', order: 'desc', title: 'Completed' },
  ],
  submission: [
    { vector: 'createdAt', order: 'desc', title: 'Date' },
    { vector: 'site.name', order: 'desc', title: 'Site' },
    { vector: 'issueCount', order: 'desc', title: 'Number of Issues' },
  ],
};

export const SLIDER_MARKS = [
  {
    value: 0,
    label: 'Uncategorized',
  },
  {
    value: 1,
    label: 'Low',
  },
  {
    value: 2,
    label: 'Medium',
  },
  {
    value: 3,
    label: 'High',
  },
  {
    value: 4,
    label: 'Off Track',
  },
];

export const SLIDER_MARKS_SUBMISSION = [
  {
    value: 0,
    label: '0+',
  },
  {
    value: 1,
    label: '1+',
  },
  {
    value: 2,
    label: '2+',
  },
];
