import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minWidth: 260,
    maxWidth: 360,
    backgroundColor: '#FFF',
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'inherit',
      cursor: 'pointer',
    },
  },
  iconButton: {
    color: '#fff',
  },
  popover: {
    borderRadius: '20px',
  },
  input: {
    display: 'none',
  },
  link: {
    cursor: 'pointer',
  },
});

export default useStyles;
