import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Styles
import useStyles from './update-action-issue.styles';

const UpdateActionIssue = ({
  singleView = true,
  actionType,
  createdBy,
  currentUserId,
  categorization = 0,
  refId,
  site,
  milestone,
}) => {
  const classes = useStyles();

  // Author String
  const authorStr =
    currentUserId === createdBy._id
      ? 'You'
      : `${createdBy.firstName.slice(0, 1).toUpperCase()}. ${
          createdBy.lastName
        }`;

  // Do we make components for these ?
  /*
        <UpdateRefId refId={123} /> <-- renders proper bracker and link and to where

        <UAction type={issue}>
          <Sentence />       <--- cam nesting fix this issue ?
        </UAction>



  */

  // Action Types
  switch (actionType) {
    case 'Field Submission':
      return singleView ? (
        <div className={classes.root}>
          <span className={classes.actionText}>
            New Issue Identified via Field Submission
          </span>
          <span className={classes.defaultText}>by</span>
          <span className={classes.authorText}>{authorStr}</span>
        </div>
      ) : (
        <div className={classes.root}>
          <span className={classes.actionText}>
            {`New Issue [${refId}] Identified via ${milestone.name} at ${site.name}`}
          </span>
          <span className={classes.defaultText}>by</span>
          <span className={classes.authorText}>{authorStr}</span>
        </div>
      );
    case 'Started Sharing':
      return (
        <div className={classes.root}>
          <span className={classes.actionText}>Sharing Link Created</span>
          <span className={classes.defaultText}>by</span>
          <span className={classes.authorText}>{authorStr}</span>
        </div>
      );
    case 'Stopped Sharing':
      return (
        <div className={classes.root}>
          <span className={classes.actionText}>Sharing Link Revoked</span>
          <span className={classes.defaultText}>by</span>
          <span className={classes.authorText}>{authorStr}</span>
        </div>
      );
    case 'Share Link Comment':
      return (
        <div className={classes.root}>
          <span className={classes.authorText}>{authorStr}</span>
          <span className={classes.actionText}>Commented</span>
          <span className={classes.defaultText}>via Sharing Link</span>
        </div>
      );
    case 'Resolved':
      return (
        <div className={classes.root}>
          <span className={classes.actionText}>Resolved</span>
          <span className={classes.defaultText}>by</span>
          <span className={classes.authorText}>{authorStr}</span>
        </div>
      );
    case 'Reopened':
      return (
        <div className={classes.root}>
          <span className={classes.actionText}>Reopened</span>
          <span className={classes.defaultText}>by</span>
          <span className={classes.authorText}>{authorStr}</span>
        </div>
      );
    case 'Deleted':
      return (
        <div className={classes.root}>
          <span className={classes.actionText}>Deleted</span>
          <span className={classes.defaultText}>by</span>
          <span className={classes.authorText}>{authorStr}</span>
        </div>
      );
    case 'Restored':
      return (
        <div className={classes.root}>
          <span className={classes.actionText}>Restored</span>
          <span className={classes.defaultText}>by</span>
          <span className={classes.authorText}>{authorStr}</span>
        </div>
      );
    case 'Updated':
      return (
        <div className={classes.root}>
          <span className={classes.authorText}>{authorStr}</span>
          <span className={classes.actionText}>Commented</span>
        </div>
      );
    case 'Reply':
      return (
        <div className={classes.root}>
          <span className={classes.authorText}>{authorStr}</span>
          <span className={classes.actionText}>Replied</span>
        </div>
      );
    case 'Recategorized':
      let recatText;
      let iconClass;
      if (categorization === 0) {
        recatText = 'NONE';
        iconClass = classes.none;
      } else if (categorization === 1) {
        recatText = 'LOW';
        iconClass = classes.low;
      } else if (categorization === 2) {
        recatText = 'MEDIUM';
        iconClass = classes.medium;
      } else if (categorization === 3) {
        recatText = 'HIGH';
        iconClass = classes.high;
      } else if (categorization === 4) {
        recatText = 'OFF TRACK';
        iconClass = classes.offTrack;
      } else {
        recatText = 'ERROR';
        iconClass = classes.none;
      }
      return (
        <div className={classes.root}>
          <span className={classes.actionText}>Recategorized</span>
          <span className={classes.defaultText}>to</span>
          <span className={clsx(classes.recatRoot, iconClass)}>
            {recatText}
          </span>
          <span className={classes.defaultText}>by</span>
          <span className={classes.authorText}>{authorStr}</span>
        </div>
      );

    default:
      return <div>Invalid Action Type</div>;
  }
};

UpdateActionIssue.propTypes = {
  singleView: PropTypes.bool.isRequired,
  actionType: PropTypes.string.isRequired,
  createdBy: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  categorization: PropTypes.number,
  refId: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
  milestone: PropTypes.object.isRequired,
};

export default UpdateActionIssue;
