export const shared = {
  bg: 'rgba(205,213,222,0.60)', //'#cddade', 200 210 225
  bgLight: '#fafafa',
  primaryHover: '#3ba3fc',
  statusRed: '#ff6252', // #d12716 this can be used in gantt site view for dates
  statusRedBorder: '#e82424',
  statusRedBorderAlt: '#f24735',
  statusRedChipBg: 'rgba(244, 67, 54, 0.3)',
  statusGreen: '#55de84',
  statusGreenBorder: '#05a910',
  statusGreenChipBg: 'rgba(76, 175, 80, 0.3)',
  statusOrange: '#ffa726',
  statusOrangeAlt: '#ff9800',
  statusOrangeBorder: '#ff8826',
  statusOrangeChipBg: 'rgba(255, 152, 0, 0.3)',
  statusGrey: '#BBBBBB',
  statusGreyBorder: '#6a6a6a',
  statusContrastText: '#FFF',
  statusContrastTextAlt: '#6b6b6b',
  popupBackground: 'rgba(255, 255, 255, 0.9)',
  legendBg: 'rgba(0,0,0,0.5)',
  issueboxHeadingBackground: 'rgba(238, 238, 238, 1)',
  issueCardBg: '#f5786b',
  issueCardAvatar: '#f34230',
  exportBtnOrange: '#ff751b',
  exportBtnOrangeHover: '#ff6500',
  darkgrey: '#333',
  mediumgrey: '#555',
  softGrey: '#9e9e9e',
  lightgrey: '#DDD',
  lightergrey: '#ececec',
  lightestgrey: '#f1f1f1',
  lightestergrey: '#f9f9f9',
  lightgreyGantt: '#d3d3d3',
  drawerDivider: 'rgba(158,158,158,0.22)',
  modalDivider: '#eaeaea',
  progressCircleBg: '#ddd',
  highlighter: '#fffabc',
  highlighterAlpha: '#fffabc85',
  orangeHilighter: '#ffde9b',
  orangeHilighterAlpha: '#ffcb99a8',
  buttonPurple: '#b081da',
  buttonHoverPurple: '#9a4edd',
  mediumpurple: '#9c68bd',
  mediumpink: '#f773cd',
  lightSlateBlue: '#516c84',
  slateBlue: '#558ade',
  slateHeader: '#608cb3c7',
  slateHeaderNoTrans: '#608cb3',
  mainAlt: '#319cf8',
  ganttGrid: 'rgba(221, 221, 221, 0.8)',
  ganttGreyText: '#acacac',
  ganttPlannedTimeline: '#767676',
  ganttPlannedTimelineText: 'rgb(106,106,106)',
  ganttHeaderBg: '#608cb3c7',
  ganttHeaderText: '#fff',
  ganttBlue: '#8ecbff', //'#77c0ff' or '#54c6f9',
  ganttBlueHalf: '#8ecbff57', // '#77c0ff52' or '#54c6f978',
  ganttTodayLine: '#58b2ff54',
  ganttTodayFlag: '#74B6FF',
  ganttStripes: 'rgba(255,255,255,.1)',
  ganttStripesProgress: 'rgba(0,0,0,0.12)',
  standardBorder: 'rgba(0,0,0,0.12)',
  ganttMarker: 'rgba(146,216,255,0.34)',
  photoBorderHover: 'rgba(255,255,255,0.1)',
  issueUncatBg: 'rgba(179,179,179, 0.9)',
  issueLowBg: 'rgba(255, 167, 38, 0.9)',
  issueMediumBg: 'rgba(239, 83, 80,0.9)',
  issueHighBg: 'rgba(211, 47, 47, 0.9)',
  issueOffTrackBg: 'rgba(183, 28, 28, 0.9)',
  issueUncatBgHover: 'rgba(179,179,179, 1)',
  issueLowBgHover: 'rgba(255, 167, 38, 1)',
  issueMediumBgHover: 'rgba(239, 83, 80,1)',
  issueHighBgHover: 'rgba(211, 47, 47, 1)',
  issueOffTrackBgHover: 'rgba(183, 28, 28, 1)',
  appbarShadow:
    '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  iconShadow:
    '0px 0px 2px -1px rgba(0,0,0,0.2), 2px 1px 6px 0px rgba(0,0,0,0.14), 1px 1px 10px 0px rgba(0,0,0,0.12)',
  popoverShadow:
    '0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12)',
};

export const symbols = {
  check: {
    hex: '&#x2713;',
    symbol: '✓',
  },
  circleBullet: {
    hex: '&#10687;',
    symbol: '⦿',
  },
  rightTriangle: {
    hex: '&#9656;',
    symbol: '▸',
  },
  downTriangle: {
    hex: '&#9662;',
    symbol: '▾',
  },
  bullet: {
    hex: '&#8226;',
    symbol: '•',
  },
  upArrow: {
    hex: '&#x2191;',
    symbol: '↑',
  },
  downArrow: {
    hex: '&#x2193;',
    symbol: '↓',
  },
};

export const overrides = {
  MuiTooltip: {
    tooltip: {
      fontSize: '0.8rem',
      textAlign: 'center',
    },
  },
  MuiCardContent: {
    root: {
      '&:last-child': {
        paddingBottom: '16px',
      },
    },
  },
  MuiButtonGroup: {
    groupedTextPrimary: {
      '&:not(:last-child)': {
        border: 'transparent',
      },
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(0,0,0,0.78)',
    },
  },
  MuiOutlinedInput: {
    multiline: {
      backgroundColor: '#fff',
    },
  },
};
