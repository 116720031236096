import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  plannedTimelineWrapper: {
    display: 'grid',
    gridGap: '0px ',
    margin: '0',
    gridColumnStart: 4,
    zIndex: 2,
    gridTemplateColumns: (props) => props.plannedTimelineGrid,
  },
  plannedTimeline: {
    gridColumnStart: 2,
    backgroundColor: 'transparent',
    width: '100%',
    height: '2px',
    borderLeft: `3px solid ${theme.custom.ganttPlannedTimeline}`,
    borderRight: `3px solid ${theme.custom.ganttPlannedTimeline}`,
    borderBottom: '3px solid transparent',
    borderTop: `3px solid ${theme.custom.ganttPlannedTimeline}`,
    marginBottom: '0px',
  },
  gapStart: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 0,
  },
  gapEnd: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-start',
    height: 0,
  },
  dateStart: {
    zIndex: 4,
    display: 'flex',
    width: '0px',
    overflow: 'visible',
    transform: 'translate(-26px, -5px)',
  },
  dateEnd: {
    zIndex: 4,
    display: 'flex',
    width: '0px',
    overflow: 'visible',
    transform: 'translate(2px, -5px)',
  },
  date: {
    color: theme.custom.ganttPlannedTimelineText,
    fontSize: '9px',
    fontWeight: 500,
  },
  hide: {
    display: 'none',
  },
}));

export default useStyles;
