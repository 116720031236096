import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

// Styles
import useStyles from './drawer-footer.styles';

const DrawerFooter = ({ drawerOpen }) => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <div className={classes.footerContent}>
        <div
          className={clsx(
            classes.footerOpenContent,
            !drawerOpen ? classes.hidden : null
          )}>
          <Avatar variant='rounded' className={classes.largeAvatar}>
            G
          </Avatar>
          <div className={classes.gbs}>Global Build Solutions</div>
          <div className={classes.buttons}>
            <Button
              size='small'
              color='inherit'
              component={RouterLink}
              to={`#`}>
              Contact
            </Button>
            <Button size='small' component={RouterLink} to={`#`}>
              Privacy
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

DrawerFooter.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
};

export default DrawerFooter;
