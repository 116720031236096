import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props) => props.width,
    padding: (props) => props.padding,
  },
  mapContainer: {
    width: (props) => props.width,
    height: (props) => props.height,
    borderRadius: '20px',
    backgroundColor: theme.custom.bg,
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // },
  },
  latlng: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '16px',
    fontSize: '0.9rem',
  },
}));

export default useStyles;
