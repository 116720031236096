import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link as RouterLink, useParams } from 'react-router-dom';

// Material UI
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// Styles
import useStyles from './notification-menu.styles';

// Selectors
import { selectIssuesOutstandingUncategorizedAll } from '../../redux/issue/issue.selectors';

// Utilities
import { dateToTimeAgo } from '../../utils/utilityFns';

function NotificationMenu({ uncategorizedIssues }) {
  const classes = useStyles();
  const { projectId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  return (
    <>
      <Tooltip title='Notifications'>
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          className={classes.iconButton}>
          <Badge
            badgeContent={uncategorizedIssues.length}
            color='secondary'
            overlap='circular'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <List
          className={classes.root}
          subheader={
            <ListSubheader className={classes.header}>
              {uncategorizedIssues.length > 0
                ? 'Categorize these 🙏🏻'
                : 'Nothing to see here'}
            </ListSubheader>
          }>
          <Divider />
          {uncategorizedIssues
            .sort(
              (a, b) =>
                new Date(b.updates[0].createdAt) -
                new Date(a.updates[0].createdAt)
            )
            .map((item, idx) => (
              <ListItem
                key={item.id}
                button
                component={RouterLink}
                to={`/projects/${projectId}/submissions/${item.submission}`}
                divider={idx === uncategorizedIssues.length - 1 ? false : true}>
                <ListItemAvatar>
                  <Avatar className={classes.icon}>
                    {dateToTimeAgo(item.updates[0].createdAt, 'mini')}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${item.site.name}`}
                  secondary={
                    <Typography variant='body2'>
                      {item.question?.errorName}
                      <span className={classes.subtext}>
                        {item.question?.errorText}
                      </span>
                    </Typography>
                  }
                />
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  );
}

NotificationMenu.propTypes = {
  uncategorizedIssues: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  uncategorizedIssues: selectIssuesOutstandingUncategorizedAll,
});

export default connect(mapStateToProps)(NotificationMenu);
