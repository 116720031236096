import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Container from '@material-ui/core/Container';

// Components
import DrawerContainer from '../../components/drawer-container/drawer-container.component';
import MainAppbar from '../../components/main-appbar/main-appbar.component';
import PhotoModal from '../../components/photo-modal/photo-modal.component';
import IssueResolutionModal from '../../components/issue-resolution-modal/issue-resolution-modal.component';
import SubmissionResolutionModal from '../../components/submission-resolution-modal/submission-resolution-modal.component';
import ExportModal from '../../components/export-modal/export-modal.component';
import IssueNotesModal from '../../components/issue-notes-modal/issue-notes-modal.component';
import SubmissionNotesModal from '../../components/submission-notes-modal/submission-notes-modal.component';
import SidebarModal from '../../components/sidebar-modal/sidebar-modal.component';
import ConfirmModal from '../confirm-modal/confirm-modal.component';
import FullscreenSubmissionModal from '../fullscreen-submission-modal/fullscreen-submission-modal.component';
import ShareDeleteModal from '../../components/share-delete-modal/share-delete-modal.component';
import ShareCreateModal from '../../components/share-create-modal/share-create-modal.component';
import ReactionModal from '../reaction-modal/reaction-modal.component';
import CommentModal from '../comment-modal/comment-modal.component';
import Copyright from '../../components/copyright/copyright.component';

// Styles
import useStyles from './main-container.styles';

// Selectors
import { selectDrawerPosition } from '../../redux/ui/ui.selectors';

const MainContainer = ({ children, drawerOpen }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <MainAppbar />
        <DrawerContainer />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}>
          <div className={classes.drawerHeader} />
          <Container maxWidth='lg' className={classes.container}>
            {children}
            <Copyright />
          </Container>
        </main>
      </div>
      <IssueResolutionModal />
      <IssueNotesModal />
      <SubmissionResolutionModal />
      <SubmissionNotesModal />
      <ExportModal />
      <PhotoModal />
      <SidebarModal />
      <ConfirmModal />
      <FullscreenSubmissionModal />
      <ShareDeleteModal />
      <ShareCreateModal />
      <ReactionModal />
      <CommentModal />
    </>
  );
};

// Proptypes
MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  drawerOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  drawerOpen: selectDrawerPosition,
});

export default connect(mapStateToProps)(MainContainer);
