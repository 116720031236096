import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  filterPopoverRoot: {
    '& .MuiPaper-rounded': {
      minWidth: '300px',
      marginTop: '12px',
    },
  },
  filterPopoverSubheader: {
    padding: '16px 0px 8px 0px',
    textAlign: 'center',
    lineHeight: '1rem',
    color: theme.palette.primary.main,
  },
  listItem: {
    fontSize: '0.8rem',
    fontWeight: 100,
    display: 'flex',
    justifyContent: 'space-between',
  },
  listItemSlider: {
    fontSize: '0.8rem',
    fontWeight: 100,
    display: 'flex',
    justifyContent: 'center',
  },
  listItemOther: {
    fontSize: '0.8rem',
    fontWeight: 100,
    display: 'flex',
    justifyContent: 'center',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    maxWidth: 480,
  },
  chip: {
    margin: 2,
  },
  chipSelect: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.7rem',
  },
  chipSelectAction: {
    cursor: 'pointer',
    padding: '2px 6px',
    background: theme.custom.ganttTodayFlag,
    marginRight: '4px',
    borderRadius: '12px',
    '&:hover': {
      color: theme.custom.mediumgrey,
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 220,
  },
  slider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 380,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '18px',
    '& button': {
      margin: '4px',
    },
  },
}));

export default useStyles;
