import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '400px',
    //margin: 'auto',
    position: 'absolute',
    zIndex: '10000!important',
    bottom: '8px',
    right: '8px',
    '& > * + *': {
      marginTop: '8px',
    },
  },
});

export default useStyles;
