import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Badge from '@material-ui/core/Badge';

// Components
import FilterMenu from '../filter-menu/filter-menu.component';

// Styles
import useStyles from './filter-button.styles';

const FilterButton = ({ queryObj, setQueryObj }) => {
  const classes = useStyles();
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const filterOpen = Boolean(anchorElFilter);
  const numFilters = queryObj.rangeArr.length + Object.keys(queryObj.limitObj).length;

  const handleFilterMenuClose = () => {
    setAnchorElFilter(null);
  };

  const handleFilterMenuClick = (e) => {
    setAnchorElFilter(e.currentTarget);
  };

  return (
    <>
      <IconButton
        className={classes.iconButton}
        aria-label='toggle filters'
        color={filterOpen ? 'primary' : 'default'}
        onClick={handleFilterMenuClick}>
        <Badge
          badgeContent={numFilters}
          color='secondary'
          overlap='circular'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <FilterListIcon />
        </Badge>
      </IconButton>
      <FilterMenu
        handleFilterMenuClose={handleFilterMenuClose}
        anchorElFilter={anchorElFilter}
        filterOpen={filterOpen}
        queryObj={queryObj}
        setQueryObj={setQueryObj}
      />
    </>
  );
};

FilterButton.propTypes = {
  queryObj: PropTypes.object.isRequired,
  setQueryObj: PropTypes.func.isRequired,
};

FilterButton.defaultProps = {
  queryObj: {},
};

export default FilterButton;
