import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  container: {
    paddingTop: '64px',
    maxWidth: '680px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  codeContainer: {
    width: '100%',
    display: 'grid',
    gap: '8px',
    gridTemplateColumns: '60px 60px 60px 60px 60px 60px',
    justifyContent: 'center',
  },
  codeDigit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    color: '#666',
    border: '2px solid rgba(0,0,0,0.12)',
    borderRadius: '8px',
    textAlign: 'center',
    fontSize: '3rem',
    fontWeight: 900,
    textTransform: 'uppercase',
    width: '60px',
    height: '80px',
    cursor: 'not-allowed',
    lineHeight: 1,
  },
  help: {
    fontSize: '2rem',
    lineHeight: 1.2,
    textAlign: 'center',
  },
  back: {
    background: '#2876e51c',
    color: '#38f',
    fontWeight: 700,
    fontSize: '1.5rem',
    textDecoration: 'none',
    cursor: 'pointer',
    border: '1px solid #38f',
    borderRadius: '24px',
    padding: '4px 16px',
    '&:hover': {
      backgroundColor: '#2876e533',
    },
    marginBottom: '64px',
  },
}));

export default useStyles;
