import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  feedItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '16px',
  },
  dateHeader: {
    display: 'flex',
    fontSize: '0.85rem',
    color: '#444',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
}));

export default useStyles;
