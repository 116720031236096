import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    color: theme.palette.grey[500],
  },
  attribution: {
    fontSize: '0.75rem',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  button: {
    zIndex: '500!important',
  },
  legendWrapper: {
    zIndex: '500!important',
    padding: '3px',
    borderRadius: '8px',
    backgroundColor: theme.custom.legendBg,
    display: 'flex',
    marginBottom: '12px',
  },
  legend: {
    padding: '0px 8px',
    fontSize: '0.75rem',
    '& span': {
      paddingRight: '8px',
      '&:last-of-type': {
        paddingRight: '4px',
      },
    },
    '& .complete': {
      color: theme.custom.statusGreen,
      fontWeight: 700,
    },
    '& .ongoing': {
      color: theme.custom.statusOrange,
      fontWeight: 700,
    },
    '& .stopped': {
      color: theme.custom.statusRed,
      fontWeight: 700,
    },
    '& .notstarted': {
      color: theme.custom.statusGrey,
      fontWeight: 700,
    },
  },
}));

export default useStyles;
