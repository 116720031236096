import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import WarningIcon from '@material-ui/icons/Warning';
import CommentIcon from '@material-ui/icons/Comment';
import CheckIcon from '@material-ui/icons/Check';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import RestoreIcon from '@material-ui/icons/Restore';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import EditIcon from '@material-ui/icons/Edit';
import MessageIcon from '@material-ui/icons/Message';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ErrorIcon from '@material-ui/icons/Error';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';

// Styles
import useStyles from './update-icon.styles';

const UpdateIcon = ({ update }) => {
  const classes = useStyles();

  const actionType = update.actionType;
  const categorization = update?.categorization ? update.categorization : 0;

  let rootClass;
  let iconElem;
  let iconClass;

  switch (actionType) {
    case 'Field Submission':
      rootClass = classes.root;
      if (update?.type === 'issue') {
        iconElem = <ErrorIcon className={classes.icon} />;
        iconClass = classes.isSubmissionIssue;
      } else {
        iconElem = <AssignmentIcon className={classes.icon} />;
        iconClass = classes.isSubmission;
      }
      break;
    case 'Recategorized':
      if (categorization === 0) {
        rootClass = clsx(classes.root, classes.none);
      } else if (categorization === 1) {
        rootClass = clsx(classes.root, classes.low);
      } else if (categorization === 2) {
        rootClass = clsx(classes.root, classes.medium);
      } else if (categorization === 3) {
        rootClass = clsx(classes.root, classes.high);
      } else if (categorization === 4) {
        rootClass = clsx(classes.root, classes.offTrack);
      } else {
        rootClass = clsx(classes.root, classes.none);
      }

      iconElem = <SwapCallsIcon className={classes.icon} />;
      iconClass = classes.isRecategorized;
      break;
    case 'Updated':
      rootClass = classes.root;
      iconElem = <CommentIcon className={classes.icon} />;
      iconClass = classes.isComment;
      break;
    case 'Status Change':
      rootClass = classes.root;
      iconElem = <UpdateOutlinedIcon className={classes.icon} />;
      iconClass = classes.isSubmission;
      break;
    case 'Reply':
      rootClass = classes.root;
      iconElem = <MessageIcon className={classes.icon} />;
      iconClass = classes.isComment;
      break;
    case 'Resolved':
      rootClass = classes.root;
      iconElem = <CheckIcon className={classes.icon} />;
      iconClass = classes.isResolved;
      break;
    case 'Reopened':
      rootClass = classes.root;
      iconElem = <RestoreIcon className={classes.icon} />;
      iconClass = classes.isReopened;
      break;
    case 'Deleted':
      rootClass = classes.root;
      iconElem = <DeleteIcon className={classes.icon} />;
      iconClass = classes.isDeleted;
      break;
    case 'Restored':
      rootClass = classes.root;
      iconElem = <RestoreFromTrashIcon className={classes.icon} />;
      iconClass = classes.isRestored;
      break;
    case 'Started Sharing':
      rootClass = classes.root;
      iconElem = <LinkIcon className={classes.icon} />;
      iconClass = classes.isShared;
      break;
    case 'Stopped Sharing':
      rootClass = classes.root;
      iconElem = <LinkOffIcon className={classes.icon} />;
      iconClass = classes.isShared;
      break;
    case 'Share Link Comment':
      rootClass = classes.root;
      iconElem = <RecordVoiceOverIcon className={classes.icon} />;
      iconClass = classes.isComment;
      break;
    case 'Edited':
      rootClass = classes.root;
      iconElem = <EditIcon className={classes.icon} />;
      iconClass = classes.isEdited;
      break;
    default:
      rootClass = classes.root;
      iconElem = <WarningIcon className={classes.icon} />;
      iconClass = classes.isOpen;
      break;
  }
  return <span className={clsx(rootClass, iconClass)}>{iconElem}</span>;
};

UpdateIcon.propTypes = {
  update: PropTypes.object.isRequired,
};

export default UpdateIcon;
