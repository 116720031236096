import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  header: {
    backgroundColor: theme.palette.primary.darkest,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    border: `4px solid ${theme.palette.primary.contrastText}`,
    borderBottom: 0,
  },
}));

export default useStyles;
