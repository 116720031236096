import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.custom.statusContrastTextAlt,
    marginLeft: '5px',
    minWidth: '48px',
  },
  divider: {
    backgroundColor: theme.custom.drawerDivider,
  },
  hidden: {
    cursor: 'not-allowed',
  },
  list: {},
  itemText: {
    opacity: (props) => (props.drawerOpen ? 1 : 0),
    transition: 'opacity 0.8s',
    '& span': {
      fontSize: '0.9rem',
    },
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.125)',
    },
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.25)',
    },
  },
}));

export default useStyles;
