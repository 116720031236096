import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Avatar from '@material-ui/core/Avatar';

// Assets
import mapIcon from '../../assets/map-marker-icon-256.jpg';

// Styles
import useStyles from './widget-info.styles';

// Selectors
import { selectActiveSiteOrProject } from '../../redux/site/site.selectors';

const WidgetInfo = ({ selection }) => {
  const classes = useStyles();

  if (!selection) return null;

  let summaryText;
  if (!selection.description || selection.description === '') {
    summaryText = 'Please add a short description to this site or project.';
  } else {
    summaryText = selection.description;
  }

  let gpsText;
  if (!selection.lat || !selection.lng) {
    gpsText = <span>'Summary'</span>;
  } else {
    gpsText = (
      <>
        &#128205;
        <span className={classes.latLng}>
          `(${Math.round(selection.lat * 1000) / 1000}, $
          {Math.round(selection.lng * 1000) / 1000})`
        </span>
      </>
    );
  }

  let nameText;
  if (!selection.shortName || selection.shortName === '') {
    nameText = 'Description';
  } else {
    nameText = selection.shortName;
  }

  return (
    <div className={classes.root}>
      <div className={classes.photo}>
        <Avatar
          src={mapIcon}
          className={classes.avatarLarge}
          variant='circular'
        />
      </div>
      <div className={classes.detailsContainer}>
        <div className={classes.title}>{nameText}</div>
        <div className={classes.details}>
          <span className={classes.description}>{summaryText}</span>
        </div>
      </div>
    </div>
  );
};

WidgetInfo.propTypes = {
  selection: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  selection: selectActiveSiteOrProject,
});

export default connect(mapStateToProps)(WidgetInfo);
