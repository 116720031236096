import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ListAltIcon from '@material-ui/icons/ListAlt';

const SubmissionArchiveButton = ({ projectId, activeSiteInfo }) => {
  return (
    <Tooltip title={activeSiteInfo.titleStr}>
      <IconButton
        aria-label='open submission archive'
        href={`/projects/${projectId}/submissions${activeSiteInfo.queryStr}`}>
        <ListAltIcon />
      </IconButton>
    </Tooltip>
  );
};

// Proptypes
SubmissionArchiveButton.propTypes = {
  projectId: PropTypes.string.isRequired,
  activeSiteInfo: PropTypes.object.isRequired,
};

export default SubmissionArchiveButton;
