import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Tooltip from '@material-ui/core/Tooltip';

// Styles
import useStyles from './update-form-photo.styles';

// Constants
import { MAX_ATTACHMENT_SIZE } from '../../../utils/constants';

export const UpdateFormPhoto = ({ currentPhoto, startAlert, updatePhoto }) => {
  const classes = useStyles();

  const handleAttachmentChange = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    if (e.target.files[0].size > MAX_ATTACHMENT_SIZE) {
      startAlert({
        msg: `Max image filesize (${MAX_ATTACHMENT_SIZE / 1024 / 1024}mb) exceeded.`,
        alertType: 'error',
      });
      return;
    }
    let formData = new FormData();
    formData.append('image', e.target.files[0]);
    updatePhoto(formData);
  };

  return (
    <>
      <div className={classes.container}>
        <img src={currentPhoto} alt='project' className={classes.headerImage} />
        <input
          className={classes.input}
          accept='image/*'
          id='attach-form-photo'
          type='file'
          onChange={(e) => handleAttachmentChange(e)}
        />
        <label htmlFor='attach-form-photo'>
          <Tooltip title='Change Photo'>
            <IconButton color='primary' component='span' className={classes.iconButton}>
              <PhotoCameraIcon />
            </IconButton>
          </Tooltip>
        </label>
      </div>
    </>
  );
};

UpdateFormPhoto.propTypes = {
  currentPhoto: PropTypes.string.isRequired,
  startAlert: PropTypes.func.isRequired,
  updatePhoto: PropTypes.func.isRequired,
};

export default UpdateFormPhoto;
