import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Chip from '@material-ui/core/Chip';

// Styles
import useStyles from './search-filter-tag.styles';

const SearchFilterTag = ({ text, handleDelete }) => {
  const classes = useStyles();
  return (
    <Chip
      className={classes.root}
      label={text}
      onDelete={handleDelete}
      variant='outlined'
      color='primary'
      size='small'
    />
  );
};

SearchFilterTag.propTypes = {
  text: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default SearchFilterTag;
