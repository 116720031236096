import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: theme.custom.bg,
  },
  container: {
    marginTop: theme.mixins.toolbar.minHeight,
    paddingTop: '32px',
    paddingBottom: '32px',
  },
  appBar: {
    backgroundColor: theme.palette.primary.darkest,
  },
  toolbar: {
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
    },
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
