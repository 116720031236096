import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: '6px',
    paddingBottom: '4px',
    flexWrap: 'wrap',
  },
  thumb: {
    cursor: 'pointer',
    width: '120px',
    height: '100px',
    borderRadius: '8px',
    boxShadow: theme.custom.appbarShadow,
    '&:hover': {
      filter: 'brightness(0.8)',
    },
  },
}));

export default useStyles;
