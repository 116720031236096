import React, { useState, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

// Material UI
import Box from '@material-ui/core/Box';

// Styles
import useStyles from './map-gps.styles';

// API Key via Configuration File
import config from '../../utils/config';

const MapGps = ({ lat, lng, updateGps }) => {
  const [draggable, setDraggable] = useState(false);
  const [position, setPosition] = useState({ lat, lng });
  const markerRef = useRef(null);
  const classes = useStyles();

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  return (
    <>
      <MapContainer center={{ lat, lng }} zoom={13} className={classes.mapContainer}>
        <TileLayer
          attribution={''}
          url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}`}
          id='globalbuildsolutions/cl31kvkvq000415mntx5b7e2u'
          tileSize={512}
          zoomOffset={-1}
          accessToken={config.MAPBOX_API_ACCESS_TOKEN}
        />
        <Marker
          draggable={draggable}
          eventHandlers={eventHandlers}
          position={position}
          ref={markerRef}>
          <Popup minWidth={90}>
            <span onClick={toggleDraggable} style={{ color: '#FFF' }}>
              {draggable
                ? `Marker is draggable ${position.lat} ${position.lng}`
                : 'Click here to make marker draggable'}
            </span>
          </Popup>
        </Marker>
      </MapContainer>
      <Box className={classes.latLngBox}>
        {`Latitude: ${position.lat} - Longitude: ${position.lng}`}
      </Box>
    </>
  );
};

MapGps.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  updateGps: PropTypes.func.isRequired,
};

export default MapGps;
