import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link as RouterLink } from 'react-router-dom';

// Material UI
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import MailOutline from '@material-ui/icons/MailOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';

// Styles
import useStyles from './photo-modal.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';

// Selectors
import { selectModalPhotoGallery } from '../../redux/ui/ui.selectors';

const PhotoModal = ({
  photoGallery: { isOpen, content },
  toggleModalDispatch,
}) => {
  const classes = useStyles();
  const startPhoto = content.hasOwnProperty('startPhoto')
    ? content.startPhoto
    : 0;
  const [photoNum, setPhotoNum] = useState(startPhoto);
  const [delay, setDelay] = useState(null);

  useEffect(() => {
    setPhotoNum(startPhoto);
    setDelay(true);
  }, [startPhoto, setDelay, delay]);

  const [snackOpen, setSnackOpen] = useState(false);

  const photos = content.hasOwnProperty('photos')
    ? content.photos.map((el) => {
        const canUserDelete = Boolean(
          el.isDeletableByOwner &&
            content?.currentUserId &&
            content?.handleDelete &&
            el.createdBy._id === content?.currentUserId
        );
        return { ...el, isDeletable: canUserDelete };
      })
    : [];

  const numImages = content.hasOwnProperty('photos')
    ? content.photos.length
    : 0;

  const handleClick = (type) => {
    let newPhotoNum = 0;
    if (numImages <= 1) {
      return;
    } else {
      if (type === 'next' && photoNum !== numImages - 1) {
        newPhotoNum = photoNum + 1;
      } else if (type === 'prev' && photoNum !== 0) {
        newPhotoNum = photoNum - 1;
      } else if (type === 'prev' && photoNum === 0) {
        newPhotoNum = numImages - 1;
      }
    }
    setPhotoNum(newPhotoNum);
  };

  const handleClickClose = (e) => {
    const targetId = e.target.closest(`div`)?.id;
    if (
      targetId !== 'photoWrapper' &&
      targetId !== 'toolbarWrapper' &&
      targetId !== 'controlWrapper'
    ) {
      setPhotoNum(0);
      setDelay(null);
      toggleModalDispatch({ modalName: 'photoGallery', modalContent: {} });
    }
  };

  const handleClickCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackOpen(true);
  };

  const handleCloseCopy = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const generateCaptionElement = (photoObj) => {
    if (
      photoObj &&
      photoObj.hasOwnProperty('caption') &&
      photoObj.caption.length > 0
    ) {
      if (photoObj.hasOwnProperty('captionLink') && photoObj.captionLink) {
        return (
          <span className={classes.caption}>
            <RouterLink
              className={classes.link}
              to={photoObj.captionLink}
              target='_blank'
              rel='noopener noreferrer'>
              {photoObj.caption}
            </RouterLink>
            {photoObj.hasOwnProperty('issueCount') &&
            photoObj.issueCount > 0 ? (
              <span className={classes.issue}>Issue</span>
            ) : null}
          </span>
        );
      } else {
        return (
          <span className={classes.caption}>
            {photoObj.caption}
            {photoObj.hasOwnProperty('issueCount') &&
            photoObj.issueCount > 0 ? (
              <span className={classes.issue}>Issue</span>
            ) : null}
          </span>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={isOpen}
        onClick={(e) => handleClickClose(e)}>
        <div className={classes.galleryWrapper}>
          <div id='toolbarWrapper' className={classes.toolbar}>
            <Tooltip title='Share via Email'>
              <span>
                <IconButton
                  className={classes.test}
                  href={`mailto:?subject=Global%20Build%20Solutions%20Photo%20Share&body=Photo%20Link:%20${photos[photoNum]?.url}`}>
                  <MailOutline />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Copy Link'>
              <IconButton
                onClick={() => handleClickCopy(photos[photoNum]?.url)}>
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Open Fullsize'>
              <IconButton href={photos[photoNum]?.url} target='_blank'>
                <FullscreenIcon />
              </IconButton>
            </Tooltip>
            {photos[photoNum]?.isDeletable && (
              <Tooltip title='Delete Photo'>
                <IconButton
                  onClick={() =>
                    content.handleDelete({
                      projectId: content?.projectId,
                      milestoneId: content?.milestoneId,
                      attachmentId: photos[photoNum]?._id,
                      embeddedField: 'photos',
                      component: 'sidebar',
                    })
                  }>
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div id='photoWrapper' className={classes.photoWrapper}>
            {delay && (
              <>
                <img
                  src={photos[photoNum]?.url}
                  alt=''
                  className={classes.photo}
                  onClick={() => handleClick('next')}
                />
                {generateCaptionElement(photos[photoNum])}
              </>
            )}
          </div>
          <div id='controlWrapper' className={classes.galleryControls}>
            {numImages > 1 && (
              <span
                className={classes.buttonWrapper}
                onClick={() => handleClick('prev')}>
                <Tooltip title='Previous'>
                  <IconButton
                    disableRipple={true}
                    onClick={() => handleClick('prev')}>
                    <ChevronLeftIcon fontSize='large' />
                  </IconButton>
                </Tooltip>
                <span
                  onClick={() => handleClick('prev')}
                  className={classes.buttonText}>
                  Prev
                </span>
              </span>
            )}
            <span className={classes.spacer}></span>
            {numImages > 1 && (
              <span
                className={classes.buttonWrapper}
                onClick={() => handleClick('next')}>
                <span
                  onClick={() => handleClick('next')}
                  className={classes.buttonText}>
                  Next
                </span>
                <Tooltip title='Next'>
                  <IconButton
                    disableRipple={true}
                    onClick={() => handleClick('next')}>
                    <ChevronRightIcon fontSize='large' />
                  </IconButton>
                </Tooltip>
              </span>
            )}
          </div>
        </div>
      </Backdrop>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        severity='success'
        onClose={handleCloseCopy}
        open={snackOpen}
        autoHideDuration={1500}
        message='Copied link to clipboard!'
      />
    </>
  );
};

PhotoModal.propTypes = {
  photoGallery: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  photoGallery: selectModalPhotoGallery,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoModal);
