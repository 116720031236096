import React from 'react';
import PropTypes from 'prop-types';

// Components
import UpdateCard from '../update-card/update-card.component';
import ShareNotesComment from '../share-notes-comment/share-notes-comment.component';
import UpdatesFilter from '../updates-filters/updates-filters.component';

// Styles
import useStyles from './share-notes-fullwidth.styles';

// Utilities
import { sortDate } from '../../utils/utilityFns';

const ShareNotesFullwidth = ({
  title,
  updates,
  toggleModalDispatch,
  canComment,
  setContent,
  startAlert,
  filters,
  setUpdateFiltersDispatch,
}) => {
  const classes = useStyles();

  const filteredUpdates = updates
    .filter((el) => filters[el.actionType])
    .sort(sortDate('date', 'asc'));

  return (
    <>
      <div className={classes.header}>
        <h1 className={classes.textHeader}>{title}</h1>
        <UpdatesFilter
          filters={filters}
          setUpdateFiltersDispatch={setUpdateFiltersDispatch}
        />
      </div>
      <div className={classes.root}>
        <div className={classes.cardContainer}>
          {filteredUpdates.map((el) => (
            <UpdateCard
              key={el._id}
              actionType={el.actionType}
              categorization={el.categorization}
              date={new Date(el.createdAt)}
              user={el.createdBy}
              currentUserId={'NA'}
              content={el.content}
              docId={'NA'}
              contentId={el._id}
              toggleModal={toggleModalDispatch}
              handleDeleteUpdate={() => console.log('Void')}
              fullWidth={true}
              shareView={true}
            />
          ))}
        </div>
        <div className={classes.modalFormContainer}>
          {canComment ? (
            <ShareNotesComment
              setContent={setContent}
              startAlert={startAlert}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

ShareNotesFullwidth.propTypes = {
  title: PropTypes.string.isRequired,
  updates: PropTypes.array.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  canComment: PropTypes.bool.isRequired,
  setContent: PropTypes.func.isRequired,
  startAlert: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setUpdateFiltersDispatch: PropTypes.func.isRequired,
};

export default ShareNotesFullwidth;
