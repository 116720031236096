import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Popup } from 'react-leaflet';

// Styles
import useStyles from './map-marker-grouped-popup.styles';

const MapMarkerGroupedPopup = ({
  groupName,
  groupSites,
  activeSiteId,
  updateActiveSiteIdDispatch,
}) => {
  const classes = useStyles();
  if (!groupSites || !groupName || groupSites.length === 0) return null;

  const handleGroupedSiteClick = (_, el) => {
    if (el._id === activeSiteId) {
      updateActiveSiteIdDispatch('-1');
    } else {
      updateActiveSiteIdDispatch(el._id);
    }
  };

  return (
    <Popup closeButton={false} autoClose={false} closeOnClick={false}>
      <div className={classes.root}>
        <div className={clsx(classes.row, classes.headerRow)}>{groupName}</div>
        {groupSites.map((el, idx) => (
          <div className={classes.row} key={`groupSite-${idx}`}>
            <div
              className={clsx(
                classes.name,
                el._id === activeSiteId ? classes.active : null
              )}
              onClick={(e) => handleGroupedSiteClick(e, el)}>
              {el.name}
            </div>
            <div
              className={clsx(
                classes.status,
                el.status === 'Completed'
                  ? classes.completed
                  : el.status === 'Ongoing'
                  ? classes.ongoing
                  : el.status === 'Off Track'
                  ? classes.offTrack
                  : classes.notStarted
              )}></div>
          </div>
        ))}
      </div>
    </Popup>
  );
};

MapMarkerGroupedPopup.propTypes = {
  groupName: PropTypes.string,
  groupSites: PropTypes.array,
  activeSiteId: PropTypes.string.isRequired,
  updateActiveSiteIdDispatch: PropTypes.func.isRequired,
};

export default MapMarkerGroupedPopup;
