const progressCircleMarker = (
  progressEmpty,
  progressFull,
  fillColor,
  progressPercent,
  dateAgo,
  issueCount
) => {
  return {
    className: 'marker',
    html: `
    <svg height="60" width="60" class="marker">
      <defs>
        <filter id="shadow">
          <feDropShadow dx="0" dy="0" stdDeviation="0.85" flood-color="black" flood-opacity="0.5"/>
        </filter>
      </defs>
      <circle cx="30" cy="26.25" r="21.5" stroke="${progressEmpty}" stroke-width="4.5" fill="none" stroke-miterlimit="10" style="filter:url(#shadow)"/>
      <circle cx="30" cy="26.25" r="21.5" stroke="${progressFull}" stroke-width="4.5" fill="none" stroke-miterlimit="10" stroke-dasharray="135" stroke-dashoffset="${progressPercent}"/>
      <circle cx="30" cy="26.25" r="19.4" stroke-width="0" fill="${fillColor}"/>
      <text x="50%" y="49%" text-anchor="middle" fill="white" font-size="2em" font-family="sans-serif" font-weight="bold">${issueCount}</text>
      <text x="50%" y="67%" text-anchor="middle" fill="white" font-size="0.90em" font-family="sans-serif" font-weight="normal">${dateAgo}</text>
    </svg>`,
    iconAnchor: [12, 24],
    popupAnchor: [14, -7],
  };
};

const issueCountMarker = (fillColor, issueCount) => {
  return {
    className: 'marker',
    html: `
    <svg height="60" width="60" class="marker">
      <defs>
        <filter id="shadow">
          <feDropShadow dx="0" dy="0" stdDeviation="0.85" flood-color="black" flood-opacity="0.5"/>
        </filter>
      </defs>
      <circle cx="30" cy="30" r="17" stroke-width="3" stroke="#747474" fill="${fillColor}"/>
      <text x="50%" y="60%" text-anchor="middle" fill="white" font-size="1.6em" font-family="sans-serif" font-weight="bold">${issueCount}</text>
    </svg>`,
    iconAnchor: [12, 24],
    popupAnchor: [14, -7],
  };
};

const fillOnly = (fillColor) => {
  return {
    className: 'marker',
    html: `
    <svg height="20" width="20" class="marker">
      <defs>
        <filter id="shadow">
          <feDropShadow dx="0" dy="0" stdDeviation="0.85" flood-color="black" flood-opacity="0.5"/>
        </filter>
      </defs>
      <circle cx="10" cy="10" r="6"  stroke="black" stroke-width="2" fill="${fillColor}"/>
    </svg>`,
    iconAnchor: [12, 24],
    popupAnchor: [14, -7],
  };
};

export const generateMarkerType = ({
  markerName,
  progressEmpty,
  progressFull,
  fillColor,
  progressPercent,
  dateAgo,
  issueCount,
}) => {
  switch (markerName) {
    case 'PROGRESS_CIRCLE_MARKER':
      return progressCircleMarker(
        progressEmpty,
        progressFull,
        fillColor,
        progressPercent,
        dateAgo,
        issueCount
      );
    case 'ISSUE_COUNT':
      return issueCountMarker(fillColor, issueCount);
    case 'FILL_ONLY':
      return fillOnly(fillColor);
    default:
      return fillOnly(fillColor);
  }
};
