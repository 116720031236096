import {
  GET_OUTSTANDING_ISSUES_START,
  GET_OUTSTANDING_ISSUES_SUCCESS,
  GET_OUTSTANDING_ISSUES_FAILURE,
  GET_FILTERED_ISSUES_START,
  GET_FILTERED_ISSUES_SUCCESS,
  GET_FILTERED_ISSUES_FAILURE,
  GET_SINGLE_ISSUE_START,
  GET_SINGLE_ISSUE_SUCCESS,
  GET_SINGLE_ISSUE_FAILURE,
  UPDATE_ISSUE_START,
  UPDATE_ISSUE_SUCCESS,
  UPDATE_ISSUE_FAILURE,
  DELETE_ISSUE_UPDATE_START,
  DELETE_ISSUE_UPDATE_SUCCESS,
  DELETE_ISSUE_UPDATE_FAILURE,
  UPDATE_ISSUE_SEEN_START,
  UPDATE_ISSUE_SEEN_SUCCESS,
  UPDATE_ISSUE_SEEN_FAILURE,
  DELETE_ISSUE_SHARE_START,
  DELETE_ISSUE_SHARE_SUCCESS,
  DELETE_ISSUE_SHARE_FAILURE,
  CREATE_ISSUE_SHARE_START,
  CREATE_ISSUE_SHARE_SUCCESS,
  CREATE_ISSUE_SHARE_FAILURE,
} from '../types';

export const getOutstandingIssuesStart = (fetchObj) => ({
  type: GET_OUTSTANDING_ISSUES_START,
  payload: fetchObj,
});

export const getOutstandingIssuesSuccess = (issueData) => ({
  type: GET_OUTSTANDING_ISSUES_SUCCESS,
  payload: issueData,
});

export const getOutstandingIssuesFailure = () => ({
  type: GET_OUTSTANDING_ISSUES_FAILURE,
});

export const getFilteredIssuesStart = (fetchObj) => ({
  type: GET_FILTERED_ISSUES_START,
  payload: fetchObj,
});

export const getFilteredIssuesSuccess = (issueData) => ({
  type: GET_FILTERED_ISSUES_SUCCESS,
  payload: issueData,
});

export const getFilteredIssuesFailure = () => ({
  type: GET_FILTERED_ISSUES_FAILURE,
});

export const getSingleIssueStart = (fetchObj) => ({
  type: GET_SINGLE_ISSUE_START,
  payload: fetchObj,
});

export const getSingleIssueSuccess = (updateObj) => ({
  type: GET_SINGLE_ISSUE_SUCCESS,
  payload: updateObj,
});

export const getSingleIssueFailure = () => ({
  type: GET_SINGLE_ISSUE_FAILURE,
});

export const updateIssueStart = (issueObj) => ({
  type: UPDATE_ISSUE_START,
  payload: issueObj,
});

export const updateIssueSuccess = (updatedIssueObj) => ({
  type: UPDATE_ISSUE_SUCCESS,
  payload: updatedIssueObj,
});

export const updateIssueFailure = () => ({
  type: UPDATE_ISSUE_FAILURE,
});

export const deleteIssueUpdateStart = (idObj) => ({
  type: DELETE_ISSUE_UPDATE_START,
  payload: idObj,
});

export const deleteIssueUpdateSuccess = (updateObj) => ({
  type: DELETE_ISSUE_UPDATE_SUCCESS,
  payload: updateObj,
});

export const deleteIssueUpdateFailure = () => ({
  type: DELETE_ISSUE_UPDATE_FAILURE,
});

export const updateIssueSeenStart = (idObj) => ({
  type: UPDATE_ISSUE_SEEN_START,
  payload: idObj,
});

export const updateIssueSeenSuccess = (updateObj) => ({
  type: UPDATE_ISSUE_SEEN_SUCCESS,
  payload: updateObj,
});

export const updateIssueSeenFailure = () => ({
  type: UPDATE_ISSUE_SEEN_FAILURE,
});

export const deleteIssueShareStart = (updateObj) => ({
  type: DELETE_ISSUE_SHARE_START,
  payload: updateObj,
});

export const deleteIssueShareSuccess = (updateObj) => ({
  type: DELETE_ISSUE_SHARE_SUCCESS,
  payload: updateObj,
});

export const deleteIssueShareFailure = () => ({
  type: DELETE_ISSUE_SHARE_FAILURE,
});

export const createIssueShareStart = (updateObj) => ({
  type: CREATE_ISSUE_SHARE_START,
  payload: updateObj,
});

export const createIssueShareSuccess = (updateObj) => ({
  type: CREATE_ISSUE_SHARE_SUCCESS,
  payload: updateObj,
});

export const createIssueShareFailure = () => ({
  type: CREATE_ISSUE_SHARE_FAILURE,
});
