import {
  GET_ALL_SITES_START,
  GET_ALL_SITES_SUCCESS,
  GET_ALL_SITES_FAILURE,
  UPDATE_ACTIVE_SITE_ID,
} from '../types';

export const getAllSitesStart = (fetchObj) => ({
  type: GET_ALL_SITES_START,
  payload: fetchObj,
});

export const getAllSitesSuccess = (siteData) => ({
  type: GET_ALL_SITES_SUCCESS,
  payload: siteData,
});

export const getAllSitesFailure = () => ({
  type: GET_ALL_SITES_FAILURE,
});

export const updateActiveSiteId = (siteId) => ({
  type: UPDATE_ACTIVE_SITE_ID,
  payload: siteId,
});
