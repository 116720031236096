import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  spinnerOverlay: {
    height: '80vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    width: '380px',
    borderRadius: '50%',
  },
  text: {
    marginTop: '28px',
    fontWeight: 700,
    fontSize: '2rem',
    animation: `$pulsate 1500ms ${theme.transitions.easing.easeInOut} infinite`,
  },
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(0.95)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  textSmall: {
    fontSize: '1.1rem',
  },
}));

export default useStyles;
