import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Components
import InfoTooltip from '../info-tooltip/info-tooltip.component';
import ModalForm from '../modal-form/modal-form.component';

// Styles
import useStyles from './issue-resolution-modal.styles';

// Actions
import { toggleModal } from '../../redux/ui/ui.actions';
import { updateIssueStart } from '../../redux/issue/issue.actions';

// Selectors
import { selectModalIssueResolve } from '../../redux/ui/ui.selectors';

// Constant
import { ISSUE_UPLOAD_TYPES } from '../../utils/constants';

function IssueResolutionModal({
  issueResolve: { isOpen, content },
  toggleModalDispatch,
  updateIssueStartDispatch,
}) {
  const classes = useStyles();

  const handleClose = () => {
    toggleModalDispatch({ modalName: 'issueResolve', modalContent: {} });
  };

  if (Object.values(content).length === 0) return null;

  const colorTheme =
    content.actionType === 'Deleted' ||
    content.actionType === 'Restored' ||
    content.actionType === 'Recategorized'
      ? 'secondary'
      : 'primary';
  const headingText =
    content.actionType === 'Resolved'
      ? 'Resolve Issue'
      : content.actionType === 'Reopened'
      ? 'Reopen Issue'
      : content.actionType === 'Deleted'
      ? 'Delete Issue'
      : content.actionType === 'Restored'
      ? 'Restore Issue'
      : content.actionType === 'Recategorized'
      ? `Mark ${content?.site ? content.site : 'Site'} as Off Track`
      : 'Invalid Action';

  const bodyText =
    content.actionType === 'Resolved'
      ? 'Describe how this issue was resolved.'
      : content.actionType === 'Reopened'
      ? 'Describe why you wish to reopen this issue.'
      : content.actionType === 'Deleted'
      ? 'Describe why this issue should be deleted.'
      : content.actionType === 'Restored'
      ? 'Describe why you wish to restore this issue.'
      : content.actionType === 'Recategorized'
      ? `Warning you are about to mark site ${
          content?.site ? content.site : 'this site'
        } as
      off track. If you wish to proceed, `
      : 'Invalid Action';

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle className={classes.title}>{headingText}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.text}>
          <>
            {bodyText}
            You can <em>optionally</em> attach up to 5 supporting photos and/or
            files, below.
          </>
          <InfoTooltip text={ISSUE_UPLOAD_TYPES} />
        </DialogContentText>
      </DialogContent>
      <ModalForm
        updateStartDispatch={updateIssueStartDispatch}
        content={content}
        handleClose={handleClose}
        handleSubmit={handleClose}
        color={colorTheme}
      />
    </Dialog>
  );
}

IssueResolutionModal.propTypes = {
  issueResolve: PropTypes.object.isRequired,
  toggleModalDispatch: PropTypes.func.isRequired,
  updateIssueStartDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  issueResolve: selectModalIssueResolve,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalDispatch: (modalObj) => dispatch(toggleModal(modalObj)),
  updateIssueStartDispatch: (updateObj) =>
    dispatch(updateIssueStart(updateObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueResolutionModal);
