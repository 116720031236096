import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// Styles
import useStyles from './code-user.styles';

const CodeUser = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const pCode = queryParams.get('p');

  // TODO need to fall back to some other page and go back to /code not just back
  if (!pCode || typeof pCode !== 'string' || pCode.length < 6)
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.back} onClick={history.goBack}>
            👈 Enter a different code
          </div>
          <div className={classes.help}>
            There was an error with the registration code.
          </div>
        </div>
      </div>
    );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.back} onClick={history.goBack}>
          👈 Enter a different code
        </div>
        <div className={classes.help}>Complete your registration for code:</div>
        <div className={classes.codeContainer}>
          {pCode && pCode.length > 0
            ? pCode.split('').map((char, idx) => (
                <div key={idx} className={classes.codeDigit}>
                  {char}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

CodeUser.propTypes = {};

export default CodeUser;
