import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';

// Styles
import useStyles from './modal-form.styles';

// Actions
import { startAlert } from '../../redux/alert/alert.actions';

// Constants
import {
  MAX_ATTACHMENTS_ISSUE,
  MAX_ATTACHMENT_SIZE,
} from '../../utils/constants';

const ModalForm = ({
  content,
  updateStartDispatch,
  startAlertDispatch,
  handleClose,
  handleSubmit,
  color,
  fullWidth = false,
}) => {
  const classes = useStyles();
  const { projectId } = useParams();
  const [attachmentsToUpload, setAttachmentsToUpload] = useState(null);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    return function cleanup() {
      setAttachmentsToUpload(null);
      setNotes('');
    };
  }, []);

  const handleChange = (e) => {
    setNotes(e.target.value);
  };

  const handleClear = () => {
    setNotes('');
    setAttachmentsToUpload(null);
  };

  const enableSubmit = Boolean(
    (notes && notes.length > 0) ||
      (attachmentsToUpload !== null && attachmentsToUpload.length > 0)
  );

  const handleFormSubmit = () => {
    const update = {
      actionType: content.actionType,
      categorization: content.categorization,
      content: {
        notes: notes,
      },
    };

    let numFiles = attachmentsToUpload
      ? attachmentsToUpload.length < MAX_ATTACHMENTS_ISSUE
        ? attachmentsToUpload.length
        : MAX_ATTACHMENTS_ISSUE
      : 0;

    let formData = new FormData();
    for (let i = 0; i < numFiles; i++) {
      formData.append('attachments', attachmentsToUpload[i]);
    }
    formData.append('update', JSON.stringify(update));

    updateStartDispatch({
      docId: content.id,
      formData,
      milestoneFlag: content.milestoneFlag,
      actionType: content.actionType,
      projectId,
    });

    setAttachmentsToUpload(null);
    setNotes('');
    handleSubmit();
  };

  const handleAttachmentChange = (e) => {
    if (e.target.files.length > MAX_ATTACHMENTS_ISSUE) {
      startAlertDispatch({
        msg: `Max number of attachments (${MAX_ATTACHMENTS_ISSUE}) exceeded.`,
        alertType: 'error',
      });
      setAttachmentsToUpload(null);
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > MAX_ATTACHMENT_SIZE) {
        startAlertDispatch({
          msg: `Max filesize (${
            MAX_ATTACHMENT_SIZE / 1024 / 1024
          }mb) exceeded in one or more files.`,
          alertType: 'error',
        });
        setAttachmentsToUpload(null);
        return;
      }
    }
    setAttachmentsToUpload(e.target.files);
  };

  return (
    <>
      <DialogContent
        className={clsx(
          classes.content,
          fullWidth ? classes.fullWidthForm : null
        )}>
        <TextField
          autoFocus={content?.noAutoFocus ? false : true}
          multiline
          minRows={5}
          margin='dense'
          id='name'
          label='Add a note, file or photo'
          type='text'
          fullWidth
          variant='outlined'
          color={color}
          onChange={handleChange}
          value={notes}
        />
      </DialogContent>
      <DialogActions
        className={clsx(
          classes.buttonGroup,
          fullWidth ? classes.fullWidthBtns : null
        )}>
        <input
          className={classes.input}
          id='attachments'
          multiple
          type='file'
          onChange={(e) => handleAttachmentChange(e)}
        />
        <label htmlFor='attachments'>
          <IconButton color={color} aria-label='attach files' component='span'>
            <Badge
              badgeContent={
                attachmentsToUpload ? attachmentsToUpload.length : 0
              }
              color='secondary'
              overlap='circular'>
              <AttachFileIcon />
            </Badge>
          </IconButton>
        </label>
        {fullWidth ? (
          <Button onClick={handleClear} color={color}>
            Clear
          </Button>
        ) : (
          <Button onClick={handleClose} color={color}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleFormSubmit}
          color={color}
          disabled={!enableSubmit}>
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

ModalForm.propTypes = {
  content: PropTypes.object.isRequired,
  updateStartDispatch: PropTypes.func.isRequired,
  startAlertDispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  startAlertDispatch: (alertObj) => dispatch(startAlert(alertObj)),
});

export default connect(null, mapDispatchToProps)(ModalForm);
