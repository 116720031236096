import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Components
import SvgFileIcon from '../svg-file-icon/svg-file-icon.component';

const FileButton = ({ file }) => {
  return (
    <Tooltip title={`${file.filename}`}>
      <IconButton aria-label='file' href={file.url} target='_blank'>
        <SvgFileIcon ext={file.extension} iconSize='large' />
      </IconButton>
    </Tooltip>
  );
};

FileButton.propTypes = {
  file: PropTypes.shape({
    filename: PropTypes.string,
    url: PropTypes.string,
    extension: PropTypes.string,
  }).isRequired,
};

export default FileButton;
