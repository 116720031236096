import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  edit: {
    '&.MuiSvgIcon-root': {
      fill: '#fff',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
  editMode: {
    '&.MuiSvgIcon-root': {
      fill: theme.custom.ganttBlue,
    },
  },
  editButton: {
    display: 'flex',
  },
  saveButton: {
    '&:hover': {
      fill: theme.custom.ganttBlue,
    },
  },
  expandButton: {
    display: (props) => (props.showExpand ? 'flex' : 'none'),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '24px',
    '& .MuiButton-root': {
      color: '#6b6b6b',
    },
  },
  disabled: {
    fill: theme.custom.statusGrey,
    cursor: 'not-allowed',
  },
  header: {
    margin: '40px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0 0 0',
    },
    padding: '6px 8px 6px 16px',
    backgroundColor: theme.palette.primary.darkest,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    '& h1': {
      color: theme.custom.lightestgrey,
      margin: 0,
    },
    '& span': {
      color: theme.custom.lightestgrey,
    },
  },
  controls: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
