import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import Grid from '@material-ui/core/Grid';

// Styles
import useStyles from './table-header.styles';

function TableHeader({
  title,
  children,
  topSpacing = true,
  subheading = false,
}) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <div
        className={clsx(
          classes.header,
          topSpacing ? classes.topSpacing : null
        )}>
        <span>
          <h1>{title}</h1>
          {subheading ? <span>{subheading}</span> : null}
        </span>
        <span>{children}</span>
      </div>
    </Grid>
  );
}

TableHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default TableHeader;
