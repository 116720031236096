import React, { useState } from 'react';

// Material UI
import { useTheme } from '@material-ui/core/styles';
import ExploreIcon from '@material-ui/icons/Explore';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Styles
import useStyles from './legend-bar.styles';

function LegendBar() {
  const [showLegend, setShowLegend] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const handleClick = () => {
    setShowLegend(!showLegend);
  };

  return (
    <div className={classes.root}>
      <>
        {showLegend && (
          <div className={classes.legendWrapper}>
            <div className={classes.attribution}>
              © <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> ©{' '}
              <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>{' '}
              |{' '}
              <a
                href='https://www.mapbox.com/map-feedback/'
                target='_blank'
                rel='noopener noreferrer'>
                Feedback
              </a>
            </div>
            <div className={classes.legend}>
              <span className='complete'>
                {theme.custom.symbols.circleBullet.symbol} Complete
              </span>
              <span className='ongoing'>
                {theme.custom.symbols.circleBullet.symbol} Ongoing
              </span>
              <span className='stopped'>
                {theme.custom.symbols.circleBullet.symbol} Off Track
              </span>
              <span className='notstarted'>
                {theme.custom.symbols.circleBullet.symbol} Not Started
              </span>
            </div>
          </div>
        )}
        <Tooltip title='Toggle Legend'>
          <IconButton onClick={handleClick} className={classes.button}>
            <ExploreIcon />
          </IconButton>
        </Tooltip>
      </>
    </div>
  );
}

export default LegendBar;
