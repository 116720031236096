import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShareIcon from '@material-ui/icons/Share';

// Componnts
import TableColgroup from '../table-colgroup/table-colgroup.component';

// Styles
import useStyles from './milestone-table.styles';

//Utilities
import { formatDateTime, sortAlpha, sortDate } from '../../utils/utilityFns';

function MilestoneTable({ docs, handleToggleModal, currentUserId }) {
  const classes = useStyles();

  const [sortVector, setSortVector] = useState({
    vector: 'name',
    asc: true,
  });

  const handleSort = (vector) => {
    if (sortVector.vector === vector) {
      setSortVector({
        ...sortVector,
        asc: !sortVector.asc,
      });
    } else {
      setSortVector({
        ...sortVector,
        asc: true,
        vector: vector,
      });
    }
  };

  const { vector } = sortVector;
  const isAlpha = vector === 'name' || vector === 'actualStatus' ? true : false;
  const arrow = sortVector.asc ? ' ↑' : ' ↓';
  const direction = sortVector.asc ? 'asc' : 'desc';

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableColgroup cols={[30, 36, 16, 6, 6, 6]} />
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>
              <span
                className={clsx(
                  classes.sortable,
                  sortVector.vector === 'name' ? classes.sortableActive : null
                )}
                onClick={() => handleSort('name')}>
                Milestone
              </span>
              {sortVector.vector === 'name' ? arrow : null}
            </TableCell>
            <TableCell>
              <span
                className={clsx(
                  classes.sortable,
                  sortVector.vector === 'datePlannedStart'
                    ? classes.sortableActive
                    : null
                )}
                onClick={() => handleSort('datePlannedStart')}>
                Dates
              </span>
              {sortVector.vector === 'datePlannedStart' ? arrow : null}
            </TableCell>
            <TableCell>
              <span
                className={clsx(
                  classes.sortable,
                  sortVector.vector === 'actualStatus'
                    ? classes.sortableActive
                    : null
                )}
                onClick={() => handleSort('actualStatus')}>
                Status
              </span>
              {sortVector.vector === 'actualStatus' ? arrow : null}
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>Details</TableCell>
            <TableCell style={{ textAlign: 'center' }}>Share</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {docs
            .sort(
              isAlpha
                ? sortAlpha(vector, direction)
                : sortDate(vector, direction)
            )
            .map((row) => (
              <TableRow key={row._id} className={classes.tableRow}>
                <TableCell>
                  <strong>{row.name}</strong>
                  <br />
                  {row.description}
                </TableCell>
                <TableCell>
                  {row?.hideFromGantt === true ? null : (
                    <>
                      <strong>Scheduled: </strong>

                      {`${formatDateTime(
                        row?.datePlannedStart
                      )} - ${formatDateTime(row?.datePlannedEnd)}`}
                      <br />
                      <strong>Actual: </strong>

                      {`${formatDateTime(
                        row?.dateActualStart
                      )} - ${formatDateTime(row?.dateActualEnd)}`}
                      <br />
                    </>
                  )}
                  {row?.actualStartMessage}
                </TableCell>
                <TableCell>
                  <div
                    className={clsx(
                      classes.status,
                      row.actualStatus.toLowerCase()
                    )}>
                    {row.actualStatus}
                  </div>
                </TableCell>
                <TableCell>
                  <Tooltip title='Details'>
                    <IconButton
                      aria-label='details'
                      onClick={() =>
                        handleToggleModal({
                          modalName: 'sidebarNotes',
                          modalContent: {
                            id: row._id,
                            item: row,
                            currentUserId: currentUserId,
                            doFetch: true,
                            urlFetch: `/api/v1/projects/${row.project}/milestones/${row._id}/getAllMilestoneDetails`,
                          },
                        })
                      }>
                      <Avatar className={classes.photo}>
                        <AssignmentIcon />
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title='Share'>
                    <IconButton aria-label='share'>
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

MilestoneTable.propTypes = {
  docs: PropTypes.array.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
};

export default MilestoneTable;
