import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    width: '30px',
    minWidth: '30px',
    maxWidth: '30px',
    height: '30px',
    minHeight: '30px',
    maxHeight: '30px',
  },
  reCatRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    width: '36px',
    minWidth: '36px',
    maxWidth: '36px',
    height: '22px',
    minHeight: '22px',
    maxHeight: '22px',
    backgroundColor: 'transparent',
  },
  icon: {
    color: '#fff',
    width: '1.1em',
    height: '1.1em',
    fontSize: '1.1rem',
  },
  recatText: {
    color: '#fff',
    fontSize: '0.75rem',
    fontWeight: 900,
    lineHeight: 'normal',
  },
  isOpen: {
    backgroundColor: theme.custom.softGrey,
  },
  isResolved: {
    backgroundColor: theme.palette.success.main,
  },
  isReopened: {
    backgroundColor: theme.palette.success.main,
  },
  isDeleted: {
    backgroundColor: theme.custom.statusRed,
  },
  isRestored: {
    backgroundColor: theme.custom.statusRed,
  },
  isShared: {
    backgroundColor: theme.custom.slateBlue,
  },
  isFilesAdded: {
    backgroundColor: theme.custom.mediumpurple,
  },
  isPhotosAdded: {
    backgroundColor: theme.custom.mediumpurple,
  },
  isEdited: {
    backgroundColor: theme.custom.mediumpink,
  },
  isSubmission: {
    backgroundColor: '#c286e8',
  },
  isSubmissionIssue: {
    backgroundColor: '#c286e8',
  },
  isComment: {
    backgroundColor: theme.palette.primary.main,
  },
  isRecategorized: {
    backgroundColor: theme.custom.issueUncatBg,
  },
  none: {
    backgroundColor: theme.custom.issueUncatBg,
  },
  low: {
    backgroundColor: theme.custom.issueLowBg,
  },
  medium: {
    backgroundColor: theme.custom.issueMediumBg,
  },
  high: {
    backgroundColor: theme.custom.issueHighBg,
  },
  offTrack: {
    backgroundColor: theme.custom.issueOffTrackBg,
  },
}));

export default useStyles;
