import {
  CHECK_USER_SESSION,
  USER_SESSION_FOUND,
  USER_SESSION_NOT_FOUND,
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_IN_START,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_START,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_START,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_PHOTO_START,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_PROFILE_PHOTO_FAILURE,
  UPDATE_DEFAULT_PROJECT_START,
  UPDATE_DEFAULT_PROJECT_SUCCESS,
  UPDATE_DEFAULT_PROJECT_FAILURE,
  UPDATE_PROJECT_LIST_START,
  UPDATE_PROJECT_LIST_SUCCESS,
  UPDATE_PROJECT_LIST_FAILURE,
  SET_LAST_LOGIN_DATE,
} from '../types';

const INITIAL_STATE = {
  currentUser: null,
  loading: true,
  isAuthenticated: false,
  lastLoginDate: 946702800000,
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHECK_USER_SESSION:
    case SIGN_UP_START:
    case SIGN_IN_START:
    case SIGN_OUT_START:
      return {
        ...state,
        currentUser: null,
        loading: true,
        isAuthenticated: false,
      };
    case SET_LAST_LOGIN_DATE:
      return {
        ...state,
        lastLoginDate: payload,
      };
    case USER_SESSION_FOUND:
    case SIGN_UP_SUCCESS:
    case SIGN_IN_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        currentUser: payload,
        loading: false,
        isAuthenticated: true,
      };
    case USER_SESSION_NOT_FOUND:
    case SIGN_UP_FAILURE:
    case SIGN_IN_FAILURE:
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_FAILURE:
      return {
        ...state,
        currentUser: null,
        loading: false,
        isAuthenticated: false,
      };
    case FORGOT_PASSWORD_START:
    case RESET_PASSWORD_START:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: {
          ...state.currentUser,
          firstName: payload.firstName,
          lastName: payload.lastName,
          position: payload.position,
          organization: payload.organization,
          phoneNumber: payload.phoneNumber,
        },
      };
    case UPDATE_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: {
          ...state.currentUser,
          photo: payload.photo,
        },
      };
    case UPDATE_DEFAULT_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: {
          ...state.currentUser,
          defaultProject: payload.defaultProject,
        },
      };
    case UPDATE_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: {
          ...state.currentUser,
          projectList: payload.projectList,
        },
      };
    case FORGOT_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case UPDATE_PASSWORD_START:
    case UPDATE_PASSWORD_SUCCESS:
    case UPDATE_PASSWORD_FAILURE:
    case UPDATE_PROFILE_START:
    case UPDATE_PROFILE_FAILURE:
    case UPDATE_PROFILE_PHOTO_START:
    case UPDATE_PROFILE_PHOTO_FAILURE:
    case UPDATE_DEFAULT_PROJECT_START:
    case UPDATE_DEFAULT_PROJECT_FAILURE:
    case UPDATE_PROJECT_LIST_START:
    case UPDATE_PROJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
