import { takeLatest, takeEvery, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

// Action Types
import {
  CHECK_USER_SESSION,
  SIGN_UP_START,
  SIGN_IN_START,
  SIGN_OUT_START,
  FORGOT_PASSWORD_START,
  RESET_PASSWORD_START,
  UPDATE_PASSWORD_START,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_PHOTO_START,
  UPDATE_DEFAULT_PROJECT_START,
  UPDATE_PROJECT_LIST_START,
} from '../types';

// Actions
import {
  userSessionFound,
  userSessionNotFound,
  signUpFailure,
  signUpSuccess,
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  updatePasswordSuccess,
  updatePasswordFailure,
  updateProfileSuccess,
  updateProfileFailure,
  updateProfilePhotoSuccess,
  updateProfilePhotoFailure,
  updateDefaultProjectSuccess,
  updateDefaultProjectFailure,
  updateProjectlistSuccess,
  updateProjectlistFailure,
  setLastLoginDate,
} from './user.actions';
import { startAlert } from '../alert/alert.actions';

/**
 * Utility Functions
 */
function forwardTo(history, location) {
  history.push(location);
}

/**
 * Workers
 */
export function* checkUserSession() {
  try {
    const res = yield axios.get('/api/v1/users/is-authenticated');
    const today = new Date().getTime();
    yield put(userSessionFound(res.data.data.user));
    yield put(setLastLoginDate(today));
  } catch (err) {
    yield put(userSessionNotFound());
  }
}

export function* signUp({ payload }) {
  try {
    const res = yield axios.post('/api/v1/users/signup', payload);
    yield put(signUpSuccess(res.data.data.user));
    yield put(startAlert({ msg: 'Sign Up Success!', alertType: 'success' }));
  } catch (err) {
    yield put(signUpFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* signIn({ payload }) {
  try {
    const res = yield axios.post('/api/v1/users/signin', payload);
    yield put(signInSuccess(res.data.data.user));
    yield put(startAlert({ msg: 'Sign in Success!', alertType: 'success' }));
  } catch (err) {
    yield put(signInFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* signOut() {
  try {
    yield axios.get('/api/v1/users/signout');
    yield put(signOutSuccess());
    yield put(startAlert({ msg: 'Signed Out!', alertType: 'success' }));
  } catch (err) {
    yield put(signOutFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* forgotPassword({
  payload: {
    email,
    ownProps: { history },
  },
}) {
  try {
    yield axios.post('/api/v1/users/forgot-password', { email });
    yield put(forgotPasswordSuccess());
    yield call(forwardTo, history, '/forgot-password-success');
  } catch (err) {
    yield put(forgotPasswordFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* resetPassword({
  payload: { resetToken, password, passwordConfirm },
}) {
  try {
    const res = yield axios.patch(
      `/api/v1/users/reset-password/${resetToken}`,
      {
        password,
        passwordConfirm,
      }
    );
    yield put(resetPasswordSuccess(res.data.data.user));
    yield put(
      startAlert({
        msg: 'Your password has been successfully changed.',
        alertType: 'success',
      })
    );
  } catch (err) {
    yield put(resetPasswordFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updatePassword({
  payload: { password, newPassword, newPasswordConfirm },
}) {
  try {
    yield axios.patch(`/api/v1/users/updatePassword`, {
      password,
      newPassword,
      newPasswordConfirm,
    });
    yield put(updatePasswordSuccess());
    yield put(
      startAlert({
        msg: 'Your password has been successfully changed.',
        alertType: 'success',
      })
    );
  } catch (err) {
    yield put(updatePasswordFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updateProfile({
  payload: { firstName, lastName, position, organization, phoneNumber },
}) {
  try {
    const res = yield axios.patch(`/api/v1/users/updateMe`, {
      firstName,
      lastName,
      position,
      organization,
      phoneNumber,
    });
    yield put(updateProfileSuccess(res.data.data.user));
    yield put(
      startAlert({
        msg: 'Your profile has been successfully updated.',
        alertType: 'success',
      })
    );
  } catch (err) {
    yield put(updateProfileFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updateProfilePhoto({ payload }) {
  try {
    const res = yield axios.patch(`/api/v1/users/updateMyPhoto`, payload);
    yield put(updateProfilePhotoSuccess(res.data.data.user));
    yield put(
      startAlert({
        msg: 'Your profile photo has been successfully updated.',
        alertType: 'success',
      })
    );
  } catch (err) {
    yield put(updateProfilePhotoFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updateDefaultProject({ payload: { projectId, userId } }) {
  try {
    const res = yield axios.patch(`/api/v1/users/${userId}`, {
      defaultProject: projectId,
    });
    yield put(updateDefaultProjectSuccess(res.data.data.docs));
    yield put(
      startAlert({
        msg: 'Your defaut project has been successfully updated.',
        alertType: 'success',
      })
    );
  } catch (err) {
    yield put(updateDefaultProjectFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

export function* updateProjectList({ payload: { projects, userId } }) {
  try {
    const res = yield axios.patch(`/api/v1/users/${userId}`, {
      projectList: projects,
    });
    yield put(updateProjectlistSuccess(res.data.data.docs));
    yield put(
      startAlert({
        msg: 'Your project list has been successfully updated.',
        alertType: 'success',
      })
    );
  } catch (err) {
    yield put(updateProjectlistFailure());
    yield put(
      startAlert({ msg: err.response.data.message, alertType: 'error' })
    );
  }
}

/**
 * Watchers
 */
export function* onCheckUserSession() {
  yield takeEvery(CHECK_USER_SESSION, checkUserSession);
}

export function* onSignUpStart() {
  yield takeLatest(SIGN_UP_START, signUp);
}

export function* onSignInStart() {
  yield takeLatest(SIGN_IN_START, signIn);
}

export function* onSignOutStart() {
  yield takeLatest(SIGN_OUT_START, signOut);
}

export function* onForgotPasswordStart() {
  yield takeLatest(FORGOT_PASSWORD_START, forgotPassword);
}

export function* onResetPasswordStart() {
  yield takeLatest(RESET_PASSWORD_START, resetPassword);
}

export function* onUpdatePasswordStart() {
  yield takeLatest(UPDATE_PASSWORD_START, updatePassword);
}

export function* onUpdateProfileStart() {
  yield takeLatest(UPDATE_PROFILE_START, updateProfile);
}

export function* onUpdateProfilePhotoStart() {
  yield takeLatest(UPDATE_PROFILE_PHOTO_START, updateProfilePhoto);
}

export function* onUpdateDefaultProjectStart() {
  yield takeLatest(UPDATE_DEFAULT_PROJECT_START, updateDefaultProject);
}

export function* onUpdateProjectListStart() {
  yield takeLatest(UPDATE_PROJECT_LIST_START, updateProjectList);
}

/**
 * Export Saga
 */
export function* userSagas() {
  yield all([
    call(onSignUpStart),
    call(onCheckUserSession),
    call(onSignInStart),
    call(onSignOutStart),
    call(onForgotPasswordStart),
    call(onResetPasswordStart),
    call(onUpdatePasswordStart),
    call(onUpdateProfileStart),
    call(onUpdateProfilePhotoStart),
    call(onUpdateDefaultProjectStart),
    call(onUpdateProjectListStart),
  ]);
}
