import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Styles
import useStyles from './gantt-row-planned.styles';

const GanttRowPlanned = ({
  hidePlannedTimeline,
  el: { plannedTimelineGrid, planStartStr, planEndStr },
  editMode,
}) => {
  const classes = useStyles({
    plannedTimelineGrid,
  });

  return (
    <div
      className={clsx(
        classes.plannedTimelineWrapper,
        hidePlannedTimeline ? classes.hide : null
      )}>
      <div className={clsx(classes.gapStart, editMode ? null : classes.hide)}>
        <span className={clsx(classes.dateStart, classes.date)}>
          {planStartStr}
        </span>
      </div>
      <div className={classes.plannedTimeline}></div>
      <div className={clsx(classes.gapEnd, editMode ? null : classes.hide)}>
        <span className={clsx(classes.dateEnd, classes.date)}>
          {planEndStr}
        </span>
      </div>
    </div>
  );
};

GanttRowPlanned.propTypes = {
  hidePlannedTimeline: PropTypes.bool.isRequired,
  el: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
};

export default GanttRowPlanned;
