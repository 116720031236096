import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';

// Styles
import useStyles from './mentions-paragraph.styles';

const MentionsParagraph = ({ text, mentions, fancy = false }) => {
  const classes = useStyles();

  if (!text || !mentions || mentions.length === 0) return null;

  const handleClick = (type, targetId) => {
    console.log('click', type, targetId);
  };

  const mentionsObj = mentions.reduce(
    (acc, { _id, type, reg, targetId, details }) => ({
      ...acc,
      [reg]: { _id, type, targetId, details },
    }),
    {}
  );

  // Make special tooltip for mentions as separate component <--
  // actually the mention tag is its own component, which we can use here
  // and when we render out a mention update

  // When we get the updates array we may need to propogate a field in the mentions
  // which is derived from isses, milestone, submissions in state, or fire off a useeffect
  // to an endpoint to add one to state and grab it, or preview unavailable

  const sampleTooltipData = {
    site: 'Kadjucu',
    name: 'Preliminary Assessment',
    lastSubmission: '7/31/2024',
    totalSubmissions: 21,
  };

  const mentionsRegexStr = mentions.map((el) => el.reg).join('|');
  const mentionsRegex = new RegExp(String.raw`(${mentionsRegexStr})`, 'g');

  const parts = text.split(mentionsRegex);

  return (
    <span className={classes.root}>
      {parts.map((part, idx) => {
        if (
          part.startsWith('@') ||
          (part.startsWith('#') && mentionsObj.hasOwnProperty(part))
        ) {
          const mentionClass = fancy
            ? mentionsObj[part].type === 'user'
              ? {
                  mention: classes.user,
                  tooltip: classes.userTooltip,
                  arrow: classes.userArrow,
                }
              : mentionsObj[part].type === 'issue'
              ? {
                  mention: classes.issue,
                  tooltip: classes.issueTooltip,
                  arrow: classes.issueArrow,
                }
              : mentionsObj[part].type === 'submission'
              ? {
                  mention: classes.submission,
                  tooltip: classes.submissionTooltip,
                  arrow: classes.submissionArrow,
                }
              : mentionsObj[part].type === 'milestone'
              ? {
                  mention: classes.milestone,
                  tooltip: classes.milestoneTooltip,
                  arrow: classes.milestoneArrow,
                }
              : { mention: null, tooltip: null, arrow: null }
            : {
                mention: classes.default,
                tooltip: classes.defaultTooltip,
                arrow: classes.defaultArrow,
              };

          return (
            <Tooltip
              arrow
              placement='top'
              key={`mention-${idx}`}
              classes={{
                tooltip: clsx(classes.tooltip, mentionClass.tooltip),
                arrow: mentionClass.arrow,
              }}
              title={
                <div className={classes.contentContainer}>
                  <span>
                    <strong>{mentionsObj[part].details.title}</strong>
                  </span>
                  <span>{mentionsObj[part].details.site}</span>
                  <span>{mentionsObj[part].details.date}</span>
                </div>
              }>
              <span
                className={clsx(classes.mention, mentionClass.mention)}
                onClick={() =>
                  handleClick(
                    mentionsObj[part].type,
                    mentionsObj[part].targetId
                  )
                }>
                {part}
              </span>
            </Tooltip>
          );
        } else {
          return <React.Fragment key={`mention-${idx}`}>{part}</React.Fragment>;
        }
      })}
    </span>
  );
};

MentionsParagraph.propTypes = {
  text: PropTypes.string.isRequired,
  mentions: PropTypes.array.isRequired,
  fancy: PropTypes.bool,
};

export default MentionsParagraph;
