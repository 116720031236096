import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Material UI
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MapIcon from '@material-ui/icons/Map';
import ChatIcon from '@material-ui/icons/Chat';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import HistoryIcon from '@material-ui/icons/History';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import FaceIcon from '@material-ui/icons/Face';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import LinkOffOutlinedIcon from '@material-ui/icons/LinkOffOutlined';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

// Components
import FileButton from '../file-button/file-button.component';

// Styles
import useStyles from './update-card.styles';
import UpdateLinks from '../update-links/update-links.component';

const UpdateCard = ({
  actionType,
  categorization = 0,
  date,
  user,
  currentUserId,
  content,
  docId,
  contentId,
  toggleModal,
  handleDeleteUpdate,
  fullWidth = false,
  shareView = false,
  highlighted = false,
  highlightLinks = true,
}) => {
  const classes = useStyles();
  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  };

  let actionIcon;
  let actionTypeClass;

  if (actionType === 'Field Submission') {
    actionIcon = <MapIcon />;
    actionTypeClass = classes.actionTypeFieldSub;
  } else if (actionType === 'Updated') {
    actionIcon = <ChatIcon />;
    actionTypeClass = classes.actionTypeUpdated;
  } else if (actionType === 'Share Link Comment') {
    actionIcon = <FaceIcon />;
    actionTypeClass = classes.actionTypeShareLinkComment;
  } else if (actionType === 'Resolved') {
    actionIcon = <DoneIcon />;
    actionTypeClass = classes.actionTypeResolve;
  } else if (actionType === 'Reopened') {
    actionIcon = <HistoryIcon />;
    actionTypeClass = classes.actionTypeReopened;
  } else if (actionType === 'Dismissed') {
    actionIcon = <DeleteIcon />;
    actionTypeClass = classes.actionTypeDismiss;
  } else if (actionType === 'Deleted') {
    actionIcon = <DeleteIcon />;
    actionTypeClass = classes.actionTypeDeleted;
  } else if (actionType === 'Edited') {
    actionIcon = <EditIcon />;
    actionTypeClass = classes.actionTypeEdited;
  } else if (actionType === 'Started Sharing') {
    actionIcon = <LinkOutlinedIcon />;
    actionTypeClass = classes.actionTypeShared;
  } else if (actionType === 'Stopped Sharing') {
    actionIcon = <LinkOffOutlinedIcon />;
    actionTypeClass = classes.actionTypeShared;
  } else if (actionType === 'Restored') {
    actionIcon = <RestoreFromTrashIcon />;
    actionTypeClass = classes.actionTypeRestored;
  } else if (actionType === 'Recategorized' && categorization === 0) {
    actionIcon = <span>NONE</span>;
    actionTypeClass = classes.actionTypeReCat0;
  } else if (actionType === 'Recategorized' && categorization === 1) {
    actionIcon = <span>LOW</span>;
    actionTypeClass = classes.actionTypeReCat1;
  } else if (actionType === 'Recategorized' && categorization === 2) {
    actionIcon = <span>MED</span>;
    actionTypeClass = classes.actionTypeReCat2;
  } else if (actionType === 'Recategorized' && categorization === 3) {
    actionIcon = <span>HIGH</span>;
    actionTypeClass = classes.actionTypeReCat3;
  } else if (actionType === 'Recategorized' && categorization === 4) {
    actionIcon = (
      <span>
        OFF
        <br />
        TRACK
      </span>
    );
    actionTypeClass = classes.actionTypeReCat4;
  } else {
    actionIcon = null;
    actionTypeClass = null;
  }

  const noteDate = date.toLocaleString('en-US', dateOptions);
  let datetimeArea;
  if (shareView) {
    datetimeArea = <>{noteDate}</>;
  } else {
    datetimeArea = (
      <>
        {noteDate}
        {(user._id === currentUserId && actionType === 'Updated') ||
        actionType === 'Share Link Comment' ? (
          <Tooltip title='Delete'>
            <DeleteForeverIcon
              id={contentId}
              color='action'
              fontSize='small'
              className={classes.editIcons}
              onClick={() =>
                handleDeleteUpdate({ docId, embeddedId: contentId })
              }
            />
          </Tooltip>
        ) : null}
      </>
    );
  }

  const handleSendEmail = () => {
    const subject = 'Reply to your update on Global Build Solution';
    const body = `${noteDate}: ${actionType} on Global Build Solutions.`;

    // Construct the mailto URL
    const mailtoLink = `mailto:${user?.email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Trigger the mailto link
    window.location.href = mailtoLink;
  };

  return (
    <Card
      className={clsx(
        fullWidth ? classes.rootFullWidth : classes.root,
        highlighted ? classes.highlighted : null
      )}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Avatar
            variant='square'
            className={clsx(classes.actionType, actionTypeClass)}>
            {actionIcon}
          </Avatar>
        }
        action={
          <Tooltip
            title={
              <div>
                <div>Click to Email</div>
                <div>{`${user?.firstName} ${user?.lastName}`}</div>
                <div>{`${user?.email}`}</div>
              </div>
            }>
            <Avatar
              aria-label='user'
              className={classes.userAvatar}
              src={user?.photo}
              onClick={handleSendEmail}
            />
          </Tooltip>
        }
        title={`${actionType} by ${user?.firstName?.charAt(0).toUpperCase()}. ${
          user?.lastName
        }`}
        subheader={datetimeArea}
      />
      {content.photos.length > 0 &&
        (actionType !== 'Field Submission' || fullWidth === false) && (
          <>
            <CardMedia
              className={classes.media}
              image={content.photos[0].url}
              title='Photo Gallery'
              onClick={() =>
                toggleModal({
                  modalName: 'photoGallery',
                  modalContent: {
                    photos: content.photos,
                  },
                })
              }
            />
            {content.photos.length > 1 && (
              <div
                className={clsx(
                  classes.photoBadgeContainer,
                  content.hasOwnProperty('notes') && content.notes.length > 0
                    ? null
                    : classes.elevatedPhotoBadge
                )}>
                <div className={classes.photoBadgeLarge}>
                  {`${content.photos.length} photos`}
                </div>
              </div>
            )}
          </>
        )}
      {content.hasOwnProperty('notes') &&
        content.notes.length > 0 &&
        (actionType !== 'Field Submission' || fullWidth === false) && (
          <CardContent
            className={clsx(
              classes.textContent,
              content.photos.length > 0 ? classes.withPhoto : null
            )}>
            <Typography
              variant='subtitle1'
              color='textSecondary'
              component='span'>
              {highlightLinks ? (
                <UpdateLinks notes={content.notes} />
              ) : (
                content.notes
              )}
            </Typography>
          </CardContent>
        )}
      {content.attachments.length > 0 && (
        <List
          dense={true}
          subheader={
            <ListSubheader className={classes.subheader}>
              Attachments
            </ListSubheader>
          }>
          {content.attachments.map((el) => (
            <ListItem key={el._id} className={classes.listItem}>
              <ListItemText
                primary={el.filename}
                secondary={el.filesize ? el.filesize : 'Unknown size.'}
              />
              <ListItemSecondaryAction>
                <FileButton file={el} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Card>
  );
};

UpdateCard.propTypes = {
  actionType: PropTypes.oneOf([
    'Field Submission',
    'Recategorized',
    'Updated',
    'Dismissed',
    'Resolved',
    'Reopened',
    'Restored',
    'Edited',
    'Deleted',
    'Started Sharing',
    'Stopped Sharing',
    'Share Link Comment',
  ]).isRequired,
  date: PropTypes.instanceOf(Date),
  user: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  docId: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleDeleteUpdate: PropTypes.func.isRequired,
  categorization: PropTypes.oneOf([0, 1, 2, 3, 4]),
  fullWidth: PropTypes.bool,
  shareView: PropTypes.bool,
};

export default UpdateCard;
